import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import AccountVerification from "src/components/molecules/dform/AccountVerification";

const VerifyWithAccount = ({ data, stepsCMS }) => {
  const accountList = useSelector((state) => state.settings.data);
  return (
    <div>
      {accountList ? (
        <AccountVerification data={data} stepsCMS={stepsCMS} />
      ) : (
        <div className="py-6 flex items-center justify-center">
          <CircularProgress sx={{ color: "#A31D1A" }} />
        </div>
      )}
    </div>
  );
};

export default VerifyWithAccount;
