/* eslint-disable */
import FilterField from "src/components/molecules/dform/FilterField";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LegalInfo = ({ data, step_id }) => {
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;
  const [currentHelperText, setCurrentHelperText] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect?.top <= 400 && rect?.bottom >= 10) {
          const key = section?.getAttribute("data-key");
          const disable = section?.getAttribute("data-disable");
          if (!disable) {
            currentSection = { key, position: rect.top };
          }
        }
      });

      setCurrentHelperText(currentSection.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="py-5 flex">
        <div className="w-full flex">
          {data?.map((fdata, i) => {
            const showCard = currentHelperText === fdata.id || (currentHelperText === "" && i === 0);
            return (
              <div className={`flex evidence-section w-full gap-4`} data-key={fdata.id} data-disable={fdata?.helperTextDisable} key={i}>
                <div className={`flex-1 transition-all duration-100 ${showCard && "relative"}`}>
                  <FilterField data={fdata} showCard={showCard} page={"dform"} aid={step_id} helperText={stepsCMS[fdata?.id] && stepsCMS[fdata?.id]?.helpdesk} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LegalInfo;
