import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React from "react";
import TableHeader from "src/components/atoms/common/TableHeader";
import Paper from "@mui/material/Paper";
import "./table.css";
import { BsEye } from "react-icons/bs";
import editOn from "src/assets/img/edit-on.png";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { addApplicationStepSuccess, setApplicationSidebar } from "src/store/actions/application";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { parseDateFormat, statusRenderGerman } from "src/helpers/document";
import useSortableData from "src/hooks/useSortableData";

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
`;

const OverviewTable = ({ title, tableHead, tableBody, css }) => {
  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useSortableData(tableBody);
  const queryParams = new URLSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const completedHandle = (item) => {
    navigate("/verfahren/antrag-eingereicht/" + item.service_order_type + "/" + item.procedure_form_id);
    dispatch(setApplicationSidebar());
  };
  const pendingHandle = (item) => {
    Cookies.set("submitted_id", item?.procedure_form_id);
    dispatch(addApplicationStepSuccess({ user_id: Cookies.get("userId"), submitted_id: item?.procedure_form_id }));
    let step = item?.step_id.split(",");
    queryParams.set("active", step.length - 1);
    const query = queryParams.toString();
    setTimeout(() => {
      dispatch(setApplicationSidebar());
      navigate("/verfahren/antrag-bearbeiten/" + item?.service_order_type + "?" + query);
    }, 300);
  };

  const handleNavigateEvidence = (item) => {
    const path = `/nachweise-hochladen-ausstehend/${item.service_order_type}/${item.service_order_nbr}`;
    navigate(path);
  };

  return (
    <div className={`w-full ${css}`}>
      <h2 className="!mb-5 text-2xl font-tahoma-bold sm:mb-4 md:mb-4 flex items-center gap-3">{title}</h2>
      <TableContainer component={Paper} style={{ borderRadius: "12px", background: "#fff" }}>
        <Table aria-label="simple table">
          <TableHeader tableData={tableHead} increaseSort={requestSortIncrease} decreaseSort={requestSortDecrease} />
          <TableBody>
            {sortedData.length > 0 ? (
              <>
                {sortedData.map((item, index) => {
                  return (
                    <TableRow key={`${item.id}-${index}`} className={item?.isActive ? "active-row" : ""}>
                      <StyledTableCell align="left" style={{ paddingLeft: "40px" }}>
                        <Typography>{item?.service_order_type}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className="italic">{item?.service_order_nbr ? item?.service_order_nbr : "Entwurf"}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">{parseDateFormat(item?.scheduled_end_date)}</StyledTableCell>
                      <StyledTableCell align="left">{item?.description}</StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.proceduralStatus ? (
                          item?.proceduralStatus === "Nachweiserbringung erforderlich" || item?.proceduralStatus === "Nachweisprüfung erforderlich" ? (
                            <button className="p-2 border rounded-xl border-SmokeyGray" onClick={() => handleNavigateEvidence(item)}>
                              {item?.proceduralStatus}
                            </button>
                          ) : (
                            item?.proceduralStatus
                          )
                        ) : (
                          statusRenderGerman(item.statusDisplayName ?? item.status)
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div className="flex gap-5">
                          {item?.status === "Open" ||
                            (item?.status === "completed" && (
                              <BsEye
                                className="text-AFPPrimary cursor-pointer"
                                onClick={() => {
                                  completedHandle(item);
                                }}
                              />
                            ))}
                          {item?.status === "pending" && <img className="cursor-pointer" src={editOn} alt="editOn" onClick={() => pendingHandle(item)} />}
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <h5 className="text-red-400">Noch keine Verfahren</h5>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OverviewTable;
