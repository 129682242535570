/* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { addApplicationStepStarted, createCompleteApplicationStarted } from "src/store/actions/application";
import { useEffect, useState } from "react";
import StepsFormFinished from "src/components/molecules/dform/StepsForm/StepsFormFinished";
import { ServiceOrderPayloadModify } from "src/helpers/dform";

const DynamicFormStepsFinished = ({ activeStep, stepChangeHandle, id, step_id, procedure_id }) => {
  const application = useSelector((state) => state.application);
  const currentStep = useSelector((state) => state.currentStep);
  const { data, addingStep } = application;
  const [closeForm, setCloseForm] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));

  const saveHandle = () => {
    setCloseForm(true);
    saveFormData(false);
  };

  const saveFormData = (addNewService) => {
    dispatch(
      addApplicationStepStarted({
        procedure_data: [{ ...data, [currentStep.stepID]: currentStep.data }],
        step_id: step_id,
        service_order_types_id: id,
        procedure_form_id: procedure_id,
        addNewService,
      })
    );
  };

  const prevStepHandle = () => {
    if (!addingStep.isLoading) {
      activeStep !== 0 ? stepChangeHandle(activeStep - 1) : navigate("/verfahren/uebersicht");
    }
  };

  const nextStepHandle = () => {
    if (!addingStep.isLoading) {
      if (activeStep === 0) {
        if (currentStep?.data[0]?.inputTypeValue === true) {
          saveFormData(false);
        } else {
          toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
        }
      } else {
        saveFormData(false);
      }
    }
  };

  useEffect(() => {
    if (closeForm) {
      if (addingStep.isLoading === false && addingStep.status === true) {
        setTimeout(() => {
          navigate("/");
        }, 400);
      }
    }
    if (addingStep.isLoading === false && addingStep.status === true) {
      stepChangeHandle(activeStep + 1);
    }
  }, [addingStep]);

  const createServiceOrderSubmit = () => {
    let findCheck = currentStep?.data?.filter((step) => {
      if (step?.inputType == "signaturedropdown") {
        if (step?.inputRequired && Array.isArray(step?.inputTypeValue) ? step?.inputTypeValue.length > 0 : step?.inputTypeValue) {
          console.log("");
        } else {
          return step;
        }
      }
    });
    if (findCheck?.length > 0) {
      toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
    } else {
      let ownerFind = currentStep?.data.find((stepvalue) => stepvalue.id === "05_SIGNATURE_OWNER");
      let owner_id = id == "EPQ" ? (ownerFind ? ownerFind.inputTypeValue : "") : locationValidation?.existing_owner_id;
      if (id == "IWA") {
        dispatch(
          createCompleteApplicationStarted({
            ...ServiceOrderPayloadModify({
              procedure_data: [{ ...application.data, [currentStep.stepID]: currentStep.data }],
              step_id: step_id,
              service_order_types_id: id,
              procedure_form_id: procedure_id,
              owner_id,
            }),
            existing_owner_id: locationValidation?.existing_owner_id,
          })
        );
      } else {
        dispatch(
          createCompleteApplicationStarted(
            ServiceOrderPayloadModify({
              procedure_data: [{ ...application.data, [currentStep.stepID]: currentStep.data }],
              step_id: step_id,
              service_order_types_id: id,
              procedure_form_id: procedure_id,
              owner_id,
            })
          )
        );
      }
    }
  };

  return (
    <>
      <div className="pl-4 md:pl-8 lg:pl-16 pr-2 md:pr-3 lg:pr-5">
        <div className="text-black relative md:pt-[4.5rem] py-4 mx-3 sm:mx-4 md:mx-8">
          <div className="text-black  w-full">
            <StepsFormFinished activeStep={activeStep} data={data} step_id={step_id} stepID={currentStep.stepID} createServiceOrderSubmit={createServiceOrderSubmit} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicFormStepsFinished;
