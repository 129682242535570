import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const ToggleArrow = ({ id, selectedMenu }) => {
  if (selectedMenu.filter((menu) => menu.id == id)?.length > 0) {
    return <IoIosArrowUp />;
  } else {
    return <IoIosArrowDown />;
  }
};

export default ToggleArrow;
