export const overViewDataHeader = (head) => {
  return [
    {
      id: 1,
      title: head?.procedure_type,
      key: ["service_order_type"],
      icons: true,
      sortKey: "service_order_type",
    },
    {
      id: 2,
      title: head?.order_number,
      key: ["service_order_nbr"],
      icons: true,
      sortKey: "service_order_nbr",
    },
    {
      id: 3,
      title: head?.due_date,
      key: ["scheduled_end_date"],
      icons: true,
      sortKey: "scheduled_end_date",
    },
    {
      id: 4,
      title: head?.notes,
      key: ["description"],
      icons: true,
      sortKey: "description",
      width:266
    },
    {
      id: 5,
      title: head?.status,
      key: ["status"],
      icons: true,
      sortKey: "status",
      width:266
    },
    {
      id: 6,
      title: head?.actions,
      key: ["business"],
      icons: false,
      sortKey: "business",
    },
  ];
};

export const pastDataHeader = (head) => {
  return [
    {
      id: 1,
      title: head.location,
      key: ["company_name"],
      icons: true,
      sortKey: "company_name",
    },
    {
      id: 2,
      title: head.file_number,
      key: ["service_order_nbr"],
      icons: true,
      sortKey: "service_order_nbr",
    },
    {
      id: 3,
      title: head.application_type,
      key: ["service_order_type"],
      icons: true,
      sortKey: "service_order_type",
    },
    {
      id: 4,
      title: head.issue_date,
      key: ["scheduled_end_date"],
      icons: true,
      sortKey: "scheduled_end_date",
    },
    {
      id: 5,
      title: head.documents,
      key: ["business"],
      icons: false,
      sortKey: "business",
    },
    {
      id: 6,
      title: head.status,
      key: ["business"],
      icons: false,
      sortKey: "business",
    },
  ];
};

