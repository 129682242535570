import {
  LIST_FROM_TO_YOU_STARTED,
  DOCUMENT_LIST_FAILURE,
  LIST_FROM_TO_YOU_SUCCESS,
  LIST_USER_FILES_FAILURE,
  LIST_USER_FILES_SUCCESS,
  LIST_USER_FILES_STARTED,
  LIST_DOCUMENT_CATEGORY_STARTED,
  LIST_DOCUMENT_CATEGORY_SUCCESS,
  LIST_DOCUMENT_CATEGORY_FAILURE,
  UPLOAD_DOCUMENT_STARTED,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  UPLOAD_DMS_DOCUMENT_STARTED,
  UPLOAD_DMS_DOCUMENT_SUCCESS,
  UPLOAD_DMS_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_BASE64_FAILURE,
  UPLOAD_DOCUMENT_BASE64_SUCCESS,
  UPLOAD_DOCUMENT_BASE64_STARTED,
  REMOVE_USER_FILES_DOCUMENT_BY_ID,
} from "../actionTypes";

// export const addDocumentbyId = (payload) => {
//   return {
//     type: ADD_DOCUMENT_BY_ID,
//     payload,
//   };
// };

export const listFromToYou = (payload) => {
  return {
    type: LIST_FROM_TO_YOU_STARTED,
    payload
  };
};

export const listFromToYouSuccess = (payload) => {
  return {
    type: LIST_FROM_TO_YOU_SUCCESS,
    payload,
  };
};

export const documentListFailure = (payload) => {
  return {
    type: DOCUMENT_LIST_FAILURE,
    payload,
  };
};

export const listUserFiles = () => {
  return {
    type: LIST_USER_FILES_STARTED,
  };
};

export const listUserFilesSuccess = (payload) => {
  return {
    type: LIST_USER_FILES_SUCCESS,
    payload,
  };
};

export const listUserFilesFailure = (payload) => {
  return {
    type: LIST_USER_FILES_FAILURE,
    payload,
  };
};

export const removeUserFilesDocumentById = (payload) => {
  return {
    type: REMOVE_USER_FILES_DOCUMENT_BY_ID,
    payload,
  };
};



export const listDocumentCategory = () => {
  return {
    type: LIST_DOCUMENT_CATEGORY_STARTED,
  };
};

export const listDocumentCategorySuccess = (payload) => {
  return {
    type: LIST_DOCUMENT_CATEGORY_SUCCESS,
    payload,
  };
};

export const listDocumentCategoryFailure = (payload) => {
  return {
    type: LIST_DOCUMENT_CATEGORY_FAILURE,
    payload,
  };
};

/**  upload files(image, pdf, doc)  */
export const uploadDocumentStarted = (payload) => {
  return {
    type: UPLOAD_DOCUMENT_STARTED,
    payload,
  };
};

export const uploadDocumentSuccess = (payload) => {
  return {
    type: UPLOAD_DOCUMENT_SUCCESS,
    payload,
  };
};
export const uploadDocumentFailure = (payload) => {
  return {
    type: UPLOAD_DOCUMENT_FAILURE,
    payload,
  };
};
/**  upload files base 64(image, pdf, doc)  */
export const uploadDocumentBase64Started = (payload) => {
  return {
    type: UPLOAD_DOCUMENT_BASE64_STARTED,
    payload,
  };
};

export const uploadDocumentBase64Success = (payload) => {
  return {
    type: UPLOAD_DOCUMENT_BASE64_SUCCESS,
    payload,
  };
};
export const uploadDocumentBase64Failure = (payload) => {
  return {
    type: UPLOAD_DOCUMENT_BASE64_FAILURE,
    payload,
  };
};

/**  upload files(image, pdf, doc) dms  */
export const uploadDMSDocumentStarted = (payload) => {
  return {
    type: UPLOAD_DMS_DOCUMENT_STARTED,
    payload,
  };
};

export const uploadDMSDocumentSuccess = (payload) => {
  return {
    type: UPLOAD_DMS_DOCUMENT_SUCCESS,
    payload,
  };
};
export const uploadDMSDocumentFailure = (payload) => {
  return {
    type: UPLOAD_DMS_DOCUMENT_FAILURE,
    payload,
  };
};
