import { getApiCall, putApiCall } from "src/services/utils/axios";
import { BASE_URL, API } from "src/services/utils/defines";

export const getProfile = async (paramsData) => {
  const url = `${BASE_URL}${API.userDetails}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const putProfile = async (payload) => {
  const url = `${BASE_URL}${API.updateDetails}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await putApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message || "Failed to fetch profile");
  }
};
