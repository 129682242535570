import { PROCEDURE_TEMPLATES, SET_PROCEDURE_TEMPLATES, PROCEDURE_FAILURE } from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case PROCEDURE_TEMPLATES: {
      return { ...state, isLoading: true, error: null };
    }
    case SET_PROCEDURE_TEMPLATES: {
      return { ...state, data: action.payload, isLoading: false, error: null };
    }
    case PROCEDURE_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
