/* eslint-disable */
export const ServiceOrderPayloadModify = (payload) => {
  const owner_id = payload?.owner_id;
  let user_id = payload.user_id;
  let service_order_type_id = payload.service_order_types_id;
  let procedure_id = payload.procedure_form_id;

  switch (service_order_type_id) {
    case "EPQ":
      let service_areas = [];
      let formDetail = payload.procedure_data[0];
      let location_id = formDetail["02_FIRMA"][1]["inputTypeValue"];
      let technical_leader_ids = formDetail["02_FIRMA"][2]["inputTypeValue"];

      let service_areas_details = formDetail["03_VERSORGUNGSBEREICHE"][0]["inputTypeValue"];

      for (const area of service_areas_details) {
        const techLeaders = (area.selectedTechnicalLeaders || []).map((leader) => leader.id.toString());
        const service_area = area.selectedArea;
        service_areas.push({
          service_area,
          technical_leader_ids: techLeaders,
        });
      }

      return { location_id, user_id, service_order_type_id, technical_leader_ids, service_areas, procedure_id, owner_id };
    case "REPQ":
      let service_areasrepq = [];
      let formDetailrepq = payload.procedure_data[0];
      let location_idrepq = formDetailrepq["02_FIRMA"][1]["inputTypeValue"];
      let technical_leader_idsrepq = formDetailrepq["02_FIRMA"][2]["inputTypeValue"];

      let service_areas_detailsrepq = formDetailrepq["03_VERSORGUNGSBEREICHE"][0]["inputTypeValue"];

      for (const area of service_areas_detailsrepq) {
        const techLeaders = (area.selectedTechnicalLeaders || []).map((leader) => leader.id.toString());
        const service_area = area.selectedArea;
        service_areasrepq.push({
          service_area,
          technical_leader_ids: techLeaders,
        });
      }

      return {
        location_id: location_idrepq,
        user_id,
        service_order_type_id,
        technical_leader_ids: technical_leader_idsrepq,
        service_areas: service_areasrepq,
        procedure_id,
        owner_id,
      };

    case "KA":
      let formDetailka = payload.procedure_data[0];
      let location_idka = formDetailka["02_FIRMA"][1]["inputTypeValue"];
      // let technical_leader_idka = formDetailka["02_FIRMA"][2]["inputTypeValue"];
      return { location_id: location_idka, user_id, service_order_type_id, technical_leader_ids: [], procedure_id, service_areas: [], owner_id };

    case "AA":
      let service_areasaa = [];
      let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));
      let formDetailaa = payload.procedure_data[0];
      let location_idaa = formDetailaa["02_FIRMA"][1]["inputTypeValue"];
      let technical_leader_idaa = locationValidation?.exsiting_team_leaders;
      let service_areas_detailaa = formDetailaa["03_VERSORGUNGSBEREICHE"][0]["inputTypeValue"];
      for (const area of service_areas_detailaa) {
        const techLeaders = (area.selectedTechnicalLeaders || []).map((leader) => leader.id.toString());
        const service_area = area.selectedArea;
        service_areasaa.push({
          service_area,
          technical_leader_ids: techLeaders,
        });
      }
      return { location_id: location_idaa, user_id, service_order_type_id, technical_leader_ids: technical_leader_idaa, procedure_id, service_areas: service_areasaa, owner_id };

    case "SA":
      let formDetailsa = payload.procedure_data[0];
      let location_idsa = formDetailsa["02_FIRMA"][1]["inputTypeValue"];
      // let technical_leader_idka = formDetailsa["02_FIRMA"][2]["inputTypeValue"];
      return { location_id: location_idsa, user_id, service_order_type_id, technical_leader_ids: [], procedure_id, service_areas: [], owner_id };

    case "IWA":
      let service_areasiwa = [];
      let formDetailiwa = payload.procedure_data[0];
      let location_idiwa = formDetailiwa["02_FIRMA"][1]["inputTypeValue"];
      let technical_leader_idiwa = formDetailiwa["02_FIRMA"][2]["inputTypeValue"];
      let service_areas_detailsiwa = formDetailiwa["03_VERSORGUNGSBEREICHE"][0]["inputTypeValue"];

      for (const area of service_areas_detailsiwa) {
        const techLeaders = (area.selectedTechnicalLeaders || []).map((leader) => leader?.id?.toString());
        const service_area = area.selectedArea;
        service_areasiwa.push({
          service_area,
          technical_leader_ids: techLeaders,
        });
      }
      return { location_id: location_idiwa, user_id, service_order_type_id, technical_leader_ids: technical_leader_idiwa, procedure_id, service_areas: service_areasiwa, owner_id };

    case "UA":
      let locationValidationua = JSON.parse(localStorage.getItem("locationValidation"));
      let service_areasua =
        locationValidationua?.exsiting_service_areas?.map((area) => {
          let { team_leader_ids, ...rest } = area;
          return { ...rest, technical_leader_ids: team_leader_ids };
        }) || [];
      let formDetailua = payload.procedure_data[0];
      let location_idua = formDetailua["02_FIRMA"][1]["inputTypeValue"];
      let technical_leader_idua = locationValidationua?.exsiting_team_leaders || [];
      // let service_areas_detailsua = formDetailua["03_VERSORGUNGSBEREICHE"][2]["inputTypeValue"];

      // for (const area of service_areas_detailsua) {
      //   const techLeaders = (area.selectedTechnicalLeaders || []).map((leader) => leader.id.toString());
      //   const service_area = area.selectedArea;
      //   service_areasua.push({
      //     service_area,
      //     technical_leader_ids: techLeaders,
      //   });
      // }
      return {
        location_id: location_idua,
        user_id,
        service_order_type_id,
        technical_leader_ids: technical_leader_idua,
        procedure_id,
        service_areas: service_areasua || [],
        owner_id,
      };

    default:
      return {};
  }
};

export const sendSignaturePayloadModify = (payload) => {
  let user_id = payload.user_id;
  let service_order_type_id = payload.service_order_types_id;
  let procedure_id = payload.procedure_form_id;
  let signatures =
    Object.entries(payload.signatures).length > 0 ? Object.entries(payload.signatures).map(([key, value]) => ({ contact_id: parseInt(key), signature_image: value })) : [];

  switch (service_order_type_id) {
    case "EPQ":
      let formDetail = payload.procedure_data[0];
      let technical_leader_ids = [...formDetail["02_FIRMA"][2]["inputTypeValue"], parseInt(payload?.inhaber_id)];
      return { user_id, procedure_id, contact_id: technical_leader_ids, base_url: window.location.origin + "/unterschreiben", signatures };
    case "REPQ":
      let formDetailrepq = payload.procedure_data[0];
      let technical_leader_idsrepq = [...formDetailrepq["02_FIRMA"][2]["inputTypeValue"], parseInt(payload?.inhaber_id)];
      return { user_id, procedure_id, contact_id: technical_leader_idsrepq, base_url: window.location.origin + "/unterschreiben", signatures };
    case "UA":
      let formDetailua = payload.procedure_data[0];
      let technical_leader_idsua = [parseInt(payload?.inhaber_id)];
      // let technical_leader_idsua = [...formDetailua["02_FIRMA"][2]["inputTypeValue"], parseInt(payload?.inhaber_id)];
      return { user_id, procedure_id, contact_id: technical_leader_idsua, base_url: window.location.origin + "/unterschreiben", signatures };
    case "AA":
      let formDetailaa = payload.procedure_data[0];
      let technical_leader_idsaa = [parseInt(payload?.inhaber_id)];
      // let technical_leader_idsaa = [...formDetailua["02_FIRMA"][2]["inputTypeValue"], parseInt(payload?.inhaber_id)];
      return { user_id, procedure_id, contact_id: technical_leader_idsaa, base_url: window.location.origin + "/unterschreiben", signatures };
    case "IWA":
      let formDetailiwa = payload.procedure_data[0];
      let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));
      let newFilter = formDetailiwa["02_FIRMA"][2]["inputTypeValue"].filter((value) => !locationValidation?.exsiting_team_leaders?.includes(value));
      let technical_leader_idsiwa = [...newFilter, parseInt(payload?.inhaber_id)];
      return { user_id, procedure_id, contact_id: technical_leader_idsiwa, base_url: window.location.origin + "/unterschreiben", signatures };
    case "KA":
      let formDetails = payload.procedure_data[0];
      let technical_leader_id = [parseInt(payload?.inhaber_id)];
      return { user_id, procedure_id, contact_id: technical_leader_id, base_url: window.location.origin + "/unterschreiben", signatures };
    case "SA":
      let formDetailssa = payload.procedure_data[0];
      let technical_leader_idsa = [parseInt(payload?.inhaber_id)];
      return { user_id, procedure_id, contact_id: technical_leader_idsa, base_url: window.location.origin + "/unterschreiben", signatures };
    default:
      return {};
  }
};

export const setShowDifferntNameModify = (payload) => {
  const service_order_types_id = payload.service_order_types_id;
  let formDetail = payload.procedure_data[0];
  const techLeaders = formDetail["02_FIRMA"][2] && [...formDetail["02_FIRMA"][2]["inputTypeValue"]];
  const inhaber = payload.inhaber_id;
  const contactSignature = payload.contactSignature;
  switch (service_order_types_id) {
    case "EPQ":
      if (inhaber) {
        if ([...techLeaders, inhaber]?.length == Object.keys(contactSignature)?.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    case "REPQ":
      if (inhaber) {
        if ([...techLeaders, inhaber]?.length == Object.keys(contactSignature)?.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    case "UA":
      if (inhaber) {
        if ([inhaber]?.length == Object.keys(contactSignature)?.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    case "AA":
      if (inhaber) {
        if ([inhaber]?.length == Object.keys(contactSignature)?.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }

    case "IWA":
      if (inhaber) {
        let newLocationLength = JSON.parse(localStorage.getItem("locationValidation"))?.exsiting_team_leaders?.length;
        if ([...techLeaders, inhaber]?.length - newLocationLength == Object.keys(contactSignature)?.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    case "KA":
      if (inhaber) {
        if ([inhaber]?.length == Object.keys(contactSignature)?.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }

    case "SA":
      if (inhaber) {
        if ([inhaber]?.length == Object.keys(contactSignature)?.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }

    default:
      return false;
  }
};
