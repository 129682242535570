/* eslint-disable */
import { InputStandardField } from "src/components/atoms/input";
import style from "./paymentSetting.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PiWarningFill } from "react-icons/pi";
import CommonSelect from "src/components/atoms/select/CommonSelect";
import { PrimaryButton } from "src/components/atoms/button";
import PaymentUserModal from "src/components/atoms/modal/PaymentUserModal";
import { createdResetUserAccount } from "src/store/actions/userSettings";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";

const AccountVerification = ({ data, stepsCMS }) => {
  const accountList = useSelector((state) => state.settings);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const accountListOption = accountList.data;
  const [open, setOpen] = useState(false);

  const [accountLists, setAccountLists] = useState(accountListOption);

  const cmsData = cmsDashboarddata?.settings;
  const settings = useSelector((state) => state.settings);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      accountHolder: "",
      iban: "",
      bic: "",
      creditInstitute: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const handleChangeAccount = (event) => {
    const selectedAccountId = event?.target?.value;
    const selectedAccountData = accountListOption?.find((option) => option?.id === selectedAccountId);
    setAccountLists(selectedAccountData);
    setAccoundToFormikNStore(selectedAccountData);
  };

  useEffect(() => {
    if (accountLists && accountLists?.length > 0) {
      const firstAccount = accountLists?.find((account) => account?.is_default == true);
      if (firstAccount) {
        setAccoundToFormikNStore(firstAccount);
      } else {
        setAccoundToFormikNStore(accountLists[0]);
      }
    }
  }, [accountLists]);

  const setAccoundToFormikNStore = (firstAccount) => {
    formik.setValues({
      accountHolder: firstAccount?.account_holder ?? "",
      iban: firstAccount?.iban ?? "",
      bic: firstAccount?.bic ?? "",
      creditInstitute: firstAccount?.credit_institute ?? "",
    });
    dispatch(
      addCurrentStepDetailValue({
        valueId: data?.id,
        value: firstAccount,
        valueKey: "inputPaymentValue",
      })
    );
  };

  useEffect(() => {
    settings?.newUser?.created && (setOpen(false), dispatch(createdResetUserAccount()));
  }, [settings?.newUser]);

  return (
    <>
      <div className="!text-SmokeyGray">
        {(!accountList?.data || accountList?.data?.length <= 0) && (
          <div className="flex gap-x-3 mt-4 ">
            <PiWarningFill fontSize={20} className="text-AFPPrimary" /> <p className="text-AFPPrimary">Keine Zahlungsinformationen verfügbar</p>
          </div>
        )}

        <h1 className="!mb-5 text-xl font-tahoma-bold text-black mt-10">{stepsCMS[data?.id]?.title}</h1>
        <div className="justify-start gap-12 flex flex-col md:flex-row">
          <p className="textStyle max-w-[640px] mb-5">{stepsCMS[data?.id]?.description}</p>
        </div>

        <div className=" flex justify-between py-2 gap-x-4  max-w-[680px]">
          <div className="flex flex-1 ">
            {accountList?.data?.length > 0 ? (
              <CommonSelect handle={handleChangeAccount} selectedOption={accountLists} dataList={accountListOption} />
            ) : (
              <h2 className="font-tahoma-bold md:text-sm lg:text-lg  text-red-300 mb-6">Keine Zahlungsadressen</h2>
            )}
          </div>
          <PrimaryButton btnStyle="max-w-[50%]" title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.create_new} handler={handleOpen} />
        </div>

        <div className="py-8 w-full">
          <div className={style.formLayout}>
            <InputStandardField
              label={cmsData?.pay_bank?.account_holder}
              placeholder={cmsData?.pay_bank?.account_holder}
              name="accountHolder"
              id="accountHolder"
              formik={formik}
              disabled={true}
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <InputStandardField
              label={cmsData?.pay_bank?.iban}
              placeholder={cmsData?.pay_bank?.iban}
              name="iban"
              id="iban"
              formik={formik}
              disabled={true}
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <InputStandardField
              label={cmsData?.pay_bank?.bic}
              placeholder={cmsData?.pay_bank?.bic}
              name="bic"
              id="bic"
              formik={formik}
              disabled={true}
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <InputStandardField
              label={cmsData?.pay_bank?.credit_institution}
              placeholder={cmsData?.pay_bank?.credit_institution}
              name="creditInstitute"
              id="creditInstitute"
              formik={formik}
              disabled={true}
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
          </div>
        </div>
      </div>
      {open && <PaymentUserModal open={open} handleClose={handleClose} />}
    </>
  );
};

export default AccountVerification;
