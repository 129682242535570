import {
  GET_HELPDESK_ARTICLE_CONTENT_FAILURE,
  GET_HELPDESK_ARTICLE_CONTENT_STARTED,
  GET_HELPDESK_ARTICLE_CONTENT_SUCCESS,
  GET_HELPDESK_ARTICLE_DETAIL_FAILURE,
  GET_HELPDESK_ARTICLE_DETAIL_STARTED,
  GET_HELPDESK_ARTICLE_DETAIL_SUCCESS,
  GET_HELPDESK_ARTICLE_LIST_FAILURE,
  GET_HELPDESK_ARTICLE_LIST_STARTED,
  GET_HELPDESK_ARTICLE_LIST_SUCCESS,
} from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  articleDetail: {
    data: null,
    isLoading: false,
    error: null,
  },
  articleContent: {
    data: {},
    isLoading: false,
    error: null,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_HELPDESK_ARTICLE_LIST_STARTED: {
      return { ...state, isLoading: true };
    }
    case GET_HELPDESK_ARTICLE_LIST_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case GET_HELPDESK_ARTICLE_LIST_FAILURE: {
      return { ...state, isLoading: false, data: null };
    }
    case GET_HELPDESK_ARTICLE_DETAIL_STARTED: {
      return { ...state, articleDetail: { ...state?.articleDetail, isLoading: true } };
    }
    case GET_HELPDESK_ARTICLE_DETAIL_SUCCESS: {
      return { ...state, articleDetail: { ...state?.articleDetail, data: action.payload, isLoading: false } };
    }
    case GET_HELPDESK_ARTICLE_DETAIL_FAILURE: {
      return { ...state, articleDetail: { ...state?.articleDetail, isLoading: false, data: null } };
    }

    case GET_HELPDESK_ARTICLE_CONTENT_STARTED: {
      return { ...state, articleContent: { ...state?.articleContent, isLoading: true } };
    }
    case GET_HELPDESK_ARTICLE_CONTENT_SUCCESS: {
      if (state?.articleContent?.data[action?.payload?.crisp_id]) {
        let newContentData = state.articleContent?.data;
        newContentData[action.payload.crisp_id] = action.payload.data;
        return { ...state, articleContent: { ...state?.articleContent, data: newContentData, isLoading: false } };
      } else {
        let newContentData = state.articleContent?.data;
        newContentData[action.payload.crisp_id] = action.payload.data;
        return { ...state, articleContent: { ...state?.articleContent, data: newContentData, isLoading: false } };
      }
    }
    case GET_HELPDESK_ARTICLE_CONTENT_FAILURE: {
      return { ...state, articleContent: { ...state?.articleContent, isLoading: false, data: null } };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
