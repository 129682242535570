import TextField from "@mui/material/TextField";

const InputField = ({ name, label, type, css, formik, variant, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      className={css}
      id={name}
      name={name}
      label={label}
      type={type}
      value={formik.values[name]}
      onChange={formik.handleChange}
      variant={variant}
      {...formik.getFieldProps(name)}
      error={formik.touched[name] && formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{
        style: {
          color: "#000",
          fontWeight: "600",
        },
      }}
      inputProps={{
        style: {
          borderColor: "#00f",
          color: "#000",
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px",
          borderColor: "#00f",
        },
        "& .Mui-disabled": {
          WebkitTextFillColor: "#6B7280 !important",
          background: "#F3F4F6 !important"
        },
      }}
    />
  );
};

InputField.defaultProps = {
  name: "inputField",
  label: "Label",
  type: "text",
  css: "",
  disabled: false,
  formik: {
    getFieldProps: () => ({}),
    touched: {},
    errors: {},
  },
};

export default InputField;
