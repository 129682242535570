import BreadCrumbHome from "./BreadCrumbHome";

const firstBreadCrumbHome = {
  id: 1,
  title: <BreadCrumbHome />,
  link: "/",
};

export const Helpbreadcrumbs = (title) => {
  return [
    firstBreadCrumbHome,
    {
      id: 3,
      title: title,
      link: null,
    },
  ];
};
export const procedureCMSbreadcrumbs = (title) => {
  return [
    firstBreadCrumbHome,
    {
      id: 3,
      title: title,
      link: null,
    },
  ];
};

export const evidenceCMSbreadcrumbs = (title) => {
  return [
    firstBreadCrumbHome,
    {
      id: 3,
      title: title,
      link: null,
    },
  ];
};
export const MarketBreadcrumb = [
  {
    id: 1,
    title: <BreadCrumbHome />,
    link: "/",
  },
  {
    id: 3,
    title: "Marketing-Paket",
    link: null,
  },
];
export const Recruiterbreadcrumbs = [
  {
    id: 1,
    title: <BreadCrumbHome />,
    link: "/",
  },
  {
    id: 3,
    title: "Kunden Werben",
    link: null,
  },
];
export const Businesspremise = (title) => {
  return [
    firstBreadCrumbHome,
    {
      id: 3,
      title: title,
      link: null,
    },
  ];
};
export const inboxBreadcrumb = (title) => {
  return [
    firstBreadCrumbHome,
    {
      id: 3,
      title: title,
      link: null,
    },
  ];
};
export const Uploads = (title, name) => {
  return [
    firstBreadCrumbHome,
    {
      id: 2,
      title: title,
      link: "/verfahren/uebersicht",
    },
    {
      id: 3,
      title: name,
      link: null,
    },
  ];
};
export const BusinesspremiseEdit = (title, name) => {
  return [
    firstBreadCrumbHome,
    {
      id: 2,
      title: title,
      link: "/betriebsstaetten",
    },
    {
      id: 3,
      title: name || "",
      link: null,
    },
  ];
};

export const settingBreadcrumbs = (id, sidebar) => {
  const settingBreadcrumbsDetail = settingBreadcrumbsOld(sidebar);
  return settingBreadcrumbsDetail[id];
};
export const settingBreadcrumbsOld = (sidebar) => {
  return {
    "persoenliche-daten": [
      firstBreadCrumbHome,
      {
        id: 2,
        title: sidebar?.setting,
        link: "/einstellungen/persoenliche-daten",
      },
      {
        id: 3,
        title: sidebar?.personal_data,
        link: null,
      },
    ],
    zahlungsadressen: [
      firstBreadCrumbHome,
      {
        id: 2,
        title: sidebar?.setting,
        link: "/einstellungen/persoenliche-daten",
      },
      {
        id: 3,
        title: sidebar?.bank_details,
        link: null,
      },
    ],
    nutzerverwaltung: [
      firstBreadCrumbHome,
      {
        id: 2,
        title: sidebar?.setting,
        link: "/einstellungen/persoenliche-daten",
      },
      {
        id: 3,
        title: sidebar?.user_management,
        link: null,
      },
    ],
    "kennwort-aendern": [
      firstBreadCrumbHome,
      {
        id: 2,
        title: sidebar?.setting,
        link: "/einstellungen/persoenliche-daten",
      },
      {
        id: 3,
        title: sidebar?.change_password,
        link: null,
      },
    ],
  };
};

export const DocumentBreadcrumbs = (title, name) => [
  firstBreadCrumbHome,
  {
    id: 3,
    title: title,
    link: "/dokumente/afpq",
  },
  {
    id: 13,
    title: name,
    link: null,
  },
];

export const procedureBreadcrumbs = (title, name) => {
  return [
    firstBreadCrumbHome,
    {
      id: 3,
      title: title,
      link: "/verfahren/uebersicht",
    },
    {
      id: 13,
      title: name,
      link: null,
    },
  ];
};
