import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { autocompleteClasses } from "@mui/material/Autocomplete";

const Root = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 280px;
  display: flex;
  flex-wrap: wrap;  
  border-radius: 12px;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
 

  & input {
    width: 0;
    border: 0;
    margin: 0;
    outline: 0;
    flex-grow: 1;
    height: 30px;
    min-width: 30px;
    padding: 20px 16px;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  outline: 0;
  margin: 4px;
  height: 30px;
  display: flex;
  overflow: hidden;
  line-height: 22px;
  border-radius: 10px;
  align-items: center;
  border-radius: 10px;
  padding: 0 4px 0 10px;
  box-sizing: content-box;
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"};

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  padding: 0;
  z-index: 1;
  width: 280px;
  overflow: auto;
  list-style: none;
  max-height: 250px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    font-weight: 600;
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
  }

  & li.${autocompleteClasses.focused} {  
    cursor: pointer;
    & svg {
      color: currentColor;
    }
  }
`
);

export default function DocumentSearch({ locations, filterValue, handleChange, placeholder }) {
  const { getRootProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, value, focused, setAnchorEl } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: filterValue,
    multiple: true,
    options: locations,
    onChange: (event, newValue) => handleChange(event, newValue),
    getOptionLabel: (option) => option,
    filterOptions: (options, { inputValue }) => options?.filter((option) => option?.toLowerCase()?.includes(inputValue?.toLowerCase())),
  });

  const handleDelete = (index) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    handleChange(newValue);
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {value.map((option, index) => (
            <StyledTag label={option} onDelete={() => handleDelete(index)} key={index} {...getTagProps({ index })} />
          ))}
          <input {...getInputProps()} placeholder={placeholder} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li key={index} {...getOptionProps({ option, index })}>
              <span>{option}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}

DocumentSearch.defaultProps = {
  placeholder: "Betriebsstät suchen",
  handleChange: function () {},
};
