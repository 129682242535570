import {
  GET_COMPLETED_SERVICE_ORDER_FAILURE,
  GET_COMPLETED_SERVICE_ORDER_STARTED,
  GET_COMPLETED_SERVICE_ORDER_SUCCESS,
  GET_GOING_SERVICE_ORDER_PAGINATE_FAILURE,
  GET_GOING_SERVICE_ORDER_PAGINATE_STARTED,
  GET_GOING_SERVICE_ORDER_PAGINATE_SUCCESS,
  GET_ON_GOING_SERVICE_ORDER_FAILURE,
  GET_ON_GOING_SERVICE_ORDER_STARTED,
  GET_ON_GOING_SERVICE_ORDER_SUCCESS,
} from "../actionTypes";

export const getOnGoingServiceOrderStarted = (payload) => {
  return {
    type: GET_ON_GOING_SERVICE_ORDER_STARTED,
    payload,
  };
};

export const getOnGoingServiceOrderSuccess = (payload) => {
  return {
    type: GET_ON_GOING_SERVICE_ORDER_SUCCESS,
    payload,
  };
};

export const getOnGoingServiceOrderFailure = (payload) => {
  return {
    type: GET_ON_GOING_SERVICE_ORDER_FAILURE,
    payload,
  };
};

export const getCompletedServiceOrderStarted = (payload) => {
  return {
    type: GET_COMPLETED_SERVICE_ORDER_STARTED,
    payload,
  };
};

export const getCompletedServiceOrderSuccess = (payload) => {
  return {
    type: GET_COMPLETED_SERVICE_ORDER_SUCCESS,
    payload,
  };
};

export const getCompletedServiceOrderFailure = (payload) => {
  return {
    type: GET_COMPLETED_SERVICE_ORDER_FAILURE,
    payload,
  };
};

export const getServiceOrderPaginationStarted = (payload) => {
  return {
    type: GET_GOING_SERVICE_ORDER_PAGINATE_STARTED,
    payload,
  };
};

export const getServiceOrderPaginationSuccess = (payload) => {
  return {
    type: GET_GOING_SERVICE_ORDER_PAGINATE_SUCCESS,
    payload,
  };
};

export const getServiceOrderPaginationFailure = (payload) => {
  return {
    type: GET_GOING_SERVICE_ORDER_PAGINATE_FAILURE,
    payload,
  };
};
