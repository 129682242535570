import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";

const InputLabelFiled = (props) => {
  const { label, id, extra, col, type, placeholder, variant, disabled, extraLabel, extraInput, formik } = props;
  const [editable, setEditable] = useState(false);

  return (
    <div className={`${extra} ${col}`}>
      <label
        htmlFor={id}
        className={`text-sm font-tahoma-bold text-black mt-3 lg:mt-0 ${extraLabel} ${col ? "w-full " : "w-full"} ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"}`}
      >
        {label}
      </label>
      <div className="w-full">
        <div className="flex justify-between items-center gap-x-4">
          <input
            disabled={!editable || disabled}
            type={type}
            id={id}
            name={id}
            placeholder={placeholder}
            className={`flex h-10 w-full items-center justify-center rounded-xl border border-gray-400 p-3 text-sm outline-none ${
              editable ? "bg-white" : "bg-AFPBGPrimary"
            } ${extraInput}}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[id]}
          />
          <AiFillEdit
            onClick={() => {
              !disabled && setEditable(!editable);
            }}
            size={16}
            className={`${editable ? "text-SeaGreen" : "text-SmokeyGray"} cursor-pointer`}
          />
        </div>
      </div>
    </div>
  );
};

InputLabelFiled.defaultProps = {
  label: "Label",
  id: "inputField",
  name: "inputField",
  extra: "",
  col: "",
  type: "text",
  placeholder: "",
  variant: "",
  state: "",
  disabled: false,
  extraLabel: "",
  extraInput: "",
  formik: {
    touched: {},
    errors: {},
  },
};

export default InputLabelFiled;
