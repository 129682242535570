import { useFormik } from "formik";
import { Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { SecondaryButton } from "src/components/atoms/button";
import { INHABERINITIALVALUSE, VALIDATIONSCHEMAINHABER } from "src/services/validation/buinessPremises";
import NewFormInhaber from "./NewFormInhaber";
import { useEffect, useState } from "react";
import ExistingFormInhaber from "./ExistingFormInhaber";
import { useSelector } from "react-redux";

const AddInhaberForm = ({ handleClose, handleOpen, addinhaberHandler, initialValues, isLoading }) => {
  const [NewInhablerSelect, setNewInhaberSelect] = useState({
    new: null,
    exiting: null,
  });
  const style = {
    p: 2,
    top: "50%",
    left: "50%",
    width: "90%",
    boxShadow: 24,
    maxWidth: "600px",
    bgcolor: "#FBFBFB",
    borderRadius: "12px",
    position: "absolute",
    border: "1px solid #CBCBCB",
    transform: "translate(-50%, -50%)",
  };
  const business = useSelector((state) => state?.management);
  const formik = useFormik({
    initialValues: initialValues !== null ? initialValues : INHABERINITIALVALUSE,
    validationSchema: VALIDATIONSCHEMAINHABER,
    onSubmit: (values) => {
      addinhaberHandler({ ...values, status: initialValues === null ? "add" : "edit" });
      handleClose();
      formik.resetForm();
    },
  });

  const selectHandle = (values) => {
    setNewInhaberSelect(values);
  };

  useEffect(() => {
    if (initialValues !== null) {
      setNewInhaberSelect({
        new: "selected",
        exiting: null,
      });
    }
  }, []);

  return (
    <Modal open={handleOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="outline-none">
        <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl md:text-2xl font-tahoma-bold">neu anlegen Inhaber</h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2" onClick={handleClose} />
          </div>
          {NewInhablerSelect.new === null && NewInhablerSelect.exiting === null && (
            <>
              {isLoading !== true && (
                <SecondaryButton
                  variant="secondary"
                  title="Vorhandener Inhaber"
                  css="w-full my-8"
                  handler={() => {
                    selectHandle({
                      new: null,
                      exiting: "selected",
                    });
                  }}
                />
              )}
              <SecondaryButton
                variant="secondary"
                title="neu Inhaber"
                css="w-full my-8"
                handler={() => {
                  selectHandle({
                    new: "selected",
                    exiting: null,
                  });
                }}
              />
            </>
          )}
          {NewInhablerSelect.new && <NewFormInhaber formik={formik} handleClose={handleClose} />}

          {NewInhablerSelect.exiting && (
            <ExistingFormInhaber
              handleClose={handleClose}
              addinhaberHandler={addinhaberHandler}
              inhaberList={business?.contactList?.data?.filter((data) => data.contact_class === "INHABER")}
            />
          )}
        </div>
      </Box>
    </Modal>
  );
};

AddInhaberForm.defaultProps = {
  handleClose: () => {},
  handleOpen: false,
  addinhaberHandler: () => {},
  initialValues: null,
  isLoading: false,
};

export default AddInhaberForm;
