import { useSelector } from "react-redux";
import RegisterForm from "src/components/organisms/register/RegisterForm";
import RegisterSuccess from "src/components/organisms/register/RegisterSuccess";
import Register from "src/components/template/Register";

const Signup = () => {
  const users = useSelector((state)=>state.users)
  return (
    <div className="flexCenter min-h-screen bg-black">
      {!users?.userRegistered ? (
        <Register>
          <RegisterForm />
        </Register>
        ) : (
        <div className="min-h-[96vh] md:min-h-[80vh] w-[96%] md:w-[92%] lg:w-[88%] my-2 md:my-4 lg:my-8 flex justify-center items-center bg-white rounded-xl p-4">
          <RegisterSuccess />
        </div>
      )}
    </div>
  );
};

export default Signup;
