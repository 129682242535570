import React from "react";
import { PrimaryPanelTab } from "src/components/atoms/tabs";
const TabBody = ({ value, tabList }) => {
  return (
    <>
      {tabList.map((tab) => {
        return (
          <PrimaryPanelTab key={tab.uuid} value={value} index={tab.id} data={{ id: value }}>
            {tab.component}
          </PrimaryPanelTab>
        );
      })}
    </>
  );
};

export default TabBody;
