import InboxTable from "./InboxTable";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "src/hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { StandardSearch } from "src/components/molecules/search";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import DocumentSearch from "src/components/molecules/search/DocumentSearch";

const InboxContent = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState(data);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  const allLocations = data.map((item) => item.business);
  const uniqueBusinesses = Array.from(new Set(allLocations));

  const handleLocationChange = (event, newValue) => {
    setSelectedLocations(newValue || []);
  };

  useEffect(() => {
    const filteredData = data.filter(
      (item) =>
        ((selectedLocations.length === 0 || selectedLocations.includes(item.business)) && item.regarding.toLowerCase().includes(searchTerm.toLowerCase())) ||
        item.date.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilterData(filteredData);
  }, [searchTerm, selectedLocations]);

  const handleSearch = useDebounce((term) => {
    setSearchTerm(term);
  });

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    handleSearch(value);
  };

  const dispatch = useDispatch();

  return (
    <div className="bg-AFPBGPrimary px-3 md:px-8 py-2">
      <div className="mt-5 w-full flex justify-end items-start gap-y-4 gap-x-2 flex-wrap">
        <DocumentSearch placeholder={cmsDashboarddata?.inbox?.filter?.location} handleChange={handleLocationChange} locations={uniqueBusinesses} />
        <div className="relative w-64 md:ml-4 rounded-full">
          <StandardSearch placeholder={cmsDashboarddata?.inbox?.filter?.search} value={searchTerm} handleSearch={handleSearchChange} />
        </div>
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "inbox",
                unique: "filter",
                heading: "Inbox",
              })
            );
          }}
        />
      </div>
      <div className="mt-10">
        <EditIconCMS
          css="float-right mb-2"
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "inbox",
                unique: "table",
                heading: "Inbox",
              })
            );
          }}
        />
        <InboxTable msgList={filterData} />
      </div>
    </div>
  );
};

export default InboxContent;
