import { useState } from "react";
import { SecondaryButton } from "src/components/atoms/button";
import MTable from "src/components/pages/settings/Management/MTable";
import { HELPTABLEHEAD, helpGroupButton } from "src/mockData/help";
import HelpTableBody from "./HelpTableBody";
import { useSelector, useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

function HelpTable() {
  const [selectedBtn, setSelectedBtn] = useState(1);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const tableBody = useSelector((state) => state.help.helpList);
  const dispatch = useDispatch();

  const list = cmsDashboarddata.help_support.table_head;
  const headList = HELPTABLEHEAD(list?.tickets_created, list?.news, list?.status, list?.date);
  const btnHandler = (id) => {
    setSelectedBtn(id);
  };

  return (
    <div className="" id="tablesection">
      <h1 className="text-xl text-center pt-8 font-tahoma-bold text-black flex justify-center items-center gap-3">
        {cmsDashboarddata.help_support.table_heading.title}{" "}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "help_support",
                unique: "table_heading",
                heading: "Help & Support",
              })
            );
          }}
        />
      </h1>
      <div className="lg:m-10 border-solid border border-SilverSandLight rounded-lg">
        <div className="flex justify-start md:justify-between gap-y-3  items-end md:items-center px-5 py-3 flex-col md:flex-row">
          <div className="flex items-center">
            {helpGroupButton.map((button, i) => {
              let first = i == 0;
              let last = helpGroupButton.length - 1 === i;
              return (
                <div key={button.id}>
                  <button
                    onClick={() => {
                      btnHandler(button.id);
                    }}
                    className={`${
                      first ? "rounded-s-xl" : last ? "rounded-e-xl" : "border-l-0 border-r-0"
                    }  py-2 px-3 text-md flexCenter border hover:bg-transparent duration-300 text-AFPPrimary bg-transparent border-AFPPrimary hover:text-AFPPrimary ${
                      selectedBtn === button.id ? "!bg-AFPPrimary !text-white" : ""
                    }`}
                  >
                    {cmsDashboarddata.help_support.filter[button.cmsId]}
                  </button>
                </div>
              );
            })}
            <EditIconCMS
              css="ml-2"
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "help_support",
                    unique: "filter",
                    heading: "Help & Support",
                  })
                );
              }}
            />
          </div>

          <div className="min-w-[180px] flex items-center gap-2">
            <SecondaryButton title={cmsDashboarddata.help_support.table_button.title} css="w-full font-tahoma-bold font-semibold px-4" />{" "}
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "help_support",
                    unique: "table_button",
                    heading: "Help & Support",
                  })
                );
              }}
            />
          </div>
        </div>

        <div className="">
          <EditIconCMS
            css="float-right"
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "help_support",
                  unique: "table_head",
                  heading: "Help & Support",
                })
              );
            }}
          />
          <MTable headList={headList} tableCss={"w-[650px]"}>
            {tableBody && <HelpTableBody bodyList={tableBody} keys={headList.map((head) => head.key)} />}
          </MTable>
        </div>
      </div>
    </div>
  );
}

export default HelpTable;
