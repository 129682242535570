/* eslint-disable */
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { INITIALVALUESEDITUSERPAYMENT, VALIDATIONSCHEMAEDITUSERPAYMENT } from "src/services/validation/settings";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { updateUserAccount } from "src/store/actions/userSettings";
import style from "./paymentSetting.module.css";
import { CheckBox, InputStandardField } from "src/components/atoms/input";
import BackdropSubmit from "src/components/atoms/button/BackdropSubmit";

const PaymentSettingForm = ({ editMode, setEditMode, toggleFormik }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const cmsData = cmsDashboarddata?.settings;
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: toggleFormik?.data ? toggleFormik?.data : INITIALVALUESEDITUSERPAYMENT,
    validationSchema: VALIDATIONSCHEMAEDITUSERPAYMENT,
    onSubmit: (values) => {
      if (editMode) {
        dispatch(updateUserAccount({ user_id: Cookies.get("userId"), ...values }));
        setEditMode(false);
      }
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="textStyle max-w-[500px] ">
          <div className=" flex justify-end w-full">
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "settings",
                    unique: "pay_note",
                    heading: "Einstellungen",
                  })
                );
              }}
            />
          </div>

          {cmsData?.pay_note?.note}
        </div>

        <div className="py-8 w-full ">
          <div className=" flex justify-end w-full max-w-[680px]">
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "settings",
                    unique: "pay_bank",
                    heading: "Einstellungen",
                  })
                );
              }}
            />
          </div>

          <div className={style.formLayout}>
            <InputStandardField
              formik={formik}
              disabled={!editMode}
              label={cmsData?.pay_bank?.account_holder}
              placeholder={cmsData?.pay_bank?.account_holder}
              name="account_holder"
              id="account_holder"
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <InputStandardField
              disabled={!editMode}
              label={cmsData?.pay_bank?.iban}
              placeholder={cmsData?.pay_bank?.iban}
              formik={formik}
              name="iban"
              id="iban"
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <InputStandardField
              disabled={!editMode}
              label={cmsData?.pay_bank?.bic}
              placeholder={cmsData?.pay_bank?.bic}
              formik={formik}
              name="bic"
              id="bic"
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <InputStandardField
              disabled={!editMode}
              label={cmsData?.pay_bank?.credit_institution}
              placeholder={cmsData?.pay_bank?.credit_institution}
              formik={formik}
              name="credit_institute"
              id="credit_institute"
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <InputStandardField
              disabled={true}
              label={cmsData?.pay_bank?.invoice_email}
              placeholder={cmsData?.pay_bank?.invoice_email}
              formik={formik}
              name="invoice_email"
              id="invoice_email"
              extra="!items-start"
              extraLabel="w-full mt-3 lg:mt-0"
              extraInput="bg-white"
            />
            <div className="flex items-start">
              <CheckBox css="flex items-center h-5" name="is_default" formik={formik} label={cmsData?.pay_bank?.default} type="checkbox" disabled={!editMode} />
            </div>
            {editMode && (
              <div className="mx-auto">
                <BackdropSubmit css="flexCenter 2xl:mt-8 w-40" btnCss="!py-3 rounded-xl" type="submit" btnTitle={"Aktualisieren"} disabled={!formik.dirty} />
                {/* <SubmitButton css="flexCenter 2xl:mt-8 w-40" btnCss="!py-2" type="submit" btnTitle={"Aktualisieren"} loading={false} /> */}
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentSettingForm;
