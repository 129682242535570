import React, { useEffect, useState } from "react";
import BackdropLoader from "../../loader/BackdropLoader";

const BackdropButton = (props) => {
  const { title, icon, type, handler, btnStyle, loading, disable, position, borderRadius } = props;

  const [backdropOpen, setBackdropOpen] = useState(false);

  const backdropOpenHandle = () => {
    setBackdropOpen(true);
  };
  const backdropCloseHandle = () => {
    setBackdropOpen(false);
  };

  useEffect(() => {
    if (loading) {
      backdropOpenHandle();
    } else {
      backdropCloseHandle();
    }
  }, [loading]);

  return (
    <>
      <button
        disabled={loading || disable}
        type={type}
        onClick={handler}
        className={`tracking-wide bg-AFPPrimary text-white rounded-xl py-2 flexCenter gap-1 border border-AFPPrimary hover:bg-AFPPrimaryDark hover:font-tahoma-bold duration-300 w-full ${btnStyle} ${
          disable && "!text-gray-500 bg-white border-SmokeyGray border hover:bg-white cursor-not-allowed hover:!font-tahoma"
          }`}
      >
        <>
          {position == "before" && icon}
          {title}
          {position == "after" && icon}
        </>
      </button>
      <BackdropLoader open={backdropOpen} borderRadius={borderRadius} />
    </>
  );
};

BackdropButton.defaultProps = {
  title: "Button",
  icon: null,
  type: "button",
  btnStyle: "",
  loading: false,
  position: "before",
  handler: function () {},
  disable: false,
  borderRadius: 0,
};

export default BackdropButton;
