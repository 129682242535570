import React from "react";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import PrimaryTab from "src/components/molecules/tabs/PrimaryTab";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { settingBreadcrumbs } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import { useParams } from "react-router-dom";
import { TABLISTHEAD, tabList } from "src/mockData/settingMock";
import { useSelector } from "react-redux";
import MessageModal from "src/components/organisms/cms/MessageModal";

const Settings = () => {
  const { id } = useParams();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;

  const list = cmsDashboarddata?.settings?.tabs;

  const headList = TABLISTHEAD(list?.personal_data, list?.payment_settings, list?.user_management, list?.change_password);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-AFPBGPrimary">
        {sidebar_menu?.sidebar_list && <Breadcrumbs data={settingBreadcrumbs(id, sidebar_menu?.sidebar_list)} />}
        {headList.length > 0 && <PrimaryTab tabList={tabList(list)} />}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export default Settings;
