/* eslint-disable */
import FilterField from "src/components/molecules/dform/FilterField";
import { getAllBusinessStarted } from "src/store/actions/business";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const BusinessInfo = ({ data, step_id }) => {
  const [modalContent, setModalContent] = useState({ inputHint: "", helpText: "" });
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;
  let [miniAids, setMiniAids] = useState(false);
  const dform = useSelector((state) => state.dform);

  const dispatch = useDispatch();
  const business = useSelector((state) => state?.management);
  const [currentHelperText, setCurrentHelperText] = useState("");

  useEffect(() => {
    if (!business?.allLocation?.data) {
      dispatch(getAllBusinessStarted());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleHover = (item) => {
    setModalContent({ inputHint: item.inputHint, helpText: item.helpText });
  };

  useEffect(() => {
    if (dform?.page_aids && dform?.page_aids["dform"] && dform?.page_aids["dform"].includes("step_id")) {
      if (!miniAids) {
        setMiniAids(true);
      }
    } else {
      if (miniAids) {
        setMiniAids(false);
      }
    }
  }, [dform?.page_aids]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect?.top <= 400 && rect?.bottom >= 10) {
          const key = section?.getAttribute("data-key");
          const disable = section?.getAttribute("data-disable");
          if (!disable) {
            currentSection = { key, position: rect.top };
          }
        }
      });

      setCurrentHelperText(currentSection.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="w-full flex">
        <div className="flex-1">
          <div className="w-full">
            {data &&
              data.map((fdata, i) => {
                const showCard = currentHelperText === fdata.id || (currentHelperText === "" && i === 0);
                return (
                  <div className="flex evidence-section w-full gap-x-4" data-key={fdata.id} data-disable={fdata?.helperTextDisable} key={i}>
                    <div className={`flex-1 ${showCard && "relative"}`}>
                      <FilterField
                        data={fdata}
                        handleHover={handleHover}
                        showCard={showCard}
                        page={"dform"}
                        step_id={step_id}
                        helperText={stepsCMS[fdata?.id] && stepsCMS[fdata?.id]?.helpdesk}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
