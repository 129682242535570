import ContentLayout from "src/components/atoms/common/ContentLayout";
import SignatureWrap from "src/components/molecules/signature/SignatureWrap";
const Signature = () => {
  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-AFPBGPrimary">
        <SignatureWrap />
      </div>
    </ContentLayout>
  );
};
export default Signature;