import Cookies from "js-cookie";
import { put } from "redux-saga/effects";
import { GET_MARKETING_DETAIL } from "../requests/marketing";
import { getMarketingFailure, getMarketingSuccess } from "src/store/actions/marketing";

export function* getMarketingHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_MARKETING_DETAIL(paramsData);
    if (response.status) {
      yield put(getMarketingSuccess(response.data));
    } else {
      yield put(
        getMarketingFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(getMarketingFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
