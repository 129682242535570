import React from "react";
import style from "./checkbox.module.css";
const ArrayCheckBox = ({ css, inputcss, label, formik, type, set, selection, index }) => {
  return (
    <>
      <div className={css}>
        <input
          id={`${set}[${index}]${selection}`}
          type={type}
          className={`${style.input} ${inputcss}`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[`${set}`][index][`${selection}`]}
          checked={formik.values[`${set}`][index][`${selection}`]}
        />
      </div>
      <div className={`${style.labelContainer}`}>
        <label htmlFor={`${set}[${index}]${selection}`} className={`${style.label}`}>
          {label}
        </label>
      </div>
    </>
  );
};

ArrayCheckBox.defaultProps = {
  css: "flex items-center",
  label: "Label",
  type: "checkbox",
  inputcss: "",
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    values: { email: "" },
  },
};

export default ArrayCheckBox;
