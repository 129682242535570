export const umzugAddressValid = (currentStep) => {
  return currentStep?.data?.filter((step) => {
    if (step?.inputType === 'address-relocation') {
      let inputValue = Object.values(step?.inputTypeValue).filter(
        (value, index) => {
          if (value?.length < 0 && index !== 4 && index !== 3) {
            return true;
          } else if (value?.length !== 5 && index === 3) {
            return true;
          }
        },
      );
      return inputValue?.length > 0;
    }
  });
};

export const FormStepValidation = (currentStep) => {
  return currentStep?.filter((step) => {
    if (
      step?.inputType === 'checkBox' ||
      step?.inputType === 'verifyCheckBoxWithAccount' ||
      step?.inputType === 'groupButton' ||
      step?.inputType === 'dropdownTag' ||
      step?.inputType === 'groupButtonInhaber' ||
      step?.inputType === 'service-areas-modal' ||
      step.inputType === 'verifyCheckBoxWithAccount' ||
      step?.inputType === 'signaturedropdown'
    ) {
      if (
        !(step?.inputRequired && Array.isArray(step?.inputTypeValue)
          ? step?.inputTypeValue.length > 0
          : step?.inputTypeValue)
      ) {
        return step;
      }
    }
  });
};

export const signatureValid = (currentStep) => {
  return currentStep?.data?.filter((step) => {
    if (step?.inputType === 'signaturedropdown') {
      if (step?.inputRequired && Array.isArray(step?.inputTypeValue) ? step?.inputTypeValue?.length > 0 : step?.inputTypeValue) {
        console.log('');
      } else {
        return step;
      }
    }
  });
};

export const contactListShow = (allContact, result, id, serviceAreaDetail) => {
  return allContact?.filter((contact) => {
    let newResult = result[0]?.inputTypeValue;
    if (id === 'IWA') {
      newResult = newResult?.filter((re) => {
        if (!serviceAreaDetail?.exsiting_team_leaders.includes(re)) {
          return re;
        }
      });
      return newResult?.includes(contact?.contact_id);
    } else if (id === 'UA') {
      return false;
    } else if (id === 'AA') {
      newResult = newResult?.filter((re) => {
        if (!serviceAreaDetail?.exsiting_team_leaders.includes(re)) {
          return re;
        }
      });
      return newResult?.includes(contact?.contact_id);
    } else {
      return newResult?.includes(contact?.contact_id);
    }
  });
};