import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const CircularLoader = ({ css }) => {
  return (
    <div className={`w-full flex justify-center items-center ${css}`}>
      <Box sx={{ display: "flex" }}>
        <CircularProgress sx={{ color: "#A31D1A" }} />
      </Box>
    </div>
  );
};

export default CircularLoader;
