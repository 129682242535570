import { useMemo, useState } from "react";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (isDate(aValue) && isDate(bValue)) {
          return sortDates(aValue, bValue, sortConfig.direction);
        } else {
          return sortStrings(aValue, bValue, sortConfig.direction);
        }
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, direction = "asc") => {
    if (sortConfig && sortConfig.key === key && sortConfig.direction === direction) {
      return;
    }
    setSortConfig({ key, direction });
  };

  const requestSortIncrease = (key) => {
    requestSort(key, "asc");
  };

  const requestSortDecrease = (key) => {
    requestSort(key, "desc");
  };

  return { items: sortedItems, requestSortIncrease, requestSortDecrease, sortConfig };
};

const isDate = (value) => {
  return /^\d{2}\.\d{2}\.\d{4}$/.test(value);
};

const parseDate = (dateString) => {
  const [day, month, year] = dateString.split(".").map(Number);
  return new Date(year, month - 1, day);
};

const sortDates = (a, b, direction) => {
  const dateA = parseDate(a);
  const dateB = parseDate(b);
  return direction === "asc" ? dateA - dateB : dateB - dateA;
};

const sortStrings = (a, b, direction) => {
  const modifier = direction === "asc" ? 1 : -1;
  if (a < b) return -1 * modifier;
  if (a > b) return 1 * modifier;
  return 0;
};

export default useSortableData;
