import React from "react";

const RegisterGrid = ({ heading, children, extraCss, css }) => {
  return (
    <div className={`flex flex-col md:grid md:grid-cols-4 my-4 md:my-6 lg:my-6 rounded-xl ${css}`}>
      <div className="col-span-1 lg:col-span-1 rounded-xl md:rounded-tr-none md:rounded-br-none ">
        <h3 className="text-md mt-2 font-tahoma-bold text-black mb-4 md:mb-0">{heading}</h3>
      </div>
      <div className={`col-span-3 lg:col-span-3 md:grid ${extraCss} `}>{children}</div>
    </div>
  );
};

RegisterGrid.defaultProps = {
  heading: "Default Heading",
  extraCss: "",
  css: "",
};

export default RegisterGrid;
