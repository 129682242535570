/* eslint-disable */
import {
  addApplicationStepFailure,
  addApplicationStepSuccess,
  addServiceApplicationStepFailure,
  addServiceApplicationStepSuccess,
  applicationLocationValidationFailure,
  applicationLocationValidationSuccess,
  applicationProcessStatusFailure,
  applicationProcessStatusStarted,
  applicationProcessStatusSuccess,
  createCompleteApplicationFailure,
  createCompleteApplicationSuccess,
  getApplicationFailure,
  getApplicationSuccess,
  newServiceOrderCostFailure,
  newServiceOrderCostSuccess,
  sendSignatureRequestEmailFailure,
  sendSignatureRequestEmailSuccess,
} from "src/store/actions/application";
import { ADD_APPLICATION_STEP, CREATE_COMPLETE_APPLICATION, GET_APPLICATION_BY_ID, GET_APPLICATION_PROCESS_STATUS, GET_LOCATION_STATUS_BY_ID, NEW_SERVICE_ORDER_COSTS, SEND_SIGNATURE_REQUEST_EMAIL } from "../requests/application";
import { put } from "redux-saga/effects";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getLeaderByLocationStarted, ownerByLocationStarted } from "src/store/actions/business";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";

export function* getApplicationbyIDHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_APPLICATION_BY_ID(queryParams);
    if (response.status) {
      yield put(getApplicationSuccess({ data: response.data, step_id: action.payload.step_id }));
    } else {
      yield put(
        getApplicationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getApplicationFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* AddApplicationStepHandler(action) {
  try {
    let { addNewService, ...payload } = action.payload;
    const newPayload = {
      ...payload,
      user_id: Cookies.get("userId")
    }
    const response = yield ADD_APPLICATION_STEP(newPayload);
    if (response.status) {
      if(!Cookies.get('submitted_id')){
        Cookies.set('submitted_id', response?.data?.id)
      }
      if (addNewService) {
        yield put(addApplicationStepSuccess({ user_id: Cookies.get("userId"), submitted_id: response?.data?.id, stepStatus: 'stopNext' }));
      } else { 
        yield put(addApplicationStepSuccess({ user_id: Cookies.get("userId"), submitted_id: response?.data?.id, }));
        toast.success(response?.message);
      }
     
    } else {
      yield put(
        addApplicationStepFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(addApplicationStepFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* AddServiceApplicationStepHandler(action) {
  try {
    let { ...payload } = action.payload;
    const newPayload = {
      ...payload,
      user_id: Cookies.get("userId")
    }
    const response = yield ADD_APPLICATION_STEP(newPayload);
    if (response.status) {
      yield put(addServiceApplicationStepSuccess())
    } else {
      yield put(
        addServiceApplicationStepFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(addServiceApplicationStepFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* CreateCompleteApplicationHandler(action) {
  try {
    const newPayload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield CREATE_COMPLETE_APPLICATION(newPayload);
    if (response.status) {
      yield put(createCompleteApplicationSuccess(response?.data));
      yield put(addApplicationStepSuccess({ user_id: Cookies.get("userId") }));
      toast.success(response?.message);
    } else {
      yield put(
        createCompleteApplicationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(createCompleteApplicationFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* newServiceOrderCostHandler(action) {
  try {
    const newPayload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield NEW_SERVICE_ORDER_COSTS(newPayload);
    if (response.status) {
      yield put(newServiceOrderCostSuccess(response?.data));
      // toast.success(response?.message);
    } else {
      yield put(
        newServiceOrderCostFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(newServiceOrderCostFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* sendSignatureRequestEmailHandler(action) {
  try {
    const newPayload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield SEND_SIGNATURE_REQUEST_EMAIL(newPayload);
    if (response.status) {
      yield put(sendSignatureRequestEmailSuccess(response?.data));
      toast.success(response?.message);
      yield put(applicationProcessStatusStarted({procedure_id: action?.payload?.procedure_id}))
    } else {
      yield put(
        sendSignatureRequestEmailFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(sendSignatureRequestEmailFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getApplicationProcessStatusHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_APPLICATION_PROCESS_STATUS(queryParams);
    if (response.status) {
      yield put(applicationProcessStatusSuccess({ data: response.data, }));
    } else {
      yield put(
        applicationProcessStatusFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      applicationProcessStatusFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}
export function* getApplicationBusinessStatusByIdHandler(action) {
  try {
    const {valueId, valueKey, ...rest} = action.payload
    const queryParams = {
      queryParams: {
        ...rest,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_LOCATION_STATUS_BY_ID(queryParams);
    if (response.status) {
      yield put(addCurrentStepDetailValue({
        valueId: action.payload?.valueId,
        value: action.payload?.location_id,
        valueKey: action.payload?.valueKey,
      }));
      localStorage.setItem("locationValidation", JSON.stringify(response.data))
      yield put(addCurrentStepDetailValue({
        valueId: action.payload?.valueId,
        value: response.data,
        valueKey: "locationValidation",
      }))
      yield put(applicationLocationValidationSuccess({ data: response.data, }));
      yield put(getLeaderByLocationStarted({ location_id: action.payload?.location_id }));
      yield put(ownerByLocationStarted({ location_id: action.payload?.location_id }));
    } else {
      yield put(
        applicationLocationValidationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      applicationLocationValidationFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}