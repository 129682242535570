import React, { useMemo } from "react";
import { Paper, Table, TableContainer } from "@mui/material";
import DocumentTableHead from "src/components/molecules/table/DocumentTable/DocumentTableHead";
import { useState } from "react";
import { userDocumentTableHeaderCMS } from "src/mockData/document";
import UserDocTableBody from "./UserDocTableBody";
import { useSelector } from "react-redux";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = typeof a[sortConfig.key] === "string" ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
        const bValue = typeof b[sortConfig.key] === "string" ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, direction = "asc") => {
    if (sortConfig && sortConfig.key === key && sortConfig.direction === direction) {
      return;
    }
    setSortConfig({ key, direction });
  };

  const requestSortIncrease = (key) => {
    requestSort(key, "asc");
  };

  const requestSortDecrease = (key) => {
    requestSort(key, "desc");
  };

  return { items: sortedItems, requestSortIncrease, requestSortDecrease, sortConfig };
};

const UserDocTable = ({ document }) => {
  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useSortableData(document);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  const list = cmsDashboarddata?.document?.list_table_head;
  const headList = userDocumentTableHeaderCMS(list?.name, list?.category, list?.date);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {sortedData?.length > 0 && <DocumentTableHead tableHeader={headList} increaseSort={requestSortIncrease} decreaseSort={requestSortDecrease} />}
        {sortedData?.length > 0 && <UserDocTableBody bodyContent={sortedData} />}
      </Table>
      {!(sortedData?.length > 0) && <h4 className="text-red-400 text-center my-6">Keine Daten gefunden</h4>}
    </TableContainer>
  );
};

export default UserDocTable;
