// export const BASE_URL = "https://eacademyeducation.com:8900/v1/";
export const BASE_URL = "https://backend.meine.afp-da.de/v1/";

export const API = {
  /* Users */
  DASHBOARD: "dashboard/dashboard",
  LOGIN: "auth/login",
  REGISTER: "auth/register",
  REGISTERMODIFY: "users/create-invited-user",
  FORGOTPASSWORD: "auth/forgot-password",
  RESETPASSWORD: "auth/reset-password",
  CHANGEPASSWORD: "auth/change-password",
  ADDUSERSACCOUNTS: "users/add-accounts",
  UPDATEUSERSACCOUNTS: "users/update-account",
  ACCOUNTSLIST: "users/user-accounts-list",

  /* settings */
  userDetails: "users/user-details",
  updateDetails: "users/update-user",
  UPDATEUSERDETAIL: "users/update-account",
  DELETEACCOUNT: "users/delete-accounts",

  /* user management */
  ADDUSERMANAGEMENT: "users/add-user-by-management",
  // UPDATEUSERMANAGEMENT: "users/update-user-by-management",
  UPDATEUSERMANAGEMENT: "users/update-invited-user",
  DELETEUSERMANAGEMENT: "users/delete-invited-user",
  USERMANAGEMENTLIST: "users/invited-user-list",

  /* business premises */
  ADD_BUSINESS_PREMISES: "users/add-business",
  LIST_BUSINESS_PREMISES: "users/business-list",
  GET_BUSINESS_PREMISESES: "users/business-dropdown-options",
  DETAIL_BUSINESS_PREMISES: "users/business-details",
  UPDATE_BUSINESS_PREMISES: "users/update-business",
  DELETE_BUSINESS_PREMISES: "users/delete-business",

  PROFESSIONALGROUPS: "/lists/get-professional-groups",
  GET_LEADERS_BY_LOCATION: "contacts/get-technical-leaders-by-location",
  GET_OWNER_BY_LOCATION: "contacts/get-owners-by-location",
  ADD_LEADERS_BY_LOCATION: "contacts/add-new-technical-lead",
  ADD_OWNER_BY_LOCATION: "contacts/add-new-owner",

  /* CMS */
  UPDATE_CMS_CONTENT: "cms/update-cms",
  ALL_CMS_CONTENT: "cms/get-all-cms",
  // GET_CMS_DForm_CONTENT: "cms/get-procedure-aa-cms",
  GET_CMS_DForm_CONTENT: "cms/get-dynamic-form",
  GET_CMS_DForm_BY_ID: "cms/dynamic-form-details",
  UPDATE_DFORM_CMS_BY_ID: "cms/add-update-dynamic-form",
  /** helpdesk */
  GET_HELPDESK_LIST: "crisp/article-list",
  GET_HELPDESK_DETAIL: "crisp/article-details",
  GET_HELPDESK_CONTENT: "crisp/article-by-id",

  /* master */
  CONTACT_LIST: "users/user-contacts",

  /* help */
  HELP_LIST: "users/help-list",

  /* document */
  FROM_YOU_TO_LIST: "documents/dms-available-documents",
  USER_FILES_LIST: "documents/dms-self-uploaded-documents",
  DOCUMENT_CATEGORY_LIST: "documents/category-list",
  UPLOAD_DOCUMENT: "documents/upload-document",
  UPLOAD_DOCUMENT_BASE64: "documents/upload-base64-document",
  UPLOAD_DMS_DOCUMENT: "documents/upload-dms-document",

  /* inbox */
  MESSAGE_LIST: "inbox/massage-list",
  READ_MESSAGE: "inbox/read-message",

  /* procedure */
  PROCEDURE_TEMPLATE: "procedure/get-service-order-types",
  // PROCEDURE_LIST: "procedure/procedure-form-overview-list",
  /* application */
  // APPLICATION_METADATA: "procedure/procedure-type-metadata",
  APPLICATION_METADATAS: "procedure/get-meta-data",

  ADD_PROCEDURE_APPLICATION: "procedure/add-procedure-application",
  CREATE_COMPLETE_APPLICATION: "procedure/add-new-service-order",
  NEW_SERVICE_ORDER_COSTS: "procedure/new-service-order-costs",
  SEND_SIGNATURE_REQUEST_EMAIL: "procedure/send-signature-request-email",
  APPLICATION_PROCESS_STATUS: "procedure/get-procedure-details",
  APPLICATION_PROCESS_STATUS_BY_SERVICE_ORDER_ID: "procedure/get-procedure-details-by-number",
  APPLICATION_LOCATION_STATUS_BY_ID: "procedure/get-service-order-type-validations",

  /* steps */
  STEPS_LIST: "procedure/get-service-order-steps",
  SERVICE_AREA_LIST: "procedure/get-service-areas",
  SERVICE_AREA_DETAIL: "procedure/get-service-area-details",

  /* dashboard */

  WELCOME_MESSAGE: "dashboard/welcome-message",

  /* Marketing */
  MARKETING_DETAIL: "marketing/get-marketing",

  /* Recruit client */
  RECRUIT_CLIENT_DETAIL: "kundenWerben/get-kundenWerben",

  /* service order type */
  ON_GOING_SERVICE_ORDER: "procedure/on-going-service-orders",
  COMPLETED_SERVICE_ORDER: "procedure/completed-service-orders",
  ON_GOING_SERVICE_ORDER_PAGINATE: "procedure/on-going-service-orders/paginate",

  /* service order evidence */
  NEW_SERVICE_ORDER_EVIDENCE: "procedure/new-service-order-evidences",
  NEW_SERVICE_ORDER_EVIDENCE_PENDING: "procedure/pending-service-order-evidences",
  GET_SERVICE_ORDER_EVIDENCE: "procedure/get-service-order-evidence",
  SAVE_SERVICE_ORDER_EVIDENCE: "procedure/save-service-order-evidence",
  SAVE_SERVICE_ORDER_EVIDENCE_ERP: "procedure/service-order-evidence-pdf",
  UPLOAD_EVIDENCE: "procedure/upload-evidence",
  UPLOAD_SERVICE_ORDER_EVIDENCE: "procedure/upload-service-order-evidence",

  /* signature upload */
  SIGNATURE_UPLOAD: "procedure/upload-signature",

  /*document download */
  DOWNLOAD_DMS_DOCUMENT: "documents/download-dms-document",
  DOWNLOAD_ORDER: "documents/download-order-document",
  DOWNLOAD_INVOICE: "documents/download-invoice-document",
  DOWNLOAD_CERTIFICATE: "documents/download-certificate-document",
  DOWNLOAD_XML: "documents/download-xml-document",
  DOWNLOAD_EVIDENCE_FILE: "procedure/service-order-evidence",
  DELETE_UPLOADED_EVIDENCE: "procedure/service-order-evidence",
};
