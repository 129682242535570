/* eslint-disable */
import { Box, Modal } from "@mui/material";
import React from "react";
import { SecondaryButton } from "../../button";
import DeleteIcon from "src/assets/icons/delete_icon.png";

const style = {
  top: "50%",
  left: "50%",
  boxShadow: 24,
  width: "90%",
  maxWidth: "600px",
  bgcolor: "#FBFBFB",
  position: "absolute",
  transform: "translate(-50%, -50%)",
};

const DeleteModal = ({ handleOpen = false, showModal = false, confirmHandle }) => {
  const cancel = () => {
    handleOpen(false);
  };
  return (
    <Modal open={showModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="outline-none">
        <div className="flex md:w-full w-full">
          <img src={DeleteIcon} alt="Delete-icon" className="w-2/5" />
          <div className={`overflow-y-auto overflow-x-hidden customScrollBar w-full  pt-4`}>
            <div className="w-full mt-16 mb-24 pl-4">
              <h2 className="font-bold text-xl">Löschen ?</h2>
              <h2 className="mt-4 font-thin">Sind Sie sicher, dass Sie dies löschen möchten?</h2>
            </div>
            <div className="flex flex-row">
              <SecondaryButton
                variant="secondary"
                title="Abbrechen"
                css="w-full !rounded-none border-l-0 border-b-0 border-r-0 !shadow-none"
                handler={() => {
                  cancel();
                }}
              />
              <SecondaryButton
                variant="secondary"
                title="Löschen"
                css="w-full !rounded-none border-r-0 border-b-0 !shadow-none"
                handler={() => {
                  confirmHandle();
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
