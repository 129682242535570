/* eslint-disable */
import { business, inbox, manageApp, messageList } from "src/mockData/dashboard";
import { dashboardPortal } from "src/mockData/dashboard";
import SecondaryButton from "src/components/atoms/button/SecondaryButton";
import DashboardMessage from "src/components/molecules/DashboardMessage";
import PortalArea from "src/components/molecules/afpcard/PortalArea";
import { useSelector, useDispatch } from "react-redux";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { useEffect, useState } from "react";
import OverviewModal from "../procedure/overview/OverviewModal";
import { useNavigate } from "react-router-dom";
import { setApplicationSidebar } from "src/store/actions/application";
import Cookies from "js-cookie";

let portList = (item) => {
  const { document, setting, clients, help } = item;
  return [
    {
      heading: document?.manage_document,
      text: document?.manage_document_short,
    },
    {
      heading: setting.settings,
      text: setting.settings_short,
    },
    {
      heading: item?.package?.marketing_package,
      text: item?.package?.marketing_package_short,
    },
    {
      heading: clients?.recruit_clients,
      text: clients?.recruit_clients_short,
    },
    {
      heading: help?.help_support,
      text: help?.help_support_short,
    },
  ];
};
const Dashboard = () => {
  const dispatch = useDispatch();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const [message, setMessage] = useState("");
  const profile = useSelector((state) => state.profile);
  const dashboard = useSelector((state) => state.dashboard);
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboard.welcome.data) {
      let data = dashboard.welcome.data.replace("&lt;NAME&gt;", ", " + profile?.data?.first_name + " " + profile?.data?.last_name);
      setMessage(data);
    }
  }, [dashboard?.welcome, profile]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModalHandle = () => {
    setIsModalOpen(true);
  };
  const closeModalHandle = () => {
    setIsModalOpen(false);
  };

  const onboardingHandle = () => {
    dispatch(setApplicationSidebar());
    Cookies.remove("submitted_id");
    let url = "verfahren/antrag/AA";
    if (profile?.data?.role === "admin") {
      navigate("/admin/" + url);
    } else {
      navigate(url);
    }
  };

  return (
    <div className="bg-AFPBGPrimary px-3 md:px-10 py-10">
      <h1 className="text-2xl font-semibold text-AFPHeading flex items-center gap-4">
        {cmsDashboarddata?.dashboard?.welcome_message?.title1 && message && profile?.data?.first_name && (
          <div>{cmsDashboarddata?.dashboard?.welcome_message?.title1 + "" + profile?.data?.company_name + ", " + profile?.data?.first_name + " " + profile?.data?.last_name}</div>
        )}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "dashboard",
                unique: "welcome_message",
                heading: "Dashboard",
              })
            );
          }}
        />
      </h1>
      <h1 className="text-xl text-AFPHeading mt-4 flex">
        {cmsDashboarddata?.dashboard?.welcome_message?.title2 && message && profile?.data?.first_name && <div>{cmsDashboarddata?.dashboard?.welcome_message?.title2}</div>}
      </h1>
      <div className="pt-10 pb-14 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 2xl:gap-12">
        <DashboardMessage
          business={business(dashboard?.data?.businessLocation)}
          inbox={inbox(
            `${dashboard?.data?.inbox?.newMessage + " "} ${cmsDashboarddata?.dashboard?.inbox?.new_message}`,
            `${dashboard?.data?.inbox?.newInvoice + " "} ${cmsDashboarddata?.dashboard?.inbox?.new_bill}`,
            `${dashboard?.data?.inbox?.signature + " "} ${cmsDashboarddata?.dashboard?.inbox?.signature_required}`
          )}
          manageApp={manageApp(dashboard?.data?.dynamicForm)}
          messageList={messageList(
            cmsDashboarddata?.dashboard?.inbox?.inbox,
            cmsDashboarddata.dashboard?.manage_business?.manage_business_premises,
            cmsDashboarddata?.dashboard?.applications.applications_procedures
          )}
        />
      </div>
      <h1 className="text-2xl font-semibold flex items-center gap-4">
        {cmsDashboarddata.dashboard?.next?.like_next}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "dashboard",
                unique: "next",
                heading: "Dashboard",
              })
            );
          }}
        />
      </h1>
      <div className="pt-6 pb-10 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 xl:gap-10 2xl:gap-12">
        <SecondaryButton handler={openModalHandle} title={cmsDashboarddata.dashboard?.next?.new_application} css={"font-semibold py-3 w-full font-tahoma "} />
        <SecondaryButton handler={onboardingHandle} variant="secondary" title={cmsDashboarddata.dashboard?.next?.onboarding} css={"font-tahoma font-semibold px-2"} />
        <SecondaryButton variant="disabled" title={cmsDashboarddata.dashboard?.next?.submit_evidence} css={"px-2"} />
        <SecondaryButton variant="disabled" title={cmsDashboarddata.dashboard?.next?.monitoring_edit} css={"px-2"} />
      </div>
      <div className="w-full bg-AFPDisabled h-[1px] mb-8" />
      <PortalArea portal={dashboardPortal(...portList(cmsDashboarddata.dashboard))} />
      {isModalOpen && (
        <>
          <OverviewModal open={isModalOpen} handleClose={closeModalHandle} />{" "}
        </>
      )}
    </div>
  );
};

export default Dashboard;
