import Card from "src/components/atoms/card/Card";
import { useSelector, useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { useNavigate } from "react-router-dom";

const AFPPortal = ({ portal }) => {
  const dispatch = useDispatch();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const navigateHandler = (url) => {
    if (profile?.data?.role === "admin") {
      navigate("/admin" + url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <h1 className="text-2xl font-semibold flex items-center gap-4">
        {cmsDashboarddata.dashboard?.portal?.other_portal}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "dashboard",
                unique: "portal",
                heading: "Dashboard",
              })
            );
          }}
        />
      </h1>
      <div className="pt-6 pb-10 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5 lg:gap-10 lg:gap-x-12 xl:gap-x-20">
        {portal.map((item, index) => (
          <Card key={index} css="justify-start cursor-pointer">
            <div
              className="flex gap-4 mb-4 items-center"
              onClick={() => {
                navigateHandler(item.url);
              }}
            >
              {item.icon}
              <h3 className="text-AFPHeading text-lg font-semibold">{item.title}</h3>
              <EditIconCMS
                handleOpen={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "dashboard",
                      unique: item.cmsId,
                      heading: "Dashboard",
                    })
                  );
                }}
              />
            </div>
            <p
              className="text-md text-SmokeyGray mt-4 overflow-hidden overflow-ellipsis"
              onClick={() => {
                navigateHandler(item.url);
              }}
            >
              {item.content}
            </p>
          </Card>
        ))}
      </div>
    </>
  );
};

AFPPortal.defaultProps = {
  portal: [],
};

export default AFPPortal;
