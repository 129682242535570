import { put } from "redux-saga/effects";
import { GET_COMPLETED_SERVICE_ORDER_DETAIL, GET_ON_GOING_SERVICE_ORDER_DETAIL, GET_ON_GOING_SERVICE_ORDER_PAGINATION } from "../requests/serviceOrder";
import {
  getCompletedServiceOrderFailure,
  getCompletedServiceOrderSuccess,
  getOnGoingServiceOrderFailure,
  getOnGoingServiceOrderSuccess,
  getServiceOrderPaginationFailure,
  getServiceOrderPaginationSuccess,
} from "src/store/actions/serviceOrder";
import Cookies from "js-cookie";

export function* getOnGoingServiceOrderHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId")
      },
      isAuthorized: true,
    };
    const response = yield GET_ON_GOING_SERVICE_ORDER_DETAIL(paramsData);
    if (response.status) {
      yield put(getOnGoingServiceOrderSuccess(response.data));
    } else {
      yield put(
        getOnGoingServiceOrderFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(getOnGoingServiceOrderFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getCompletedServiceOrderHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId")
      },
      isAuthorized: true,
    };
    const response = yield GET_COMPLETED_SERVICE_ORDER_DETAIL(paramsData);
    if (response.status) {
      yield put(getCompletedServiceOrderSuccess(response.data));
    } else {
      yield put(
        getCompletedServiceOrderFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(getCompletedServiceOrderFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getOnGoingServiceOrderPaginationHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_ON_GOING_SERVICE_ORDER_PAGINATION(paramsData);
    if (response.status) {
      yield put(getServiceOrderPaginationSuccess({ data: response.data, location_id: action.payload.location_id }));
    } else {
      yield put(
        getServiceOrderPaginationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(getServiceOrderPaginationFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
