import { useEffect } from "react";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { Helpbreadcrumbs } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import HelpCard from "src/components/molecules/help/HelpCard";
import HelpTable from "src/components/molecules/table/HelpTable/Index";
import { useSelector, useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { fetchHelpListRequest } from "src/store/actions/help";

function Help() {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHelpListRequest());
  }, []);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-AFPBGPrimary">
        {sidebar_menu?.sidebar_list?.help_support && <Breadcrumbs data={Helpbreadcrumbs(sidebar_menu?.sidebar_list?.help_support)} /> }
        {cmsDashboarddata.listLoaded && (
          <>
            <h2 className="!mb-10 text-2xl font-tahoma-bold m-3 sm:m-4 md:m-6 flex items-center gap-3">
              {cmsDashboarddata?.help_support?.heading?.title}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "help_support",
                      unique: "heading",
                      heading: "Help & Support",
                    })
                  );
                }}
              />
            </h2>
            <div className="bg-AFPBGPrimary px-3 md:px-10 py-10 pt-0">
              <HelpCard />
            </div>
            <div className="bg-BrightGray px-3 md:px-10 py-10">
              <HelpTable />
            </div>
          </>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
}

export default Help;
