import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import OverviewCompleted from "./OverviewCompleted";

const Overview = () => {
  const dispatch = useDispatch();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { procedures } = cmsDashboarddata;

  return (
    <>
      <div className="bg-AFPBGPrimary flex flex-col ">
        <h2 className="text-2xl font-tahoma-bold m-0 flex items-center gap-x-3">
          {procedures?.heading_overview?.title_overview}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "procedures",
                  unique: "heading_overview",
                  heading: "Anträge/Verfahren",
                })
              );
            }}
          />
        </h2>
        <div className="bg-AFPBGPrimary mt-3 sm:mt-4 md:mt-5">
          <p className="textStyle max-w-[540px] mb-5">{procedures?.heading_overview?.sub_title}</p>
        </div>
      </div>
      <OverviewCompleted />
    </>
  );
};

export default Overview;
