/*eslint-disable */
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceOrderPayloadModify } from "src/helpers/dform";
import { newServiceOrderCostStarted } from "src/store/actions/application";
import { useParams } from "react-router-dom";

const ServiceOrderCast = ({ data, stepsCMS, step_id }) => {
  const { id } = useParams();
  const application = useSelector((state) => state.application);
  const currentStep = useSelector((state) => state.currentStep);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && currentStep.stepID && currentStep.data) {
      dispatch(
        newServiceOrderCostStarted(
          ServiceOrderPayloadModify({
            procedure_data: [{ ...application.data, [currentStep.stepID]: currentStep.data }],
            step_id: step_id,
            service_order_types_id: id,
            procedure_form_id: Cookies.get("submitted_id"),
            user_id: Cookies.get("userId"),
          })
        )
      );
    }
    // }
  }, [data]);

  return (
    <div className="pt-9">
      <h1 className="mb-3 font-bold">{stepsCMS?.COST_OVERVIEW?.title}</h1>
      <div className="border ml-2 rounded-md border-AFPLightGray w-full md:w-[841px] h-[135px] md:h-auto flex flex-col justify-start place-items-start mx-auto">
        <div className="flex flex-row md:flex-row gap-4 md:gap-52 text-sm font-tahoma">
          <ul className="text-black p-4 md:p-7">
            <li>Netto</li>
            <li>MwSt. (19%)</li>
            <li>Gesamtbetrag</li>
          </ul>
          <ul className="text-black p-4 md:p-7">
            {application?.orderCosts?.isLoading ? (
              <CircularProgress sx={{ color: "#A31D1A" }} />
            ) : (
              <>
                <li align="right">{application?.orderCosts?.data?.totalNetAmount}</li>
                <li align="right">{application?.orderCosts?.data?.totalTaxAmount}</li>
                <li align="right">{application?.orderCosts?.data?.totalAmount}</li>{" "}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceOrderCast;
