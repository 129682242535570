import { put } from "redux-saga/effects";
import { fetchMessageList, fetchMessageRead } from "../requests/inbox";
import {
  MessageReadSuccss,
  messageListFailure,
  messageReadFailure,
  setMessageList,
} from "src/store/actions/inbox";
import Cookies from "js-cookie";
import { GET_DASHBOARD_DATA } from "../requests/dashboard";
import { getDashboardDataSuccess } from "src/store/actions/dashboard";

export function* messageListHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId")
      },
      isAuthorized: true,
    };
    const response = yield fetchMessageList(paramsData);
    if (response.status) {
      yield put(setMessageList(response.data));
    } else {
      yield put(
        messageListFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(messageListFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* messageReadHandler(action) {
  try {
    const payload = {
        ...action.payload,
        user_id: Cookies.get("userId"),
    };
    const response = yield fetchMessageRead(payload);
    const dashboardQueryParams = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const dashboardResponse = yield GET_DASHBOARD_DATA(dashboardQueryParams);
    if (response.status) {
      yield put(MessageReadSuccss(response.data));
      yield put(getDashboardDataSuccess(dashboardResponse.data));
    } else {
      yield put(
        messageReadFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      messageReadFailure({ message: error?.message || "Etwas ist schief gelaufen!" })
    );
  }
}
