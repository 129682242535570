/* eslint-disable */

import React, { Fragment, useState } from "react";
import { InputStandardField } from "src/components/atoms/input";
import RegisterGrid from "src/components/molecules/RegisterGrid";
import { AiFillEdit } from "react-icons/ai";
import { RiDeleteBinFill } from "react-icons/ri";
import CircularButton from "src/components/atoms/button/CircularButton";
import { SecondaryButton } from "src/components/atoms/button";
import AddUserForm from "src/components/atoms/modal/AddBusinessModal/AddUserForm";
import { String12Random } from "src/helpers/setting";
import AddInhaberForm from "src/components/atoms/modal/AddBusinessModal/AddInhaberForm";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import AutoCompleteFieldCard from "./AutoCompleteFieldCard";
import { useDispatch } from "react-redux";
import { updateBusinessPremises } from "src/store/actions/business";
import toast from "react-hot-toast";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { useNavigate } from "react-router-dom";
import { setApplicationSidebar } from "src/store/actions/application";
import InputStandardFieldArray from "src/components/atoms/input/InputStandardField/InputStandardFieldArray";
import Cookies from "js-cookie";
import BackdropButton from "src/components/atoms/button/BackdropButton";
import { CgCloseR } from "react-icons/cg";

const EditForm = ({setFormReset}) => {
  const business = useSelector((state) => state.management);
  let pre_business_owner = business.detail.business_owners || [];
  let pre_technical_leaders = business.detail.technical_leaders || [];
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);

  const [openAddLeaderModal, setOpenAddLeaderModal] = useState(false);
  const [openAddInhaberModal, setOpenAddInhaberModal] = useState(false);
  const [inhaberList, setInhaberList] = useState(pre_business_owner);
  const [leaderList, setLeaderList] = useState(pre_technical_leaders);
  const [initialValuesInhaberLeader, setInitialValuesInhaberLeader] = useState({
    leader: null,
    inhaber: null,
  });
  const [initialInhaberLeaderDirty, setInitialInhaberLeaderDirty] = useState(false)

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const cmsData = cmsDashboarddata?.business_premises;
  let { business_owners, technical_leaders, ...detail } = business.detail; // eslint-disable-line no-unused-vars
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: { ...detail, country: detail?.country === "DE" ? "Deutschland" : detail?.country, ik_number: business.detail.ik_number.includes("[") ? JSON?.parse(business.detail.ik_number) : business.detail.ik_number },
    validationSchema: "",
    onSubmit: (values) => {
      let business_owners = inhaberList.map((inhaber) => {
        let preInhaber = pre_business_owner.find((pre_Inhaber) => pre_Inhaber.id === inhaber.id);
        if (preInhaber) {
          return inhaber;
        } else {
          let { id, ...rest } = inhaber; // eslint-disable-line no-unused-vars
          return rest;
        }
      });
      let technical_leaders = leaderList.map((leader) => {
        let preLeader = pre_technical_leaders.find((pre_leader) => pre_leader.id === leader.id);
        if (preLeader) {
          return { ...leader, professional_groups: JSON.parse(leader?.professional_groups) };
        } else {
          let { id, ...rest } = leader; // eslint-disable-line no-unused-vars
          return { ...rest, professional_groups: JSON.parse(rest?.professional_groups) };
        }
      });
      let ik_number = JSON.stringify(values.ik_number.map((number) => number.toString()));
      if (business_owners.length > 0 && technical_leaders.length > 0) {
        const payload = {
          ...values,
          country: detail?.country,
          ik_number: JSON?.parse(ik_number),
          business_owners: business_owners,
          technical_leaders: technical_leaders,
        };
        dispatch(updateBusinessPremises(payload));
        setFormReset(true)
      } else {
        toast.error('Mindestens ein "Inhaber" und ein "Fachlicher Leiter" müssen hinzugefügt werden.');
      }
    },
  });

  const closeAddLeaderModalHandle = () => {
    setOpenAddLeaderModal(false);
    setInitialValuesInhaberLeader({
      leader: null,
      inhaber: null,
    });
  };
  const openAddLeaderModalHandle = () => {
    setOpenAddLeaderModal(true);
  };

  const handleCloseInhaberModal = () => {
    setOpenAddInhaberModal(false);
    setInitialValuesInhaberLeader({
      leader: null,
      inhaber: null,
    });
  };

  const openAddInhaberModalHandle = () => {
    setOpenAddInhaberModal(true);
  };

  const addLeaderHandler = (leader) => {
    let group = leader?.professional_groups?.map((leader) => leader?.id);
    if (leader?.id) {
      let UpdateLeader = leaderList?.map((user) => (user?.id === leader?.id ? { ...leader, professional_groups: JSON?.stringify(group) } : user));
      setLeaderList(UpdateLeader);
    } else if (leader?.newId) {
      let find = leaderList?.find((item) => item?.id === leader?.newId);
      if (find) {
        let UpdateLeader = leaderList?.map((user) => (user?.id === leader?.id ? { ...leader, professional_groups: JSON?.stringify(group) } : user));
        setLeaderList(UpdateLeader);
      } else {
        setLeaderList((prev) => [
          ...prev,
          {
            id: leader?.newId,
            ...leader,
            professional_groups: JSON.stringify(group),
          },
        ]);
      }
    } else {
      setLeaderList((prev) => [
        ...prev,
        {
          id: String12Random(12),
          ...leader,
          professional_groups: JSON.stringify(group),
        },
      ]);
    }
    initialInhaberLeaderDirty==false && setInitialInhaberLeaderDirty(true)
  };

  const addInhaberHandler = (inhaber) => {
    if (inhaber.id) {
      let UpdateInhaber = inhaberList?.map((item) => (item?.id === inhaber?.id ? inhaber : item));
      setInhaberList(UpdateInhaber);
    } else if (inhaber?.newId) {
      let find = inhaberList?.find((item) => item.id === inhaber?.newId);
      if (find) {
        let UpdateInhaber = inhaberList?.map((item) => (item.id === inhaber?.newId ? inhaber : item));
        setInhaberList(UpdateInhaber);
      } else {
        setInhaberList((prev) => [...prev, { id: inhaber?.newId, ...inhaber }]);
      }
    } else {
      setInhaberList((prev) => [...prev, { id: String12Random(12), ...inhaber }]);
    }
    initialInhaberLeaderDirty==false && setInitialInhaberLeaderDirty(true)
  };

  const removeInhaberHandler = (id) => {
    let inhaberFilter = inhaberList?.map((inhaber) => {
      if (inhaber.id === id) {
        return { ...inhaber, status: "delete" };
      } else {
        return inhaber;
      }
    });
    setInhaberList(inhaberFilter);
    initialInhaberLeaderDirty==false && setInitialInhaberLeaderDirty(true)
  };

  const editInhaberHandler = (inhaber) => {
    setInitialValuesInhaberLeader({
      leader: null,
      inhaber: inhaber,
    });
    setOpenAddInhaberModal(true);
    initialInhaberLeaderDirty==false && setInitialInhaberLeaderDirty(true)
  };

  const editLeaderHandler = (leader) => {
    setInitialValuesInhaberLeader({
      leader: leader,
      inhaber: null,
    });
    setOpenAddLeaderModal(true);
    initialInhaberLeaderDirty==false && setInitialInhaberLeaderDirty(true)
  };

  const editNumberToggler = () => {
    formik.setFieldValue("ik_number", [...formik.values.ik_number, ""]);
  };

  const ikNumberRemoveHandle = (index) => {
    const updatedIkNumbers = [...formik.values.ik_number];
    updatedIkNumbers.splice(index, 1);
    formik.setFieldValue("ik_number", updatedIkNumbers);
  };

  const removeFachlicheLeiterHandler = (contact_id) => {
    let newleaderList = leaderList.map((leader) => {
      if (leader?.contact_id === contact_id) {
        return { ...leader, status: "delete" };
      } else {
        return leader;
      }
    });
    setLeaderList(newleaderList);
    initialInhaberLeaderDirty==false && setInitialInhaberLeaderDirty(true)
  };

  const InhaberwechselHandle = (service) => {
    const locationDetail = business?.detail;
    localStorage.setItem("dformId", service);
    localStorage.setItem("locationDetailForm", JSON.stringify(locationDetail));
    localStorage.setItem("percentageStep", "0");
    Cookies.remove("submitted_id");

    if (profile?.data?.role === "admin") {
      navigate("/admin/verfahren/antrag/" + service);
    } else {
      navigate("/verfahren/antrag/" + service);
    }
    dispatch(setApplicationSidebar());
  };

  const dateCheck = (pqDate)=> {

    if (!pqDate) {
        return true;
    }
    
    const parsedDate = new Date(pqDate);
    
    if (isNaN(parsedDate)) {
        return false;
    }
    
    const today = new Date();
    
    today.setHours(0, 0, 0, 0);
    
    if (parsedDate < today) {
        return true;
    }
    
    return false;
  }

  /** enabled-disabled feilds */
  const editAccess = business?.detail?.editable && (dateCheck(business?.detail?.date) || business?.detail?.service_order_nbr?.length === 0) ? false : true;
  return (
    <>
      <h2 className="!mb-0 text-2xl font-tahoma-bold m-3 sm:m-4 md:m-6">{business?.detail?.name}</h2>
      <form className="mx-3 sm:mx-4 md:mx-6" onSubmit={formik.handleSubmit}>
        <div className="flex justify-end w-full">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_name",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>
        <RegisterGrid heading={cmsData?.add_form_name?.name} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8 ">
          <InputStandardField placeholder={cmsData?.add_form_name?.name} id="name" formik={formik} disabled={editAccess} />
        </RegisterGrid>
        <hr />

        <RegisterGrid heading={cmsData?.add_form_name?.ik_number} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8 ">
          {formik.values.ik_number.map((number, index) => {
            return (
              <Fragment key={index}>
                <InputStandardFieldArray placeholder={cmsData?.add_form_name?.ik_number} type="number" id={`ik_number`} disabled={editAccess} idIndex={index} formik={formik} />
                {!editAccess && <>
                {formik.values.ik_number.length > 1 ? (
                  <CgCloseR
                    className="cursor-pointer text-red-400 mt-4"
                    onClick={() => {
                      ikNumberRemoveHandle(index);
                    }}
                  />
                ) : (
                  <div></div>
                )} 
                </>}
              </Fragment>
            );
          })}
          {!editAccess && <div className="flex gap-x-3 items-center">
            <CircularButton title={cmsData?.add_ik_number_button?.create_button} handler={editNumberToggler} css="!justify-start" />
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "business_premises",
                    unique: "add_ik_number_button",
                    heading: "Betriebsstätten",
                  })
                );
              }}
            />
          </div>}
        </RegisterGrid>
        <hr />
        <div className="w-full flex justify-between mt-2">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "address",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_location",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>
        <RegisterGrid heading={cmsData?.address?.address} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8 gap-y-4 ">
          <InputStandardField
            placeholder={cmsData?.add_form_location?.state}
            disabled={editAccess}
            label={cmsData?.add_form_location?.state}
            extra="!items-start"
            id="street"
            formik={formik}
          />
          <InputStandardField
            placeholder={cmsData?.add_form_location?.house_number}
            type="number"
            disabled={editAccess}
            label={cmsData?.add_form_location?.house_number}
            extra="!items-start"
            id="house_number"
            formik={formik}
          />
          <InputStandardField
            placeholder={cmsData?.add_form_location?.city}
            disabled={editAccess}
            label={cmsData?.add_form_location?.city}
            extra="!items-start"
            id="city"
            formik={formik}
          />
          <InputStandardField
            placeholder={cmsData?.add_form_location?.postcode}
            label={cmsData?.add_form_location?.postcode}
            type="number"
            extra="!items-start"
            disabled={editAccess}
            id="post_code"
            formik={formik}
          />
          <InputStandardField
            placeholder={cmsData?.add_form_location?.country}
            label={cmsData?.add_form_location?.country}
            extra="!items-start"
            id="country"
            formik={formik}
            disabled={true}
          />
        </RegisterGrid>
        <hr />
        <div className="w-full flex justify-end mt-2">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_mail",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>
        <RegisterGrid heading={cmsData?.add_form_mail?.email} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8 ">
          <InputStandardField placeholder={cmsData?.add_form_mail?.email} id="official_email" formik={formik} />
        </RegisterGrid>
        <hr />
        <RegisterGrid heading={cmsData?.add_form_mail?.telephone} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8 ">
          <InputStandardField placeholder={cmsData?.add_form_mail?.telephone} type="number" id="phone_number" formik={formik} />
        </RegisterGrid>
        <hr />
        <div className="w-full flex justify-end mt-2">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_owner",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>

        <RegisterGrid heading={cmsData?.add_form_owner?.heading} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%] p-0"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8">
          <div className="w-full md:max-w-[80%]">
            <ul>
              <li className="text-sm text-SmokeyGray">{cmsData?.add_form_owner?.name}</li>
              {inhaberList.map((user, index) => {
                return (
                  user.status !== "delete" && (
                    <li className="flex justify-between text-[#1B1B1B] mt-2" key={index}>
                      <div>{user.title + " " + user.first_name + " " + user.last_name}</div>
                      <div className="flex gap-x-2">
                        <AiFillEdit
                          className={`text-SmokeyGray ${!editAccess ? "cursor-pointer" : "cursor-not-allowed opacity-30"}`}
                          onClick={() => !editAccess && editInhaberHandler(user)}
                        />

                        <RiDeleteBinFill
                          className={`text-SmokeyGray ${!editAccess ? "cursor-pointer" : "cursor-not-allowed opacity-30"}`}
                          onClick={() => !editAccess && removeInhaberHandler(user.id)}
                        />
                      </div>
                    </li>
                  )
                );
              })}
            </ul>
            {!editAccess && <CircularButton title={cmsData?.add_form_owner?.new_button} handler={openAddInhaberModalHandle} />}
          </div>
        </RegisterGrid>
        <hr />
        <div className="w-full flex justify-between mt-2">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_leader",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "groups",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>

        <RegisterGrid heading={cmsData?.add_form_leader?.heading} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:gap-x-8 ">
          <div className="grid grid-cols-3">
            <div className="col-span-3 xl:col-span-2">
              <div className="w-full">
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 py-1">
                  <p className="text-sm text-SmokeyGray xl:col-span-1">{cmsData?.add_form_leader?.name}</p>
                  <p className="text-sm text-SmokeyGray xl:col-span-2">{cmsData?.groups?.professional_groups}</p>
                </div>
                {leaderList.map((user, index) => {
                  return (
                    user.status !== "delete" && (
                      <AutoCompleteFieldCard
                        user={user}
                        disabled={editAccess}
                        LegalPersonList={business?.professionalGroup?.data}
                        key={index}
                        setLeaderList={setLeaderList}
                        leaderList={leaderList}
                        removeFachlicheLeiterHandler={removeFachlicheLeiterHandler}
                        handler={() => {
                          !editAccess && editLeaderHandler(user);
                        }}
                      />
                    )
                  );
                })}
                {!editAccess && (
                  <div className="flex gap-x-3 items-center">
                    <CircularButton title={cmsData?.update_leader?.new_leaders} handler={openAddLeaderModalHandle} />
                    <EditIconCMS
                      handleOpen={() => {
                        dispatch(
                          dashboardModalOpenCMS({
                            cms_key: "business_premises",
                            unique: "update_leader",
                            heading: "Betriebsstätten",
                          })
                        );
                      }}
                    />
                  </div>
                )}

                <div className="flex mt-12 md:justify-between flex-col md:flex-row gap-3 md:flex-wrap">
                  <div className="flex justify-start gap-4 items-center flex-col md:flex-row ">
                    <SecondaryButton title={"Inhaberwechsel"} css="!w-[150px]" handler={() => InhaberwechselHandle("IWA")} />
                    <SecondaryButton title={cmsData?.update_extra_button?.move} css="!w-[150px]" handler={() => InhaberwechselHandle("UA")} />
                    <SecondaryButton title={cmsData?.update_extra_button?.quit} css="!w-[150px]" handler={() => InhaberwechselHandle("KA")} />
                    <EditIconCMS
                      handleOpen={() => {
                        dispatch(
                          dashboardModalOpenCMS({
                            cms_key: "business_premises",
                            unique: "update_extra_button",
                            heading: "Betriebsstätten",
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="flex justify-center md:justify-end gap-4 items-center">
                    <BackdropButton type="submit" title={cmsData?.update_form_button?.add_button} btnStyle="!w-[150px]" loading={business?.isLoading} disable={!(formik.dirty || initialInhaberLeaderDirty)}/>{" "}
                    <EditIconCMS
                      handleOpen={() => {
                        dispatch(
                          dashboardModalOpenCMS({
                            cms_key: "business_premises",
                            unique: "update_form_button",
                            heading: "Betriebsstätten",
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RegisterGrid>
        {openAddLeaderModal && (
          <AddUserForm
            handleClose={closeAddLeaderModalHandle}
            handleOpen={openAddLeaderModal}
            addLeaderHandler={addLeaderHandler}
            initialValues={initialValuesInhaberLeader.leader}
            isLoading={business?.contactList?.isLoading}
          />
        )}
        {openAddInhaberModal && (
          <AddInhaberForm
            handleClose={handleCloseInhaberModal}
            handleOpen={openAddInhaberModal}
            addinhaberHandler={addInhaberHandler}
            initialValues={initialValuesInhaberLeader.inhaber}
            isLoading={business?.contactList?.isLoading}
          />
        )}
      </form>
    </>
  );
};

export default EditForm;
