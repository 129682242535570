/* eslint-disable */
import styles from "./style.module.css";
import { Box, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ModalContent from "../ModalContent";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { procedureTemplates } from "src/store/actions/procedure";
import { styleModal } from "src/components/atoms/modal/modal";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const OverviewModal = ({ open, handleClose }) => {
  const procedure = useSelector((state) => state.procedure);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(procedureTemplates());
  }, []);

  const handleHint = () => {
    window.open("https://afpq.crisp.help/de/article/welche-antrage-kann-ich-stellen-1l2qlbz/?bust=1718975760554", "_blank");
  }

  return (
    <>
      <Modal open={open} className={styles.customModalRoot}>
        <Box sx={styleModal} className={styles.customInnerChild} tabIndex={"undefined"}>
          <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
            <div className="flex justify-between items-center mb-2 md:px-8">
              <h2 className="text-xl md:text-2xl font-tahoma-bold flex items-center gap-x-3">Neuer Antrag</h2>
              <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2" onClick={handleClose} />
            </div>
            <p className="flex items-center mb-6 md:px-8 text-red-400 font-medium">
              <span className="cursor-pointer flex items-center" onClick={handleHint}>
                <AiOutlineQuestionCircle className="mr-1" />
                Welche Anträge kann ich stellen?
              </span>
            </p>
            {procedure?.isLoading ? (
              <div className="flex justify-center items-center mt-4 min-h-[80vh]">
                <CustomCircularLoader />
              </div>
            ) : procedure?.data?.length > 0 ? (
              <ModalContent />
            ) : (
              <p className="my-12 text-red-500 text-center">Keine Daten gefunden</p>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default OverviewModal;
