import { Checkbox } from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CustomCheckbox = ({ handle=()=>{}, isChecked }) => {
  return (
    <Checkbox
      {...label}
      sx={{
        color: "transparent",
        borderRadius: "4px",
        border: "1px solid #706F6F",

        "&.Mui-checked": {
          color: "#A31D1A",
          border: "1px solid #A31D1A",
          "& .MuiSvgIcon-root": {
            color: "A31D1A",
            borderRadius: "25px",
          },
        },
        "& .MuiCheckbox-indeterminate .MuiSvgIcon-root": {
          color: "#656565",
        },

        margin: 0,
        padding: 0,
      }}
      checkedIcon={<SquareIcon />}
      onChange={(event) => handle(event)}
      checked={isChecked}
    />
  );
};

export default CustomCheckbox;
