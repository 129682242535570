/* eslint-disable */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApplicationVerifyFinished from "src/components/organisms/dform/ApplicationVerify/ApplicationVerifyFinished";
import EvidenceUploadConfirm from "src/components/organisms/dform/EvidenceUploadConfirm";
import { addCurrentStepDetail } from "src/store/actions/currentStep";

const StepsFormFinished = ({ activeStep, data, step_id, stepID, createServiceOrderSubmit }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addCurrentStepDetail({ detail: data[step_id], step_id }));
  }, []);
  return (
    <>
      {activeStep === 0
        ? data[step_id] && stepID === step_id && <ApplicationVerifyFinished data={data[step_id]} step_id={step_id} createServiceOrderSubmit={createServiceOrderSubmit} />
        : activeStep === 1
        ? data[step_id] && stepID === step_id && <EvidenceUploadConfirm data={data[step_id]} step_id={step_id} />
        : null}
    </>
  );
};

export default StepsFormFinished;
