import { TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoCompleteLabel from 'src/components/atoms/input/AutoCompleteField/AutoCompleteLabel';
import { addCurrentStepDetailValueOnchange, removeCurrentStepLocationById } from 'src/store/actions/currentStep';
import { IoTrashBinOutline } from 'react-icons/io5';

const AreaTable = ({ data, index, valueId, css, datadisable, datakey, fullData = '' }) => {
  const dispatch = useDispatch();
  const [selectedLeader, setSelectedLeader] = useState(data?.selectedTechnicalLeaders);
  const [technicalLeader, setTechnicalLeader] = useState(
    data.technicalLeaders?.length > 0
      ? data.technicalLeaders?.map((leader) => {
          return { id: leader.id, name: leader.name };
        })
      : []
  );
  const handleRemoveService = (index) => {
    dispatch(removeCurrentStepLocationById({ valueId: valueId, index: index }));
  };

  const handleChange = (event, value) => {
    dispatch(
      addCurrentStepDetailValueOnchange({
        valueId: fullData?.id,
        value: [
          {
            description: fullData?.inputTypeValue[0].description,
            selectedArea: fullData?.inputTypeValue[0].selectedArea,
            selectedTechnicalLeaders: value,
            specialCriteria: fullData?.inputTypeValue[0].specialCriteria,
            specialCriteriaIds: fullData?.inputTypeValue[0].specialCriteriaIds,
            technicalLeaders: technicalLeader,
          },
        ],
        valueKey: "inputTypeValue",
      })
    );
    setSelectedLeader(value);
  };

  useEffect(() => {
    setTechnicalLeader(
      data.technicalLeaders?.length > 0
        ? data.technicalLeaders?.map((leader) => {
            return { id: leader.id, name: leader.name };
          })
        : []
    );
  }, [data]);

  return (
    <>
      <TableRow key={index} className={css} data-key={datakey} data-disable={datadisable}>
        <TableCell component="th" scope="row">
          <span className="!text-black">{data?.description}</span>
        </TableCell>
        <TableCell align="left" className="!text-left textStyle">
          <ul className="list-disc pl-4">
            {data?.includedAid?.split(",")?.map((tool, toolIndex) => (
              <li className="!text-SmokeyGray" key={toolIndex}>
                {tool}
              </li>
            ))}
          </ul>
        </TableCell>
        <TableCell align="left" className="!text-left textStyle">
          <ul className="list-disc pl-4">
            {data?.specialCriteria &&
              data?.specialCriteria?.map((criteria, criteriaIndex) => (
                <li className="!text-SmokeyGray w-[250px]" key={criteriaIndex}>
                  {criteria}
                </li>
              ))}
          </ul>
        </TableCell>

        <TableCell>
          <div className="flex  flex-col items-end gap-5 h-full relative jus">
            <div className="flex items-center " style={{ width: "100%" }}>
              <div className="rounded-[12px] border border-AFPLightGray flex justify-between px-0 pr-2 flex-1">
                {technicalLeader?.length > 0 && <AutoCompleteLabel selectedPersonOption={selectedLeader} LegalPersonList={technicalLeader} handleChange={handleChange} />}
              </div>
            </div>
          </div>
        </TableCell>

        <TableCell>
          <div className="flex  flex-col items-end gap-5 h-full relative jus" title="Versorgungsbereich löschen">
            <div className="bg-gray-200 rounded-full p-2 shadow-md flex justify-center items-center cursor-pointer w-10 h-10">
              <IoTrashBinOutline className="text-gray-500 text-[1.2rem]" onClick={() => handleRemoveService(index)} />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AreaTable;
