import DecreasefillCard from "../DecreasefillCard";
import ExpandableCard from "../ExpandableCard";

const ServiceAreaShowExpand = ({ showCard, page, aid, helperText, miniAids }) => {
  return (
    <div className={`hidden lg:block ${miniAids ? "md:min-w-[90px] lg:min-w-[90px] xl:min-w-[90px]" : "w-[700px]"}`}>
      <div
        className="w-full h-10 relative bg-transparent"
        style={{
          position: "sticky",
          top: "120px",
        }}
      >
        {showCard && (
          <div className=" absolute w-full top-0 left-0">
            <DecreasefillCard className="relative w-[100%]" miniAids={miniAids}>
              <ExpandableCard page={page} aid={aid} miniAids={miniAids} helperText={helperText} />
            </DecreasefillCard>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceAreaShowExpand;
