import React from "react";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordUser } from "src/store/actions/users";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { PasswordCheckList, PasswordCompare } from "src/components/atoms/input";
import { INITIALVALUESCHANGEPASSWORD, VALIDATIONSCHEMACHANGEPASSWORD } from "src/services/validation/users";
import BackdropSubmit from "src/components/atoms/button/BackdropSubmit";

const { passwordLevel, repeat } = {
  passwordLevel: "Passwort",
  repeat: "Passwort wiederholen",
};

const ChangePassword = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const prefix = cmsDashboarddata?.settings;
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const formik = useFormik({
    initialValues: INITIALVALUESCHANGEPASSWORD,
    validationSchema: VALIDATIONSCHEMACHANGEPASSWORD,
    onSubmit: (values) => {
      dispatch(changePasswordUser({ newPassword: values.password, id: Cookies.get("userId"), oldPassword: values.oldPassword }));
    },
  });
  return (
    <div className="px-1 md:px-2 py-6 w-full lg:w-3/4 xl:w-3/5 2xl:w-2/5">
      {cmsDashboarddata.listLoaded && (
        <>
          <p className="textStyle flex items-center gap-3">
            {prefix?.ch_note?.notes}
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "settings",
                    unique: "ch_note",
                    heading: "Einstellungen",
                  })
                );
              }}
            />
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="my-5">
              <PasswordCheckList id="oldPassword" unique="ch_input1" label={prefix?.ch_input1?.title} placeholder={passwordLevel} type="standard" formik={formik} />
            </div>
            <div className="mb-5">
              <PasswordCheckList id="password" unique="ch_input2" label={prefix?.ch_input2?.title} placeholder={passwordLevel} type="checkpassword" formik={formik} />
            </div>
            <div className="mb-5">
              <PasswordCompare placeholder={repeat} id="repeat" name="repeat" formik={formik} />
            </div>
            <div className="flex items-center gap-3">
              <div className="w-full md:w-40">
                <BackdropSubmit css="flexCenter" type="submit" btnTitle={prefix?.ch_submit_button?.title} loading={users?.isLoading} />
              </div>
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "ch_submit_button",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ChangePassword;
