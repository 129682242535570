import { put } from 'redux-saga/effects';
import {
  getServiceAreaDetailFailure,
  getServiceAreaDetailSuccess,
  getServiceAreaFailure,
  getServiceAreaSuccess,
  getStepsFailure,
  getStepsSuccess,
} from 'src/store/actions/dform';
import { GET_SERVICE_AREA, GET_SERVICE_AREA_DETAIL, GET_STEPS } from '../requests/dform';
import Cookies from 'js-cookie';
import { addNewTechnicalLeader } from 'src/store/actions/currentStep';

export function* getStepsHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_STEPS(queryParams);
    if (response.status) {
      yield put(getStepsSuccess(response.data));
    } else {
      yield put(
        getStepsFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getStepsFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getServiceAreaHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_SERVICE_AREA(queryParams);
    if (response.status) {
      yield put(getServiceAreaSuccess(response.data));
    } else {
      yield put(
        getServiceAreaFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getServiceAreaFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getServiceAreaDetailHandler(action) {
  try {
    let {detailType, ...rest} = action.payload
    const queryParams = {
      queryParams: {
        ...rest,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_SERVICE_AREA_DETAIL(queryParams);
    if (response.status) {
      if(detailType){
        let newPayload = {...response.data, detailType}
        yield put(getServiceAreaDetailSuccess(newPayload));
      } else {
        yield put(getServiceAreaDetailSuccess(response.data));
      }
    } else {
      yield put(
        getServiceAreaDetailFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getServiceAreaDetailFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getServiceAreaTechnicalLeaderHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_SERVICE_AREA_DETAIL(queryParams);
    if (response.status) {
      let res = {
        ...response.data,
        serviceArea: action.payload.service_id
      }
      yield put(addNewTechnicalLeader(res));
    } else {
      yield put(
        getServiceAreaFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getServiceAreaFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}