import { CheckBox, InputField, PasswordField } from "src/components/atoms/input";
import { INITIALVALUESLOGIN, VALIDATIONSCHEMALOGIN } from "src/services/validation/users";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/store/actions/users";
import { SubmitButton } from "src/components/atoms/button";
import AccountYet from "src/components/atoms/extra/AccountYet";

const { email, password, checkbox, forget, btnHeading } = {
  email: "E-Mail",
  password: "Passwort",
  checkbox: "Für 30 Tage speichern",
  forget: "Passwort vergessen?",
  btnHeading: "Einloggen",
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.users);

  const formik = useFormik({
    initialValues: INITIALVALUESLOGIN,
    validationSchema: VALIDATIONSCHEMALOGIN,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });

  return (
    <form className="p-4 py-1 2xl:py-4" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-8">
        <InputField name="email" css="!font-tahoma-bold" label={email} type="email" variant="standard" formik={formik} />
        <PasswordField name="password" css="!font-tahoma-bold" label={password} formik={formik} />
      </div>
      <div className="flex items-center justify-between 2xl:my-6 my-2 ml-2">
        <div className="flex items-start">
          <CheckBox css="flex items-center h-5" name="remember" label={checkbox} formik={formik} />
        </div>
        <p onClick={() => navigate("/passwort-vergessen")} className="text-sm text-SmokeyGray cursor-pointer">
          {forget}
        </p>
      </div>
      <SubmitButton css="flexCenter mt-4 2xl:mt-16" type="submit" btnTitle={btnHeading} loading={users.isLoading} />
      <AccountYet />
    </form>
  );
};

export default LoginForm;
