import {
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_STARTED,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_MESSAGE_FAILURE,
  GET_DASHBOARD_MESSAGE_STARTED,
  GET_DASHBOARD_MESSAGE_SUCCESS,
} from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  welcome: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_STARTED: {
      return {
        data: null,
        isLoading: true,
        error: null,
        welcome: {
          ...state.welcome,
        },
      };
    }

    case GET_DASHBOARD_DATA_SUCCESS: {
      return {
        data: action.payload,
        isLoading: false,
        error: null,
        welcome: {
          ...state.welcome,
        },
      };
    }

    case GET_DASHBOARD_DATA_FAILURE: {
      return {
        data: null,
        isLoading: false,
        error: null,
        welcome: {
          ...state.welcome,
        },
      };
    }
    case GET_DASHBOARD_MESSAGE_STARTED: {
      return {
        ...state,
        welcome: {
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }

    case GET_DASHBOARD_MESSAGE_SUCCESS: {
      return {
        ...state,
        welcome: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    }

    case GET_DASHBOARD_MESSAGE_FAILURE: {
      return {
        ...state,
        welcome: {
          data: null,
          isLoading: false,
          error: null,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
