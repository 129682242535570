/* eslint-disable */

import { PrimaryButton } from "src/components/atoms/button";
import { UploadInputField } from "src/components/atoms/input";
import StandardSelect from "../../select/StandardSelect";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import CameraAssets from "./CameraAssets";
import BackdropButton from "src/components/atoms/button/BackdropButton";

const AddDocumentForm = ({ formik, uploadedImage, setUploadedImage, showCamera, setShowCamera }) => {
  const document = useSelector((state) => state.document);
  const [docUploadStatus, setDocUploadStatus] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(true);
  const cmsDocumentdata = useSelector((state) => state?.cmsDocument?.document);

  const allLocations = document?.selfDocuments?.data?.map((item) => ({
    title: item?.location_name,
    value: item?.location_id,
  }));

  const documentCategory = document?.documentCategory?.data;

  const categoryList = documentCategory?.map((doc) => ({
    title: doc?.name,
    value: doc?.id,
  }));

  useEffect(() => {
    if (allLocations.length === 1 && formik.values.location_id !== allLocations[0].value) {
      formik.setFieldValue("location_id", allLocations[0].value);
    }
  }, [allLocations, formik.values.location_id, formik]);

  const submitValidation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (!(formik.values.docs.length > 0) || !uploadedImage) {
      setDocUploadStatus(true);
    }
  };

  const resetDocStatus = (status) => {
    setDocUploadStatus(status);
  };

  const dispatch = useDispatch();
  const isSelectedCategory = formik.values.category_id && formik.values.category_id !== "";
  const isSelectedLocation = formik.values.location_id && formik.values.location_id !== "";

  return (
    <form className="flex flex-col gap-4" onSubmit={submitValidation}>
      <StandardSelect
        extra="w-full mb-4"
        extraLabel="hidden"
        label={"Kategorie"}
        placeholder="Type"
        id="category_id"
        options={[...categoryList]}
        choose={!isSelectedCategory}
        formik={formik}
      />
      <StandardSelect
        extra="w-full mb-6"
        extraLabel="hidden"
        label={"Betriebsstätten"}
        placeholder="Type"
        id="location_id"
        options={[...allLocations]}
        choose={!isSelectedLocation}
        formik={formik}
      />
      <CameraAssets
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
        showCamera={showCamera}
        setShowCamera={setShowCamera}
        cameraAccess={cameraAccess}
        setCameraAccess={setCameraAccess}
      />
      {!uploadedImage && cameraAccess && <h4 className="text-sm text-center">Oder</h4>}
      {!uploadedImage && <UploadInputField formik={formik} resetDocStatus={resetDocStatus} />}
      {docUploadStatus || (!uploadedImage && <div className="warning">Dokument muss ausgefüllt werden</div>)}
      <div className="flex justify-end mt-6">
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "document",
                unique: "upload_submit",
                heading: "Dokumentenverwaltung",
              })
            );
          }}
        />
      </div>
      <BackdropButton type="submit" title={cmsDocumentdata?.upload_submit?.title} loading={document?.documentUpload?.isLoading || document?.dmsDocumentUpload?.isLoading} />
    </form>
  );
};

export default AddDocumentForm;
