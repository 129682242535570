/* eslint-disable */
import groupPlus from "src/assets/img/groupPlus.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import CustomCheckbox from "src/components/atoms/input/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { uploadEvidenceStarted } from "src/store/actions/evidence";
import { CircularProgress } from "@mui/material";
import { FaRegFileAlt } from "react-icons/fa";
import { evidenceCheckDetail } from "src/helpers/dform/validateEvidence";
import { FaCirclePlus } from "react-icons/fa6";

const EvindenceSignature = ({ evedenceData, evidence, handleCheckChangeDetailFill, entriesKey }) => {
  const [fileCheck, isFileCheck] = useState(evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length > 0 ? true : false);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const inputRef = useRef();
  const [initialCheck, setInitialCheck] = useState("");
  const { service, id } = useParams();

  const evidenceUploadHandler = (e) => {
    const fileName = e.target.value;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "docx" || fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      dispatch(
        uploadEvidenceStarted({
          file: formData,
          evidenceId: evedenceData?.inventoryID?.value,
          service_order_types_id: evidence?.evidenceDetail?.details?.service_order_types_id || service,
          service_order_number: evidence?.evidenceDetail?.details?.erp_service_order_id || id,
          creator_user: profile.data.first_name + " " + profile.data.last_name,
        })
      );
      isFileCheck(true);
      if (evidence.evidenceDetail.data[evedenceData?.inventoryID?.value] && evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length > 0) {
        if (!initialCheck) {
          setInitialCheck("check");
        }
      } else {
        setInitialCheck("");
      }
    } else {
      toast.error("Ungültiger Dateityp. Es werden nur PDF- und DOC-Dateien akzeptiert.");
    }
  };

  const fileCheckhandle = (event) => {
    let checkedValue = event.target.checked;
    if (evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length > 0) {
      isFileCheck(checkedValue);
    } else {
      toast.error("Datei aussuchen");
    }
  };
  useEffect(() => {
    let newObj = {};
    let markObj = {};
    evidence?.data?.forEach((ab) => {
      ab?.prerequisiteGroup?.value;
      if (newObj[ab?.prerequisiteGroup?.value]) {
        newObj[ab?.prerequisiteGroup?.value]?.push(ab);
      } else {
        newObj[ab?.prerequisiteGroup?.value] = [ab];
        markObj[ab?.prerequisiteGroup?.value] = false;
      }
    });
    const isCheckUpdated = evidenceCheckDetail(newObj[entriesKey], evidence);
    if (isCheckUpdated) {
      handleCheckChangeDetailFill(entriesKey, true);
    } else {
      handleCheckChangeDetailFill(entriesKey, false);
    }
  }, [initialCheck]);
  return (
    <>
      <div className=" w-full mb-5 flex justify-between flex-wrap">
        <div className="flex gap-x-2 items-start justify-start" id={evedenceData?.inventoryID?.value}>
          <CustomCheckbox isChecked={fileCheck} handle={fileCheckhandle} />

          <div className="m-0 p-0">
            <div className="p-0 m-0 font-bold" dangerouslySetInnerHTML={{ __html: evedenceData?.prerequisite?.ValueDescription }}></div>
            <div className="text-DarkCharcoal text-[14px]" dangerouslySetInnerHTML={{ __html: evedenceData?.longDescription?.value }}></div>
          </div>
        </div>
        <div className=" flex gap-1 items-start text-[16px]">
          {evidence.evidenceDetail.data[evedenceData?.inventoryID?.value] ? evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length + " Datei" : ""}{" "}
          <MdOutlineKeyboardArrowRight fontSize={20} />
        </div>
      </div>
      {!isChecked ? <span className="text-red-400 text-sm">* Pflichtfeld</span> : null}
      <div className="flex items-center gap-4">
        {evidence.evidenceDetail.data[evedenceData?.inventoryID?.value] &&
          evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.map((data) => {
            return (
              <div className="w-[42px] h-[48px] flex justify-center items-center border rounded-md text-[#D5D5D5] overflow-hidden">
                {data.path?.split(".")?.pop()?.toLowerCase() === "png" ||
                data.path?.split(".")?.pop()?.toLowerCase() === "jpg" ||
                data.path?.split(".")?.pop()?.toLowerCase() === "jpeg" ? (
                  <img src={data.path} alt="idfImg" className="w-full h-full" />
                ) : (
                  <FaRegFileAlt className="text-4xl" />
                )}
              </div>
            );
          })}
        <div className="w-[42px] h-[48px] flex justify-center items-center border rounded-md text-[#D5D5D5] overflow-hidden">
          {evidence?.uploadEvidence?.isLoading && evedenceData.inventoryID.value == evidence?.uploadEvidence?.file_id ? (
            <CircularProgress sx={{ color: "#A31D1A" }} />
          ) : (
            // <img
            //   src={groupPlus}
            //   alt="idfImg"
            //   className="w-full h-full"
            //   onClick={() => {
            //     inputRef.current.click();
            //   }}
            // />
            <div className="bg-white w-full h-full cursor-pointer m-auto text-center" onClick={() => { inputRef.current.click() }}>
              <div className="bg-white w-full h-full cursor-pointer flex justify-center items-center hover:text-AFPPrimary">
                <FaCirclePlus className="text-2xl" />
              </div>
            </div>
          )}
          <input type="file" ref={inputRef} onChange={evidenceUploadHandler} className="hidden" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
        </div>
      </div>
    </>
  );
};

export default EvindenceSignature;
