/* eslint-disable */
import { Box, Modal } from "@mui/material";
import React from "react";
import { WiCloudUp } from "react-icons/wi";
import { AiOutlineClose } from "react-icons/ai";
import CameraEvidence from "src/components/atoms/modal/AddDocumentModal/CameraEvidence";
import { PrimaryButton } from "src/components/atoms/button";
import styles from "./mediamodal.module.css";

const style = {
  p: 2,
  left: "50%",
  width: "90%",
  maxWidth: "840px",
  bgcolor: "#fbfbfb",
  overflowY: "auto",
  top: "50%",
  borderRadius: "12px",
  position: "relative",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};
const MediaModal = ({
  open,
  closeHandle,
  uploadedImage,
  setUploadedImage,
  showCamera,
  setShowCamera,
  cameraAccess,
  setCameraAccess,
  evidenceUploadHandler,
  inputRef
}) => {
  return (
    <Modal keepMounted open={open} className={styles.customModalRoot}>
      <Box sx={style} className={styles.customInnerChild}>
        <div className="relative">
          <div className="flex justify-between items-center mb-6 md:px-2 min-h-12">
            <h2 className="text-xl md:text-2xl font-tahoma-bold flex items-center gap-x-3"></h2>
            <AiOutlineClose
              size={24}
              className="text-SmokeyGray cursor-pointer -mt-5"
              onClick={closeHandle}
            />
          </div>
          <hr className="mt-2 mb-9" />
        </div>
        <div className="flex flex-col gap-6 mb-12">
          <CameraEvidence
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
            showCamera={showCamera}
            setShowCamera={setShowCamera}
            cameraAccess={cameraAccess}
            setCameraAccess={setCameraAccess}
          />
          {!uploadedImage && cameraAccess && (
            <h4 className="text-sm text-center">Oder</h4>
          )}
          {!uploadedImage && (
              <div className="min-h-20 border border-dashed border-black rounded-lg bg-white flex justify-center items-center cursor-pointer" onClick={()=>{inputRef.current.click();closeHandle();}}>
              <WiCloudUp className="text-5xl text-AFPLightGray" />
            </div>
          )}
          {uploadedImage && cameraAccess && (
            <PrimaryButton title="Speichern" type="submit" handler={()=>evidenceUploadHandler(uploadedImage, 'base64')}/>
          )}
        </div>
      </Box>
    </Modal>
  );
};
export default MediaModal;
