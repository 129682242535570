/* eslint-disable */
import { useEffect, useState } from "react";
import { IoMdMail } from "react-icons/io";
import { IoIosHelpCircleOutline } from "react-icons/io";
import Badge from "@mui/material/Badge";
import HeaderDropdown from "src/components/molecules/HeaderDropdown";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfileRequest } from "src/store/actions/settings";
import { cmsContentList } from "src/store/actions/cmsDashboard";
import { useNavigate } from "react-router-dom";
import { getDashboardDataStarted } from "src/store/actions/dashboard";
const Header = () => {
  const [dTitle, setDTitle] = useState("");
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const dashboard = useSelector((state) => state.dashboard);
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.loading) {
      dispatch(fetchProfileRequest());
      dispatch(cmsContentList());
    }
    dispatch(getDashboardDataStarted());
  }, []);

  const headerButtonHandle = (url) => {
    if (url) {
      if (profile?.data?.role === "admin") {
        navigate("/admin" + url);
      } else {
        navigate(url);
      }
    }
  };

  return (
    <nav className="md:pl-80 2xl:pl-[21rem] absolute top-0 left-0 w-full z-10 bg-white hidden md:flex-row md:flex-nowrap md:justify-start md:flex items-center p-4 bg-PoliceBlue shadow-md">
      <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
        <div className="text-white text-lg uppercase inline-block font-semibold">
          {dTitle && (
            <span
              className="text-center mb-8 text-black text-xl font-semibold relative after:content-[''] after:w-[16px] after:h-[16px] after:bg-AppleHover after:absolute after:left-[-20px] after:top-[-4px] after:mb-[12px] before:content-[''] before:w-[16px] before:h-[16px] before:bg-Apple before:absolute before:mb-[12px] before:left-[-30px] before:top-[-12px] 
        before:z-10"
              onClick={() => {
                setDTitle("");
              }}
            >
              {dTitle}
            </span>
          )}
        </div>
        <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
          <div className="relative flex flex-row gap-4 items-center">
            <div className="w-10 h-10  flexCenter border-SmokeyGray cursor-pointer" onClick={() => headerButtonHandle("/posteingang")}>
              {dashboard?.data?.inbox?.newMessage > 0 ? (
                <Badge
                  badgeContent={`${dashboard?.data?.inbox?.newMessage !== undefined ? dashboard?.data?.inbox?.newMessage : ""}`}
                  color="primary"
                  sx={{
                    color: "red",
                    backgroundColor: "#A31D1A",
                    "& .MuiBadge-badge": {
                      backgroundColor: "#A31D1A",
                      minWidth: "16px",
                      height: "16px",
                      fontSize: "9px",
                    },
                  }}
                >
                  <IoMdMail className="text-[1.6rem] bg-white text-black hover:text-AFPPrimary" />
                </Badge>
              ) : (
                <IoMdMail className="text-[1.6rem] bg-white text-black hover:text-AFPPrimary" />
              )}
            </div>
            <div className="w-10 h-10  flexCenter border-SmokeyGray cursor-pointer hover:text-AFPPrimary" onClick={() => headerButtonHandle("/hilfe")}>
              <IoIosHelpCircleOutline className="text-[1.6rem]" />
            </div>
            <HeaderDropdown profile={profile?.data} />
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
