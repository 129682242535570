import React from "react";
import AppNavigator from "src/config/AppNavigator";
import ScrollToTop from "src/components/molecules/ScrollToTop";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "./store/configureStore";
function App() {
  return (
    <>
      <Provider store={store}>
        <Toaster
          toastOptions={{
            position: "top-center",
            duration: 3000,
            maxToasts: 1,
            style: {
              zIndex: 9999,  
            }
          }}
        />
        <ScrollToTop>
          <AppNavigator />
        </ScrollToTop>
      </Provider>
    </>
  );
}

export default App;
