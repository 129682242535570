export const businessPremisesHeader = (head) => {
  return [
    {
      id: 1,
      title: head.name,
      icons: true,
      sortKey: "name",
    },
    {
      id: 2,
      title: head.location,
      icons: true,
      sortKey: "house_number",
    },
    {
      id: 3,
      title: head.ik_number,
      icons: true,
      sortKey: "ik_number",
    },
    {
      id: 4,
      title: head.prequalifiedby,
      icons: true,
      sortKey: "prequalifiedBy",
    },
    {
      id: 5,
      title: head.owner,
      icons: true,
      sortKey: "business_owners[0].first_name",
    },
    {
      id: 6,
      title: head.leaders,
      icons: true,
      sortKey: "technical_leaders[0].first_name",
    },
    {
      id: 7,
      title: "",
      icons: false,
    },
  ];
};

export const documentFromAndforYouHeadCMS = (name, type, business_premises, application_type, date, document) => {
  return [
    {
      id: 1,
      title: name,
      icons: true,
      sortKey: "name",
    },
    {
      id: 2,
      title: type,
      icons: true,
      sortKey: "category",
    },
    {
      id: 3,
      title: business_premises,
      icons: true,
      sortKey: "location_name",
    },
    {
      id: 4,
      title: application_type,
      icons: true,
      sortKey: "service_order_type",
    },
    {
      id: 5,
      title: date,
      icons: true,
      sortKey: "created_at",
    },
    {
      id: 6,
      title: document,
      icons: true,
      sortKey: "file_type",
    },
  ];
};
