import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { DocumentBreadcrumbs } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import DocumentTab from "src/components/molecules/tabs/DocumentTab";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { documenTabs } from "src/mockData/settingMock";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

const DocumentNavigator = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const [changeOccure, setChangeOccure] = useState("");
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;
  const { id } = useParams();
  const [title, setTitle] = useState();
  const dispatch = useDispatch();

  const changeBreadcrumbs = (para) => {
    setChangeOccure(para);
  };
  useEffect(() => {
    if (cmsDashboarddata?.document?.tab) {
      setTitle(documenTabs(cmsDashboarddata?.document?.tab)?.tabs?.find((tab) => tab?.uuid == id));
    }
  }, [cmsDashboarddata?.document?.tab, changeOccure]);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-10 py-8 min-h-screen bg-AFPBGPrimary">
        {cmsDashboarddata?.document && (
          <>
            {sidebar_menu?.sidebar_list?.document && <Breadcrumbs data={DocumentBreadcrumbs(sidebar_menu?.sidebar_list?.document, title?.title)} />}
            {cmsDashboarddata.listLoaded && (
              <>
                <div className="bg-AFPBGPrimary mx-3 sm:mx-4 md:mx-6 mt-0 p-0">
                  <h2 className="!mb-5 text-2xl font-tahoma-bold my-3 sm:my-4 md:my-6 flex items-center gap-x-3">
                    {id === "afpq" ? cmsDashboarddata?.document?.heading?.title : cmsDashboarddata?.document?.heading2?.title}
                    <EditIconCMS
                      handleOpen={() => {
                        dispatch(
                          dashboardModalOpenCMS({
                            cms_key: "document",
                            unique: id === "afpq" ? "heading" : "heading2",
                            heading: "Dokumentenverwaltung",
                          })
                        );
                      }}
                    />
                  </h2>
                  <p className="textStyle max-w-[530px] mb-5">
                    {id === "afpq" ? cmsDashboarddata?.document?.heading?.discription : cmsDashboarddata?.document?.heading2?.discription}
                  </p>
                </div>
                {cmsDashboarddata?.document?.tab && <DocumentTab tabList={documenTabs(cmsDashboarddata?.document?.tab)} changeOccure={changeBreadcrumbs} />}
              </>
            )}
          </>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export default DocumentNavigator;
