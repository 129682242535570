import { TableCell, TableRow, TableBody } from "@mui/material";

const HelpTableBody = ({ bodyList, keys, standardBody }) => {
  return (
    <TableBody>
      {bodyList.map((item, index) => {
        return (
          <TableRow
            key={index}
            sx={{
              ...standardBody.TableBodyRow,
              "&:last-child td, &:last-child th": { border: 0 },
              td: {
                borderBottom: "none",
                color: "#000",
              },
              th: {
                borderBottom: "none",
              },
              background: index % 2 === 0 ? "#F3F4F6" : "#fff",
            }}
          >
            <TableCell>
              <p className="font-tahoma-bold text-black">{item[keys[0][0]]}</p>
              <p>{item[keys[0][0]]}</p>
            </TableCell>
            <TableCell className="">
              <p className="font-tahoma-bold text-center text-black">{item[keys[1]]}</p>
            </TableCell>
            <TableCell align="center">
              {item[keys[2]] === "Offen" ? (
                <button className="bg-transparent text-green-700 font-semibold w-32 py-1 px-2 border border-green-500 rounded">{item[keys[2]]}</button>
              ) : (
                <button className="bg-transparent text -AFPLightGray font-semibold w-32 py-1 px-2 border border-AFPLightGray rounded">{item[keys[2]]}</button>
              )}
            </TableCell>
            <TableCell className=" px-6 py-4 !text-black !font-tahoma-bold" align="center">
              {item[keys[3]]}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

HelpTableBody.defaultProps = {
  bodyList: [],
  standardBody: {
    TableBody: {},
    TableBodyRow: {},
  },
};

export default HelpTableBody;
