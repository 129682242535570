import "./headmenu.css";
import { Button, Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { LiaUserCircleSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { menuList } from "src/mockData/dashboard";
import CookieStorage from "src/services/storage/cookiesStorage";

export default function HeaderDropdown({ profile }) {
  const cookie = new CookieStorage();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, url) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (url) {
      if (profile?.role === "admin") {
        navigate("/admin" + url);
      } else {
        navigate(url);
      }
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const buttonStyle = {
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  return (
    <>
      <div className="w-10 h-10 flexCenter border-SmokeyGray cursor-pointer">
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={buttonStyle}
        >
          <LiaUserCircleSolid className={`text-[1.6rem] text-[#706F6F] hover:text-AFPPrimary ${open ? "text-AFPPrimary" : "text-[#706F6F]"}`} />
        </Button>
      </div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "right top",
            }}
          >
            <Paper className="bg-white !rounded-2xl !shadow-xl border border-BrightGray">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className="!min-w-44" autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={handleClose}
                    className="!text-center !justify-between gap-1 !text-ellipsis !text-gray-500 hover:text-gray-800 hover:bg-BrightGray !font-semibold"
                    // sx={{
                    //   "&:hover": {
                    //     background: "transparent",
                    //   },
                    // }}
                  >
                    {profile?.first_name} {profile?.last_name}
                    <IoIosArrowUp className="!text-AFPPrimary" />
                  </MenuItem>
                  {menuList.map((menu) => {
                    return (
                      <MenuItem
                        key={menu?.id}
                        onClick={(event) => {
                          handleClose(event, menu?.link);
                        }}
                        className="!text-gray-500 hover:text-gray-800 hover:bg-BrightGray !border-t-2 !border-SilverSand !border-solid !font-semibold"
                      >
                        {/* <ListItemIcon>{menu?.icon}</ListItemIcon> */}
                        {menu?.label}
                      </MenuItem>
                    );
                  })}
                  <MenuItem
                    onClick={() => {
                      cookie.logout();
                      window.location.reload();
                    }}
                    className="!text-gray-500 hover:text-gray-800 hover:bg-BrightGray !border-t-2 !border-SilverSand !border-solid !font-semibold"
                  >
                    Ausloggen
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

HeaderDropdown.defaultProps = {
  profile: { first_name: "", last_name: "" },
};
