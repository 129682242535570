import Visibility from "@mui/icons-material/Visibility";
import { useState } from "react";

const PasswordToggle = ({ password }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  function hideString(originalString) {
    return "*".repeat(originalString.length);
  }
  return (
    <>
      <h4 className="my-1 whitespace-nowrap overflow-hidden overflow-ellipsis">{showPassword ? password : hideString(password)}</h4>
      <h4 className="text-AFPSecondary text-sm mt-2">
        <Visibility className="cursor-pointer" onClick={handleClickShowPassword} />
        <span className="ml-2">Passwort anzeigen</span>
      </h4>
    </>
  );
};

PasswordToggle.defaultProps = {
  password: "********", 
};

export default PasswordToggle;
