import React from "react";
import style from "./checkbox.module.css";
const CheckBox = ({ css, inputcss, name, label, formik, type, disabled=false }) => {
  return (
    <>
      <div className={css}>
        <input
          disabled={disabled}
          id={name}
          aria-describedby="remember"
          type={type}
          className={`${style.input} ${inputcss}`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          checked={formik.values[name]}
        />
      </div>
      <div className={`${style.labelContainer}`}>
        <label htmlFor={name} className={`${style.label}`}>
          {label}
        </label>
      </div>
    </>
  );
};

CheckBox.defaultProps = {
  css: "flex items-center",
  name: "checkbox",
  label: "Label",
  type: "checkbox",
  inputcss: "",
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    values: { email: "" },
  },
};

export default CheckBox;
