import { put } from "redux-saga/effects";
import { getDocumentCategoryList, getDocumentList, getUserFilesList, UPLOAD_DMS_DOCUMENT, UPLOAD_DOCUMENT, UPLOAD_DOCUMENT_BASE64 } from "../requests/document";

import { documentListFailure, listDocumentCategoryFailure, listDocumentCategorySuccess, listFromToYouSuccess, listUserFilesFailure, listUserFilesSuccess, uploadDMSDocumentFailure, uploadDMSDocumentStarted, uploadDMSDocumentSuccess, uploadDocumentFailure, uploadDocumentSuccess } from "src/store/actions/document";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

export function* documentListHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getDocumentList(paramsData);
    if (response.status) {
      yield put(listFromToYouSuccess({data: response?.data, ...action.payload}));
    } else {
      yield put(
        documentListFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(documentListFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* listUserFilesHandler() {
  try {
    const queryParams = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getUserFilesList(queryParams);
    if (response.status) {
      yield put(listUserFilesSuccess(response.data));
    } else {
      yield put(
        listUserFilesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(listUserFilesFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* listDocumentCategorysHandler() {
  try {
    const queryParams = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getDocumentCategoryList(queryParams);
    if (response.status) {
      yield put(listDocumentCategorySuccess(response.data));
    } else {
      yield put(
        listDocumentCategoryFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(listDocumentCategoryFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* uploadDocumentHandler(action) {
  try {
    const response = yield UPLOAD_DOCUMENT(action.payload.file);
    if (response.status) {
      // yield put(uploadEvidenceSuccess({...response?.data, evidenceId: action.payload.evidenceId}));
      yield put(uploadDMSDocumentStarted({
        user_id: Cookies.get('userId'),
        directory_path: response?.data?.path,
        file_name: response?.data?.fileName,
        category_id: action.payload.category_id,
        category: action?.payload?.category,
        creator_user: action.payload.creator_user,
        location_id: action.payload.location_id,
        contact_id: action.payload.contact_id,
        date: action.payload.date
      }));
    } else {
      yield put(
        uploadDocumentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(uploadDocumentFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* uploadDocumentBase64Handler(action) {
  try {
    const base64payload = {
      user_id: Cookies.get('userId'),
      base64 : action.payload.base64
    }
    const response = yield UPLOAD_DOCUMENT_BASE64(base64payload);
    if (response.status) {
      // yield put(uploadEvidenceSuccess({...response?.data, evidenceId: action.payload.evidenceId}));
      yield put(uploadDMSDocumentStarted({
        user_id: Cookies.get('userId'),
        directory_path: response?.data?.path,
        file_name: response?.data?.fileName,
        category_id: action.payload.category_id,
        creator_user: action.payload.creator_user,
        location_id: action.payload.location_id,
        contact_id: action.payload.contact_id
      }));
    } else {
      yield put(
        uploadDocumentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(uploadDocumentFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* uploadDMSDocumentHandler(action) {
  try {
    const {category, ...newPayload} = action.payload // eslint-disable-next-line no-unused-vars
    console.log(category)
    const response = yield UPLOAD_DMS_DOCUMENT(newPayload);
    if (response.status) {
      yield put(uploadDocumentSuccess(response?.data));
      yield put(uploadDMSDocumentSuccess({data: response?.data, ...action.payload}));
      toast.success(response?.message);
    } else {
      yield put(
        uploadDMSDocumentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(uploadDMSDocumentFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}