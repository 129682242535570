import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useSearchParams } from "react-router-dom";

export default function AutoCompleteLabel({ selectedPersonOption, LegalPersonList, handleChange, isDisabled }) {
  const [selectedOptions, setSelectedOptions] = useState(selectedPersonOption ?? []);
  const [searchParams] = useSearchParams();

  const currentStepParam = searchParams.get("active");

  let locationDetailForm = JSON.parse(localStorage.getItem("locationDetailForm"));

  return (
    <Stack spacing={3} sx={{ width: "100%" }}>
      <Autocomplete
        disabled={!!(locationDetailForm && +currentStepParam !== 2) || isDisabled}
        multiple
        sx={{ height: "auto", padding: "0px", margin: "0px" }}
        id="tags-outlined"
        options={LegalPersonList}
        getOptionLabel={(option) => option?.name}
        onChange={(event, newVal) => {
          setSelectedOptions(newVal);
          handleChange(event, newVal);
        }}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value?.map((option, index) => {
            let personTag = LegalPersonList?.find((person) => person?.id === option?.id);
            return (
              <Tooltip key={index} title={personTag ? personTag?.name : option?.id}>
                <Chip label={option?.name} {...getTagProps({ index })} />
              </Tooltip>
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  height: "40px",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
                "&.Mui-focused:hover fieldset": {
                  border: "none",
                },
                "&:active fieldset": {
                  border: "none",
                },
              },
              "& .MuiAutocomplete-inputRoot": {
                padding: "6px 6px",
                "&:hover": {
                  outline: "none",
                },
                "&.Mui-focused": {
                  outline: "none",
                },
              },
            }}
            placeholder={LegalPersonList ? "Auswählen" : "Fachlichen Leiter nicht gefunden"}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionKey={(option) => option.id}
        value={selectedOptions}
      />
    </Stack>
  );
}
