import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { TableCell, TableHead, TableRow } from "@mui/material";

const TableHeader = ({ tableData, increaseSort, decreaseSort }) => {
  const increaseSortHandle = (key) => {
    increaseSort(key);
  };
  const decreaseSortHandle = (key) => {
    decreaseSort(key);
  };
  return (
    <TableHead>
      <TableRow
        sx={{
          th: {
            color: "#706F6F",
            "&:first-of-type": {
              paddingLeft: "40px",
            },
          },
        }}
      >
        {tableData.map((item, index) => {
          return (
            <TableCell key={index} className={`${item?.width && 'w-[266px]'}`}>
              <div className={`flex whitespace-nowrap gap-4  justify-start`}>
                {item.title}
                {item.icons === true && (
                  <div className="w-[20px] flex flex-col justify-center items-center text-SmokeyGray">
                    <SlArrowUp size={9} onClick={() => increaseSortHandle(item.sortKey)} className="flex cursor-pointer hover:text-AFPPrimary font-medium" />
                    <SlArrowDown size={9} onClick={() => decreaseSortHandle(item.sortKey)} className="flex cursor-pointer hover:text-AFPPrimary font-medium" />
                  </div>
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeader.defaultProps = {
  tableHeader: [],
  increaseSort: function () {},
  decreaseSort: function () {},
};

export default TableHeader;
