import {
  ADD_CURRENT_STEP_DETAIL,
  ADD_CURRENT_STEP_DETAIL_VALUE,
  ADD_CURRENT_STEP_DETAIL_VALUE_ONCHANGE,
  ADD_CURRENT_STEP_TECHNICAL_LEADER,
  REMOVE_CURRENT_STEP_LOCATION_BY_ID,
  RESET_CURRENT_STEP_DETAIL,
} from '../actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  stepID: '',
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CURRENT_STEP_DETAIL: {
      return { ...state, ...initialState };
    }
    case ADD_CURRENT_STEP_DETAIL: {
      return {
        ...state,
        data: action.payload.detail,
        stepID: action.payload.step_id,
      };
    }
    case ADD_CURRENT_STEP_DETAIL_VALUE: {
      let newDetail = state.data.map((detail) => {
        if (detail.id === action.payload.valueId) {
          return {
            ...detail,
            [action.payload.valueKey]: action.payload.value,
          };
        } else {
          return detail;
        }
      });
      return { ...state, data: newDetail };
    }

    case ADD_CURRENT_STEP_DETAIL_VALUE_ONCHANGE: {
      let newDetail = state.data.map((detail) => {
        if (detail.id === action.payload.valueId) {
          const newAddedValue = detail.inputTypeValue;
          // const findOne = newAddedValue.find((newA)=> newA.selectedArea == action.payload.value[0].selectedArea)
          detail.inputTypeValue = newAddedValue.map((service) => {
            if (service.selectedArea === action.payload.value[0].selectedArea) {
              return {
                ...action.payload.value[0],
              };
            } else {
              return service;
            }
          });
          return detail;
          // if(findOne){
          // return {
          //   ...detail,
          //   [action.payload.valueKey]: action.payload.value
          // }} else {
          //   return detail
          // }
        } else {
          return detail;
        }
      });
      return { ...state, data: newDetail };
    }

    case REMOVE_CURRENT_STEP_LOCATION_BY_ID: {
      let newDetail = state.data.map((detail) => {
        if (detail.id === action.payload.valueId) {
          let locationData = [...detail.inputTypeValue]; // Create a copy of inputTypeValue array
          locationData.splice(action.payload.index, 1);
          return {
            ...detail,
            inputTypeValue: locationData,
          };
        } else {
          return detail;
        }
      });
      return { ...state, data: newDetail };
    }

    case ADD_CURRENT_STEP_TECHNICAL_LEADER: {
      let newDetail = state.data.map((detail) => {
        if (detail.id === '03_SUPPLY_AREAS') {
          let inputTypeValue = detail.inputTypeValue;
          detail.inputTypeValue = inputTypeValue.map((value) => {
            if (value.selectedArea === action.payload.serviceArea) {
              return { ...value, technicalLeaders: action.payload.technicalLeaders };
            } else {
              return value;
            }
          });
          // let locationData = [...detail.inputTypeValue,technicalLeaders: action.payload.technicalLeaders]; // Create a copy of inputTypeValue array
          // locationData.splice(action.payload.index, 1);
          // return {
          //   ...detail,
          //   inputTypeValue: locationData,
          // };
          return detail;
        } else {
          return detail;
        }
      });
      return { ...state, data: newDetail };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
