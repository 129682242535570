import { useState, useMemo } from "react";

// Get the nested value, considering array indexing
const getNestedValue = (obj, path) => {
  return path.split(".").reduce((value, key) => {
    const arrayIndexMatch = key.match(/^(\w+)\[(\d+)\]$/);
    if (arrayIndexMatch) {
      const arrayKey = arrayIndexMatch[1];
      const index = parseInt(arrayIndexMatch[2], 10);
      return value && value[arrayKey] ? value[arrayKey][index] : undefined;
    }
    return value ? value[key] : undefined;
  }, obj);
};

// Compare two values
const compareValues = (a, b) => {
  if (a === undefined || b === undefined) {
    return a === undefined ? 1 : -1;
  }
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }
  const aValue = a.toString().toLowerCase();
  const bValue = b.toString().toLowerCase();
  if (aValue < bValue) return -1;
  if (aValue > bValue) return 1;
  return 0;
};

// Sort the array by a specific key
const sortByKey = (array, key, direction) => {
  return [...array].sort((a, b) => {
    const aValue = getNestedValue(a, key);
    const bValue = getNestedValue(b, key);

    const comparison = compareValues(aValue, bValue);
    return direction === "asc" ? comparison : -comparison;
  });
};

// Custom hook for sorting
const useNestedArraySorting = (items) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = useMemo(() => {
    if (!sortConfig.key) return items;

    return sortByKey(items, sortConfig.key, sortConfig.direction);
  }, [items, sortConfig]);

  const requestSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const requestSortIncrease = (key) => {
    requestSort(key, "asc");
  };

  const requestSortDecrease = (key) => {
    requestSort(key, "desc");
  };

  return {
    items: sortedItems,
    requestSortIncrease,
    requestSortDecrease,
    sortConfig,
  };
};

export default useNestedArraySorting;
