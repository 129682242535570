import React from "react";
import { CircularProgress } from "@mui/material";
const PrimaryButton = (props) => {
  const { title, icon, type, handler, btnStyle, loading, disable, position } = props;
  return (
    <button
      disabled={loading || disable}
      type={type}
      onClick={handler}
      className={`tracking-wide bg-AFPPrimary text-white rounded-xl py-2 flexCenter gap-1 border border-AFPPrimary hover:bg-AFPPrimaryDark hover:font-tahoma-bold duration-300 w-full ${btnStyle} ${
        disable && "cursor-not-allowed"
      }`}
    >
      {loading ? (
        <CircularProgress size={18} sx={{ color: "#ee9593" }} />
      ) : (
        <>
          {position == "before" && icon}
          {title}
          {position == "after" && icon}
        </>
      )}
    </button>
  );
};

PrimaryButton.defaultProps = {
  title: "Button",
  icon: null,
  type: "button",
  btnStyle: "",
  loading: false,
  position: "before",
  handler: function () {},
  disable: false,
};

export default PrimaryButton;
