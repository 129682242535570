import React from "react";
import "./strengthBar.css";

const StrengthBar = ({ formik }) => {
  const repeat = formik.values?.repeat;
  const password = formik.values?.password;
  const matchPercentage = calculateMatchPercentage(password, repeat);

  return (
    <div className="flex w-full">
      {matchPercentage > 0 ? (
        <progress
          className={`repeat-password ${
            password.includes(repeat) ? "repeatprogresssuccess" : "repeatprogressdanger"
          }`}
          value={matchPercentage / 100}
          max="1"
        />
      ) : (
        ""
      )}
    </div>
  );
};

const calculateMatchPercentage = (password, repeat) => {
  if (!password || !repeat) return 0;

  const matchCount = [...password].reduce((acc, char, index) => {
    return acc + (char === repeat[index] ? 1 : 0);
  }, 0);


  return (matchCount / password?.length) * 100;
};

StrengthBar.defaultProps = {
  formik: {
    values: {
      password: "",
      repeat: "",
    },
  },
};

export default StrengthBar;
