import { PROCEDURE_TEMPLATES, SET_PROCEDURE_TEMPLATES, PROCEDURE_FAILURE } from "../actionTypes";

export const procedureTemplates = () => {
  return {
    type: PROCEDURE_TEMPLATES,
  };
};

export const setProcedureTemplates = (payload) => {
  return {
    type: SET_PROCEDURE_TEMPLATES,
    payload,
  };
};
export const procedureFailure = (payload) => {
  return {
    type: PROCEDURE_FAILURE,
    payload,
  };
};