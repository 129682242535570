
import { useDispatch, useSelector } from "react-redux";
import UserManagement from "./UserManagement";
import { useEffect } from "react";
import { userManagementStarted } from "src/store/actions/userSettings";

function Management() {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userManagementStarted());
  }, []);
  return (
    <>
      {cmsDashboarddata.listLoaded && <UserManagement />}
    </>
  );
}

export default Management;
