import React, { useEffect, useState } from "react";
import UpdateUser from ".";
import { useSelector } from "react-redux";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const UpdateUserForm = () => {
  const settings = useSelector((state) => state.settings);
  const {
    user: { updateUser },
  } = useSelector((state) => state.settings);
  const [businessRole, setBusinessRole] = useState(null);
  const [formReset, setFormReset] = useState({ status: false, data: null });

  useEffect(() => {
    if (settings?.user?.roleBusinessList?.length > 0 && updateUser?.role_data) {
      const parsedRoleData = updateUser.role_data;

      const updatedBusinessRole = settings.user.roleBusinessList.map((business) => {
        const matchedRole = parsedRoleData.find((role) => role.location_id === business.location_id);
        return {
          ...business,
          selected: !!matchedRole,
          role: matchedRole ? matchedRole.role : "bearbeiter",
        };
      });

      setBusinessRole(updatedBusinessRole);
      let businessForm = updatedBusinessRole.map((business) => ({
        ...business,
      }));
      setFormReset((prev) => {
        return { ...prev, status: true, data: businessForm };
      });
    }
  }, [settings?.user?.roleBusinessList, updateUser]);

  return (
    <>
      {formReset?.data && formReset?.status ? (
        <UpdateUser businessRole={businessRole} setBusinessRole={setBusinessRole} formReset={formReset} />
      ) : (
        <div className="h-full w-full flex justify-center items-center">
          <CustomCircularLoader />
        </div>
      )}
    </>
  );
};

export default UpdateUserForm;
