import validator from "validator";

export const validateTabs = (tabList, id, setValue, navigate, role, newValue) => {
  let value = typeof newValue === "number" ? newValue.toString() : null;
  if (value) {
    const uuid = tabList?.tabs?.find((tab) => tab.id === newValue);
    if (uuid) {
      setValue(newValue);
      if (role === "admin") {
        navigate(`/admin/einstellungen/${uuid?.uuid}`);
      } else {
        navigate(`/einstellungen/${uuid?.uuid}`);
      }
    }
  } else {
    const uuid = tabList?.tabs?.find((tab) => tab.uuid === id);
    if (uuid) {
      setValue(uuid.id);
    } else {
      if (tabList?.tabs.length > 0) {
        setValue(tabList?.tabs[0]?.id);
        if (role === "admin") {
          navigate(`/admin/einstellungen/${tabList?.tabs[0]?.uuid}`);
        } else {
          navigate(`/einstellungen/${tabList?.tabs[0]?.uuid}`);
        }
      }
    }
  }
};

export const validateDocTabs = (tabList, id, setValue, navigate, role, newValue) => {
  let value = typeof newValue === "number" ? newValue.toString() : null;
  if (value) {
    const uuid = tabList?.tabs?.find((tab) => tab.id === newValue);
    if (uuid) {
      setValue(newValue);
      if (role === "admin") {
        navigate(`/admin/dokumente/${uuid?.uuid}`);
      } else {
        navigate(`/dokumente/${uuid?.uuid}`);
      }
    }
  } else {
    const uuid = tabList?.tabs?.find((tab) => tab.uuid === id);
    if (uuid) {
      setValue(uuid.id);
    } else {
      if (tabList?.tabs.length > 0) {
        setValue(tabList?.tabs[0]?.id);
        if (role === "admin") {
          navigate(`/admin/dokumente/${tabList?.tabs[0]?.uuid}`);
        } else {
          navigate(`/dokumente/${tabList?.tabs[0]?.uuid}`);
        }
      }
    }
  }
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function String12Random(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export const isBIC = (value) => {
  return validator.isBIC(value);
};

export const isIBAN = (value) => {
  return validator.isIBAN(value);
};
