import axios from "axios";
import { BASE_URL } from "../utils/defines";
// import { store } from "../../store";
// import { store } from "../../store";
import toast from "react-hot-toast";
import CookieStorage from "../storage/cookiesStorage";
const cookie = new CookieStorage();
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
axiosInstance.interceptors.request.use((config) => {
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data?.status === false) {
      toast.error(response?.data?.message || "Etwas ist schief gelaufen!", {id: "interceptor_rror"}); 
    }
    
    return response.data;
  },
  (error) => {
    // alert(error?.response?.data?.message || "Etwas ist schief gelaufen")
    if(error?.response?.status == 401 && cookie?.getLogin() == true) {
      cookie.logout();
      window.alert('Sie sind derzeit abgemeldet. Möchten Sie sich einloggen?')
      window.location.reload();
    } else {
      toast.error(error?.response?.data?.message || "Etwas ist schief gelaufen!", {id: "interceptor_rror"});
    }
    return Promise.reject(error?.response?.data);
  }
);
export default axiosInstance;
