import {
  GET_SERVICE_AREA_DETAIL_FAILURE,
  GET_SERVICE_AREA_DETAIL_STARTED,
  GET_SERVICE_AREA_DETAIL_SUCCESS,
  GET_SERVICE_AREA_FAILURE,
  GET_SERVICE_AREA_STARTED,
  GET_SERVICE_AREA_SUCCESS,
  GET_STEPS_FAILURE,
  GET_STEPS_STARTED,
  GET_STEPS_SUCCESS,
  RESET_PAGE_AIDS_BY_ID,
  RESET_SERVICE_AREA,
  RESET_SERVICE_AREA_DETAIL,
  SET_PAGE_AIDS_BY_ID,
} from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  service_area: { data: null, isLoading: false, error: null },
  service_area_detail: { data: null, isLoading: false, error: null },
  page_aids: {},
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_STEPS_STARTED: {
      return { ...state, isLoading: true };
    }
    case GET_STEPS_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case GET_STEPS_FAILURE: {
      return { ...state, isLoading: false, error: "error", data: null };
    }
    case GET_SERVICE_AREA_STARTED: {
      return { ...state, service_area: { ...state.service_area, isLoading: true } };
    }
    case GET_SERVICE_AREA_SUCCESS: {
      return { ...state, service_area: { ...state.service_area, isLoading: false, data: action.payload } };
    }
    case GET_SERVICE_AREA_FAILURE: {
      return { ...state, service_area: { ...state.service_area, isLoading: false, error: "error", data: null } };
    }
    case RESET_SERVICE_AREA: {
      return { ...state, service_area: { data: null, isLoading: false, error: null } };
    }
    case GET_SERVICE_AREA_DETAIL_STARTED: {
      return { ...state, service_area_detail: { ...state.service_area_detail, isLoading: true } };
    }
    case GET_SERVICE_AREA_DETAIL_SUCCESS: {
      return { ...state, service_area_detail: { ...state.service_area_detail, isLoading: false, data: action.payload } };
    }
    case GET_SERVICE_AREA_DETAIL_FAILURE: {
      return { ...state, service_area_detail: { ...state.service_area_detail, isLoading: false, error: "error", data: null } };
    }
    case RESET_SERVICE_AREA_DETAIL: {
      return { ...state, service_area_detail: { data: null, isLoading: false, error: null } };
    }
    case SET_PAGE_AIDS_BY_ID: {
      const { page, aidId } = action.payload;
      const aidsfind = state.page_aids[page] || [];
    
      const updatedAids = aidsfind.includes(aidId)
        ? aidsfind.filter((aid) => aid !== aidId)
        : [...aidsfind, aidId];
    
      return {
        ...state,
        page_aids: {
          ...state.page_aids,
          [page]: updatedAids,
        },
      };
    }
    case RESET_PAGE_AIDS_BY_ID: {
      return {
        ...state,
        page_aids: {
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
