/*eslint-disable*/
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UMSTABLEHEAD } from "src/mockData/settingMock";
import { StandardSearch } from "src/components/molecules/search";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import NewUser from "src/components/organisms/settings/Management/NewUser";
import BusinessList from "src/components/organisms/settings/Management/BusinessList";
import { resetUserManagement, removeUserManagementByID, businessListInviteStarted } from "src/store/actions/userSettings";
import { getAllBusinessStarted } from "src/store/actions/business";
import UpdateUserForm from "src/components/organisms/settings/Management/UpdateUser/UpdateUserForm";

function UserManagement() {
  const dispatch = useDispatch();
  const [showData, setShowData] = useState(10);
  const { user } = useSelector((state) => state.settings);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const business = useSelector((state) => state?.management);

  const list = cmsDashboarddata?.settings?.ums_table_head;

  const headList = UMSTABLEHEAD(list?.email, list?.name, list?.business_premises);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(user?.managementList?.length > 0 ? user?.managementList : []);
  const [pagingData, setPagingData] = useState("");
  const [isBusinessListAvailable, setIsBusinessListAvailable] = useState(false)

  useEffect(() => {
    if (searchTerm == "") {
      if (user?.managementList?.length > 10) {
        setPagingData(user?.managementList.slice(0, showData));
      } else {
        user?.managementList && setPagingData(user?.managementList.slice(0, showData));
      }
    }
  }, [user?.managementList, searchTerm]);

  function customDebounce(func, delay) {
    let timeoutId;
    return function (...args) {
      const context = this;

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const handleSearch = customDebounce((text) => {
    if (text != "") {
      const filtered =
        user?.managementList?.length > 0 &&
        user?.managementList.filter(
          (item) =>
            item?.first_name?.toLowerCase().includes(text.toLowerCase()) ||
            item?.last_name.toLowerCase().includes(text.toLowerCase()) ||
            item?.email.toLowerCase().includes(text.toLowerCase())
        );
      user?.managementList?.length > 0 && setPagingData(filtered);
    } else {
      setPagingData(user?.managementList.slice(0, showData));
    }
  }, 1000);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch(e.target.value);
    setShowData(10);
  };

  useEffect(() => {
    dispatch(businessListInviteStarted());
    return () => {
      dispatch(resetUserManagement());
    };
  }, []);

  useEffect(() => {
    if (searchTerm.length > 0) {
      handleSearch(searchTerm);
    } else {
      setFilteredData(user?.managementList);
    }
  }, [user?.managementList]);

  const userRemove = (id) => {
    dispatch(removeUserManagementByID({ id }));
  };

  const handleShowMore = () => {
    if (searchTerm != "") {
      if (pagingData < 10) {
        setPagingData(filteredData.slice(0, showData + 10));
        setShowData(showData + 10);
      }
    } else {
      if (pagingData < user?.managementList) {
        setPagingData(filteredData.slice(0, showData + 10));
        setShowData(showData + 10);
      }
    }
  };

  useEffect(() => {
    const getLocalData = JSON.parse(localStorage.getItem("getAllLocation"));
    if (!!getLocalData) {
      setIsBusinessListAvailable(true)
    } else if (getLocalData == null) {
      dispatch(getAllBusinessStarted());
    }
  }, [business?.allLocation?.data]);

  return (
    <>
      {user?.updateUser ? (
        <UpdateUserForm userRemove={userRemove} />
      ) : (
        <>
          <div className="bg-AFPBGPrimary">
            <div className="flex justify-between px-3 pt-4">
              <p className="textStyle flex items-start w-2/3 justify-start">
                {cmsDashboarddata?.settings?.ums_note?.notes}
                <span className="w-14 h-14">
                  <EditIconCMS
                    handleOpen={() => {
                      dispatch(
                        dashboardModalOpenCMS({
                          cms_key: "settings",
                          unique: "ums_note",
                          heading: "Einstellungen",
                        })
                      );
                    }}
                  />
                </span>
              </p>
              <div className="relative w-64 rounded-full flex items-center gap-3">
                <StandardSearch value={searchTerm} handleSearch={handleChange} placeholder={cmsDashboarddata?.settings?.ums_search?.search} css="0rem" />
                <EditIconCMS
                  handleOpen={() => {
                    dispatch(
                      dashboardModalOpenCMS({
                        cms_key: "settings",
                        unique: "ums_search",
                        heading: "Einstellungen",
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full mt-7 flex justify-end">
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "settings",
                    unique: "ums_table_head",
                    heading: "Einstellungen",
                  })
                );
              }}
            />
          </div>
          {pagingData && isBusinessListAvailable && <BusinessList headList={headList} bodyList={pagingData} userRemove={userRemove} />}
          {filteredData?.length > 10 && filteredData?.length !== pagingData?.length && (
            <div className="mb-0 mt-10 md:mb-10 md:mt-20 flex justify-center items-center border-t relative">
              <button
                onClick={() => handleShowMore()}
                className={`bg-AFPBGPrimary m-0 p-0  absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-AFPPrimary font-semibold text-center whitespace-nowrap cursor-pointer flex items-center gap-x-3 px-1 `}
              >
                Mehr anzeigen
              </button>
            </div>
          )}

          <NewUser />
        </>
      )}
    </>
  );
}

export default UserManagement;
