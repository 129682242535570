import { GET_HELPDESK_ARTICLE_CONTENT_FAILURE, GET_HELPDESK_ARTICLE_CONTENT_STARTED, GET_HELPDESK_ARTICLE_CONTENT_SUCCESS, GET_HELPDESK_ARTICLE_DETAIL_FAILURE, GET_HELPDESK_ARTICLE_DETAIL_STARTED, GET_HELPDESK_ARTICLE_DETAIL_SUCCESS, GET_HELPDESK_ARTICLE_LIST_FAILURE, GET_HELPDESK_ARTICLE_LIST_STARTED, GET_HELPDESK_ARTICLE_LIST_SUCCESS } from "../actionTypes";

export const gethelpDeskListStarted = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_LIST_STARTED,
      payload,
    };
  };
  export const gethelpDeskListSuccess = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_LIST_SUCCESS,
      payload,
    };
  };
  export const gethelpDeskListFailure = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_LIST_FAILURE,
      payload,
    };
  };

  export const gethelpDeskDetailStarted = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_DETAIL_STARTED,
      payload,
    };
  };
  export const gethelpDeskDetailSuccess = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_DETAIL_SUCCESS,
      payload,
    };
  };
  export const gethelpDeskDetailFailure = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_DETAIL_FAILURE,
      payload,
    };
  };

  export const gethelpDeskContentStarted = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_CONTENT_STARTED,
      payload,
    };
  };
  export const gethelpDeskContentSuccess = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_CONTENT_SUCCESS,
      payload,
    };
  };
  export const gethelpDeskContentFailure = (payload) => {
    return {
      type: GET_HELPDESK_ARTICLE_CONTENT_FAILURE,
      payload,
    };
  };