import Logo from "src/assets/img/newLogo.png";

const Register = ({ children }) => {
  return (
    <div className="registerwrap">
      <div className="mb-8">
        <img src={Logo} alt="logo" />
      </div>

      <div className="w-full md:w-[88%] lg:w-[80%]">{children}</div>
    </div>
  );
};

export default Register;
