import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { createdResetBusinessPremises, listBusinessPremises } from "src/store/actions/business";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import AddBusinessModal from "src/components/atoms/modal/AddBusinessModal";
import { Businesspremise } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import BusinessPremisesList from "src/components/organisms/businesspremises/BusinessPremisesList/BusinessPremisesList";
import MessageModal from "src/components/organisms/cms/MessageModal";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import Cookies from "js-cookie";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const BusinessPremises = () => {
  const dispatch = useDispatch();
  const [openAddBussinessModal, setOpenAddBussinessModal] = useState(false);
  const [businessList, setBusinessList] = useState(null);
  const business = useSelector((state) => state?.management);
  const profile = useSelector((state) => state?.profile);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, listLoaded, business_premises, sidebar_menu } = cmsDashboarddata;
  const handleOpen = () => {
    setOpenAddBussinessModal(true);
  };
  const handleClose = () => {
    setOpenAddBussinessModal(false);
  };

  useEffect(() => {
    dispatch(listBusinessPremises({ user_id: Cookies.get("userId") }));
  }, []);

  useEffect(() => {
    setBusinessList(business?.list?.businessList);
  }, [business?.list?.businessList]);
  useEffect(() => {
    business?.newUser?.created && (setOpenAddBussinessModal(false), dispatch(createdResetBusinessPremises()));
  }, [business?.newUser]);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-10 py-8 min-h-screen bg-AFPBGPrimary">
        {listLoaded && (
          <>
            {sidebar_menu?.sidebar_list?.location && <Breadcrumbs data={Businesspremise(sidebar_menu?.sidebar_list?.location)} />}
            <h2 className="!mb-5 text-2xl font-tahoma-bold m-3 sm:m-4 md:m-6 flex items-center gap-2">
              {business_premises?.heading?.title}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "business_premises",
                      unique: "heading",
                      heading: "Betriebsstätten",
                    })
                  );
                }}
              />
            </h2>

            <div className="bg-AFPBGPrimary mx-3 sm:mx-4 md:mx-6 mt-0 p-0">
              <p className="textStyle max-w-[540px] mb-5">{business_premises?.heading?.subTitle}</p>
              {businessList && !business.isLoading ? (
                <BusinessPremisesList handleOpen={handleOpen} businessList={businessList} />
              ) : (
                business.isLoading && (
                  <div className="flex w-full h-[40vh] justify-center items-center">
                    <CustomCircularLoader />
                  </div>
                )
              )}
            </div>
            {profile?.data && openAddBussinessModal && <AddBusinessModal open={openAddBussinessModal} handleClose={handleClose} />}
          </>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export default BusinessPremises;
