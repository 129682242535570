import React, { useState } from "react";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import { PiWarningFill } from "react-icons/pi";
import HelperExpand from "src/components/atoms/card/Card/HelperExpand";
const CheckBoxDF = ({ data, stepsCMS, css, miniAids }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(data?.inputTypeValue ? data?.inputTypeValue : false);
  const handleCheckboxChange = (event) => {
    let check = event.target.checked;
    setIsChecked(check);
    dispatch(
      addCurrentStepDetailValue({
        valueId: data.id,
        value: check,
        valueKey: "inputTypeValue",
      })
    );
  };
  return (
    <HelperExpand miniAids={miniAids}>
      <h1 className={`!mb-5 text-2xl font-tahoma-bold text-black ${css}`}>{stepsCMS[data?.id]?.title}</h1>

      <div className="justify-start gap-12 flex flex-col md:flex-row">
        <p className="textStyle mb-5" dangerouslySetInnerHTML={
                { __html: stepsCMS[data?.id]?.description }
             }></p>
      </div>
      <div className="text-black font-tahoma-bold md:pt-0 pt-4">
        <FormControlLabel
          sx={{
            display: "flex",
            alignItems: "start",
          }}
          control={
            <Checkbox
              className="p-1 m-1"
              sx={{
                marginTop: "-10px",
                color: "#484848",
                "&.Mui-checked": {
                  color: "#A31D1A",
                },
              }}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          }
          label={<span className="text-SmokeyGray">{stepsCMS[data?.id]?.inputLabel}</span>}
        />
        {!isChecked ? <span className="text-red-400 text-sm">* Pflichtfeld</span> : null}
        {data.warningMsg && (
          <div className="flex gap-x-2 items-center  text-[12px] mt-4">
            <PiWarningFill fontSize={20} className="text-AFPPrimary" /> {data.warningMsg}
          </div>
        )}
      </div>
    </HelperExpand>
  );
};

export default CheckBoxDF;
