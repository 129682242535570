/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { RiZoomInLine } from "react-icons/ri";
import { RiZoomOutLine } from "react-icons/ri";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineCloudDownload } from "react-icons/md";

const PdfPreview = ({ selectedFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.2); // State for zoom scale

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => setPageNumber((prevPageNumber) => prevPageNumber - 1);
  const goToNextPage = () => setPageNumber((prevPageNumber) => prevPageNumber + 1);

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.1); // Increase scale by 0.1
  };

  const zoomOut = () => {
    setScale((prevScale) => prevScale - 0.1); // Decrease scale by 0.1
  };

  const downloadFile = () => {
    window.open(selectedFile?.url, "_blank")
  }

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <div className="flex-grow justify-center items-center overflow-auto w-full mt-0">
        <Document file={`${selectedFile?.url}`} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => console.error("Failed to load PDF file:", error)}>
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
      {numPages && (
        <div className="flex justify-center items-center p-2 w-full bg-white">
          <button type="button" disabled={pageNumber <= 1} onClick={goToPrevPage} className={`mx-2 my-0 ${!(pageNumber <= 1) && 'hover:text-AFPPrimary cursor-pointer'}`}>
            <FaArrowLeft />
          </button>
          <span>{`Page ${pageNumber} of ${numPages}`}</span>
          <button type="button" disabled={pageNumber >= numPages} onClick={goToNextPage} className={`mx-2 my-0 ${!(pageNumber >= numPages) && 'hover:text-AFPPrimary cursor-pointer'}`}>
            <FaArrowRight />
          </button>
          <button type="button" onClick={zoomIn} className="mx-2 my-0 hover:text-AFPPrimary cursor-pointer">
          <RiZoomInLine className="text-xl" />
          </button>
          <button type="button" onClick={zoomOut} className="mx-2 my-0 hover:text-AFPPrimary cursor-pointer">
            <RiZoomOutLine className="text-xl" />
          </button>
          <button type="button" onClick={downloadFile} className="mx-2 my-0 hover:text-AFPPrimary cursor-pointer">
            <MdOutlineCloudDownload className="text-xl" />
          </button>
        </div>
      )}
    </div>
  );
};

export default PdfPreview;
