/* eslint-disable */
// import { useState } from "react";
// import { AiFillEdit } from "react-icons/ai";
import StandardSelect from "../../../select/StandardSelect";
import style from "../inputstyle.module.css";

const InputWithDropDown = (props) => {
  const { label, id, type, placeholder, disabled, formik, options } = props;
  // const [editable, setEditable] = useState(false);
  return (
    <div className={`inputExtraCss w-full`}>
      <label htmlFor={id} className={`${style.labelDropdown} inputExtraLabel`}>
        {label}
      </label>
      <div className="w-full">
        <div className={`${style.wrapper}`}>
          {/* {editable ? (
            <> */}
              <StandardSelect extra="w-full" extraLabel="hidden" extraInput="border-gray-400" label={label} placeholder="Herr/Frau" id={id} options={options} formik={formik} />
            {/* </>
          ) : (
            <input
              disabled={!editable || disabled}
              type={type}
              id={id}
              name={id}
              placeholder={placeholder}
              className={`${style.dropdownInput} `}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={options.find((option) => option.value === formik.values[id])?.title}
            />
          )} */}
          {/* <AiFillEdit
            onClick={() => {
              !disabled && setEditable(!editable);
            }}
            size={16}
            className={`${editable ? "text-SeaGreen" : "text-SmokeyGray"} cursor-pointer`}
          /> */}
        </div>
      </div>
    </div>
  );
};

InputWithDropDown.defaultProps = {
  label: "Label",
  id: "inputField",
  name: "inputField",
  type: "text",
  placeholder: "",
  state: "",
  disabled: false,
  formik: {
    getFieldProps: () => ({}),
    touched: {},
    errors: {},
    handleChange: () => {},
    handleBlur: () => {},
    values: {},
  },
};

export default InputWithDropDown;
