import { GET_MARKETING_FAILURE, GET_MARKETING_STARTED, GET_MARKETING_SUCCESS } from "../actionTypes";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKETING_STARTED: {
      return { ...state, isLoading: true, error: null };
    }
    case GET_MARKETING_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload, error: null };
    }
    case GET_MARKETING_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
