/* eslint-disable */
import { ArrayCheckBox, InputStandardField } from "src/components/atoms/input";
import StandardSelect from "src/components/atoms/select/StandardSelect";
import { SecondaryButton } from "src/components/atoms/button";
import style from "src/components/pages/settings/Management/management.module.css";
import { settingRole } from "src/mockData/settingMock";
import { useSelector, useDispatch } from "react-redux";
import { resetUserManagement, updateUserManagementByIDStarted } from "src/store/actions/userSettings";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import BackdropButton from "src/components/atoms/button/BackdropButton";

const UpdateUser = ({ userRemove, businessRole, formReset }) => {
  const dispatch = useDispatch();

  const settingData = useSelector((state) => state?.cmsDocument?.settings);

  const settings = useSelector((state) => state.settings);
  const {
    user: { updateUser },
  } = useSelector((state) => state.settings);

  const formik = useFormik({
    initialValues: {
      id: updateUser.id,
      email: updateUser.email,
      first_name: updateUser.first_name,
      last_name: updateUser.last_name,
      business: formReset?.data ? formReset?.data : updateUser.business ? settings?.user?.roleBusinessList.find((business) => business.location_id === updateUser.business[0].id) : updateUser.business,
    },
    onSubmit: (values) => {
      let business = values.business
        .filter((value) => value.selected === true)
        ?.map((value) => {
          return { id: value.id, role: value.role, location_id: value.location_id };
        });
      dispatch(updateUserManagementByIDStarted({ ...values, business: business, user_id: Cookies.get("userId") }));
      window?.scrollTo(0, 0);
    },
  });

  return (
    <>
      <div className="bg-AFPBGPrimary">
        <h3 className="text-2xl font-tahoma-bold mb-6 mt-4">Nutzer Bearbeiten</h3>
        <div className="mx-0 md:mx-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center gap-3">
              <InputStandardField
                extra="md:!flex-row !mb-5 w-full"
                extraInput="max-w-[300px]"
                label={settingData?.ums_email?.email}
                placeholder={settingData?.ums_email?.email}
                id="email"
                type="text"
                formik={formik}
                disabled={true}
              />
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "ums_email",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </div>
            <hr className="mb-4" />
            <div className="flex items-center gap-3">
              <InputStandardField
                extra="md:!flex-row !mb-5 w-full"
                extraInput="max-w-[300px]"
                label={settingData?.ums_name?.name}
                placeholder={settingData?.ums_name?.name}
                id="first_name"
                type="text"
                formik={formik}
                disabled={true}
              />
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "ums_name",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </div>
            <hr className="mb-4" />
            <div className="flex items-center gap-3">
              <InputStandardField
                extra="md:!flex-row !mb-5 w-full"
                extraInput="max-w-[300px]"
                label={settingData?.ums_last_name?.name}
                placeholder={settingData?.ums_last_name?.name}
                id="last_name"
                type="text"
                formik={formik}
                disabled={true}
              />
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "ums_last_name",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </div>

            <hr />
            <div className={`block lg:flex items-start my-4`}>
              <h4 className={`${style.roleHeading} flex items-center gap-3`}>
                {settingData?.ums_label_heading?.title}{" "}
                <EditIconCMS
                  handleOpen={() => {
                    dispatch(
                      dashboardModalOpenCMS({
                        cms_key: "settings",
                        unique: "ums_label_heading",
                        heading: "Einstellungen",
                      })
                    );
                  }}
                />
              </h4>
              <div className="w-full">
                {businessRole?.length > 0 &&
                  formik?.values?.business?.length > 0 &&
                  businessRole?.map((business, index) => {
                    return (
                      <div className={`flex items-start sm:items-center gap-2 mb-3 flex-col sm:flex-row sm:gap-8`} key={index}>
                        <div className="flex justify-start items-center w-[300px]">
                          <ArrayCheckBox
                            css="flex items-center h-5"
                            selection="selected"
                            index={index}
                            set="business"
                            label={business?.name + ", " + business?.city}
                            formik={formik}
                          />
                        </div>
                        <StandardSelect
                          extra="w-[220px]"
                          extraLabel="hidden"
                          placeholder="Herr/Frau"
                          id={`business[${index}]role`}
                          options={settingRole}
                          choose={false}
                          formik={formik}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <hr className="mb-12" />
            <div className="flex justify-between gap-10 mb-8 flex-col lg:flex-row">
              <div className="flex gap-10 flex-col lg:flex-row">
                <div className="w-60">
                  <BackdropButton title="Speichern" type="submit" loading={settings?.isLoading} disable={!formik.dirty} />
                </div>

                <div className="w-60">
                  <SecondaryButton
                    variant="secondary"
                    title={"Abbrechen"}
                    css={"w-full"}
                    handler={() => {
                      dispatch(resetUserManagement());
                      window?.scrollTo(0, 0);
                    }}
                  />
                </div>
              </div>
              <div className="w-60">
                <SecondaryButton
                  variant={!updateUser?.owner_contact_id ? "warning" : "disabled"}
                  title={"Nutzer Löschen"}
                  css={"w-full"}
                  handler={() => {
                    dispatch(resetUserManagement());
                    userRemove(updateUser.id);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateUser;
