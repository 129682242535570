import { FETCH_HELP_LIST_REQUEST, FETCH_HELP_LIST_SUCCESS, FETCH_HELP_LIST_FAILURE } from "../actionTypes";

const initialState = {
  helpList: null,
  isLoading: false,
  error: null,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HELP_LIST_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case FETCH_HELP_LIST_SUCCESS: {
      return { ...state, helpList: action.payload, loading: false, error: null };
    }
    case FETCH_HELP_LIST_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
