import React, { useRef } from "react";
import CardDoc from "src/components/atoms/card/Card/CardDoc";
import group from "src/assets/img/group.png";
import vector6 from "src/assets/img/vector6.png";
import doc from "src/assets/img/doc.png";
import imagePre from "src/assets/img/imagepreview.png";
import toast from "react-hot-toast";

const NewCard = ({ category, handleOpen, location }) => {
  const inputRef = useRef();

  const docHandler = (e) => {
    const fileName = e.target.value;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    const fileNameWithExtension = fileName.split("\\").pop().split("/").pop();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    if (fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "docx" || fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
      handleOpen({
        icon: fileExtension === "pdf" ? vector6 : fileExtension === "doc" || fileExtension === "docx" ? doc : imagePre,
        category: category.name,
        category_id: category.id,
        date: formattedDate.replace(/\//g, "."),
        docs: fileNameWithExtension,
        doc_type: fileExtension,
        doc_url: e.target.files[0],
        location: location,
      });
    } else {
      toast.error("Ungültiger Dateityp. Es werden nur PDF- und DOC-Dateien akzeptiert..");
    }
  };

  return (
    <div
      className="flex flex-col items-center sm:items-start pt-10 w-full"
      onClick={() => {
        inputRef.current.click();
      }}
    >
      <CardDoc>
        <img src={group} className="mx-auto my-auto" />
      </CardDoc>
      <div className="pt-3 text-start">
        <p className="font-tahoma-bold">{category?.name}</p>
        <input type="file" ref={inputRef} onChange={docHandler} className="hidden" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
      </div>
    </div>
  );
};

export default NewCard;
