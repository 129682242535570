/*eslint-disable*/
import { initialValuesRegister, validationSchemaRegister } from "src/services/validation/Auth";
import { InputStandardField, PasswordCheckList, PasswordCompare } from "src/components/atoms/input";
import { salutationOptions } from "src/mockData/register";
import { useFormik } from "formik";
import StandardSelect from "src/components/atoms/select/StandardSelect";
import RegisterGrid from "src/components/molecules/RegisterGrid";
import { SubmitButton } from "src/components/atoms/button";
import { registerUser, registerUserModify } from "src/store/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useJwt } from "react-jwt";
import { useEffect } from "react";
import LocationTable from "./LocationTable";

const { heading, subHeading, personalData, salutation, title, firstName, lastName, companyName, loginData, email, passwordLevel, repeat, description, btnHeading } = {
  heading: "Registrierung",
  subHeading: "Bitte erstellen Sie einen neuen Account",
  personalData: "Persönliche Daten:",
  salutation: "Anrede",
  title: "Titel",
  firstName: "Vorname",
  lastName: "Nachname",
  companyName: "Firmenname",
  loginData: "Login Daten:",
  email: "Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein:",
  password: "Bitte vergeben Sie ein Passwort:",
  passwordLevel: "Passwort",
  passwordNote: "Bitte wählen Sie ein stärkeres Passwort!",
  repeat: "Passwort wiederholen",
  description:
    "Mit Ihrer Anmeldung erklären Sie sich mit unseren Nutzungsbedingungen und unserer Datenschutzrichtlinie einverstanden. Sie erklären sich außerdem damit einverstanden, kontobezogene E-Mails von der Agentur für Präqualifizierung GmbH zu erhalten, einschließlich wichtige Hinweise vom GKV-SV sowie zu Produktaktualisierungen.",
  btnHeading: "Registrieren",
  validation: {
    has8digit: "Mind. 8 Zeichen",
    hasLow: "Mind. 1x Kleinbuchstaben",
    hasCap: "Mind. 1x Großbuchstaben",
    hasNumber: "Mind. 1x Ziffern",
    hasSpecialChar: "Mind. 1x Sonderzeichen",
  },
};

const getDecodeCode = (id) => {
  try {
    const { decodedToken, isExpired } = useJwt(id); // eslint-disable-line no-unused-vars
    return decodedToken;
  } catch (error) {
    console.error("Error decoding token:", error);
  }
};

const RegisterForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.users);
  const { id } = useParams();
  const decode = id ? getDecodeCode(id) : null;

  const formik = useFormik({
    initialValues: initialValuesRegister,

    validationSchema: validationSchemaRegister,
    onSubmit: (values) => {
      let { repeat, ...rest } = values; // eslint-disable-line no-unused-vars

      if (id && decode) {
        const payload = {
          user_id: decode?.user_id,
          ...rest,
          base_url: window?.location?.origin,
          business: decode.business,
        };

        dispatch(registerUserModify(payload));
      } else {
        dispatch(registerUser({ ...rest, base_url: window?.location?.origin }));
      }
    },
  });

  useEffect(() => {
    if (decode) {
      formik.setFieldValue("first_name", decode?.first_name);
      formik.setFieldValue("last_name", decode?.last_name);
      formik.setFieldValue("email", decode?.email);
      formik.setFieldValue("company_name", decode?.company_name);
    }
  }, [decode]);

  const isSalutationSelected = formik.values.salutation && formik.values.salutation !== "";

  return (
    <>
      <h3 className="registerHeading">{heading}</h3>
      <h4 className="text-center text-lg text-SmokeyGray mt-1 mb-6">{subHeading}</h4>
      <hr className="" />
      <form onSubmit={formik.handleSubmit}>
        <RegisterGrid heading={personalData}>
          <StandardSelect
            extra="mb-5"
            extraLabel="w-full md:w-[200px] lg:w-[260px] xl:w-[300px] 2xl:w-[340px]"
            choose={!isSalutationSelected}
            label={salutation}
            placeholder="Herr/Frau"
            id="salutation"
            options={salutationOptions}
            formik={formik}
          />
          <InputStandardField extra="md:!flex-row !mb-5" label={title} placeholder={title} id="title" type="text" formik={formik} />

          <InputStandardField extra="md:!flex-row !mb-5" label={firstName} placeholder={firstName} id="first_name" type="text" formik={formik} disabled={decode ? true : false} />

          <InputStandardField extra="md:!flex-row !mb-5" label={lastName} placeholder={lastName} id="last_name" type="text" formik={formik} disabled={decode ? true : false} />

          <InputStandardField
            extra="md:!flex-row !mb-5"
            label={companyName}
            placeholder={companyName}
            id="company_name"
            type="text"
            formik={formik}
            disabled={decode ? true : false}
          />
        </RegisterGrid>
        <hr className="" />
        {id && decode && (
          <>
            <LocationTable data={decode.business} />
            <hr />
          </>
        )}

        <RegisterGrid heading={loginData}>
          <InputStandardField
            extra="!mb-5 !flex-col"
            extraLabel="!w-full"
            label={email}
            placeholder="j.hagel@afp-da.de"
            id="email"
            type="email"
            formik={formik}
            disabled={decode ? true : false}
          />

          <div className="mb-5">
            <PasswordCheckList id="password" label={passwordLevel} placeholder={passwordLevel} type="checkpassword" formik={formik} />
          </div>
          <div className="mb-5">
            <PasswordCompare placeholder={repeat} id="repeat" name="repeat" formik={formik} />
          </div>
        </RegisterGrid>
        <hr />
        <div className="flex flex-col md:grid md:grid-cols-4 my-4 md:my-6 lg:my-8 rounded-xl">
          <div className="col-span-1 lg:col-span-1 bg-white rounded-xl md:rounded-tr-none md:rounded-br-none invisible"></div>
          <div className="col-span-3 lg:col-span-3 md:grid">
            <p className="text-sm text-SmokeyGray w-full ">{description}</p>
            <h4 className="w-full text-right my-4">
              Sie haben bereits ein Konto?{" "}
              <span
                onClick={() => {
                  navigate("/login");
                }}
                className="underline cursor-pointer hover:text-AFPPrimary mr-3"
              >
                Anmeldung
              </span>
            </h4>
            <SubmitButton css="flexCenter mt-6" type="submit" btnTitle={btnHeading} loading={users.isLoading} />
          </div>
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
