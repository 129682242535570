import { gethelpDeskContentFailure, gethelpDeskContentSuccess, gethelpDeskDetailFailure, gethelpDeskDetailSuccess, gethelpDeskListFailure, gethelpDeskListSuccess } from "src/store/actions/helpdesk";
import { GET_HELPDESK_CONTENT, GET_HELPDESK_DETAIL, GET_HELPDESK_LIST } from "../requests/helpdesk";
import Cookies from "js-cookie";
import { put } from "redux-saga/effects";

export function* gethelpDeskListStartedHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_HELPDESK_LIST(paramsData);
    if (response.status) {
      yield put(gethelpDeskListSuccess(response?.data?.data));
    } else {
      yield put(
        gethelpDeskListFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(gethelpDeskListFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* gethelpDeskDetailHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_HELPDESK_DETAIL(paramsData);
    if (response.status) {
      yield put(gethelpDeskDetailSuccess(response?.data?.data));
    } else {
      yield put(
        gethelpDeskDetailFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(gethelpDeskDetailFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* gethelpDeskContentHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_HELPDESK_CONTENT(paramsData);
    if (response.status) {
      yield put(gethelpDeskContentSuccess({data:response?.data, crisp_id: action?.payload?.crisp_id}));
    } else {
      yield put(
        gethelpDeskContentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(gethelpDeskContentFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
