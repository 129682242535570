import { useDispatch, useSelector } from "react-redux";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { useEffect } from "react";
import { getDashboardDataStarted, getDashboardMessageStarted } from "src/store/actions/dashboard";
import CircularLoader from "src/components/atoms/loader/CircularLoader";
import EvideceTabCMS from "./EvideceTabCMS";

const EvidenceCMSTabs = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData } = cmsDashboarddata;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardMessageStarted());
    dispatch(getDashboardDataStarted());
  }, []);

  return (
    <>
      {cmsDashboarddata.listLoaded ? <EvideceTabCMS /> : <CircularLoader css="h-[90vh]" />}

      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </>
  );
};

export default EvidenceCMSTabs;
