/* eslint-disable */
import React, { useEffect, useState } from "react";
import DynamicStepper from "src/components/organisms/dform/DynamicStepper";
import { getApplicationStartedByID, setApplicationSidebar } from "src/store/actions/application";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DynamicFormStepsFinished from "../DynamicFormSteps/DynamicFormStepsFinished";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const DynamicFormFinishedWrap = () => {
  const { id, procedure_id } = useParams();
  const dispatch = useDispatch();
  const { dform, application } = useSelector(
    (state) => ({
      dform: state.dform,
      application: state.application,
    }),
    (prev, next) => {
      return prev.dform === next.dform && prev.application === next.application;
    }
  );
  const [activeStep, setActiveStep] = useState(null);
  const [steps, setSteps] = useState(null);
  useEffect(() => {
    if (dform?.data?.length > 0 && activeStep === null) {
      setActiveStep(0);
      let stepList = dform.data.map((d) => d.title);
      setSteps(stepList);
    }
  }, [dform]);

  useEffect(() => {
    if (activeStep !== null) {
      let filterId = dform?.data[activeStep]?.id;
      /** getting procedure/get-meta-data api */
      dispatch(
        getApplicationStartedByID({
          step_id: filterId,
          service_order_type: id,
          procedure_form_id: procedure_id,
        })
      );
    }
  }, [activeStep]);
  const stepChangeHandle = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (application?.createdApplication?.status == "completed" && steps?.length - 1 !== activeStep) {
      stepChangeHandle(activeStep + 1);
    }
  }, [application?.createdApplication?.status]);

  useEffect(() => {
    if (!application?.sidebar) {
      dispatch(setApplicationSidebar());
    }
  }, []);

  return (
    <>
      {activeStep !== null && (
        <>
          <DynamicStepper
            stepChangeHandle={(index) => {
              if (index < activeStep && application?.dformStatus?.data?.data?.status !== "completed") {
                stepChangeHandle(index);
              } else if (application?.dformStatus?.data?.data?.status === "completed") {
                stepChangeHandle(index);
              }
            }}
            steps={steps}
            activeStep={activeStep}
          />
          {application?.isLoading ? (
            <div className="flex justify-center items-center min-h-[40vh]">
              <CustomCircularLoader />
            </div>
          ) : (
            application?.data !== null && (
              <DynamicFormStepsFinished id={id} activeStep={activeStep} stepChangeHandle={stepChangeHandle} step_id={dform?.data[activeStep]?.id} procedure_id={procedure_id} />
            )
          )}
        </>
      )}
    </>
  );
};

export default DynamicFormFinishedWrap;
