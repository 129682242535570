export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const FORGOT_USER_START = "FORGOT_USER_START";
export const FORGOT_USER_SUCCESS = "FORGOT_USER_SUCCESS";

export const RESET_USER_START = "RESET_USER_START";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";

export const CHANGE_PASSWORD_USER_START = "CHANGE_PASSWORD_USER_START";
export const CHANGE_PASSWORD_USER_SUCCESS = "CHANGE_PASSWORD_USER_SUCCESS";

export const AUTH_USER_FAILURE = "AUTH_USER_FAILURE";
export const AUTH_USER_RESET = "AUTH_USER_RESET";

export const ADD_USER_ACCOUNT = "ADD_USER_ACCOUNT";
export const CREATED_USER_ACCOUNT = "CREATED_USER_ACCOUNT";
export const CREATED_RESET_USER_ACCOUNT = "CREATED_RESET_USER_ACCOUNT";
export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";
export const ACCOUNTS_LIST = "ACCOUNTS_LIST";
export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const SET_PROFILE = "SET_PROFILE";

/* user management */

export const FETCH_MANAGEMENT_FAILURE = "FETCH_MANAGEMENT_FAILURE";
export const ADD_USER_MANAGEMENT = "ADD_USER_MANAGEMENT";
export const ADD_USER_MANAGEMENT_SUCCESS = "ADD_USER_MANAGEMENT_SUCCESS";
export const ADD_USER_MANAGEMENT_FAILURE = "ADD_USER_MANAGEMENT_FAILURE";
export const BUSINESSLIST_INVITE_USER = "BUSINESSLIST_INVITE_USER";
export const BUSINESSLIST_INVITE_USER_STARTED = "BUSINESSLIST_INVITE_USER_STARTED";
export const USER_MANAGEMENT_LIST_STARTED = "USER_MANAGEMENT_LIST_STARTED";
export const USER_MANAGEMENT_LIST = "USER_MANAGEMENT_LIST";
export const UPDATE_USER_MANAGEMENT_BY_ID_STARTED = "UPDATE_USER_MANAGEMENT_BY_ID_STARTED";

export const FETCH_ACCOUNTLIST_REQUEST = "FETCH_ACCOUNTLIST_REQUEST";
export const FETCH_ACCOUNTLIST_SUCCESS = "FETCH_ACCOUNTLIST_SUCCESS";
export const FETCH_ACCOUNTLIST_FAILURE = "FETCH_ACCOUNTLIST_FAILURE";
export const SET_ACCOUNT_LIST = "SET_ACCOUNT_LIST";
export const DELETE_ACCOUNT_LIST = "DELETE_ACCOUNT_LIST";
export const START_USER_MANAGEMENT_UPDATE = "START_USER_MANAGEMENT_UPDATE";
export const RESET_USER_MANAGEMENT_UPDATE = "RESET_USER_MANAGEMENT_UPDATE";

export const REMOVE_USER_MANAGEMENT_BY_ID = "REMOVE_USER_MANAGEMENT_BY_ID";
export const REMOVE_USER_MANAGEMENT_BY_ID_SUCCESS = "REMOVE_USER_MANAGEMENT_BY_ID_SUCCESS";
export const Add_USER_MANAGEMENT_LIST = "Add_USER_MANAGEMENT_LIST";
export const UPDATE_USER_MANAGEMENT_BY_ID = "UPDATE_USER_MANAGEMENT_BY_ID";

/* BUSINESS PREMISES */
export const ADD_BUSINESS_PREMISES = "ADD_BUSINESS_PREMISES";
export const CREATED_BUSINESS_PREMISES = "CREATED_BUSINESS_PREMISES";
export const CREATED_RESET_BUSINESS_PREMISES = "CREATED_RESET_BUSINESS_PREMISES";
export const LIST_BUSINESS_PREMISES = "LIST_BUSINESS_PREMISES";
export const LIST_UPDATE_BUSINESS_PREMISES = "LIST_UPDATE_BUSINESS_PREMISES";
export const DETAIL_BUSINESS_PREMISES = "DETAIL_BUSINESS_PREMISES";
export const DETAIL_SET_BUSINESS_PREMISES = "DETAIL_SET_BUSINESS_PREMISES";
export const UPDATE_BUSINESS_PREMISES = "UPDATE_BUSINESS_PREMISES";
export const UPDATE_BUSINESS_PREMISES_SUCCESS = "UPDATE_BUSINESS_PREMISES_SUCCESS";
export const DELETE_BUSINESS_PREMISES = "DELETE_BUSINESS_PREMISES";
export const BUSINESS_PREMISES_FAILURE = "BUSINESS_PREMISES_FAILURE";
export const GET_PROFESSIONAL_GROUP = "GET_PROFESSIONAL_GROUP";
export const SET_PROFESSIONAL_GROUP = "SET_PROFESSIONAL_GROUP";
export const FAILURE_PROFESSIONAL_GROUP = "FAILURE_PROFESSIONAL_GROUP";
export const GET_LEADER_BY_LOCATION_STARTED = "GET_LEADER_BY_LOCATION_STARTED";
export const GET_LEADER_BY_LOCATION_SUCCESS = "GET_LEADER_BY_LOCATION_SUCCESS";
export const GET_LEADER_BY_LOCATION_FAILURE = "GET_LEADER_BY_LOCATION_FAILURE";
export const ADD_LEADER_BY_LOCATION_STARTED = "ADD_LEADER_BY_LOCATION_STARTED";
export const ADD_LEADER_BY_LOCATION_SUCCESS = "ADD_LEADER_BY_LOCATION_SUCCESS";
export const ADD_LEADER_BY_LOCATION_FAILURE = "ADD_LEADER_BY_LOCATION_FAILURE";
export const OWNER_BY_LOCATION_STARTED = "OWNER_BY_LOCATION_STARTED";
export const OWNER_BY_LOCATION_SUCCESS = "OWNER_BY_LOCATION_SUCCESS";
export const OWNER_BY_LOCATION_FAILURE = "OWNER_BY_LOCATION_FAILURE";
export const ADD_OWNER_BY_LOCATION_STARTED = "ADD_OWNER_BY_LOCATION_STARTED";
export const ADD_OWNER_BY_LOCATION_SUCCESS = "ADD_OWNER_BY_LOCATION_SUCCESS";
export const ADD_OWNER_BY_LOCATION_FAILURE = "ADD_OWNER_BY_LOCATION_FAILURE";
export const SET_CHECK_NEW_LEADER = "SET_CHECK_NEW_LEADER";
export const SET_CHECK_NEW_LOCATION = "SET_CHECK_NEW_LOCATION";
export const GET_ALL_BUSINESS_STARTED = "GET_ALL_BUSINESS_STARTED";
export const GET_ALL_BUSINESS_SUCCESS = "GET_ALL_BUSINESS_SUCCESS";
export const GET_ALL_BUSINESS_FAILURE = "GET_ALL_BUSINESS_FAILURE";

/* DOCUMENT */
// export const ADD_DOCUMENT_BY_ID = "ADD_DOCUMENT_BY_ID";

export const LIST_FROM_TO_YOU_STARTED = "LIST_FROM_TO_YOU_STARTED";
export const DOCUMENT_LIST_FAILURE = "DOCUMENT_LIST_FAILURE";
export const LIST_FROM_TO_YOU_SUCCESS = "LIST_FROM_TO_YOU_SUCCESS";
export const LIST_USER_FILES_STARTED = "LIST_USER_FILES_STARTED";
export const LIST_USER_FILES_SUCCESS = "LIST_USER_FILES_SUCCESS";
export const LIST_USER_FILES_FAILURE = "LIST_USER_FILES_FAILURE";
export const REMOVE_USER_FILES_DOCUMENT_BY_ID = "REMOVE_USER_FILES_DOCUMENT_BY_ID";
export const LIST_DOCUMENT_CATEGORY_STARTED = "LIST_DOCUMENT_CATEGORY_STARTED";
export const LIST_DOCUMENT_CATEGORY_SUCCESS = "LIST_DOCUMENT_CATEGORY_SUCCESS";
export const LIST_DOCUMENT_CATEGORY_FAILURE = "LIST_DOCUMENT_CATEGORY_FAILURE";
export const UPLOAD_DOCUMENT_STARTED = "UPLOAD_DOCUMENT_STARTED";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAILURE = "UPLOAD_DOCUMENT_FAILURE";
export const UPLOAD_DOCUMENT_BASE64_STARTED = "UPLOAD_DOCUMENT_BASE64_STARTED";
export const UPLOAD_DOCUMENT_BASE64_SUCCESS = "UPLOAD_DOCUMENT_BASE64_SUCCESS";
export const UPLOAD_DOCUMENT_BASE64_FAILURE = "UPLOAD_DOCUMENT_BASE64_FAILURE";
export const UPLOAD_DMS_DOCUMENT_STARTED = "UPLOAD_DMS_DOCUMENT_STARTED";
export const UPLOAD_DMS_DOCUMENT_SUCCESS = "UPLOAD_DMS_DOCUMENT_SUCCESS";
export const UPLOAD_DMS_DOCUMENT_FAILURE = "UPLOAD_DMS_DOCUMENT_FAILURE";

/* CMS */
export const MODAL_OPEN_CMS = "MODAL_OPEN_CMS";
export const MODAL_CLOSE_CMS = "MODAL_CLOSE_CMS";
export const UPDATE_CMS_CONTENT = "UPDATE_CMS_CONTENT";
export const UPDATE_CMS_CONTENT_SUCCESS = "UPDATE_CMS_CONTENT_SUCCESS";
export const UPDATE_CMS_CONTENT_FAILURE = "UPDATE_CMS_CONTENT_FAILURE";
export const LIST_CMS_CONTENT = "LIST_CMS_CONTENT";
export const LIST_CMS_CONTENT_SUCCESS = "LIST_CMS_CONTENT_SUCCESS";
export const LIST_CMS_CONTENT_FAILURE = "LIST_CMS_CONTENT_FAILURE";
export const CMS_DFORM_CONTENT = "CMS_DFORM_CONTENT";
export const CMS_DFORM_CONTENT_SUCCESS = "CMS_DFORM_CONTENT_SUCCESS";
export const CMS_DFORM_CONTENT_FAILURE = "CMS_DFORM_CONTENT_FAILURE";
/** CMS DFORM */
export const MODAL_OPEN_CMS_DFORM = "MODAL_OPEN_CMS_DFORM";
export const MODAL_CLOSE_CMS_DFORM = "MODAL_CLOSE_CMS_DFORM";
export const GET_DFORM_CMS_BY_ID = "GET_DFORM_CMS_BY_ID";
export const GET_DFORM_CMS_BY_ID_SUCCESS = "GET_DFORM_CMS_BY_ID_SUCCESS";
export const GET_DFORM_CMS_BY_ID_FAILURE = "GET_DFORM_CMS_BY_ID_FAILURE";
export const UPDATE_DFORM_CMS_BY_ID = "UPDATE_DFORM_CMS_BY_ID";
/** HELPDESK */
export const GET_HELPDESK_ARTICLE_LIST_STARTED = "GET_HELPDESK_ARTICLE_LIST_STARTED";
export const GET_HELPDESK_ARTICLE_LIST_SUCCESS = "GET_HELPDESK_ARTICLE_LIST_SUCCESS";
export const GET_HELPDESK_ARTICLE_LIST_FAILURE = "GET_HELPDESK_ARTICLE_LIST_FAILURE";
export const GET_HELPDESK_ARTICLE_DETAIL_STARTED = "GET_HELPDESK_ARTICLE_DETAIL_STARTED";
export const GET_HELPDESK_ARTICLE_DETAIL_SUCCESS = "GET_HELPDESK_ARTICLE_DETAIL_SUCCESS";
export const GET_HELPDESK_ARTICLE_DETAIL_FAILURE = "GET_HELPDESK_ARTICLE_DETAIL_FAILURE";
export const GET_HELPDESK_ARTICLE_CONTENT_STARTED = "GET_HELPDESK_ARTICLE_CONTENT_STARTED";
export const GET_HELPDESK_ARTICLE_CONTENT_SUCCESS = "GET_HELPDESK_ARTICLE_CONTENT_SUCCESS";
export const GET_HELPDESK_ARTICLE_CONTENT_FAILURE = "GET_HELPDESK_ARTICLE_CONTENT_FAILURE";

/* MASTER */
export const LIST_CONTACT = "LIST_CONTACT";
export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const CLEAR_CONTACT_LIST = "CLEAR_CONTACT_LIST";

/* HELP */
export const FETCH_HELP_LIST_REQUEST = "FETCH_HELP_LIST_REQUEST";
export const FETCH_HELP_LIST_SUCCESS = "FETCH_HELP_LIST_SUCCESS";
export const FETCH_HELP_LIST_FAILURE = "FETCH_HELP_LIST_FAILURE";

/* INBOX */
export const MESSAGE_LIST = "MESSAGE_LIST";
export const SET_MESSAGE_LIST = "SET_MESSAGE_LIST";
export const MESSAGE_LIST_FAILURE = "MESSAGE_LIST_FAILURE";

export const MESSAGE_READ = "MESSAGE_READ";
export const MESSAGE_READ_SUCCESS = "MESSAGE_READ_SUCCESS";
export const MESSAGE_READ_FAILURE = "MESSAGE_READ_FAILURE";

/* PROCEDURE */
export const PROCEDURE_TEMPLATES = "PROCEDURE_TEMPLATES";
export const SET_PROCEDURE_TEMPLATES = "SET_PROCEDURE_TEMPLATES";
export const PROCEDURE_FAILURE = "PROCEDURE_FAILURE";

/* APPLICATION */

export const GET_APPLICATION_STARTED_ByID = "GET_APPLICATION_STARTED_ByID";
export const RESET_APPLICATION = "RESET_APPLICATION";
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";
export const GET_APPLICATION_FAILURE = "GET_APPLICATION_FAILURE";
export const SET_APPLICATION_SIDEBAR = "SET_APPLICATION_SIDEBAR";
export const RESET_APPLICATION_SIDEBAR = "RESET_APPLICATION_SIDEBAR";
export const ADD_APPLICATION_STEP_STARTED = "ADD_APPLICATION_STEP_STARTED";
export const ADD_APPLICATION_STEP_SUCCESS = "ADD_APPLICATION_STEP_SUCCESS";
export const ADD_APPLICATION_STEP_FAILURE = "ADD_APPLICATION_STEP_FAILURE";
export const ADD_SERVICE_APPLICATION_STEP_STARTED = "ADD_SERVICE_APPLICATION_STEP_STARTED";
export const ADD_SERVICE_APPLICATION_STEP_SUCCESS = "ADD_SERVICE_APPLICATION_STEP_SUCCESS";
export const ADD_SERVICE_APPLICATION_STEP_FAILURE = "ADD_SERVICE_APPLICATION_STEP_FAILURE";
export const RESET_SET_APPLICATION = "RESET_SET_APPLICATION";
export const SET_APPLICATION_STEP_FACHLICHEN_LEITER_VALUE_ByID = "SET_APPLICATION_STEP_FACHLICHEN_LEITER_VALUE_ByID";
export const CREATE_COMPLETE_APPLICATION_STARTED = "CREATE_COMPLETE_APPLICATION_STARTED";
export const CREATE_COMPLETE_APPLICATION_SUCCESS = "CREATE_COMPLETE_APPLICATION_SUCCESS";
export const CREATE_COMPLETE_APPLICATION_FAILURE = "CREATE_COMPLETE_APPLICATION_FAILURE";
export const NEW_SERVICE_ORDER_COSTS_STARTED = "NEW_SERVICE_ORDER_COSTS_STARTED";
export const NEW_SERVICE_ORDER_COSTS_SUCCESS = "NEW_SERVICE_ORDER_COSTS_SUCCESS";
export const NEW_SERVICE_ORDER_COSTS_FAILURE = "NEW_SERVICE_ORDER_COSTS_FAILURE";
export const SEND_SIGNATURE_REQUEST_EMAIL_STARTED = "SEND_SIGNATURE_REQUEST_EMAIL_STARTED";
export const SEND_SIGNATURE_REQUEST_EMAIL_SUCCESS = "SEND_SIGNATURE_REQUEST_EMAIL_SUCCESS";
export const SEND_SIGNATURE_REQUEST_EMAIL_FAILURE = "SEND_SIGNATURE_REQUEST_EMAIL_FAILURE";
export const APPLICATION_PROCESS_STATUS_STARTED = "APPLICATION_PROCESS_STATUS_STARTED";
export const APPLICATION_PROCESS_STATUS_SUCCESS = "APPLICATION_PROCESS_STATUS_SUCCESS";
export const APPLICATION_PROCESS_STATUS_FAILURE = "APPLICATION_PROCESS_STATUS_FAILURE";
export const APPLICATION_LOCATION_VALIDATION_STARTED = "APPLICATION_LOCATION_VALIDATION_STARTED";
export const APPLICATION_LOCATION_VALIDATION_SUCCESS = "APPLICATION_LOCATION_VALIDATION_SUCCESS";
export const APPLICATION_LOCATION_VALIDATION_FAILURE = "APPLICATION_LOCATION_VALIDATION_FAILURE";
export const APPLICATION_LOCATION_VALIDATION_RESET = "APPLICATION_LOCATION_VALIDATION_RESET";
export const APPLICATION_SIGNATURE_BY_ID = "APPLICATION_SIGNATURE_BY_ID";
export const APPLICATION_SIGNATURE_RESET = "APPLICATION_SIGNATURE_RESET";
export const PARTICULAR_APPLICATION_SIGNATURE_RESET = "PARTICULAR_APPLICATION_SIGNATURE_RESET";

/* Dynamic Form */

export const GET_STEPS_STARTED = "GET_STEPS_STARTED";
export const GET_STEPS_SUCCESS = "GET_STEPS_SUCCESS";
export const GET_STEPS_FAILURE = "GET_STEPS_FAILURE";
export const GET_SERVICE_AREA_STARTED = "GET_SERVICE_AREA_STARTED";
export const GET_SERVICE_AREA_SUCCESS = "GET_SERVICE_AREA_SUCCESS";
export const GET_SERVICE_AREA_FAILURE = "GET_SERVICE_AREA_FAILURE";
export const RESET_SERVICE_AREA = "RESET_SERVICE_AREA";
export const GET_SERVICE_AREA_DETAIL_STARTED = "GET_SERVICE_AREA_DETAIL_STARTED";
export const GET_SERVICE_AREA_DETAIL_SUCCESS = "GET_SERVICE_AREA_DETAIL_SUCCESS";
export const GET_SERVICE_AREA_DETAIL_FAILURE = "GET_SERVICE_AREA_DETAIL_FAILURE";
export const RESET_SERVICE_AREA_DETAIL = "RESET_SERVICE_AREA_DETAIL";
export const SET_PAGE_AIDS_BY_ID = "SET_PAGE_AIDS_BY_ID";
export const RESET_PAGE_AIDS_BY_ID = "RESET_PAGE_AIDS_BY_ID";
export const GET_SERVICE_AREA_TECHNICAL_LEADER_STARTED = "GET_SERVICE_AREA_TECHNICAL_LEADER_STARTED";

/*Dashboard*/
export const GET_DASHBOARD_DATA_STARTED = "GET_DASHBOARD_DATA_STARTED";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE";
export const GET_DASHBOARD_MESSAGE_STARTED = "GET_DASHBOARD_MESSAGE_STARTED";
export const GET_DASHBOARD_MESSAGE_SUCCESS = "GET_DASHBOARD_MESSAGE_SUCCESS";
export const GET_DASHBOARD_MESSAGE_FAILURE = "GET_DASHBOARD_MESSAGE_FAILURE";
export const GET_EVIDENCE_FILE_DOWNLOAD_STARTED = "GET_EVIDENCE_FILE_DOWNLOAD_STARTED";
export const GET_EVIDENCE_FILE_DOWNLOAD_SUCCESS = "GET_EVIDENCE_FILE_DOWNLOAD_SUCCESS";
export const GET_EVIDENCE_FILE_DOWNLOAD_FAILURE = "GET_EVIDENCE_FILE_DOWNLOAD_FAILURE";
export const GET_EVIDENCE_FILE_DOWNLOAD_RESET = "GET_EVIDENCE_FILE_DOWNLOAD_RESET";

/** Marketing */
export const GET_MARKETING_STARTED = "GET_MARKETING_STARTED";
export const GET_MARKETING_SUCCESS = "GET_MARKETING_SUCCESS";
export const GET_MARKETING_FAILURE = "GET_MARKETING_FAILURE";

/** recruit client */
export const GET_RECRUIT_CLIENT_STARTED = "GET_RECRUIT_CLIENT_STARTED";
export const GET_RECRUIT_CLIENT_SUCCESS = "GET_RECRUIT_CLIENT_SUCCESS";
export const GET_RECRUIT_CLIENT_FAILURE = "GET_RECRUIT_CLIENT_FAILURE";

/** Service Order */
export const GET_ON_GOING_SERVICE_ORDER_STARTED = "GET_ON_GOING_SERVICE_ORDER_STARTED";
export const GET_ON_GOING_SERVICE_ORDER_SUCCESS = "GET_ON_GOING_SERVICE_ORDER_SUCCESS";
export const GET_ON_GOING_SERVICE_ORDER_FAILURE = "GET_ON_GOING_SERVICE_ORDER_FAILURE";

export const GET_COMPLETED_SERVICE_ORDER_STARTED = "GET_COMPLETED_SERVICE_ORDER_STARTED";
export const GET_COMPLETED_SERVICE_ORDER_SUCCESS = "GET_COMPLETED_SERVICE_ORDER_SUCCESS";
export const GET_COMPLETED_SERVICE_ORDER_FAILURE = "GET_COMPLETED_SERVICE_ORDER_FAILURE";

export const GET_GOING_SERVICE_ORDER_PAGINATE_STARTED = "GET_GOING_SERVICE_ORDER_PAGINATE_STARTED";
export const GET_GOING_SERVICE_ORDER_PAGINATE_SUCCESS = "GET_GOING_SERVICE_ORDER_PAGINATE_SUCCESS";
export const GET_GOING_SERVICE_ORDER_PAGINATE_FAILURE = "GET_GOING_SERVICE_ORDER_PAGINATE_FAILURE";

/** Current Step */
export const ADD_CURRENT_STEP_DETAIL = "ADD_CURRENT_STEP_DETAIL";
export const ADD_CURRENT_STEP_DETAIL_VALUE = "ADD_CURRENT_STEP_DETAIL_VALUE";
export const REMOVE_CURRENT_STEP_LOCATION_BY_ID = "REMOVE_CURRENT_STEP_LOCATION_BY_ID";
export const RESET_CURRENT_STEP_DETAIL = "RESET_CURRENT_STEP_DETAIL";
export const ADD_CURRENT_STEP_TECHNICAL_LEADER = "ADD_CURRENT_STEP_TECHNICAL_LEADER";
export const ADD_CURRENT_STEP_DETAIL_VALUE_ONCHANGE = "ADD_CURRENT_STEP_DETAIL_VALUE_ONCHANGE";

/** EVIDENCE */
export const NEW_SERVICE_ORDER_EVIDENCE_STARTED = "NEW_SERVICE_ORDER_EVIDENCE_STARTED";
export const NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED = "NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED";
export const NEW_SERVICE_ORDER_EVIDENCE_SUCCESS = "NEW_SERVICE_ORDER_EVIDENCE_SUCCESS";
export const NEW_SERVICE_ORDER_EVIDENCE_FAILURE = "NEW_SERVICE_ORDER_EVIDENCE_FAILURE";
export const SET_EVIDENCE_BY_ID = "SET_EVIDENCE_BY_ID";
export const UPLOAD_EVIDENCE_STARTED = "UPLOAD_EVIDENCE_STARTED";
export const UPLOAD_EVIDENCE_SUCCESS = "UPLOAD_EVIDENCE_SUCCESS";
export const UPLOAD_EVIDENCE_FAILURE = "UPLOAD_EVIDENCE_FAILURE";
export const UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED = "UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED";
export const UPLOAD_SERVICE_ORDER_EVIDENCE_SUCCESS = "UPLOAD_SERVICE_ORDER_EVIDENCE_SUCCESS";
export const UPLOAD_SERVICE_ORDER_EVIDENCE_FAILURE = "UPLOAD_SERVICE_ORDER_EVIDENCE_FAILURE";
export const UPLOAD_SERVICE_ORDER_EVIDENCE_RESET = "UPLOAD_SERVICE_ORDER_EVIDENCE_RESET";
export const SAVE_EVIDENCE_DB_STARTED = "SAVE_EVIDENCE_DB_STARTED";
export const SAVE_EVIDENCE_DB_SUCCESS = "SAVE_EVIDENCE_DB_SUCCESS";
export const SAVE_EVIDENCE_DB_FAILURE = "SAVE_EVIDENCE_DB_FAILURE";
export const SAVE_EVIDENCE_ERP_STARTED = "SAVE_EVIDENCE_ERP_STARTED";
export const SAVE_EVIDENCE_ERP_SUCCESS = "SAVE_EVIDENCE_ERP_SUCCESS";
export const SAVE_EVIDENCE_ERP_FAILURE = "SAVE_EVIDENCE_ERP_FAILURE";
export const GET_EVIDENCE_DB_STARTED = "GET_EVIDENCE_DB_STARTED";
export const GET_EVIDENCE_DB_SO_ID_STARTED = "GET_EVIDENCE_DB_SO_ID_STARTED";
export const GET_EVIDENCE_DB_SUCCESS = "GET_EVIDENCE_DB_SUCCESS";
export const GET_EVIDENCE_DB_FAILURE = "GET_EVIDENCE_DB_FAILURE";
export const UPLOAD_SERVER_ORDER_EVIDENCE_STARTED = "UPLOAD_SERVER_ORDER_EVIDENCE_STARTED";
export const UPLOAD_SERVER_ORDER_EVIDENCE_SUCCESS = "UPLOAD_SERVER_ORDER_EVIDENCE_SUCCESS";
export const UPLOAD_SERVER_ORDER_EVIDENCE_FAILURE = "UPLOAD_SERVER_ORDER_EVIDENCE_FAILURE";

export const ADD_EVIDENCE = "ADD_EVIDENCE";
export const REMOVE_EVIDENCE = "REMOVE_EVIDENCE";
export const CLEAR_EVIDENCES = "CLEAR_EVIDENCES";

/** SIGNATURE UPLOAD */
export const SIGNATURE_UPLOAD_STARTED = "SIGNATURE_UPLOAD_STARTED";
export const ASIGNATURE_UPLOAD_SUCCESS = "ASIGNATURE_UPLOAD_SUCCESS";
export const ASIGNATURE_UPLOAD_FAILURE = "ASIGNATURE_UPLOAD_FAILURE";

export const REGISTER_USER_START_MODIFY = "REGISTER_USER_START_MODIFY";

/** DOWNLOAD DOCUMENT */
export const DOWNLOAD_ORDER_STARTED = "DOWNLOAD_ORDER_STARTED";
export const DOWNLOAD_ORDER_SUCCESS = "DOWNLOAD_ORDER_SUCCESS";
export const DOWNLOAD_INVOICE_STARTED = "DOWNLOAD_INVOICE_STARTED";
export const DOWNLOAD_INVOICE_SUCCESS = "DOWNLOAD_ORDER_SUCCESS";
export const DOWNLOAD_CERTIFICATE_STARTED = "DOWNLOAD_CERTIFICATE_STARTED";
export const DOWNLOAD_CERTIFICATE_SUCCESS = "DOWNLOAD_CERTIFICATE_SUCCESS";
export const DOWNLOAD_XML_STARTED = "DOWNLOAD_XML_STARTED";
export const DOWNLOAD_XML_SUCCESS = "DOWNLOAD_XML_SUCCESS";
export const DOWNLOAD_DOCUMENT_FAILURE = "DOWNLOAD_DOCUMENT_FAILURE";
export const DELETE_SINGLE_EVIDENCE_STARTED = "DELETE_SINGLE_EVIDENCE_STARTED";
export const DELETE_SINGLE_EVIDENCE_SUCCESS = "DELETE_SINGLE_EVIDENCE_SUCCESS";
export const DELETE_SINGLE_EVIDENCE_FAILURE = "DELETE_SINGLE_EVIDENCE_FAILURE";
