import { useFormik } from "formik";
import React from "react";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import StandardSelect from "src/components/atoms/select/StandardSelect";

const ExistingUserForm = ({ addLeaderHandler, handleClose, userList }) => {
  const listInhaber = userList.map((item) => {
    return { title: `${item.first_name + " " + item.last_name}`, value: item.id };
  });
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      let filter = userList.find((value) => value.id === values.inhaber);
      let { id, ...rest } = filter;
      let professional_groups = JSON.parse(filter?.professional_groups);
      if (professional_groups?.length > 0) {
        let groupProfession = professional_groups?.map((professional) => {
          return { id: professional };
        });
        addLeaderHandler({ ...rest, professional_groups: groupProfession, newId: id, status: "edit" });
      } else {
        addLeaderHandler({ ...rest, professional_groups: [], newId: id, status: "edit" });
      }
      handleClose();
    },
  });

  const isSalutationSelected = formik.values.inhaber && formik.values.inhaber !== "";

  return (
    <form className="flex flex-col gap-4 mb-2" onSubmit={formik.handleSubmit}>
      <div>
        <StandardSelect
          extra="w-full w-auto pt-6 pb-8"
          extraLabel="hidden"
          label={"Fachliche leiter wählen"}
          placeholder="Herr/Frau"
          id="inhaber"
          options={listInhaber}
          choose={!isSalutationSelected}
          formik={formik}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
          <PrimaryButton title="Speichern" type="submit" />
          <SecondaryButton variant="secondary" title="Abbrechen" handler={handleClose} />
        </div>
      </div>
    </form>
  );
};

export default ExistingUserForm;
