import { useState } from "react";
import BusinessGrid from "../BusinessGrid";
import SearchHeader from "../SearchHeader";
import useDebounce from "src/hooks/useDebounce";
import { businessPremisesHeader } from "src/mockData/businessPremises";
import BusinessPremisesTable from "src/components/molecules/table/BusinessPremisesTable";
import { useSelector, useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

const BusinessPremisesList = ({ handleOpen, businessList }) => {
  const [view, setView] = useState("grid");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState(businessList);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { business_premises } = cmsDashboarddata;
  const dispatch = useDispatch();
  const allLocation = businessList.map((item) => item.house_number);

  const handleSearch = useDebounce((term) => {
    const filteredData = businessList.filter(
      (item) =>
        item.name.toLowerCase().includes(term.toLowerCase()) ||
        item.name.toLowerCase().includes(term.toLowerCase()) ||
        item.house_number.toLowerCase().includes(term.toLowerCase()) ||
        item.street.toLowerCase().includes(term.toLowerCase()) ||
        item.ik_number.join(",").toLowerCase().includes(term.toLowerCase()) ||
        item.business_owners.some((owner) => owner.first_name.toLowerCase().includes(term.toLowerCase()) || owner.last_name.toLowerCase().includes(term.toLowerCase())) ||
        item.technical_leaders.some((leader) => leader.first_name.toLowerCase().includes(term.toLowerCase()) || leader.last_name.toLowerCase().includes(term.toLowerCase()))
    );
    setFilterData(filteredData);
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    handleSearch(value);
  };

  const ToggleHandler = (key) => {
    setView(key);
  };

  return (
    <>
      <SearchHeader ToggleHandler={ToggleHandler} view={view} handleChange={handleChange} searchTerm={searchTerm} handleOpen={handleOpen} allLocation={allLocation} />
      {view === "list" ? (
        <>
          <EditIconCMS
            css="float-right"
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "table_head",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
          <BusinessPremisesTable businessPremisesBody={filterData} businessPremisesHeader={businessPremisesHeader(business_premises.table_head)} />
        </>
      ) : (
        <BusinessGrid handleOpen={handleOpen} filterData={filterData} />
      )}
    </>
  );
};

export default BusinessPremisesList;
