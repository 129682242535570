import Login from "src/components/template/Login";
import ForgotForm from "src/components/organisms/login/ForgotForm";

const { loginPolicy, subHeading, heading } = {
  "heading": "Kundenportal",
  "subHeading": "Passwort vergessen?",
  "loginPolicy": "Geben Sie die E-Mail-Adresse ein, die mit Ihrem AfPQ-Kundenportal verbunden ist. Wir senden Ihnen einen Link, um ein neues Passwort anzulegen, der 24 Stunden gültig ist.",
};

const forgotPreField = {
  title: heading,
  subTitle: subHeading,
  LoginPolicy: loginPolicy,
};
const ForgotPassword = () => {
  return (
    <div className="loginwrap">
      <div className="loginwcontainer">
        <Login data={forgotPreField}>
          <ForgotForm />
        </Login>
      </div>
    </div>
  );
};

export default ForgotPassword;
