import { getApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const GET_STEPS = async (paramsData) => {
  const url = `${BASE_URL}${API.STEPS_LIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_SERVICE_AREA = async (paramsData) => {
  const url = `${BASE_URL}${API.SERVICE_AREA_LIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_SERVICE_AREA_DETAIL = async (paramsData) => {
  const url = `${BASE_URL}${API.SERVICE_AREA_DETAIL}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
