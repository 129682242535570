import React from "react";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, data, ...other } = props;

  return (
    <div className="p-2" role="tabpanel" hidden={value != index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value == index && React.isValidElement(children) ? React.cloneElement(children, data) : null}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel
