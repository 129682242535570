import {
  DOWNLOAD_XML_STARTED,
  DOWNLOAD_XML_SUCCESS,
  DOWNLOAD_ORDER_STARTED,
  DOWNLOAD_ORDER_SUCCESS,
  DOWNLOAD_INVOICE_STARTED,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE,
  DOWNLOAD_CERTIFICATE_STARTED,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  GET_EVIDENCE_FILE_DOWNLOAD_FAILURE,
  GET_EVIDENCE_FILE_DOWNLOAD_SUCCESS,
  GET_EVIDENCE_FILE_DOWNLOAD_STARTED,
  GET_EVIDENCE_FILE_DOWNLOAD_RESET,
} from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  evidenceFileDownload: {
    data: null,
    isLoading: false,
    error: null,
    evidence_id: "",
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_ORDER_STARTED: {
      return { ...state, isLoading: true };
    }
    case DOWNLOAD_ORDER_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case DOWNLOAD_INVOICE_STARTED: {
      return { ...state, isLoading: true };
    }
    case DOWNLOAD_INVOICE_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case DOWNLOAD_CERTIFICATE_STARTED: {
      return { ...state, isLoading: true };
    }
    case DOWNLOAD_CERTIFICATE_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case DOWNLOAD_XML_STARTED: {
      return { ...state, isLoading: true };
    }
    case DOWNLOAD_XML_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case DOWNLOAD_DOCUMENT_FAILURE: {
      return { ...state, isLoading: false, error: "error" };
    }

    case GET_EVIDENCE_FILE_DOWNLOAD_STARTED: {
      return {
        ...state,
        evidenceFileDownload: {
          data: null,
          isLoading: true,
          error: null,
          evidence_id: "",
        },
      };
    }

    case GET_EVIDENCE_FILE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        evidenceFileDownload: {
          data: action.payload?.data,
          isLoading: false,
          error: null,
          evidence_id: action.payload?.evidence_id,
          file_name: action.payload?.file_name,
        },
      };
    }

    case GET_EVIDENCE_FILE_DOWNLOAD_FAILURE: {
      return {
        ...state,
        evidenceFileDownload: {
          data: null,
          isLoading: false,
          error: null,
          evidence_id: "",
        },
      };
    }

    case GET_EVIDENCE_FILE_DOWNLOAD_RESET: {
      return {
        ...state,
        evidenceFileDownload: {
          data: null,
          isLoading: false,
          error: null,
          evidence_id: "",
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
