/* eslint-disable */
import React, { useEffect } from "react";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import { Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { cmsModalCloseForm, updateDFormCMSbyIdStarted } from "src/store/actions/cmsDashboard";
import styles from "./style.module.css";
import ProcedureInputStandard from "./ProcedureInputStandard";
import toast from "react-hot-toast";
import * as yup from "yup";
import TextAreaFeildCms from "src/components/atoms/input/TextAreaFeildCms";

const style = {
  p: 2,
  left: "50%",
  top: "50%",
  width: "90%",
  maxWidth: "840px",
  bgcolor: "#FBFBFB",
  borderRadius: "12px",
  position: "relative",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};

const MessageModalDForm = ({ open, serviceSelected, setIsReload }) => {
  const dispatch = useDispatch();
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const modalData = cmsDocument?.adminDFormCMS?.data?.modalData;
  const currentStep = cmsDocument?.adminDFormCMS?.data?.currentStep;
  const admincmshead = cmsDocument?.adminDFormCMS?.data?.steps;
  const stephead = admincmshead.find((head) => head.id == currentStep);

  const formik = useFormik({
    initialValues: { ...modalData, [stephead.id]: stephead.title },

    onSubmit: (values) => {
      let status = true;
      let lengthStatus = true;
      const errors = {};
      const matchingData = {};
      const nonMatchingData = {};
      Object.keys(values)?.forEach((key) => {
        if (key === stephead?.id) {
          matchingData[key] = values[key];
        } else {
          nonMatchingData[key] = values[key];
          let obj = values[key];
          const invalidCharsRegex = /[\n\t]/;
          const htmlTagsRegex = /<\/?[^>]+(>|$)/;
          for (const key in obj) {
            if (key !== "helpdesk" && typeof obj[key] === "string") {
              const value = obj[key];
              const cleanedValue = value.replace(invalidCharsRegex, "");

              if (value.length < 2) {
                lengthStatus = false;
              } else if (htmlTagsRegex.test(value) || value !== cleanedValue) {
                status = false;
              }
            }
          }
        }
      });
      const hasHtmlTags = /<\/?[^>]+(>|$)/g.test(values[currentStep]);
      const inputLength = values[currentStep].length >= 2;

      if (!hasHtmlTags && status && inputLength && lengthStatus) {
        const payload = {
          service_order_type: serviceSelected,
          step_id: currentStep,
          step_title: values[currentStep],
          form_data: nonMatchingData,
        };
        dispatch(updateDFormCMSbyIdStarted(payload));
      } else {
        if (!inputLength) {
          toast.error("Mind. 2 Zeichen");
        } else if (!lengthStatus) {
          toast.error("Mind. 2 Zeichen");
        } else {
          toast.error("Werte enthalten Tags oder ungültige Zeichen wie \n oder \t");
        }
      }
    },
  });
  const handleClose = () => {
    dispatch(cmsModalCloseForm());
  };

  useEffect(() => {
    return () => {
      setIsReload(true);
    };
  }, []);

  return (
    <Modal open={open} className={styles.customModalRoot}>
      <Box sx={style} className={styles.customInnerChild}>
        <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
          <div className="flex justify-between items-center mb-6 md:pr-8">
            <h2 className="text-xl md:text-2xl font-tahoma-bold">{stephead.title}</h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2" onClick={handleClose} />
          </div>
          <form className="flex flex-col gap-4 mb-2 justify-between min-h-48" onSubmit={formik.handleSubmit}>
            <TextAreaFeildCms id={stephead.id} type="text" formik={formik} extraLabel="!ml-0" placeholder={stephead.title} label={stephead.title} extra="w-full !items-start" />

            <hr className="my-4" />
            {Object.entries(modalData).map(([key, val], index) => {
              return (
                <React.Fragment key={key}>
                  <ProcedureInputStandard itemKey={key} ItemVal={val} itemIndex={index} formik={formik} />
                  <hr className="my-6" />
                </React.Fragment>
              );
            })}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
              <PrimaryButton title="Speichern" type="submit" loading={cmsDocument?.adminDFormCMS?.isLoading} />
              <SecondaryButton variant={`${cmsDocument?.adminDFormCMS?.isLoading ? "disabled" : "secondary"}`} title="Abbrechen" handler={handleClose} />
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default MessageModalDForm;
