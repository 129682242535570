import {
  ADD_APPLICATION_STEP_FAILURE,
  ADD_APPLICATION_STEP_STARTED,
  ADD_APPLICATION_STEP_SUCCESS,
  APPLICATION_LOCATION_VALIDATION_FAILURE,
  APPLICATION_LOCATION_VALIDATION_RESET,
  APPLICATION_LOCATION_VALIDATION_STARTED,
  APPLICATION_LOCATION_VALIDATION_SUCCESS,
  APPLICATION_PROCESS_STATUS_FAILURE,
  APPLICATION_PROCESS_STATUS_STARTED,
  APPLICATION_PROCESS_STATUS_SUCCESS,
  APPLICATION_SIGNATURE_BY_ID,
  APPLICATION_SIGNATURE_RESET,
  PARTICULAR_APPLICATION_SIGNATURE_RESET,
  CREATE_COMPLETE_APPLICATION_FAILURE,
  CREATE_COMPLETE_APPLICATION_STARTED,
  CREATE_COMPLETE_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
  GET_APPLICATION_STARTED_ByID,
  GET_APPLICATION_SUCCESS,
  NEW_SERVICE_ORDER_COSTS_FAILURE,
  NEW_SERVICE_ORDER_COSTS_STARTED,
  NEW_SERVICE_ORDER_COSTS_SUCCESS,
  RESET_APPLICATION,
  RESET_APPLICATION_SIDEBAR,
  SEND_SIGNATURE_REQUEST_EMAIL_FAILURE,
  SEND_SIGNATURE_REQUEST_EMAIL_STARTED,
  SEND_SIGNATURE_REQUEST_EMAIL_SUCCESS,
  SET_APPLICATION_SIDEBAR,
  SET_APPLICATION_STEP_FACHLICHEN_LEITER_VALUE_ByID,
} from "../actionTypes";

const initialState = {
  submitted_id: "",
  data: null,
  isLoading: false,
  error: null,
  sidebar: false,
  step_id: "",
  addingStep: {
    data: null,
    isLoading: false,
    error: null,
    status: false,
  },
  createdApplication: {
    data: null,
    isLoading: false,
    error: null,
    status: '',
  },
  orderCosts: {
    data: null,
    isLoading: false,
    error: null,
  },
  sendEmail: {
    data: null,
    isLoading: false,
    error: null,
    status: 'initial'
  },
  dformStatus: {
    data: null,
    isLoading: false,
    error: null,
  },
  locationValid: {
    data: null,
    isLoading: false,
    error: null,
    status: "pending"
  },
  contactSignature:{
    data: {}
  }
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATION_STARTED_ByID: {
      return { ...state, isLoading: true, data: null, addingStep: { ...state.addingStep, status: false } };
    }
    case RESET_APPLICATION: {
      return { ...state, ...initialState };
    }
    case GET_APPLICATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        step_id: action.payload.step_id,
      };
    }
    case GET_APPLICATION_FAILURE: {
      return { ...state, isLoading: false, error: "error", data: null };
    }
    case SET_APPLICATION_SIDEBAR: {
      return { ...state, sidebar: true };
    }
    case RESET_APPLICATION_SIDEBAR: {
      return { ...state, sidebar: false };
    }
    
    case SET_APPLICATION_STEP_FACHLICHEN_LEITER_VALUE_ByID: {
      if(state?.data[action?.payload?.key]){
        let newData = state?.data[action?.payload?.key]?.map((data) => {
          if (data?.id === action?.payload?.valueId) {
            return { ...data, inputTypeValue: action?.payload?.value };
          } else {
            return data;
          }
        });
        return { ...state, data: {...state.data, [action?.payload?.key]:newData} };
      } else {
        return state
      }
    }

    /** add step of dynamic form api  */
    case ADD_APPLICATION_STEP_STARTED: {
      return {
        ...state,
        addingStep: {
          data: null,
          isLoading: true,
          error: null,
          status: false
        },
      };
    }
    case ADD_APPLICATION_STEP_SUCCESS: {
      return {
        ...state,
        submitted_id: action?.payload?.submitted_id ? action?.payload?.submitted_id : state?.submitted_id,
        addingStep: {
          data: null,
          isLoading: false,
          error: null,
          status: action.payload.stepStatus ? false : true
        },
      };
    }
    case ADD_APPLICATION_STEP_FAILURE: {
      return {
        ...state,
        addingStep: {
          data: null,
          isLoading: false,
          error: null,
          status: false
        },
      };
    }

    /** complete application form detail create api  */

    case CREATE_COMPLETE_APPLICATION_STARTED: {
      return {
        ...state,
        createdApplication: {
          ...state.createdApplication,
          data: null,
          isLoading: true,
          error: null,
        }
      };
    }
    case CREATE_COMPLETE_APPLICATION_SUCCESS: {
      return {
        ...state,
        createdApplication: {
          ...state.createdApplication,
          data: action.payload,
          isLoading: false,
          error: null,
          status: 'completed'
        },
        addingStep: {
          data: null,
          isLoading: false,
          error: null,
          status: false
        }
      };
    }
    case CREATE_COMPLETE_APPLICATION_FAILURE: {
      return {
        ...state,
        createdApplication: {
          ...state.createdApplication,
          data: null,
          isLoading: false,
          error: null,
        },
        addingStep: {
          data: null,
          isLoading: false,
          error: null,
          status: false
        }
      };
    }
    /** new service order costs  */

    case NEW_SERVICE_ORDER_COSTS_STARTED: {
      return {
        ...state,
        orderCosts: {
          ...state.orderCosts,
          data: null,
          isLoading: true,
          error: null,
        }
      };
    }
    case NEW_SERVICE_ORDER_COSTS_SUCCESS: {
      return {
        ...state,
        orderCosts: {
          ...state.orderCosts,
          data: action.payload,
          isLoading: false,
          error: null,
        }
      };
    }
    case NEW_SERVICE_ORDER_COSTS_FAILURE: {
      return {
        ...state,
        orderCosts: {
          ...state.orderCosts,
          data: null,
          isLoading: false,
          error: null,
        }
      };
    }

    case SEND_SIGNATURE_REQUEST_EMAIL_STARTED: {
      return {
        ...state,
        sendEmail: {
          ...state.sendEmail,
          data: null,
          isLoading: true,
          error: null,
          status: 'pending'
        }
      };
    }
    case SEND_SIGNATURE_REQUEST_EMAIL_SUCCESS: {
      return {
        ...state,
        sendEmail: {
          ...state.sendEmail,
          data: action.payload,
          isLoading: false,
          error: null,
          status: 'success'
        }
      };
    }
    case SEND_SIGNATURE_REQUEST_EMAIL_FAILURE: {
      return {
        ...state,
        sendEmail: {
          ...state.sendEmail,
          data: null,
          isLoading: false,
          error: null,
          status: 'failed'
        }
      };
    }

    case APPLICATION_PROCESS_STATUS_STARTED: {
      return {
        ...state,
        dformStatus: {
          ...state.dformStatus,
          data: null,
          isLoading: true,
          error: null,
        }
      };
    }
    case APPLICATION_PROCESS_STATUS_SUCCESS: {
      return {
        ...state,
        dformStatus: {
          ...state.dformStatus,
          data: action.payload,
          isLoading: false,
          error: null,
        }
      };
    }
    case APPLICATION_PROCESS_STATUS_FAILURE: {
      return {
        ...state,
        dformStatus: {
          ...state.dformStatus,
          data: null,
          isLoading: false,
          error: null,
        }
      };
    }
    /** business location validation required functionality */
    case APPLICATION_LOCATION_VALIDATION_STARTED: {
      return {
        ...state,
        locationValid: {
          ...state.locationValid,
          data: null,
          isLoading: true,
          error: null,
          status: 'proceed'
        }
      };
    }
    case APPLICATION_LOCATION_VALIDATION_SUCCESS: {
      return {
        ...state,
        locationValid: {
          ...state.locationValid,
          data: action.payload.data,
          isLoading: false,
          error: null,
          status: 'done'
        }
      };
    }
    case APPLICATION_LOCATION_VALIDATION_FAILURE: {
      return {
        ...state,
        locationValid: {
          ...state.locationValid,
          data: null,
          isLoading: false,
          error: null,
          status: 'done'
        }
      };
    }
    case APPLICATION_LOCATION_VALIDATION_RESET: {
      return {
        ...state,
        locationValid: {
          ...state.locationValid,
          data: null,
          isLoading: false,
          error: null,
          status: 'pending'
        }
      };
    }
    case APPLICATION_SIGNATURE_BY_ID: {
      return {
        ...state,
        contactSignature:{
          ...state.contactSignature,
          data: {
            ...state.contactSignature.data,
            [action.payload.id]:action.payload.signatureImage
          }
        }
      };
    }
    case APPLICATION_SIGNATURE_RESET: {
      return {
        ...state,
        contactSignature:{
          data: {  }
        }
      };
    }
    case PARTICULAR_APPLICATION_SIGNATURE_RESET: {
      delete state.contactSignature.data[action.payload.id]
      return {
        ...state,
        contactSignature:{
          data: { 
            ...state.contactSignature.data
           }
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
