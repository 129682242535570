/* eslint-disable */
import React, { useState } from "react";
import InputStandardFieldCMS from "src/components/atoms/input/InputStandardField/InputStandardFieldCMS";
import ArticleModal from "./ArticleModal";
import { PrimaryButton } from "src/components/atoms/button";
import { useSelector } from "react-redux";

const ProcedureInputStandard = ({ itemKey, ItemVal, itemIndex, formik }) => {
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState({});
  const helpdesk = useSelector((state) => state.helpdesk);
  const handleOpen = (value) => {
    setSelectedLink(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <div className="grid grid-cols-1 gap-8" key={itemIndex}>
        {Object.entries(ItemVal).map(([key, value]) => {
          if (key === "helpdesk") {
            const helpdeks = helpdesk.data?.find((data) => data.article_id == value.article_id);
            return (
              <div className="flex justify-between gap-8">
                <p className="font-tahoma-bold">{helpdeks && helpdeks?.title}</p>
                <PrimaryButton
                  title="Hilfstext"
                  handler={() => {
                    handleOpen(value);
                  }}
                  btnStyle={"!max-w-[160px]"}
                />
              </div>
            );
          } else {
            return (
              <InputStandardFieldCMS
                key={key}
                id={key}
                itemKey={itemKey}
                type="text"
                formik={formik}
                extraLabel="!font-tahoma !w-full !ml-0 !mb-2 text !font-normal"
                placeholder={value}
                label={value}
                extra="w-full  !items-start"
              />
            );
          }
        })}
      </div>
      {open && <ArticleModal handleClose={handleClose} open={open} formik={formik} itemKey={itemKey} selectedLink={selectedLink} />}
    </>
  );
};

export default ProcedureInputStandard;
