/* eslint-disable */

import React, { useEffect, useState } from "react";
import { documentRole } from "src/mockData/settingMock";
import DocumentTable from "src/components/molecules/table/DocumentTable";
import { documentFromAndforYouHeadCMS } from "src/mockData/businessPremises";
import DocumentSearch from "src/components/molecules/search/DocumentSearch";
import { StandardSearch } from "src/components/molecules/search";
import useDebounce from "src/hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { listFromToYou } from "src/store/actions/document";
import { PrimaryButton } from "src/components/atoms/button";
import DocumentFilterSelect from "src/components/atoms/select/DocumentFilterSelect";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { useParams } from "react-router-dom";
import { API, BASE_URL } from "src/services/utils/defines";
import Cookies from "js-cookie";

const FromToYou = () => {
  const { id } = useParams();
  return id === "afpq" && <FromToYouIn />;
};

const FromToYouIn = () => {
  const document = useSelector((state) => state?.document?.fromToYouList);
  const [documentFromToYou, setDocumentFromToYou] = useState(null);
  const isLoading = document?.isLoading;
  const loadmoreButton = document?.loadmoreButton;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      listFromToYou({
        page: page,
        size: size,
      })
    );
  }, [page]);

  const moreDataHandle = () => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (documentFromToYou === null) {
      setDocumentFromToYou(document?.data);
    } else {
      if (document?.data?.length > 0) {
        setDocumentFromToYou(document?.data);
      }
    }
  }, [document]);

  return (
    <>
      {isLoading && documentFromToYou === null ? (
        <div className="flex w-full h-[60vh] justify-center items-center">
          <CustomCircularLoader />
        </div>
      ) : (
        documentFromToYou && <FromToYouContent documentFromToYou={documentFromToYou} moreDataHandle={moreDataHandle} isLoading={isLoading} loadmoreButton={loadmoreButton} />
      )}
    </>
  );
};

export default FromToYou;

const FromToYouContent = ({ documentFromToYou, moreDataHandle, isLoading, loadmoreButton }) => {
  const [docList, setDocList] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [donwloadSectedItem, setDownloadSelectedItem] = useState(false);
  const [allSelect, setAllSelect] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectDocument, setSelectDocument] = useState("");

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();

  const handleLocationChange = (event, newValue) => {
    if (newValue.length > 0) {
      const filterArray = docList.filter((item) => newValue.includes(item.location_name));
      setSelectedLocations(filterArray);
      setSearchItem("");
      setSearchTerm("");
    } else if (newValue.length == 0) {
      setSelectedLocations([]);
      setSearchItem("");
      setSearchTerm("");
    }
  };

  useEffect(() => {
    if (selectDocument == "" || selectDocument == "Alle Dokumente") {
      if (selectedLocations.length > 0) {
        setSelectedLocations(selectedLocations);
        setSearchItem("");
        setSearchTerm("");
      } else {
        setDocList(documentFromToYou);
        setSearchItem("");
        setSearchTerm("");
      }
    } else {
      if (selectedLocations.length > 0) {
        const result = selectedLocations.map((item) => item.location_name);
        const filteredDocuments =
          documentFromToYou.length > 0 ? documentFromToYou.filter((item) => item.category == selectDocument && result.includes(item.location_name)) : docList;
        setSelectedLocations(filteredDocuments);
        setSearchItem("");
        setSearchTerm("");
      } else {
        const filteredDocuments = documentFromToYou.length > 1 ? documentFromToYou.filter((item) => item.category == selectDocument) : docList;
        setDocList(filteredDocuments);
        setSearchItem("");
        setSearchTerm("");
      }
    }
  }, [selectDocument, selectedLocations.length, documentFromToYou]);

  const list = cmsDashboarddata?.document?.table_head;
  const headList = documentFromAndforYouHeadCMS(list?.name, list?.type, list?.business_premises, list?.application_type, list?.date, list?.document);

  const handleSearch = useDebounce((searchTermValue) => {
    if (selectedLocations.length > 0) {
      const filteredData = selectedLocations.filter(
        (doc) =>
          doc.name.toLowerCase().includes(searchTermValue.toLowerCase()) ||
          doc.category.toLowerCase().includes(searchTermValue.toLowerCase()) ||
          doc.location_name.toLowerCase().includes(searchTermValue.toLowerCase()) ||
          doc.created_at.toLowerCase().includes(searchTermValue.toLowerCase())
      );
      if (!!searchTermValue) {
        setSearchItem(filteredData);
      } else {
        setSearchItem("");
      }
    } else {
      const filteredData = documentFromToYou.filter(
        (doc) =>
          doc.name.toLowerCase().includes(searchTermValue.toLowerCase()) ||
          doc.category.toLowerCase().includes(searchTermValue.toLowerCase()) ||
          doc.location_name.toLowerCase().includes(searchTermValue.toLowerCase()) ||
          doc.created_at.toLowerCase().includes(searchTermValue.toLowerCase())
      );
      if (!!searchTermValue) {
        setSearchItem(filteredData);
      } else {
        setSearchItem("");
      }
    }
  }, 1000);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    handleSearch(value);
  };

  const allLocation = Array.from(new Set(documentFromToYou.map((item) => item.location_name)));

  const filteredDocuments = selectedLocations.length > 1 ? selectedLocations : docList;

  const selectedItemHandle = (value) => {
    value ? setDownloadSelectedItem(value) : (setDownloadSelectedItem(value), setAllSelect(false));
  };
  const allSelectionHandler = () => {
    setAllSelect(!allSelect);
  };

  const downloadAllHandle = () => {
    let filterSelected = selectedItem
      .filter((check) => check?.checked)
      .map((data) => {
        let findOne = filteredDocuments.find((d) => d?.id == data?.id);
        return findOne;
      });
    filterSelected?.forEach((item) => {
      downloadNewHandler(item?.id);
    });
  };

  const downloadNewHandler = (url) => {
    const link = window.document.createElement("a");
    link.href = `${BASE_URL}${API?.DOWNLOAD_DMS_DOCUMENT}?user_id=${Cookies.get("userId")}&document_id=${url}`;
    link.download = "document";
    link.style.display = "none";
    window.document.body.appendChild(link);
    window.open(link.href, "_blank");
    setTimeout(() => {
      window.document.body.removeChild(link);
    }, 100);
  };

  return (
    <>
      <div className="flex items-start justify-end gap-x-10 text-SmokeyGray flex-wrap my-4 mr-5 gap-y-4">
        <div className="flex gap-x-3">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "document",
                  unique: "check",
                  heading: "Dokumentenverwaltung",
                })
              );
            }}
          />
        </div>
        <DocumentFilterSelect
          extra="w-full w-auto max-w-[220px]"
          extraLabel="hidden"
          label={"Anrede"}
          placeholder="Herr/Frau"
          id="salutation"
          options={documentRole}
          choose={false}
          setSelectDocument={setSelectDocument}
        />
        <div className="flex gap-x-3">
          <div className="relative w-64 rounded-full mr-5">
            <DocumentSearch handleChange={handleLocationChange} locations={allLocation} placeholder={cmsDashboarddata?.document?.multi_select?.placeholder} />
          </div>
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "document",
                  unique: "multi_select",
                  heading: "Dokumentenverwaltung",
                })
              );
            }}
          />
        </div>

        <div className="flex gap-x-3">
          <div className="relative w-64 rounded-full">
            <StandardSearch value={searchTerm} handleSearch={handleSearchChange} placeholder={cmsDashboarddata?.document?.search?.title} />
          </div>
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "document",
                  unique: "search",
                  heading: "Dokumentenverwaltung",
                })
              );
            }}
          />
        </div>
      </div>

      <div className="w-full flex justify-end">
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "document",
                unique: "table_head",
                heading: "Dokumentenverwaltung",
              })
            );
          }}
        />
      </div>
      <DocumentTable
        businessPremisesBody={searchItem ? searchItem : filteredDocuments}
        businessPremisesHeader={headList}
        selectedLocations
        selectedItemHandle={selectedItemHandle}
        allSelect={allSelect}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        allSelectionHandler={allSelectionHandler}
      />
      <div className="h-16 flex justify-end items-center">
        {donwloadSectedItem && (
          <>
            <PrimaryButton title={cmsDashboarddata?.document?.allDownload?.title} btnStyle="!w-[180px] !font-tahoma-bold" handler={downloadAllHandle} />
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "document",
                    unique: "allDownload",
                    heading: "Dokumentenverwaltung",
                  })
                );
              }}
            />
          </>
        )}
      </div>
      {isLoading ? (
        <div className="flex w-full justify-center items-center">
          <CustomCircularLoader />
        </div>
      ) : (
        loadmoreButton &&
        (searchItem ? searchItem?.length > 9 : filteredDocuments?.length > 9) && (
          <div className="mb-0 mt-10 md:mb-10 md:mt-20 flex justify-center items-center border-t relative">
            <p
              onClick={() => {
                moreDataHandle();
              }}
              className=" bg-AFPBGPrimary absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] px-4 md:px-10 text-AFPPrimary font-semibold text-center whitespace-nowrap cursor-pointer flex items-center gap-x-3"
            >
              {cmsDashboarddata?.document?.more_button?.title}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "document",
                      unique: "more_button",
                      heading: "Dokumentenverwaltung",
                    })
                  );
                }}
              />
            </p>
          </div>
        )
      )}
    </>
  );
};
