/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const GroupButton = ({ stepsCMS, data }) => {
  const currentStep = useSelector((state) => state.currentStep);
  const business = useSelector((state) => state.management);

  const [selectedInhaber, setSelectedInhaber] = useState("");
  const [newerInhaber, setNewerInhaber] = useState("");
  const { id } = useParams();
  const form_id_name = localStorage?.getItem("form_id_name") && JSON?.parse(localStorage?.getItem("form_id_name"));
  const businessPremisesContactHandler = localStorage?.getItem("businessPremisesContactHandler") && JSON?.parse(localStorage?.getItem("businessPremisesContactHandler"));
  const dform_name = form_id_name && form_id_name?.find((name) => id == name?.id);
  const locationValidation = JSON.parse(localStorage.getItem("locationValidation"));

  useEffect(() => {
    let group = business?.ownerByLocation?.data;
    if (currentStep?.data[0]?.inputTypeValue && group) {
      const selectedInhaber = group.filter((contact) => contact?.contact_id == currentStep?.data[0]?.inputTypeValue);
      setSelectedInhaber(selectedInhaber[0]?.title + " " + selectedInhaber[0]?.first_name + " " + selectedInhaber[0]?.last_name);
      if(id === 'IWA'){
        const newerInhaber = businessPremisesContactHandler?.filter((contact) => contact?.contact_id == locationValidation?.existing_owner_id);
        if (newerInhaber?.length > 0) {
          setNewerInhaber(newerInhaber[0]?.title + " " + newerInhaber[0]?.first_name + " " + newerInhaber[0]?.last_name);
        }
      }
    }
  }, [currentStep, business?.ownerByLocation?.data]);
  let groupLeader = JSON.parse(localStorage.getItem("getAllLocation")).filter((item) => item?.location_id == data?.inputTypeValue);
  return (
    <>
      <div className="flex border py-1 border-black">
        <h1 className="flex text-normal font-tahoma-bold text-black w-full pl-1">{dform_name?.description}</h1>
      </div>
      {/* <div className="border border-black">
        <div className="flex py-1 ">
          <h1 className="text-normal font-bold text-black w-1/2 border-r pl-1 border-black">Aktenzeichnen</h1>
          <h3 className="pl-2"></h3>
        </div>
      </div> */}
      <div className="border border-black">
        <div className="flex py-1 ">
          <h1 className="text-normal font-bold text-black w-1/2 border-r pl-1 border-black">{stepsCMS[data?.id]?.title}</h1>
          <h3 className="pl-2">{groupLeader?.length > 0 && groupLeader[0]?.name}</h3>
        </div>
      </div>
      {selectedInhaber && <div className="flex border py-1 border-black">
        <h1 className="text-normal font-bold text-black w-1/2 border-r pl-1 border-black">Inhaber</h1>
        <p className="pl-2">{selectedInhaber}</p>
      </div>}
      {id === 'IWA' && 
      <div className="flex border py-1 border-black">
        <h1 className="text-normal font-bold text-black w-1/2 border-r pl-1 border-black">Alter Inhaber</h1>
        <p className="pl-2">{newerInhaber}</p>
      </div>}
    </>
  );
};

export default GroupButton;
