import React from "react";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

const ChatButton = ({ handleClick }) => {
  return (
    <button
      onClick={handleClick}
      type="button"
      className="fixed z-50 bottom-[50px] right-[20px] bg-AFPPrimary rounded-full w-[50px] h-[50px] text-white flex justify-center items-center shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] outline-none border-none"
    >
      <ChatBubbleIcon />
    </button>
  );
};

ChatButton.defaultProps = {
  handleClick: "Submit",
};
export default ChatButton;
