/* eslint-disable */
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputStandardField } from "src/components/atoms/input";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";

const AddressRelocation = ({ data, stepsCMS }) => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.currentStep);
  const formik = useFormik({
    initialValues: data?.inputTypeValue,
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const onChangeHandle = (id, value) => {
    let newDetail = currentStep.data.find((detail) => {
      if (detail.id === data.id) {
        return detail;
      }
    })?.inputTypeValue;
    newDetail[id] = value;
    dispatch(
      addCurrentStepDetailValue({
        valueId: data.id,
        value: newDetail,
        valueKey: "inputTypeValue",
      })
    );
  };

  useEffect(() => {
    dispatch(
      addCurrentStepDetailValue({
        valueId: data.id,
        value: data?.inputTypeValue,
        valueKey: "inputTypeValue",
      })
    );
  }, []);

  return (
    <>
      <h3 className="font-tahoma-bold mb-4">Neue Adresse</h3>
      <div className="my-5 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
        <InputStandardField id="street" formik={formik} extra="!items-start" placeholder="Straße" label="Straße" extraLabel="!ml-0" onChangeHandle={onChangeHandle} />
        <InputStandardField
          id="house_number"
          formik={formik}
          type="number"
          extra="!items-start"
          placeholder="Hausnummer"
          label="Hausnummer"
          extraLabel="!ml-0"
          onChangeHandle={onChangeHandle}
        />
      </div>
      <div className="my-5 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
        <InputStandardField id="city" formik={formik} extra="!items-start" placeholder="Stadt" label="Stadt" extraLabel="!ml-0" onChangeHandle={onChangeHandle} />
        <InputStandardField id="post_code" formik={formik} extra="!items-start" placeholder="PLZ" type="number" label="PLZ" extraLabel="!ml-0" onChangeHandle={onChangeHandle} />
        <InputStandardField id="country" formik={formik} extra="!items-start" placeholder="Land" label="Land" extraLabel="!ml-0" disabled={true} onChangeHandle={onChangeHandle} />
      </div>
      <hr className="my-5" />
    </>
  );
};

export default AddressRelocation;
