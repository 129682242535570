import { getApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const GET_DOWNLOAD_ORDER = async (paramsData) => {
  const url = `${BASE_URL}${API.DOWNLOAD_ORDER}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_DOWNLOAD_INVOICE = async (paramsData) => {
  const url = `${BASE_URL}${API.DOWNLOAD_INVOICE}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_DOWNLOAD_CERTIFICATE = async (paramsData) => {
  const url = `${BASE_URL}${API.DOWNLOAD_CERTIFICATE}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_DOWNLOAD_XML = async (paramsData) => {
  const url = `${BASE_URL}${API.DOWNLOAD_XML}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_EVIDENCE_FILE_DOWNLOAD = async (paramsData) => {
  const url = `${BASE_URL}${API.DOWNLOAD_EVIDENCE_FILE}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
