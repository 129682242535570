import { isBIC, isIBAN } from "src/helpers/setting";
import * as yup from "yup";

export const INITIALVALUESEDITUSERPAYMENT = {
  account_holder: "",
  credit_institute: "",
  bic: "",
  iban: "",
  // standard: false,
};

export const VALIDATIONSCHEMAEDITUSERPAYMENT = yup.object().shape({
  account_holder: yup.string().required("Kontoinhaber/in muss ausgefüllt werden"),
  iban: yup.string().required("IBAN muss ausgefüllt werden").test("is-iban", "Ungültig IBAN", isIBAN),
  bic: yup.string().required("BIC muss ausgefüllt werden").test("is-bic", "Ungültig BIC", isBIC),
  credit_institute: yup.string().required("Kreditinstitut muss ausgefüllt werden"),
});

export const INITIALVALUESADDUSERPAYMENT = {
  account_holder: "",
  credit_institute: "",
  bic: "",
  iban: "",
  invoice_email: "",
  is_default: false,
};

export const VALIDATIONSCHEMAADDUSERPAYMENT = yup.object().shape({
  account_holder: yup.string().required("Kontoinhaber/in muss ausgefüllt werden"),
  iban: yup.string().required("IBAN muss ausgefüllt werden").test("is-iban", "Ungültig IBAN", isIBAN),
  bic: yup.string().required("BIC muss ausgefüllt werden").test("is-bic", "Ungültig BIC", isBIC),
  credit_institute: yup.string().required("Kreditinstitut muss ausgefüllt werden"),
  invoice_email: yup.string().required("Rechnungs E-Mail muss ausgefüllt werden").email("E-Mail ist ungültig"),
  invoice_email_repeat: yup.string().required("Rechnungs E-Mail muss ausgefüllt werden").email("E-Mail ist ungültig").oneOf([yup.ref("invoice_email"), null], "E-Mail muss übereinstimmen"),
});
