import { put } from "redux-saga/effects";
import { authUserFailure, changePasswordUserSuccess, forgotUserSuccess, loginUserSuccess, registerUserSuccess, reseetUserSuccess } from "src/store/actions/users";
import toast from "react-hot-toast";
import CookieStorage from "src/services/storage/cookiesStorage";
import { LOGINAPI, FORGOTPASSWORDAPI, REGISTERAPI, RESETPASSWORDAPI, CHANGEPASSWORDAPI, REGISTERAPIMODIFY } from "src/store/sagas/requests/users";

export function* userLoginHandler(action) {
  const cookie = new CookieStorage();
  try {
    let { remember, ...rest } = action.payload;
    const response = yield LOGINAPI(rest);
    if (response.status) {
      cookie.setLogin(response?.data?.tokens?.access?.token, response?.data?.user?.id, remember);
      toast.success(response?.message);
      yield put(loginUserSuccess(response.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userRegisterHandler(action) {
  try {
    const response = yield REGISTERAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(
        registerUserSuccess({
          ...response.data,
          email: response?.data?.user?.email,
          password: action?.payload?.password,
        })
      );
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userForgotHandler(action) {
  try {
    const response = yield FORGOTPASSWORDAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(forgotUserSuccess(response?.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userResetHandler(action) {
  try {
    const response = yield RESETPASSWORDAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(reseetUserSuccess(response?.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userChangePasswordHandler(action) {
  const cookie = new CookieStorage();
  try {
    const response = yield CHANGEPASSWORDAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      cookie.logout();
      window.location.reload();
      yield put(changePasswordUserSuccess(response?.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userRegisterHandlerInvitation(action) {
  try {
    const response = yield REGISTERAPIMODIFY(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(
        registerUserSuccess({
          ...response.data,
          email: response?.data?.email,
          password: action?.payload?.password,
        })
      );
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
