import React from "react";
import { RiAddCircleFill } from "react-icons/ri";

const CircularButton = ({ handler, title, css }) => {
  return (
    <button className={`flex justify-center gap-1 mt-4 items-center p-0 m-0 text-AFPPrimary font-medium ${css}`} onClick={handler} type="button">
      <RiAddCircleFill className="text-lg" />
      {title}
    </button>
  );
};

CircularButton.defaultProps = {
  title: "Button",
  css: "",
  icon: null,
  type: "button",
  handler: function () {},
};

export default CircularButton;
