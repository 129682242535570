import ResetForm from "src/components/organisms/login/ResetForm";
import Login from "src/components/template/Login";
import { LoginPolicy } from "src/mockData/login";
const resetPreField = {
  title: "Customer portal",
  subTitle: "Reset Password",
  LoginPolicy: LoginPolicy
}
const ResetPassword = () => {
  return (
    <div className="loginwrap">
      <div className="loginwcontainer">
        <Login data={resetPreField}>
          <ResetForm />
        </Login>
      </div>
    </div>
  );
};

export default ResetPassword;