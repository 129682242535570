import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import EditForm from "src/components/organisms/businesspremises/EditForm";
import { BusinesspremiseEdit } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { businessPremisesContacts, contactClearBusinessPremises, detailBusinessPremises, getProfessionalGroups } from "src/store/actions/business";
import { useEffect, useState } from "react";
import MessageModal from "src/components/organisms/cms/MessageModal";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const EditBusinessPremises = () => {
  const dispatch = useDispatch();
  const business = useSelector((state) => state.management);
  const { id } = useParams();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;
  const [formReset, setFormReset] = useState(false);

  useEffect(() => {
    dispatch(detailBusinessPremises({ id: id }));
    dispatch(businessPremisesContacts());
    dispatch(getProfessionalGroups());
    return () => {
      dispatch(contactClearBusinessPremises());
    };
  }, []);

  useEffect(() => {
    business.isLoading == false && formReset == true && (setFormReset(false), dispatch(detailBusinessPremises({ id: id })));
  }, [business, formReset]);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-10 py-8 min-h-screen bg-AFPBGPrimary">
        {sidebar_menu?.sidebar_list?.location && <Breadcrumbs data={BusinesspremiseEdit(sidebar_menu?.sidebar_list?.location, business?.detail?.name)} />}
        {business.detail && !business.professionalGroup?.isLoading ? (
          <EditForm setFormReset={setFormReset} />
        ) : (
          <div className="flex w-full h-[80vh] justify-center items-center">
            <CustomCircularLoader />
          </div>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export default EditBusinessPremises;
