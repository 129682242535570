import React from "react";
import StandardSelect from "../../select/StandardSelect";
import { PrimaryButton, SecondaryButton } from "../../button";
import { useFormik } from "formik";

const ExistingFormInhaber = ({ handleClose, addinhaberHandler, inhaberList }) => {
  const listInhaber = inhaberList.map((item) => {
    return { title: `${item.first_name + " " + item.last_name}`, value: item.id };
  });
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      let filter = inhaberList.find((value) => value.id === values.inhaber);
      let { id, ...rest } = filter;
      addinhaberHandler({ ...rest, newId: id, status: "edit" });
      handleClose();
    },
  });

  const isSalutationSelected = formik.values.inhaber && formik.values.inhaber !== "";

  return (
    <form className="flex flex-col gap-4 mb-2" onSubmit={formik.handleSubmit}>
      <div>
        <StandardSelect
          extra="w-full w-auto pt-6 pb-8"
          extraLabel="hidden"
          label={"Inhaber"}
          placeholder="Herr/Frau"
          id="inhaber"
          options={listInhaber}
          choose={!isSalutationSelected}
          formik={formik}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
          <PrimaryButton title="Speichern" type="submit" />
          <SecondaryButton variant="secondary" title="Abbrechen" handler={handleClose} />
        </div>
      </div>
    </form>
  );
};

export default ExistingFormInhaber;
