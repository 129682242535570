/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import SearchDropDown2 from "src/components/atoms/Search/SearchDropDown/SearchDropDown2";
import { CircularProgress } from "@mui/material";
import { PrimaryButton } from "src/components/atoms/button";
import AddNewInhaverForm from "./AddNewInhaverForm";
import { String12Random } from "src/helpers/setting";
import { useSearchParams } from "react-router-dom";

const OwnershipDropdown = ({ data, stepsCMS }) => {
  const [fachleiterLocation, setFachleiterLocation] = useState([]);
  const business = useSelector((state) => state.management);
  const [selectedLeader, setSelectedLeader] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.currentStep);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  let pre_business_owner = business?.detail?.business_owners || [];
  const [selectNeuEinladen, setSelectNeuEinladen] = useState(false);

  const [inhaberList, setInhaberList] = useState(pre_business_owner);
  let locationDetailForm = JSON.parse(localStorage.getItem("locationDetailForm"));
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStepParam = searchParams.get("active");

  useEffect(() => {
    let group = business?.ownerByLocation?.data;
    if (business?.ownerByLocation?.data != null) {
      let location = group?.map((contact) => {
        return {
          title: contact?.title + " " + contact?.first_name + " " + contact?.last_name,
          value: contact?.contact_id,
        };
      });
      setFachleiterLocation(location);
    }
    let locationDetailForm = JSON.parse(localStorage.getItem("locationDetailForm"));
    if (locationDetailForm && currentStepParam != 2) {
      let owner = locationDetailForm?.business_owners[0]?.contact_id;
      if (owner) {
        setSearch(owner);
        dispatch(
          addCurrentStepDetailValue({
            valueId: data.id,
            value: owner,
            valueKey: "inputTypeValue",
          })
        );
      }
    }
  }, [business?.ownerByLocation?.data]);

  const handleChange = (event, value) => {
    setSelectedLeader(value);
    if (value) {
      let contact_id = value.map((v) => v.id);
      if (contact_id) {
        dispatch(
          addCurrentStepDetailValue({
            valueId: data.id,
            value: contact_id,
            valueKey: "inputTypeValue",
          })
        );
      }
    }
  };

  useEffect(() => {
    let inhaaberSelected = currentStep?.data?.find((current) => current?.id == "GROUP_OWNERSHIP");
    if (inhaaberSelected?.inputTypeValue) {
      if (!search) {
        setSearch(inhaaberSelected?.inputTypeValue);
      }
    }
  }, []);

  const handleOpen = () => {
    setSelectNeuEinladen(true);
  };

  const handleClose = () => {
    setSelectNeuEinladen(false);
  };

  const addInhaberHandler = (inhaber) => {
    if (inhaber.id) {
      let UpdateInhaber = inhaberList?.map((item) => (item?.id === inhaber?.id ? inhaber : item));
      setInhaberList(UpdateInhaber);
    } else if (inhaber?.newId) {
      let find = inhaberList?.find((item) => item.id === inhaber?.newId);
      if (find) {
        let UpdateInhaber = inhaberList?.map((item) => (item.id === inhaber?.newId ? inhaber : item));
        setInhaberList(UpdateInhaber);
      } else {
        setInhaberList((prev) => [...prev, { id: inhaber?.newId, ...inhaber }]);
      }
    } else {
      setInhaberList((prev) => [...prev, { id: String12Random(12), ...inhaber }]);
    }
  };

  return (
    <div className="mb-12">
      <h1 className="text-xl font-tahoma-bold mb-7">{stepsCMS[data?.id]?.title}</h1>

      {business?.leaderList?.locationId ? (
        <>
          <div className="flex gap-6 flex-col lg:flex-row w-full max-w-[760px]">
            {business?.leaderList?.isLoading || business?.isLoading ? (
              <div className="w-96 flex-1 text-center">
                <CircularProgress sx={{ color: "#A31D1A" }} />
              </div>
            ) : (
              <>
                {!business?.ownerByLocation?.isLoading && (
                  <>
                    <div className="w-full max-w-[390px]">
                      <SearchDropDown2
                        data={data}
                        optionsList={fachleiterLocation}
                        id="salutation"
                        selectedVal={search}
                        handleChange={(item) => setSearch(item)}
                        handleOpen={() => {}}
                      />
                    </div>
                    <div className={`w-[180px] max-w-[300px] lg:w-[250px] ${locationDetailForm && currentStepParam != 2 ? "opacity-50" : ""}`}>
                      <PrimaryButton
                        title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.create_new}
                        css={`font-tahoma-bold font-semibold !px-2 w-48`}
                        handler={() => {
                          if (locationDetailForm && currentStepParam != 2) {
                          } else {
                            handleOpen();
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <p className="text-red-500 mt-8">Bitte wählen Sie Betriebsstätte aus</p>
      )}
      {selectNeuEinladen && !business?.professionalGroup?.isLoading && business?.leaderList?.locationId && (
        <AddNewInhaverForm
          handleClose={handleClose}
          handleOpen={true}
          addinhaberHandler={addInhaberHandler}
          initialValues=""
          isLoading={business?.ownerByLocation?.isLoading}
          locationId={business?.leaderList?.locationId}
        />
      )}
    </div>
  );
};

export default OwnershipDropdown;
