import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
export default function AutoCompleteField({ selectedPersonOption, setSelectedPersonOption, LegalPersonList, disabled = false }) {
  let PRE_LEGAL_PERSON_LIST = LegalPersonList.filter((option) => {
    return !selectedPersonOption?.some((person) => person?.id === option?.id);
  });

  const StyledChip = styled(Chip)(() => ({
    "&.Mui-disabled": {
      opacity: 1,
    },
  }));

  const handleChange = (event, value) => {
    setSelectedPersonOption(value);
  };
  const filterOptions = (options, { inputValue }) => {
    const filteredOptions = LegalPersonList?.filter((option) => {
      return !selectedPersonOption?.some((person) => person?.id === option?.id);
    });
    return filteredOptions?.filter((option) => option?.id?.toLowerCase()?.includes(inputValue?.toLowerCase()));
  };

  return (
    <Stack spacing={0} sx={{ width: "100%" }}>
      <Autocomplete
        multiple
        disabled={disabled}
        id="tags-outlined"
        options={PRE_LEGAL_PERSON_LIST}
        getOptionLabel={(option) => option?.id}
        defaultValue={selectedPersonOption ? selectedPersonOption : []}
        onChange={handleChange}
        filterSelectedOptions
        filterOptions={filterOptions}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            let personTag = LegalPersonList.find((person) => person.id == option.id);
            return (
              <Tooltip key={index} title={personTag ? personTag.name : option.id}>
                <StyledChip label={option.id} {...getTagProps({ index })} sx={{ borderRadius: "4px" }} />
              </Tooltip>
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
                "&.Mui-focused:hover fieldset": {
                  border: "none",
                },
                "&:active fieldset": {
                  border: "none",
                },
              },
              "& .MuiAutocomplete-inputRoot": {
                "&:hover": {
                  outline: "none",
                },
                "&.Mui-focused": {
                  outline: "none",
                },
              },
            }}
          />
        )}
      />
    </Stack>
  );
}
