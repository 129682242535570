import {
  GET_SERVICE_AREA_DETAIL_FAILURE,
  GET_SERVICE_AREA_DETAIL_STARTED,
  GET_SERVICE_AREA_DETAIL_SUCCESS,
  GET_SERVICE_AREA_FAILURE,
  GET_SERVICE_AREA_STARTED,
  GET_SERVICE_AREA_SUCCESS,
  GET_SERVICE_AREA_TECHNICAL_LEADER_STARTED,
  GET_STEPS_FAILURE,
  GET_STEPS_STARTED,
  GET_STEPS_SUCCESS,
  RESET_PAGE_AIDS_BY_ID,
  RESET_SERVICE_AREA,
  RESET_SERVICE_AREA_DETAIL,
  SET_PAGE_AIDS_BY_ID,
} from "../actionTypes";

export const getStepsStarted = (payload) => {
  return {
    type: GET_STEPS_STARTED,
    payload,
  };
};

export const getStepsSuccess = (payload) => {
  return {
    type: GET_STEPS_SUCCESS,
    payload,
  };
};
export const getStepsFailure = (payload) => {
  return {
    type: GET_STEPS_FAILURE,
    payload,
  };
};

export const getServiceAreaStarted = (payload) => {
  return {
    type: GET_SERVICE_AREA_STARTED,
    payload,
  };
};

export const getServiceAreaSuccess = (payload) => {
  return {
    type: GET_SERVICE_AREA_SUCCESS,
    payload,
  };
};
export const getServiceAreaFailure = (payload) => {
  return {
    type: GET_SERVICE_AREA_FAILURE,
    payload,
  };
};

export const resetServiceArea = (payload) => {
  return {
    type: RESET_SERVICE_AREA,
    payload,
  };
};

export const getServiceAreaDetailStarted = (payload) => {
  return {
    type: GET_SERVICE_AREA_DETAIL_STARTED,
    payload,
  };
};

export const getServiceAreaDetailSuccess = (payload) => {
  return {
    type: GET_SERVICE_AREA_DETAIL_SUCCESS,
    payload,
  };
};
export const getServiceAreaDetailFailure = (payload) => {
  return {
    type: GET_SERVICE_AREA_DETAIL_FAILURE,
    payload,
  };
};

export const resetServiceAreaDetail = (payload) => {
  return {
    type: RESET_SERVICE_AREA_DETAIL,
    payload,
  };
};
export const setPageAidsById = (payload) => {
  return {
    type: SET_PAGE_AIDS_BY_ID,
    payload,
  };
};

export const resetPageAidsById = (payload) => {
  return {
    type: RESET_PAGE_AIDS_BY_ID,
    payload,
  };
};

export const getServiceAreaTechnicalLeaderStarted = (payload) => {
  return {
    type: GET_SERVICE_AREA_TECHNICAL_LEADER_STARTED,
    payload,
  };
};
