import SignatureCanvas from "../SignatureCanvas";
import { useParams } from "react-router-dom";
import { useJwt } from "react-jwt";
import { signatureUploadStarted } from "src/store/actions/evidence";
import { useDispatch } from "react-redux";

const getDecodeCode = (id) => {
  try {
    const { decodedToken, isExpired } = useJwt(id); // eslint-disable-line no-unused-vars
    return decodedToken;
  } catch (error) {
    console.error("Error decoding token:", error);
  }
};

const SignatureWrap = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const decode = id ? getDecodeCode(id) : null;

  const signatureUploadHandle = (image) => {
    dispatch(
      signatureUploadStarted({
        procedure_signature_id: decode.procedure_signature_id,
        signature_image: image,
      })
    );
  };

  return (
    <div>
      <SignatureCanvas signatureUploadHandle={signatureUploadHandle} />
    </div>
  );
};

export default SignatureWrap;
