import { Checkbox, FormControlLabel, TableCell, TableHead, TableRow } from "@mui/material";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

const DocumentTableHead = ({ tableHeader, increaseSort, decreaseSort, allSelect, allSelectionHandler, allCheck=false, bodyContent }) => {
  const increaseSortHandle = (key) => {
    increaseSort(key);
  };
  const decreaseSortHandle = (key) => {
    decreaseSort(key);
  };
  return (
    <TableHead>
      <TableRow
        sx={{
          th: {
            color: "#706F6F",
            paddingLeft: "20px",
          },
        }}
      >
        {allCheck && <TableCell sx={{ padding: 0, textAlign: "left" }} align="left">
          {bodyContent?.length>0 && <FormControlLabel sx={{ padding: 0 }} control={<Checkbox onChange={allSelectionHandler} checked={allSelect} className="p-0 m-0" />} />}
        </TableCell>}
        {tableHeader.map((item, index) => {
          return (
            <TableCell
              sx={{
                textAlign: item.title === "Status" || item.title === "Hints" || item.title == "Dokumente" ? "center" : "left",
              }}
              key={index}
            >
              <div
                className={`flex gap-4 ${item.title === "Dokumente" ? "justify-center" : "justify-start"}  ${
                  item.title === "Status" || item.title === "Hints" || item.title === "item.title" ? "justify-center" : "justify-start"
                }`}
              >
                {item.title}
                {item.icons === true && (
                  <div className="w-[20px] flex flex-col justify-center items-center text-[#706F6F]">
                    <SlArrowUp size={9} onClick={() => increaseSortHandle(item.sortKey)} className="flex cursor-pointer hover:text-[#A31D1A] font-medium" />
                    <SlArrowDown size={9} onClick={() => decreaseSortHandle(item.sortKey)} className="flex cursor-pointer hover:text-[#A31D1A] font-medium" />
                  </div>
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

DocumentTableHead.defaultProps = {
  tableHeader: [],
  increaseSort: function () {},
  decreaseSort: function () {},
};

export default DocumentTableHead;
