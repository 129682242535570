/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import Line1 from "src/assets/img/Line1.png";
import { BsPlusLg } from "react-icons/bs";
import ImageHtmlFinished from "src/components/pages/ImageHtml/ImageHtmlFinished";
import { PrimaryButton } from "src/components/atoms/button";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { applicationProcessStatusStarted, newServiceOrderCostStarted, sendSignatureRequestEmailStarted } from "src/store/actions/application";
import FilterField from "src/components/molecules/dform/FilterField";
import { businessPremisesContacts } from "src/store/actions/business";
import Cookies from "js-cookie";
import { sendSignaturePayloadModify, ServiceOrderPayloadModify } from "src/helpers/dform";
import { CircularProgress } from "@mui/material";
import toast from "react-hot-toast";

function ApplicationVerifyFinished({ data, step_id, createServiceOrderSubmit }) {
  const [showName, setShowName] = useState([]);
  const dispatch = useDispatch();
  const { id, procedure_id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentStep = useSelector((state) => state.currentStep);
  const application = useSelector((state) => state.application);
  const business = useSelector((state) => state?.management);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  useEffect(() => {
    const savedData = Object?.values(application?.data).filter((item, index) => {
      return index == 1;
    });
    const result = savedData[0].filter((data, index) => {
      return data.inputType === "dropdownTag";
    });
    const hello = business?.contactList?.data?.filter((contact) => {
      return result[0]?.inputTypeValue?.includes(contact?.contact_id);
    });
    hello && setShowName([...hello]);
  }, [business?.contactList?.data]);

  useEffect(() => {
    dispatch(businessPremisesContacts());
  }, []);

  useEffect(()=>{
    if(application?.orderCosts.data == null){
      if(data && currentStep.stepID && currentStep.data){
        dispatch(
          newServiceOrderCostStarted(ServiceOrderPayloadModify({
            "procedure_data": [{...application.data, [currentStep.stepID]: currentStep.data}],
            "step_id": step_id,
            "service_order_types_id": id,
            "procedure_form_id": procedure_id,
            "user_id": Cookies.get("userId")
          }))
        );
      }
    }
  },[data])

  /** get the current dynamic application status - get-procedure-details */

  useEffect(()=>{
    dispatch(applicationProcessStatusStarted({procedure_id:procedure_id}))
  },[])

  const sendSignatureRequestEmailHandle = () => {
    if(currentStep?.data[0]?.inputTypeValue){
    dispatch(
      sendSignatureRequestEmailStarted(sendSignaturePayloadModify({
        "procedure_data": [{...application.data, [currentStep.stepID]: currentStep.data}],
        "service_order_types_id": id,
        "procedure_form_id": procedure_id,
        "user_id": Cookies.get("userId"),
        "inhaber_id": currentStep?.data[0]?.inputTypeValue?.toString()
      }))
    );
  } else {
    toast.error("Bitte wählen Sie den Inhaber aus");
  }
  }
  return (
    <div className="">
      <h1 className="!mb-5 text-xl font-tahoma-bold m-3 sm:m-4 md:m-6 text-black">Bitte Unterschreiben!</h1>
      <div className="mx-3 sm:mx-4 md:mx-6 mt-0 p-0 ">
        <div className="justify-start gap-12 flex flex-col md:flex-row">
          <p className="textStyle max-w-[540px] mb-5 pt-5">Hier finden Sie eine Zusammenfassung Ihrer Daten. Bitte gehen Sie dies noch einmal aufmerksam durch, bestätigen Sie die Richtigkeit mit Ihrer Unterschrift und/oder fordern die Unterschrift per E-Mail an.</p>
        </div>
        <div>
          <div className="w-full md:w-[841px]">{Object?.values(application?.data) && <ImageHtmlFinished data={Object?.values(application?.data)} />}</div>
          <div className="pt-10">
            <h1 className="text-black  text-lg font-tahoma-bold">Unterschrift Inhaber</h1>

            <div className="pt-5 md:pt-0 flex flex-col md:flex-row gap-8">
              <div className="flex flex-col gap-8">
                {data?.map((fdata, i) => {
                  return (
                    <Fragment key={i}>
                      <FilterField data={fdata} />
                    </Fragment>
                  );
                })}

                <div className="pt-10">
                  {showName.length > 0 &&
                    showName.map((item, index) => {
                      let signature = application?.dformStatus?.data?.data?.SignatureList?.length > 0 && application?.dformStatus?.data?.data?.SignatureList?.find((signature)=> signature?.contact_id == item.contact_id)
                      return (
                        <div className="mt-5">
                          <h1 className="text-black text-lg font-tahoma-bold mb-5">Unterschrift Fachlicher Leiter - {item.first_name + " " + item.last_name}</h1>
                          <div className="border border-AFPLightGray w-full md:w-[841px] h-[259px] flex flex-col justify-center items-center">
                            <div className="flex items-center justify-center w-10 h-10 sm:w-10 sm:h-10 rounded-full">
                              <BsPlusLg className="!text-white h-10 w-10 sm:h-10 sm:w-10" />
                            </div>
                            {signature ? <img src={signature.signature_image || Line1} alt="Line" /> :<><p className="text-black textStyle max-w-[540px]">Klicken um zu unterschreiben</p> <img src={Line1} alt="Line" /></>}
                          </div>
                        </div>
                      );
                    })}

                  <div className="pt-9">
                    <h1 className="mb-3 font-bold">Kostenübersicht</h1>
                    <div className="border ml-2 rounded-md border-AFPLightGray w-full md:w-[841px] h-[135px] md:h-auto flex flex-col justify-start place-items-start mx-auto">
                      <div className="flex flex-row md:flex-row gap-4 md:gap-52 text-sm font-tahoma">
                        <ul className="text-black p-4 md:p-7">
                          <li>Netto</li>
                          <li>MwSt. (19%)</li>
                          <li>Gesamtbetrag</li>
                        </ul>
                        <ul className="text-black p-4 md:p-7">
                          {application?.orderCosts?.data?.isLoading ? <CircularProgress  /> :<>
                          <li align="right">{application?.orderCosts?.data?.totalNetAmount}</li>
                          <li align="right">{application?.orderCosts?.data?.totalTaxAmount}</li>
                          <li align="right">{application?.orderCosts?.data?.totalAmount}</li> </>}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {(application?.dformStatus?.data?.data?.status !== "signatures-completed" &&  application?.dformStatus?.data?.data?.status !== "completed") &&<h4 className="text-red-400 text-center mt-8 text-md">Unterschriften fehlen</h4> }
                  
                  <div className="justify-center flex flex-col items-center gap-8 pt-12">
                    {application?.dformStatus?.data?.data?.status === "ongoing" || application?.dformStatus?.data?.data?.status === "signatures-requested" ? (
                    (application?.dformStatus?.data?.data?.status !== "signatures-requested" && application?.dformStatus?.data?.data?.status !== "signatures-completed" && application?.dformStatus?.data?.data?.status !== "completed") && <PrimaryButton
                      title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.request_signature}
                      btnStyle="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                      icon={<FaCheck />}
                      loading={application?.sendEmail?.isLoading}
                      position="after"
                      handler={sendSignatureRequestEmailHandle}
                      disable={application?.sendEmail?.isLoading}
                    />): 
                    application?.dformStatus?.data?.data?.status === "signatures-completed" ?  <PrimaryButton
                        title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.submit_for_a_fee}
                        btnStyle="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                        icon={<FaCheck />}
                        loading={application?.createdApplication?.isLoading}
                        position="after"
                        handler={createServiceOrderSubmit}
                      /> : null
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationVerifyFinished;
