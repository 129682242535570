import Box from "@mui/material/Box";
import { Tabs, Tab } from "@mui/material";
import { a11yProps } from "src/helpers/setting";

const TabHead = ({ handleChange, value, tabList, checked=false }) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#A31D1A",
          },
        }}
      >
        {tabList.map((tab) => {
          return (
            <Tab key={tab.uuid} label={tab.label} {...a11yProps(tab.id)} sx={{ "&.Mui-selected": { color: "#706f6f", fontWeight: "500", cursor: "pointer !important" } }} className={`!font-tahoma !normal-case ${checked && '!cursor-not-allowed'}`} />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default TabHead;
