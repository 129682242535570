/* eslint-disable */
export const saveEvidenceERPPayloadModify = (payload) => {
  const { service_order_type_id, data } = payload;
  const parseData = JSON.parse(data)[0];
  const step2 = parseData["02_FIRMA"];
  const leader = step2.find((x) => x.id == "02_FIRMA_TECHNICAL_MANAGER");
  const leaderValidation = step2.find((x) => x.id == "02_FIRMA_BUSINESS_PREMISES");
  const step3 = parseData["03_VERSORGUNGSBEREICHE"];
  const product = step3.find((x) => x.id == "03_SUPPLY_AREAS");
  const productFilter = product?.inputTypeValue?.map((team)=> {
    return {
        service_area: team?.selectedArea,
        technical_leader_ids: team?.selectedTechnicalLeaders?.map((lead)=> lead?.id)
    }
  })
  if (service_order_type_id === "AA") {
    return { technical_leader_ids: leaderValidation?.locationValidation?.exsiting_team_leaders, service_areas: productFilter, service_order_type_id };
  } else {
    if (product) {
      return { technical_leader_ids: leader?.inputTypeValue, service_areas: productFilter, service_order_type_id };
    }
    return { technical_leader_ids: [], service_areas: [] };
  }
};

export function generateRandomString(length = 12) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
}