import React, { useEffect } from "react";
import DecreasefillCard from "../DecreasefillCard";
import ExpandableCard from "../ExpandableCard";
import { useSelector } from "react-redux";

const EvidenceShowExpand = ({ showCard, page, aid, css = "", helperText, miniAids, setMiniAids }) => {
  const dform = useSelector((state) => state.dform);

  useEffect(() => {
    if (dform?.page_aids && dform?.page_aids[page] && dform?.page_aids[page].includes(aid)) {
      if (!miniAids) {
        setMiniAids(true);
      }
    } else {
      if (miniAids) {
        setMiniAids(false);
      }
    }
  }, [dform?.page_aids]);

  return (
    <>
      <div className={`hidden md:block ${miniAids ? "md:min-w-[90px] lg:min-w-[90px] xl:min-w-[90px]" : "md:w-[320px] lg:w-[320px] xl:w-[500px]"} ${css}`}>
        <div
          className="bg-transparent h-10 w-full"
          style={{
            position: "sticky",
            top: "120px",
          }}
        >
          <div>
            {showCard && (
              <>
                <DecreasefillCard className="relative w-[100%]" miniAids={miniAids}>
                  <ExpandableCard page={page} aid={aid} miniAids={miniAids} helperText={helperText} />
                </DecreasefillCard>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EvidenceShowExpand;
