/*eslint-disable */
import { useEffect, useState } from "react";
import FilterField from "src/components/molecules/dform/FilterField";
import ShowExpanded from "src/components/atoms/card/Card/ShowExpanded";
import { useSelector } from "react-redux";

const CoverageInfo = ({ data, step_id }) => {
  const [currentHelperText, setCurrentHelperText] = useState("");
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect?.top <= 400 && rect?.bottom >= 10) {
          const key = section?.getAttribute("data-key");
          currentSection = { key, position: rect?.top };
        }
      });

      setCurrentHelperText(currentSection?.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="flex justify-between">
      <div className={`w-full`}>
        {data.map((section, i) => {
          const showCard = currentHelperText === section.id || (currentHelperText === "" && i === 0);
          return (
            <div className="flex evidence-section w-full gap-x-4 justify-between mt-4 p-2" data-key={section.id} data-disable={section?.helperTextDisable} key={i}>
              <div className={`${section.id !== "03_SUPPLY_AREAS" ? "w-full md:min-w-[calc(100%-400px)] lg:min-w-[calc(100%-550px)] flex-1" : " w-full relative"}`}>
                <FilterField data={section} step_id={step_id} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CoverageInfo;
