/* eslint-disable */
import { put } from "redux-saga/effects";
import Cookies from "js-cookie";
import { downloadCertificateSuccess, downloadEvidenceFileFailure, downloadEvidenceFileSuccess, downloadInvoiceSuccess, downloadOrderSuccess, downloadXmlSuccess } from "src/store/actions/download";
import { GET_DOWNLOAD_CERTIFICATE, GET_DOWNLOAD_INVOICE, GET_DOWNLOAD_ORDER, GET_DOWNLOAD_XML, GET_EVIDENCE_FILE_DOWNLOAD } from "../requests/download";

export function* getDownloadOrderHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_DOWNLOAD_ORDER(queryParams);

    if (response.status) {
      yield put(downloadOrderSuccess({ data: response.data }));
    } else {
      yield put(
        downloadDocumentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      downloadDocumentFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getDownloadInvoiceHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_DOWNLOAD_INVOICE(queryParams);
    if (response.status) {
      yield put(downloadInvoiceSuccess({ data: response.data, step_id: action.payload.step_id }));
    } else {
      yield put(
        downloadDocumentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      downloadDocumentFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getDownloadCertificateHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_DOWNLOAD_CERTIFICATE(queryParams);
    if (response.status) {
      yield put(downloadCertificateSuccess({ data: response.data, step_id: action.payload.step_id }));
    } else {
      yield put(
        downloadDocumentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      downloadDocumentFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getDownloadXmlHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_DOWNLOAD_XML(queryParams);
    if (response.status) {
      yield put(downloadXmlSuccess({ data: response.data, step_id: action.payload.step_id }));
    } else {
      yield put(
        downloadDocumentFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      downloadDocumentFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getDownloadEvidenceFileHandler(action) {
  try {
    const {evidence_id, ...newParams} = action.payload
    const queryParams = {
      queryParams: {
        ...newParams,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_EVIDENCE_FILE_DOWNLOAD(queryParams);
    if (response.status) {
      yield put(downloadEvidenceFileSuccess({data:response.data, evidence_id: evidence_id, file_name: action.payload?.file_name}));
    } else {
      yield put(
        downloadEvidenceFileFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      downloadEvidenceFileFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}
