export const checkListRetn = (validate) => {
  return [
    {
      id: 1,
      title: "Mind. 8 Zeichen",
      hashCode: validate.has8digit,
    },
    {
      id: 2,
      title: "Mind. 1x Kleinbuchstaben",
      hashCode: validate.hasLow,
    },
    {
      id: 3,
      title: "Mind. 1x Großbuchstaben",
      hashCode: validate.hasCap,
    },
    {
      id: 4,
      title: "Mind. 1x Ziffern",
      hashCode: validate.hasNumber,
    },
    {
      id: 5,
      title: "Mind. 1x Sonderzeichen",
      hashCode: validate.hasSpecialChar,
    },
  ];
};

export const forValidatePassword = {
  standard: {
    hasLow: true,
    hasCap: true,
    hasNumber: true,
    has8digit: true,
  },
  passwordCheck: {
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    has8digit: false,
  },
};



