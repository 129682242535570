import { deleteApiCall, getApiCall, postApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const NEW_SERVICE_ORDER_EVIDENCE = async (payload) => {
  const url = `${BASE_URL}${API.NEW_SERVICE_ORDER_EVIDENCE}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const NEW_SERVICE_ORDER_EVIDENCE_PENDING = async (paramsData) => {
  const url = `${BASE_URL}${API.NEW_SERVICE_ORDER_EVIDENCE_PENDING}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const SIGNATURE_UPLOAD = async (payload) => {
  const url = `${BASE_URL}${API.SIGNATURE_UPLOAD}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const UPLOAD_EVIDENCE = async (payload) => {
  const url = `${BASE_URL}${API.UPLOAD_EVIDENCE}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const UPLOAD_SERVER_ORDER_EVIDENCE = async (payload) => {
  const url = `${BASE_URL}${API.UPLOAD_SERVICE_ORDER_EVIDENCE}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const SERVICE_ORDER_EVIDENCE_UPLOAD = async (payload) => {
  const url = `${BASE_URL}${API.UPLOAD_SERVICE_ORDER_EVIDENCE}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const SAVE_EVIDENCE_DB = async (payload) => {
  const url = `${BASE_URL}${API.SAVE_SERVICE_ORDER_EVIDENCE}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const SAVE_EVIDENCE_ERP = async (payload) => {
  const url = `${BASE_URL}${API.SAVE_SERVICE_ORDER_EVIDENCE_ERP}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const DELETE_UPLOADED_EVIDENCE = async (payload) => {
  const url = `${BASE_URL}${API.DELETE_UPLOADED_EVIDENCE}?service_order_number=${payload?.service_order_number}&file_name=${payload?.file_name}&evidence_id=${payload?.evidence_id}&procedure_id=${payload?.procedure_id}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await deleteApiCall(url, {}, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.message || "Failed to delete Document");
  }
};
