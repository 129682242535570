/* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import OverviewTable from "./OverviewTable";
import { overViewDataHeader } from "src/mockData/procedureMock";
import { useEffect, useState } from "react";
import { CircularProgress, TableBody, TableRow, TableCell, TableContainer, Table } from "@mui/material";
import TableHeader from "src/components/atoms/common/TableHeader";
import Paper from "@mui/material/Paper";
import DocumentSearch from "src/components/molecules/search/DocumentSearch";
import { getAllBusinessStarted } from "src/store/actions/business";
import { SecondaryButton } from "src/components/atoms/button";
import { BiSolidPlusCircle } from "react-icons/bi";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { getServiceOrderPaginationStarted } from "src/store/actions/serviceOrder";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const OverviewContent = ({ openModalHandle }) => {
  const increaseSort = () => {};
  const decreaseSort = () => {};
  const [filterdData, setFilteredData] = useState([]);
  const [showTableData, setShowTableData] = useState("");
  const dispatch = useDispatch();
  const business = useSelector((state) => state?.management);
  const serviceOrder = useSelector((state) => state.serviceOrder);
  const [skip, setSkip] = useState({});
  let limit = 3;

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { procedures } = cmsDashboarddata;

  useEffect(() => {
    const getLocalData = JSON.parse(localStorage.getItem("getAllLocation"));
    if (!!getLocalData) {
      const extractedFields = getLocalData.map((item) => ({
        name: item.name,
        location_id: item.location_id,
      }));
      setFilteredData([...extractedFields]);
    } else if (getLocalData == null) {
      dispatch(getAllBusinessStarted());
    }
  }, [business?.allLocation?.data]);

  const filterData = filterdData.length > 0 ? filterdData?.map((item) => item.name) : [];

  useEffect(() => {
    setShowTableData(serviceOrder?.data);
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue.length === 0) {
      setShowTableData(serviceOrder?.data);
    } else {
      // const location_ids = filterdData.length > 0 && filterdData.filter((item) => newValue?.includes(item.name)).map((item) => item.location_id);
      const filteredData = {};
      // Object.entries(serviceOrder?.data).forEach(([key, value]) => {
      //   const filteredItems = value.filter((item) => location_ids.includes(item.location_id));
      //   if (filteredItems.length > 0) {
      //     filteredData[key] = filteredItems;
      //   }
      // });
      newValue.forEach((locationName) => {
        const location_id = filterdData?.length > 0 && filterdData?.filter((item) => locationName == item?.name)?.map((item) => item?.location_id);
        let filteredItems = [];
        let locKey = locationName;
        Object.entries(serviceOrder?.data)?.forEach(([key, value]) => {
          if(filteredItems?.length == 0) {
            filteredItems = value?.filter((item) => location_id == item?.location_id);
            if(filteredItems?.length > 0) {
              locKey = key
            }
          }
        });
        filteredData[locKey] = filteredItems; 
      }); 
      setShowTableData(filteredData);
    }
  };

  const LoadMoreHandler = (locationId) => {
    const currentSkip = skip[locationId] !== undefined ? skip[locationId] : 0;

    if (currentSkip > 0) {
      limit = currentSkip + limit;
    }
    const newSkip = currentSkip + limit;

    dispatch(
      getServiceOrderPaginationStarted({
        skip: newSkip,
        limit,
        location_id: locationId,
      })
    );
    setSkip({ ...skip, [locationId]: newSkip });
  };

  return (
    <>
      <div className="w-full mt-8 flex justify-end gap-8 mb-4">
        <div>
          <SecondaryButton
            variant="secondary"
            title={procedures?.overview_btn_search?.new_procedure}
            css={"font-tahoma-bold font-semibold !px-2"}
            handler={openModalHandle}
            icon={<BiSolidPlusCircle className="text-AFPPrimaryDark cursor-pointer hover:text-white" />}
          />
        </div>
        <DocumentSearch placeholder={procedures?.overview_btn_search?.location_search} handleChange={handleChange} locations={filterData} />
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "procedures",
                unique: "overview_btn_search",
                heading: "Anträge/Verfahren",
              })
            );
          }}
        />
      </div>
      <div className="flex justify-end mt-2">
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "procedures",
                unique: "overview_table_head",
                heading: "Anträge/Verfahren",
              })
            );
          }}
        />
      </div>
      {serviceOrder?.data?.isLoading ? (
        <div className="flex justify-center items-center min-h-[60vh]">
          <CircularProgress sx={{ color: "#A31D1A" }} />
        </div>
      ) : showTableData && Object.values(serviceOrder?.data)?.length > 0 ? (
        <>
          {Object.entries(showTableData).map((order, i) => {
            const locationId = order[1][0]?.location_id;

            return (
              <div key={i} className="mt-10 ">
                <OverviewTable title={order[0]} tableHead={overViewDataHeader(procedures?.overview_table_head)} tableBody={order[1]} />

                {order[1].length >= 3 && (
                  <div className="mb-0 mt-10 md:mb-10 md:mt-16 flex justify-center items-center border-t relative">
                    {serviceOrder?.paginate?.isLoading ? (
                      <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-AFPBGPrimary px-4">
                        <CustomCircularLoader />
                      </div>
                    ) : (
                      <button
                        onClick={() => LoadMoreHandler(locationId)}
                        className={`bg-AFPBGPrimary m-0 p-0  absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-AFPPrimary font-semibold text-center whitespace-nowrap cursor-pointer flex items-center gap-x-3 px-1 `}
                      >
                        Mehr anzeigen
                      </button>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            borderRadius: "12px",
            background: "#fff",
            marginTop: "35px",
          }}
        >
          <Table>
            <TableHeader tableData={overViewDataHeader(procedures?.overview_table_head)} increaseSort={increaseSort} decreaseSort={decreaseSort} />
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <h5 className="text-red-400">Noch keine Verfahren</h5>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default OverviewContent;
