/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import style from "./inputStandardField.module.css";

const InputStandardFieldCMS = (props) => {
  const { label, id, extra, placeholder, disabled, extraLabel, extraInput, formik, editable, onChangeHandle, itemKey } = props;

  const { touched, errors, setFieldValue, values } = formik;

  const [editableS, setEditableS] = useState(!editable);
  const textareaRef = useRef(null);

  const handleChange = (event) => {
    const { value } = event.target;
    if (onChangeHandle) {
      onChangeHandle(itemKey + "." + id, value);
    }
    setFieldValue(itemKey + "." + id, value);

    // Adjust the height of the textarea
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Adjust the height initially on mount and when values change
  useEffect(() => {
    adjustTextareaHeight();
  }, [values[itemKey][id]]);

  return (
    <div className={`${style.wrapper} ${extra}`}>
      <label htmlFor={itemKey + "." + id} className={`${style.inputLabel} ${extraLabel}`}>
        {label}
      </label>
      <div className={`w-full ${editable && style.editableContainer}`}>
        <textarea
          ref={textareaRef}
          disabled={!editableS || disabled}
          id={itemKey + "." + id}
          name={itemKey + "." + id}
          placeholder={placeholder}
          onChange={handleChange}
          value={values[itemKey][id]}
          className={`${style.textarea} ${extraInput} ${editableS ? "bg-white" : "bg-AFPBGPrimary"} ${
            touched[itemKey] && touched[itemKey][id] && errors[itemKey] && errors[itemKey][id] && "!border-red-500 !bg-gray-100 "
          }`}
          style={{ overflow: "hidden", resize: "none" }}
        />
      </div>
    </div>
  );
};

InputStandardFieldCMS.defaultProps = {
  label: "",
  id: "inputField",
  extra: "",
  placeholder: "",
  disabled: false,
  extraLabel: "",
  extraInput: "",
  formik: {
    touched: {},
    errors: {},
    setFieldValue: () => {},
    values: {},
  },
  editable: false,
  onChangeHandle: () => {},
};

export default InputStandardFieldCMS;
