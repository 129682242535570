export const INBOXTABLEHEAD = (date, regarding, business) => {
  return [
    {
      id: 1,
      title: date,
      key: ["date"],
      icons: true,
      sortKey: "date",
    },
    {
      id: 2,
      title: regarding,
      key: ["regarding"],
      icons: true,
      sortKey: "regarding",
    },
    {
      id: 3,
      title: business,
      key: ["business"],
      icons: true,
      sortKey: "business",
    },
  ];
};
