import CircularLoader from "src/components/atoms/loader/CircularLoader";
import SidebarC from "./SidebarC";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData } = cmsDashboarddata;
  return (
    <>
      {cmsDashboarddata.listLoaded ? (
        <>
          <SidebarC />
        </>
      ) : (
        <CircularLoader css="h-[90vh]" />
      )}
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </>
  );
};

export default Sidebar;
