import { CardContent } from "@mui/material";
import Card from "src/components/atoms/card/Card";
import { HELPSERVICESLIST } from "src/mockData/help";
import { useSelector, useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { useNavigate } from "react-router-dom";

function HelpCard() {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderCardContent = (help) => (
    <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <h1 className="text-lg text-center font-tahoma-bold flex justify-center items-center text-balance flex-wrap gap-3">
        {cmsDashboarddata?.help_support[help?.cmsId].heading}{" "}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "help_support",
                unique: help?.cmsId,
                heading: "Help & Support",
              })
            );
          }}
        />
      </h1>
      <div className="flex justify-center my-5">{help.icon}</div>
      <h1 className="text-center text-sm text-SmokeyGray 2xl:px-8">{cmsDashboarddata?.help_support[help?.cmsId].sub_heading}</h1>
    </CardContent>
  );

  const handleClick = (index, link) => {
    if (index !== 2) {
      navigate(link);
    }
  };

  return (
    <>
      <div className="py-10 px-4 md:px-16 lg:px-20 xl:px-24 2xl:px-32 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
        {HELPSERVICESLIST.map((help, index) =>
          index === 2 ? (
            <a href="#tablesection" key={help.id}>
              <Card css="justify-between 2xl:px-2">{renderCardContent(help)}</Card>
            </a>
          ) : (
            <a href={`${cmsDashboarddata?.help_support[help?.cmsId].redirect_link}`} target="_blank" key={help.id}>
              <Card css="justify-between 2xl:px-2 cursor-pointer" key={help.id} onClick={() => handleClick(index, cmsDashboarddata?.help_support[help?.cmsId].link)}>
                {renderCardContent(help)}
              </Card>
            </a>
          )
        )}
      </div>
    </>
  );
}

export default HelpCard;
