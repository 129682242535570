/* eslint-disable */
import { call, put, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";
import { ADDUSERSACCOUNTSAPI, ACCOUNTLISTAPI, GET_ACCOUNT_LIST, PUT_USER_ADDRESS, DEL_USER_ADDRESS, ADD_USER_MANAGEMENT, USER_MANAGEMENT_LIST, UPDATE_USER_MANAGEMENT, DELETE_USER_MANAGEMENT } from "src/store/sagas/requests/userSettings";
import { accountList, addUserManagementFailure, addUserManagementSuccess, businessListInvite, createdUserAccount, fetchAccountListFailure, fetchAccountListRequest, fetchAccountListSuccess, fetchManagementFailure, removeUserManagementByIDSuccess, updateUserManagementByID, userManagementList, userManagementStarted } from "src/store/actions/userSettings";
import { authUserFailure } from "src/store/actions/users";
import Cookies from "js-cookie";
import { BUSINESS_PREMISES_LIST } from "../requests/business";

export function* addAccountHandler(action) {
  try {
    const response = yield ADDUSERSACCOUNTSAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(fetchAccountListRequest())
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* accountListHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield ACCOUNTLISTAPI(paramsData);
    if (response.status) {
      yield put(accountList(response.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* watchAccountList() {
  yield takeLatest("FETCH_ACCOUNT_LIST", accountListHandler);
}

export function* fetchAccountList() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const data = yield call(() => {
      return GET_ACCOUNT_LIST(paramsData);
    });
    yield put(createdUserAccount());
    yield put(fetchAccountListSuccess(data));
  } catch (error) {
    yield put(fetchAccountListFailure(error));
  }
}


export function* updateUserAddress(action) {
  try {
    const data = yield call(() => {
      return PUT_USER_ADDRESS({ ...action.payload });
    });
    if (data.status) {
      toast.success(data?.message);
      yield put(fetchAccountListRequest())
    } else {
      yield put(
        authUserFailure({
          message: data?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
    // yield put(fetchAccountListFailure(data));
  } catch (error) {
    yield put(fetchAccountListFailure(error));
  }
}

export function* deleteUserAddress(action) {
  try {
    const data = yield call(() => {
      return DEL_USER_ADDRESS(action.payload);
    });
    if (data.status) {
      toast.success(data?.message);
      yield put(fetchAccountListRequest())
    } else {
      yield put(
        authUserFailure({
          message: data?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
    // yield put(fetchAccountListFailure(data));
  } catch (error) {
    yield put(fetchAccountListFailure(error));
  }
}

export function* addUserManagementHandler(action) {
  try {
    const response = yield ADD_USER_MANAGEMENT(action.payload);
    /* remove this code in future when api is available for list (erp) */
    // yield put(addUserManagementOnList({ ...action.payload, id: String12Random(12) }))
    if (response.status) {
      toast.success(response?.message);
      yield put(addUserManagementSuccess({...action.payload, clip_url:response?.data?.url}))
      yield put(userManagementStarted())
    } else {
      yield put(
        addUserManagementFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(addUserManagementFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}


/* user management */

export function* businessListInviteHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield BUSINESS_PREMISES_LIST(paramsData);
    if (response.status) {
      yield put(businessListInvite(response?.data?.businessList));
    } else {
      yield put(
        fetchManagementFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(fetchManagementFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userManagementListHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
        ...action?.payload
      },
      isAuthorized: true,
    };
    const response = yield USER_MANAGEMENT_LIST(paramsData);
    if (response.status) {
      yield put(userManagementList(response.data));
    } else {
      yield put(
        fetchManagementFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(fetchManagementFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* updateUserManagementByIDHandler(action) {
  try {
    const response = yield UPDATE_USER_MANAGEMENT(action.payload);
    if (response.status) {
      yield put(updateUserManagementByID(response.data));
      yield put(userManagementStarted({refresh:true}))
    } else {
      yield put(
        fetchManagementFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(fetchManagementFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* deleteUserManagementByIDHandler(action) {
  try {
    const response = yield DELETE_USER_MANAGEMENT({...action.payload, user_id: Cookies.get("userId"),});
    if (response.status) {
      yield put(removeUserManagementByIDSuccess(response.data));
    } else {
      yield put(
        fetchManagementFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(fetchManagementFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}