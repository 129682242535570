import {
  CMS_DFORM_CONTENT,
  CMS_DFORM_CONTENT_FAILURE,
  CMS_DFORM_CONTENT_SUCCESS,
  GET_DFORM_CMS_BY_ID,
  GET_DFORM_CMS_BY_ID_FAILURE,
  GET_DFORM_CMS_BY_ID_SUCCESS,
  LIST_CMS_CONTENT,
  LIST_CMS_CONTENT_FAILURE,
  LIST_CMS_CONTENT_SUCCESS,
  MODAL_CLOSE_CMS,
  MODAL_CLOSE_CMS_DFORM,
  MODAL_OPEN_CMS,
  MODAL_OPEN_CMS_DFORM,
  UPDATE_CMS_CONTENT,
  UPDATE_CMS_CONTENT_FAILURE,
  UPDATE_CMS_CONTENT_SUCCESS,
  UPDATE_DFORM_CMS_BY_ID,
} from "../actionTypes";

export const cmsContentUpdate = (payload) => {
  return {
    type: UPDATE_CMS_CONTENT,
    payload,
  };
};
export const cmsContentUpdateSuccess = (payload) => {
  return {
    type: UPDATE_CMS_CONTENT_SUCCESS,
    payload,
  };
};
export const cmsContentUpdateFailure = (payload) => {
  return {
    type: UPDATE_CMS_CONTENT_FAILURE,
    payload,
  };
};

export const cmsContentList = () => {
  return {
    type: LIST_CMS_CONTENT,
  };
};

export const cmsContentListSuccess = (payload) => {
  return {
    type: LIST_CMS_CONTENT_SUCCESS,
    payload,
  };
};
export const cmsContentListFailure = (payload) => {
  return {
    type: LIST_CMS_CONTENT_FAILURE,
    payload,
  };
};

/* cms modal */
export const dashboardModalOpenCMS = (payload) => {
  return {
    type: MODAL_OPEN_CMS,
    payload,
  };
};
export const dashboardModalCloseCMS = (payload) => {
  return {
    type: MODAL_CLOSE_CMS,
    payload,
  };
};

export const cmsDFormContentList = (payload) => {
  return {
    type: CMS_DFORM_CONTENT,
    payload,
  };
};

export const cmsDFormContentListSuccess = (payload) => {
  return {
    type: CMS_DFORM_CONTENT_SUCCESS,
    payload,
  };
};
export const cmsDFormContentListFailure = (payload) => {
  return {
    type: CMS_DFORM_CONTENT_FAILURE,
    payload,
  };
};
/** dform cms */
export const cmsModalOpenForm = (payload) => {
  return {
    type: MODAL_OPEN_CMS_DFORM,
    payload,
  };
};
export const cmsModalCloseForm = (payload) => {
  return {
    type: MODAL_CLOSE_CMS_DFORM,
    payload,
  };
};
export const getDFormCMSbyId = (payload) => {
  return {
    type: GET_DFORM_CMS_BY_ID,
    payload,
  };
};
export const getDFormCMSbyIdSuccess = (payload) => {
  return {
    type: GET_DFORM_CMS_BY_ID_SUCCESS,
    payload,
  };
};
export const getDFormCMSbyIdFailure = (payload) => {
  return {
    type: GET_DFORM_CMS_BY_ID_FAILURE,
    payload,
  };
};
export const updateDFormCMSbyIdStarted = (payload) => {
  return {
    type: UPDATE_DFORM_CMS_BY_ID,
    payload,
  };
};
