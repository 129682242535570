import {
  ADD_CURRENT_STEP_DETAIL,
  ADD_CURRENT_STEP_DETAIL_VALUE,
  REMOVE_CURRENT_STEP_LOCATION_BY_ID,
  RESET_CURRENT_STEP_DETAIL,
  ADD_CURRENT_STEP_TECHNICAL_LEADER,
  ADD_CURRENT_STEP_DETAIL_VALUE_ONCHANGE,
} from "../actionTypes";

export const reseetCurrentStepDetail = (payload) => {
  return {
    type: RESET_CURRENT_STEP_DETAIL,
    payload,
  };
};
export const addCurrentStepDetail = (payload) => {
  return {
    type: ADD_CURRENT_STEP_DETAIL,
    payload,
  };
};
export const addCurrentStepDetailValue = (payload) => {
  return {
    type: ADD_CURRENT_STEP_DETAIL_VALUE,
    payload,
  };
};

export const removeCurrentStepLocationById = (payload) => {
  return {
    type: REMOVE_CURRENT_STEP_LOCATION_BY_ID,
    payload,
  };
};

export const addCurrentStepDetailValueOnchange = (payload) => {
  return {
    type: ADD_CURRENT_STEP_DETAIL_VALUE_ONCHANGE,
    payload,
  };
};

//Add new action for technical leader
export const addNewTechnicalLeader = (payload) => {
  return {
    type: ADD_CURRENT_STEP_TECHNICAL_LEADER,
    payload,
  };
};
