import React, { useEffect, useState } from "react";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { MdOutlineCloudDownload } from "react-icons/md";

const ImagePreview = ({ selectedFile }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(selectedFile);
        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setImageSrc(url);
        } else {
          console.error("Bild konnte nicht abgerufen werden:", response.statusText);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen des Bildes:", error);
      }
    };

    fetchImage();
  }, [selectedFile]);
  const downloadFile = () => {
    window.open(selectedFile, "_blank");
  };
  return imageSrc ? (
    <div className="relative">
      <img src={imageSrc} alt="Service Order Evidence" />{" "}
      <button type="button" onClick={downloadFile} className="bottom-0 right-0 absolute bg-AFPPrimary p-2 rounded-full m-2">
        <MdOutlineCloudDownload className="text-xl hover:text-white shadow-xl text-white" />
      </button>
    </div>
  ) : (
    <CustomCircularLoader />
  );
};

export default ImagePreview;
