/* eslint-disable */
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { useDispatch, useSelector } from "react-redux";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import useSortableData from "src/hooks/useSortableData";
import TableContainer from "@mui/material/TableContainer";
import { INBOXTABLEHEAD } from "src/mockData/InboxMock";
import TableHeader from "src/components/atoms/common/TableHeader";
import { Typography } from "@mui/material";
import { useState } from "react";
import MessageModal from "src/components/atoms/modal/MessageModal";
import { MdEmail } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import Icon2 from "../../../assets/svg/icon2.svg";
import { messageRead } from "src/store/actions/inbox";
import { parseDateFormat } from "src/helpers/document";

const InboxTable = ({ msgList }) => {
  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useSortableData(msgList);

  const [open, setOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openedMessageIds, setOpenedMessageIds] = useState([]);
  const dispatch = useDispatch();

  const handleOpen = (msg) => {
    dispatch(messageRead({ message_id: msg.id, is_open: true }));
    setSelectedMessage(msg);
    setOpen(true);
    setOpenedMessageIds((prevIds) => [...prevIds, msg.id]);
  };
  const handleClose = () => setOpen(false);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  const list = cmsDashboarddata.inbox.table;
  const headList = INBOXTABLEHEAD(list?.date, list?.regarding, list?.business);

  const attachmentHandle = (event) => {
    event.stopPropagation();
    event.preventDefault();
    downloadHandler("https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf");
  };

  const downloadHandler = (url) => {
    const link = window.document.createElement("a");
    link.href = url;
    link.download = "document";
    link.style.display = "none";
    window.document.body.appendChild(link);
    window.open(link.href, "_blank");
    setTimeout(() => {
      window.document.body.removeChild(link);
    }, 100);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHeader tableData={headList} increaseSort={requestSortIncrease} decreaseSort={requestSortDecrease} />
          {sortedData?.length > 0 ? (
            <TableBody>
              {sortedData.map((msg, index) => {
                const rowClassName = msg.isOpen ? "open-msg" : "closed-msg";
                const isMessageOpened = openedMessageIds.includes(msg.id);
                const fontWeight = !msg.isOpen && !isMessageOpened ? "600" : "400";
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:nth-of-type(even)": {
                        background: "#FBFBFB",
                        borderBottom: "none",
                      },
                      td: {
                        borderBottom: "none",
                      },
                      th: {
                        borderBottom: "none",
                      },
                      className: rowClassName,
                    }}
                    onClick={() => handleOpen(msg)}
                  >
                    <TableCell component="th" scope="row">
                      <div className="flex items-center gap-2">
                        {!msg.isOpen && !isMessageOpened ? <MdEmail style={{ cursor: "pointer" }} /> : <HiOutlineMailOpen style={{ cursor: "pointer" }} />}
                        <Typography variant="body1" fontWeight={!msg.isOpen && !isMessageOpened ? "600" : "400"} style={{ cursor: "pointer" }}>
                          {parseDateFormat(msg?.date)}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" fontWeight={fontWeight} className="flex items-center">
                        <span className="min-w-[15rem] cursor-pointer">{msg.regarding}</span>{" "}
                        {/* <span className="cursor-pointer ml-2 " onClick={attachmentHandle}>
                          <img src={Icon2} alt="Img" />
                        </span> */}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" fontWeight={fontWeight}>
                        <span className=" cursor-pointer">{msg.business}</span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <h5 className="text-red-400">Es liegen keine Nachrichten vor</h5>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {open && <MessageModal open={open} handleClose={handleClose} message={selectedMessage} />}
    </>
  );
};

export default InboxTable;
