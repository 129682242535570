import React from "react";
import { useSelector } from "react-redux";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { procedureCMSbreadcrumbs } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import ProcedureCMSButtons from "src/components/organisms/ProcedureCMSButtons";
import ProcudureCMSTable from "src/components/organisms/ProcudureCMSTable";

const ProcedureCMS = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  return (
    <>
      <ContentLayout>
        <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-AFPBGPrimary">
          {cmsDashboarddata?.sidebar_menu?.sidebar_list?.dynamic_form_cms && (
            <Breadcrumbs data={procedureCMSbreadcrumbs(cmsDashboarddata?.sidebar_menu?.sidebar_list?.dynamic_form_cms)} />
          )}
          <div className="bg-AFPBGPrimary mx-3 sm:mx-4 md:mx-6 mt-0 p-0">
            <ProcudureCMSTable />
            <ProcedureCMSButtons />
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default ProcedureCMS;
