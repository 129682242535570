import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { businessPremisesContacts } from "src/store/actions/business";
import AddAidsModal from "src/components/atoms/modal/AddAidsModal";
import AreaTable from "./AreaTable";
import { getServiceAreaStarted, getServiceAreaTechnicalLeaderStarted } from "src/store/actions/dform";
import { useParams } from "react-router-dom";
import PrefiledArea from "./PrefiledArea";
import ServiceAreaShowExpand from "src/components/atoms/card/Card/ServiceAreaShowExpand";

export default function SupplyAreaTable({ data, step_id, stepsCMS }) {
  const [aidsModal, setAidsModal] = useState(false);
  const [markPrefiledArea, setMarkPrefiledArea] = useState("initial");
  const [currentHelperText, setCurrentHelperText] = useState("");
  const { id } = useParams();
  let newData = data;
  const dform = useSelector((state) => state.dform);
  const [rows, setRows] = useState(newData);
  const [technicalLeader, setTechnicalLeader] = useState(null);
  const application = useSelector((state) => state.application);
  const currentStep = useSelector((state) => state.currentStep);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows?.splice(index, 1);
    setRows(newRows);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(businessPremisesContacts());
  }, []);

  const handleOpenModal = () => {
    setAidsModal(true);
  };

  const handleCloseModal = () => {
    setAidsModal(false);
  };

  useEffect(() => {
    if (application?.data && Object?.keys(application.data)?.length && application["step_id"] === "03_VERSORGUNGSBEREICHE") {
      const result = application?.data["03_VERSORGUNGSBEREICHE"];
      const inputTypeValue = result[0]?.inputTypeValue;
      const currentInputTypeValue = currentStep.data[0]?.inputTypeValue;
      if (inputTypeValue && currentInputTypeValue) {
        const matched = inputTypeValue?.filter((obj1) => currentInputTypeValue?.some((obj2) => obj1?.selectedArea === obj2?.selectedArea));
        if (matched && matched?.length) {
          matched?.length > 0 &&
            matched?.map((item) => {
              dispatch(
                getServiceAreaTechnicalLeaderStarted({
                  service_id: item?.selectedArea,
                  service_order_type: id,
                  contact_ids: application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue,
                  location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
                })
              );
            });
        }
      }
    }
  }, [application.data]);

  useEffect(() => {
    if (rows?.inputTypeValue?.technicalLeaders?.length > 0) {
      let techLeader = rows?.inputTypeValue[0]?.technicalLeaders?.map((leader) => {
        return { title: leader?.name, value: leader?.id };
      });
      setTechnicalLeader(techLeader);
    }
  }, [rows]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect?.top <= 400 && rect?.bottom >= 10) {
          const key = section?.getAttribute("data-key");
          currentSection = { key, position: rect?.top };
        }
      });

      setCurrentHelperText(currentSection.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (application?.data["02_FIRMA"]?.find((group) => group?.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue) {
      dispatch(
        getServiceAreaStarted({
          service_order_type: id,
          contact_ids: application?.data["02_FIRMA"]?.find((group) => group?.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue,
          location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
        })
      );
    } else if (id === "AA" && JSON.parse(localStorage.getItem("locationValidation"))) {
      let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));
      dispatch(
        getServiceAreaStarted({
          service_order_type: id,
          contact_ids: locationValidation?.exsiting_team_leaders.join(","),
          location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
        })
      );
    }
  }, []);
  let serviceareaList = dform?.service_area?.data
    ? dform?.service_area?.data?.map((area) => {
        return { title: area.name, value: area.id };
      })
    : null;

  let [miniAids, setMiniAids] = useState(false);

  useEffect(() => {
    if (dform?.page_aids && dform?.page_aids["dform"] && dform?.page_aids["dform"].includes(step_id)) {
      if (!miniAids) {
        setMiniAids(true);
      }
    } else {
      if (miniAids) {
        setMiniAids(false);
      }
    }
  }, [dform?.page_aids]);
  return (
    <>
      {!newData?.inputTypeValue?.length > 0 ? (
        <>{serviceareaList && (id === "IWA" || id === "AA") && markPrefiledArea === "initial" && <PrefiledArea data={data} setMarkPrefiledArea={setMarkPrefiledArea} />}</>
      ) : (
        id === "IWA" && <PrefiledAreaNotNeed setMarkPrefiledArea={setMarkPrefiledArea} />
      )}
      <h1 className="!mb-5 text-2xl font-tahoma-bold text-black w-full md:max-w-[350px] lg:max-w-full">{stepsCMS[data.id]?.title}</h1>
      <div className="">
        <div className="flex flex-col justify-start w-full">
          <p className="textStyle max-w-[80%] mb-8 pt-1">{stepsCMS[data?.id]?.description}</p>
          <div className="flex gap-x-10">
            <div className="flex flex-col w-full">
              <TableContainer component={Paper} sx={{ boxShadow: "none", backgroundColor: "transparent", overflowX: "auto" }}>
                <Table size="small" aria-label="">
                  <TableHead>
                    <TableRow>
                      <TableCell className="!text-SmokeyGray !text-left">
                        <span className="text-black font-tahoma-bold whitespace-nowrap ">Versorgungsbereich</span>
                      </TableCell>
                      <TableCell className="!text-SmokeyGray !text-left whitespace-nowrap font-tahoma-bold">
                        <span className="text-black font-tahoma-bold">Enthaltene Hilfsmittel</span>
                      </TableCell>
                      <TableCell className="!text-SmokeyGray whitespace-nowrap " align="left">
                        <span className="text-black font-tahoma-bold">Besondere Kriterien</span>
                      </TableCell>
                      <TableCell className="!text-SmokeyGray" align="left">
                        <span className="text-black font-tahoma-bold whitespace-nowrap ">Fachliche Leiter</span>
                      </TableCell>
                      <TableCell className="!text-SmokeyGray" align="left"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {newData?.inputTypeValue?.length > 0 &&
                      newData.inputTypeValue?.map((row, index) => {
                        return (
                          <AreaTable
                            key={row.selectedArea}
                            css="evidence-section"
                            data={row}
                            valueId={rows.id}
                            technicalLeader={technicalLeader}
                            handleRemoveRow={handleRemoveRow}
                            index={index}
                            step_id={step_id}
                            datakey={row?.selectedArea}
                            datadisable={row?.description}
                            fullData={data}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="flex flex-col items-center">
                <div
                  className="flex items-center pt-10 text-center cursor-pointer"
                  onClick={() => {
                    handleOpenModal();
                  }}
                >
                  <div className="rounded-full p-2 bg-AFPPrimary border-AFPPrimary hover:bg-AFPPrimaryDark hover:font-tahoma-bold duration-300 cursor-pointer flex items-center justify-center">
                    <IoAdd className="text-white h-4 w-4" />
                  </div>

                  <p className="text-black ml-5 font-bold">{cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.add_additional_supply_areas}</p>
                </div>
              </div>
            </div>
            {newData?.inputTypeValue?.length > 0 &&
              newData.inputTypeValue
                ?.filter((row, index) => currentHelperText === row?.selectedArea || (currentHelperText === "" && index === 0))
                .filter((showCard) => !!showCard)
                .map((row) => <ServiceAreaShowExpand key={row?.selectedArea} showCard={true} page={"dform"} aid={step_id} miniAids={miniAids} setMiniAids={setMiniAids} />)}
          </div>
        </div>
      </div>

      {aidsModal && (
        <div className="flex flex-col">
          <AddAidsModal data={data} open={aidsModal} handleClose={handleCloseModal} handleOpen={handleOpenModal} />
        </div>
      )}
    </>
  );
}

export const PrefiledAreaNotNeed = ({ setMarkPrefiledArea }) => {
  useEffect(() => {
    setMarkPrefiledArea("");
  }, []);
};
