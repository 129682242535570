export const formatDate = (dateString) => {
  if(isNaN(new Date(dateString)?.getTime())){
    return ''
  } else {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}.${month}.${year}`;
  }
};

export const currentDatePreview = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
  const year = today.getFullYear();
  return `${day}.${month}.${year}`;
}

export const parseDateFormat = (date) => {
  // return date ? (!isNaN(new Date(date)?.getTime()) ? new Date(date)?.toISOString()?.split('T')[0] : '') : ''
  if (!date) return '';

  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) return '';

  const day = String(parsedDate.getDate()).padStart(2, '0');
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); 
  const year = parsedDate.getFullYear();

  return `${day}.${month}.${year}`;
}

export const statusRenderGerman = (status) => {
  switch(status){
    case 'Open' :  return 'Offen'
    case 'On Hold' :  return 'Vorläufig erfasst'
    case 'Completed' :  return 'Beendet'
    case 'Closed' :  return 'Geschlossen'
    case 'Canceled' :  return 'Storniert'
    case 'pending' :  return 'Auf Entwurf'
    default: return status
  }
}

export function isMoreThan4WeeksPast(targetDate) {
  if(isNaN(new Date(targetDate)?.getTime())) return false;
  const msInAWeek = 7 * 24 * 60 * 60 * 1000; 
  const fourWeeksInMs = 4 * msInAWeek;

  const current = new Date();
  const target = new Date(targetDate);

  // Calculate the time difference
  const timeDifference = current - target;

  // Check if the target date is more than 4 weeks in the past
  if (timeDifference > fourWeeksInMs) {
    return true;
  }

  return false;
}