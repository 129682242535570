import { deleteApiCall, getApiCall, postApiCall, putApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const ADDBUSINESSPREMISES = async (payload) => {
  const url = `${BASE_URL}${API.ADD_BUSINESS_PREMISES}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const BUSINESS_PREMISES_LIST = async (paramsData) => {
  const url = `${BASE_URL}${API.LIST_BUSINESS_PREMISES}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_BUSINESS_PREMISESES = async (paramsData) => {
  const url = `${BASE_URL}${API.GET_BUSINESS_PREMISESES}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const BUSINESS_PREMISES_DETAIL = async (paramsData) => {
  const url = `${BASE_URL}${API.DETAIL_BUSINESS_PREMISES}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const UPDATE_PREMISES_DETAIL = async (payload) => {
  const url = `${BASE_URL}${API.UPDATE_BUSINESS_PREMISES}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await putApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const DELETE_BUSINESS_PREMISES = async (payload) => {
  const url = `${BASE_URL}${API.DELETE_BUSINESS_PREMISES}?id=${payload}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await deleteApiCall(url, {}, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.message || "Failed to delete business premises");
  }
};

export const BUSINESS_PREMISES_CONTACT_LIST = async (paramsData) => {
  const url = `${BASE_URL}${API.CONTACT_LIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_PROFESSIONAL_GROUPS = async (paramsData) => {
  const url = `${BASE_URL}${API.PROFESSIONALGROUPS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_LEADERS_BY_LOCATION = async (paramsData) => {
  const url = `${BASE_URL}${API.GET_LEADERS_BY_LOCATION}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_OWNER_BY_LOCATION = async (paramsData) => {
  const url = `${BASE_URL}${API.GET_OWNER_BY_LOCATION}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const ADD_LEADERS_BY_LOCATION = async (payload) => {
  const url = `${BASE_URL}${API.ADD_LEADERS_BY_LOCATION}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ADD_OWNER_BY_LOCATION = async (payload) => {
  const url = `${BASE_URL}${API.ADD_OWNER_BY_LOCATION}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};
