/* eslint-disable */
import { useEffect, useState } from "react";
import OverviewModal from "src/components/organisms/procedure/overview/OverviewModal";
import OverviewContent from "src/components/organisms/OverviewContent";
import { getOnGoingServiceOrderStarted } from "src/store/actions/serviceOrder";
import { useDispatch, useSelector } from "react-redux";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const OverviewCompleted = () => {
  const dispatch = useDispatch();
  const serviceOrder = useSelector((state) => state.serviceOrder);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModalHandle = () => {
    setIsModalOpen(true);
  };
  const closeModalHandle = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getOnGoingServiceOrderStarted({ service_type: "on_going" }));
  }, []);

  return (
    <>
      <div className="flex items-start flex-wrap my-4 mr-5 gap-y-4 w-full">
        <div className="w-full">
          {serviceOrder?.isLoading ? (
            <div className="flex justify-center items-center min-h-[60vh]">
              <CustomCircularLoader />
            </div>
          ) : (
            <OverviewContent openModalHandle={openModalHandle} />
          )}
        </div>
      </div>
      {isModalOpen && (
        <>
          <OverviewModal open={isModalOpen} handleClose={closeModalHandle} />{" "}
        </>
      )}
    </>
  );
};

export default OverviewCompleted;
