import React from "react";
import "./process.css";

const CustomPasswordBar = ({ strength, feedback }) => {
  return (
    <>
      <div className="flex w-full">
        <progress
          value={strength}
          max="4"
          className={`password strength-${strength}  text-black`}
        />
      </div>
      <div className={`feedback strength-${strength} flex justify-end`}>
        {feedback}
      </div>
    </>
  );
};

CustomPasswordBar.defaultProps = {
  strength: 0,
  feedback: "Weak",
};

export default CustomPasswordBar;
