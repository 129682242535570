import { fetchHelpListSuccess, fetchHelpListFailure } from "src/store/actions/help";
import { fetchHelpList } from "../requests/help";
import { put } from "redux-saga/effects";
import Cookies from "js-cookie";

export function* helpListHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield fetchHelpList(paramsData);
    if (response.status) {
      yield put(fetchHelpListSuccess(response.data));
    } else {
      yield put(
        fetchHelpListFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(fetchHelpListFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
