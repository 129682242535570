import Cookies from "js-cookie";
import { put } from "redux-saga/effects";
import { GET_RECRUIT_CLIENT_DETAIL } from "../requests/recruitClient";
import { getRecruitClientFailure, getRecruitClientSuccess } from "src/store/actions/recruitClient";

export function* getRecruitClientHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_RECRUIT_CLIENT_DETAIL(paramsData);
    if (response.status) {
      yield put(getRecruitClientSuccess(response.data));
    } else {
      yield put(
        getRecruitClientFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(getRecruitClientFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
