/* eslint-disable */
import { useNavigate } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import NavSubLink from "../NavSubLink";
import ToggleArrow from "../ToggleArrow";
import NavBadge from "src/components/atoms/badge/NavBadge";
import { useSelector } from "react-redux";

const NavLink = ({ link, handleMenuClose }) => {
  const dashboard = useSelector((state) => state.dashboard);
  const [level2Menu, setLevel2Menu] = useState([]);
  const [topMenu, setTopMenu] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const checkHyperLink = (pathname) => {
    if (typeof pathname == "string") {
      return location.pathname === pathname ? "text-black hover:text-black bg-BrightGray" : "text-gray-500 hover:text-gray-800 hover:bg-BrightGray";
    } else {
      if (pathname[1].split("/")[1] === location.pathname.split("/")[1] || pathname[1].split("/")[1] === location.pathname.split("/")[2]) {
        if (topMenu) {
          !(level2Menu.length > 0) && (setTopMenu(false), MenuFirstHandle(3));
        }
        return "text-black hover:text-black bg-BrightGray";
      }
      for (let i = 0; i < pathname.length; i++) {
        if (location.pathname === pathname[i]) {
          return "text-black hover:text-black bg-BrightGray";
        }
      }
      return "text-gray-500 hover:text-gray-800 hover:bg-BrightGray";
    }
  };
  const clickLink = (pathname) => {
    navigate(pathname);
    handleMenuClose();
  };
  const topClickLink = (pathname) => {
    navigate(pathname);
  };
  const levellingValid = (id) => {
    if (level2Menu.length > 0) {
      let filter = level2Menu.filter((menu) => {
        return menu.id == id;
      });
      if (filter.length > 0) {
        return "!flex";
      }
    }
  };

  const MenuFirstHandle = (id) => {
    setLevel2Menu((prev) => {
      let filter = prev.filter((menu) => {
        if (menu.id == id) {
          return menu;
        }
      });
      if (filter.length > 0) {
        let newPrev = prev.filter((menu) => {
          if (menu.id != id) {
            return menu;
          }
        });
        return newPrev;
      } else {
        return [...prev, { id: id }];
      }
    });
  };

  if (link?.subLink) {
    return (
      <li className={`sideNavWrapper ${level2Menu.filter((menu) => menu.id == 3)?.length > 0 ? "pb-2" : ""}`}>
        <div
          className={`flex justify-between items-center cursor-pointer ${checkHyperLink(link?.link)}`}
          onClick={() => {
            MenuFirstHandle(3)
            topClickLink(link?.menu[0]?.click);
          }}
        >
          <div className={`sideNavcontainer `}>
            {link?.link.includes(location.pathname.replace("/admin", "")) ? (
              <>
                {React.isValidElement(link.icon[0]) ? React.cloneElement(link.icon[0], { className: "mr-2 text-xl text-AFPPrimary" }) : null}{" "}
                <span className="text-black">{link.title}</span>
              </>
            ) : (
              <>
                {React.isValidElement(link.icon[1]) ? React.cloneElement(link.icon[1], { className: "mr-2 text-xl" }) : null} {link.title}
              </>
            )}
          </div>
          <div
            className="mr-2"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              MenuFirstHandle(3);
            }}
          >
            <ToggleArrow id={3} selectedMenu={level2Menu} />
          </div>
        </div>
        <ul className={`md:flex-col md:min-w-full flex-col list-none ml-6 hidden ${levellingValid(3)}`}>
          {link?.menu?.map((item) => {
            return <NavSubLink key={item?.id} link={item} handleMenuClose={handleMenuClose} />;
          })}
        </ul>
      </li>
    );
  } else {
    return (
      <>
        <li className="sideNavWrapper">
          <div
            className={`sideNavcontainer ${checkHyperLink(link?.link)}`}
            onClick={() => {
              if (link.id == 8) {
                clickLink(link?.click);
                handleMenuClose();
              } else {
                clickLink(link?.click);
              }
            }}
          >
            {link?.link.includes(location.pathname.replace("/admin", "")) ? (
              <>
                {link?.icon[0]} {link?.title} {link.id === 2 && dashboard?.data?.inbox?.newMessage > 0 && <NavBadge unread={dashboard?.data?.inbox?.newMessage} />}
              </>
            ) : (
              <>
                {link?.icon[1]} {link?.title} {link.id === 2 && dashboard?.data?.inbox?.newMessage > 0 && <NavBadge unread={dashboard?.data?.inbox?.newMessage} />}
              </>
            )}
          </div>
        </li>
      </>
    );
  }
};

NavLink.defaultProps = {
  link: {
    link: "",
    click: "",
    icon: ["", ""],
    title: "",
    subLink: false,
    menu: [],
  },
  handleMenuClose: () => {},
};

export default NavLink;
