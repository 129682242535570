/* eslint-disable */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApplicationVerify from "src/components/organisms/dform/ApplicationVerify/Index";
import BusinessInfo from "src/components/organisms/dform/BusinessInfo";
import CoverageInfo from "src/components/organisms/dform/CoverageInfo";
import EvidenceUploadConfirm from "src/components/organisms/dform/EvidenceUploadConfirm";
import LegalInfo from "src/components/organisms/dform/LegalInfo";
import LegalInformation from "src/components/organisms/dform/LegalInformation";
import { addCurrentStepDetail } from "src/store/actions/currentStep";

const StepsForm = ({ activeStep, data, step_id, stepID, createServiceOrderSubmit, saveFormData }) => {
  const currentStep = useSelector((state)=> state.currentStep)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(addCurrentStepDetail({detail: data[step_id], step_id}))
  }, []);
  return (
    <>
      {activeStep === 0 ? (
        data[step_id] && stepID === step_id && <LegalInfo data={currentStep.data} step_id={step_id} />
      ) : activeStep === 1 ? (
        data[step_id] && stepID === step_id && <BusinessInfo data={currentStep.data} step_id={step_id} />
      ) : activeStep === 2 ? (
        data[step_id] && stepID === step_id && <CoverageInfo data={currentStep.data} step_id={step_id} />
      ) : activeStep === 3 ? (
        data[step_id] && stepID === step_id && <LegalInformation data={data[step_id]} step_id={step_id} />
      ) : activeStep === 4 ? (
        data[step_id] && stepID === step_id && <ApplicationVerify data={data[step_id]} step_id={step_id} createServiceOrderSubmit={createServiceOrderSubmit} saveFormData={saveFormData} />
      ) : activeStep === 5 ? (
        data[step_id] && stepID === step_id && <EvidenceUploadConfirm data={data[step_id]} step_id={step_id} />
      ) : null}
    </>
  );
};

export default StepsForm;
