import { getApiCall, postApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const GET_APPLICATION_BY_ID = async (paramsData) => {
  const url = `${BASE_URL}${API.APPLICATION_METADATAS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const ADD_APPLICATION_STEP = async (payload) => {
  const url = `${BASE_URL}${API.ADD_PROCEDURE_APPLICATION}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const CREATE_COMPLETE_APPLICATION = async (payload) => {
  const url = `${BASE_URL}${API.CREATE_COMPLETE_APPLICATION}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const NEW_SERVICE_ORDER_COSTS = async (payload) => {
  const url = `${BASE_URL}${API.NEW_SERVICE_ORDER_COSTS}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const SEND_SIGNATURE_REQUEST_EMAIL = async (payload) => {
  const url = `${BASE_URL}${API.SEND_SIGNATURE_REQUEST_EMAIL}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const GET_APPLICATION_PROCESS_STATUS = async (paramsData) => {
  const url = `${BASE_URL}${API.APPLICATION_PROCESS_STATUS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const GET_APPLICATION_PROCESS_BY_SERVICE_ORDER_ID_STATUS = async (paramsData) => {
  const url = `${BASE_URL}${API.APPLICATION_PROCESS_STATUS_BY_SERVICE_ORDER_ID}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

/** business location validation required functionality */
export const GET_LOCATION_STATUS_BY_ID = async (paramsData) => {
  const url = `${BASE_URL}${API.APPLICATION_LOCATION_STATUS_BY_ID}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
