/* eslint-disable */

import React, { useEffect } from "react";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { Uploads } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEvidenceDBBySOidStarted, getEvidenceDBStarted, newServiceOrderEvidencePendingStarted, newServiceOrderEvidenceStarted } from "src/store/actions/evidence";
import EvidenceWrapper from "src/components/organisms/Evidence";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const EvidencePending = () => {
  const { id, service } = useParams();
  const dispatch = useDispatch();
  const evidence = useSelector((state) => state.evidence);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  useEffect(() => {
    dispatch(newServiceOrderEvidencePendingStarted({ procedure_id: id }));
    dispatch(getEvidenceDBBySOidStarted({ service_order_number: id }));
  }, []);

  return (
    <>
      <ContentLayout>
        <div className="text-black relative md:pt-[4.5rem] pb-10 py-8 min-h-screen bg-AFPBGPrimary">
          {(evidence?.evidenceDetail?.details?.erp_service_order_id || id) && cmsDashboarddata?.sidebar_menu?.sidebar_list['nachweise-hochladen'] && <Breadcrumbs data={Uploads(cmsDashboarddata?.sidebar_menu?.sidebar_list['nachweise-hochladen'], (evidence?.evidenceDetail?.details?.erp_service_order_id || id) + "")} />}
          {evidence?.isLoading || evidence?.evidenceDetail?.isLoading ? (
            <div className="flex justify-center items-center min-h-[60vh]">
              <CustomCircularLoader />
            </div>
          ) : (
            <div className="bg-AFPBGPrimary mx-3 sm:mx-4 md:mx-6 mt-0 p-0">
              <h2 className="!mb-5 text-2xl font-tahoma-bold">{cmsDashboarddata?.evidence_cms?.Evidence?.heading}</h2>
              <p className="textStyle max-w-[540px] mb-5">{cmsDashboarddata?.evidence_cms?.Evidence?.description}</p>
              {evidence?.data && Object.entries(evidence?.evidenceDetail?.details)?.length > 0 ? (
              <EvidenceWrapper />
            ) : (
              <h4 className="text-red-400 text-center my-6 mt-12">Keine Daten gefunden</h4>
            )}
            </div>
          )}
        </div>
      </ContentLayout>
    </>
  );
};

export default EvidencePending;
