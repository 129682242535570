import { FETCH_HELP_LIST_REQUEST, FETCH_HELP_LIST_SUCCESS, FETCH_HELP_LIST_FAILURE } from "../actionTypes";

export const fetchHelpListRequest = () => {
  return {
    type: FETCH_HELP_LIST_REQUEST,
  };
};

export const fetchHelpListSuccess = (payload) => {
  return {
    type: FETCH_HELP_LIST_SUCCESS,
    payload,
  };
};

export const fetchHelpListFailure = (payload) => {
  return {
    type: FETCH_HELP_LIST_FAILURE,
    payload,
  };
};
