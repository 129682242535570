import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getServiceAreaDetailStarted, resetServiceAreaDetail } from "src/store/actions/dform";
import { PrimaryButton } from "../../button";
import StateSelection from "../../select/StateSelection";
import { useParams } from "react-router-dom";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import AutoCompleteLabel from "../../input/AutoCompleteField/AutoCompleteLabel";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { addServiceApplicationStepStarted } from "src/store/actions/application";
import Cookies from "js-cookie";

const style = {
  p: 4,
  top: "50%",
  left: "50%",
  width: "90%",
  boxShadow: 24,
  maxWidth: "60%",
  bgcolor: "#FBFBFB",
  borderRadius: "12px",
  position: "absolute",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};

const AddAidsModal = ({ handleClose, data, open, step_id }) => {
  const application = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const { id } = useParams();
  const dform = useSelector((state) => state.dform);
  const currentStep = useSelector((state) => state.currentStep);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { service_area, service_area_detail } = dform;
  let serviceareaList = service_area?.data
    ? service_area?.data?.map((area) => {
        return { title: area.name, value: area.id };
      })
    : null;
  const [areaList, setAreaList] = useState(serviceareaList);
  const [technicalLeader, setTechnicalLeader] = useState(null);
  const [selectedAids, setSelectedAids] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    leader: {
      error: null,
      status: false,
    },
  });
  const [nowSubmitted, setNowSubmitted] = useState({ status: false, isLoading: false });

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedArea(value);
    if (value !== selectedArea) {
      dispatch(resetServiceAreaDetail());
      if (application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue) {
        dispatch(
          getServiceAreaDetailStarted({
            service_id: value,
            service_order_type: id,
            contact_ids: application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue,
            location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
          })
        );
      } else if (id === "AA" && JSON.parse(localStorage.getItem("locationValidation"))) {
        let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));
        dispatch(
          getServiceAreaDetailStarted({
            service_id: value,
            service_order_type: id,
            contact_ids: locationValidation?.exsiting_team_leaders.join(","),
            location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (service_area?.data?.length > 0) {
      let areaList = service_area?.data?.map((area) => {
        return { title: area.name, value: area.id };
      });
      setAreaList(areaList);
    }
  }, [service_area]);

  useEffect(() => {
    if (service_area_detail?.data?.technicalLeaders?.length > 0) {
      let techLeader = service_area_detail?.data?.technicalLeaders?.map((leader) => {
        return { name: leader.name, id: leader.id };
      });
      setTechnicalLeader(techLeader);
    }
  }, [service_area_detail]);

  useEffect(() => {
    return () => {
      dispatch(resetServiceAreaDetail());
    };
  }, []);

  const submitHandle = () => {
    if (selectedAids?.length > 0) {
      dispatch(
        addCurrentStepDetailValue({
          valueId: data.id,
          value: [
            ...(currentStep?.data[0]?.inputTypeValue ?? []),
            {
              selectedTechnicalLeaders: selectedAids,
              selectedArea,
              ...service_area_detail.data,
            },
          ],
          valueKey: "inputTypeValue",
        })
      );
      setNowSubmitted({ status: false, isLoading: true });
      setTimeout(() => {
        setNowSubmitted({ status: true, isLoading: false });
      }, 600);
    } else {
      setFieldErrors((prev) => {
        return {
          ...prev,
          leader: {
            error: "Bitte auswählen Fachliche Leiter",
            status: true,
          },
        };
      });
    }
  };

  const handleChange = (event, value) => {
    setSelectedAids(value);
    if (value?.length > 0) {
      setFieldErrors((prev) => {
        return {
          ...prev,
          leader: {
            error: null,
            status: false,
          },
        };
      });
    }
  };

  useEffect(() => {
    if (data.inputTypeValue) {
      const filterData = areaList.filter((item) => {
        return !data.inputTypeValue.some((obj2) => obj2.description === item.title);
      });
      setAreaList(filterData);
    }
  }, []);

  useEffect(() => {
    if (nowSubmitted.status) {
      dispatch(
        addServiceApplicationStepStarted({
          procedure_data: [{ ...application?.data, [currentStep.stepID]: currentStep.data }],
          step_id: step_id,
          service_order_types_id: id,
          procedure_form_id: Cookies.get("submitted_id"),
        })
      );
      handleClose();
    }
  }, [nowSubmitted.status]);

  return (
    <>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="outline-none" tabIndex={"undefined"}>
          <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl md:text-2xl font-tahoma-bold">Versorgungsbereich hinzufügen</h2>
              <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2" onClick={handleClose} />
            </div>

            <div className="min-h-[20vh]">
              {service_area.isLoading ? (
                <div className="flex justify-center items-center">
                  <CustomCircularLoader />
                </div>
              ) : areaList ? (
                <>
                  <div className="w-full">
                    <p className="mb-1 ml-1 font-tahoma-bold">Versorgungsbereich</p>
                    <StateSelection handleSelect={handleSelectChange} data={areaList} placeholder={"Auswählen"} />
                  </div>
                </>
              ) : (
                <h4 className="text-red-400 text-center my-6">Keine Daten gefunden</h4>
              )}

              {service_area_detail.isLoading ? (
                <div className="flex justify-center items-center mt-4">
                  <CustomCircularLoader />
                </div>
              ) : (
                service_area_detail?.data && (
                  <>
                    {service_area_detail?.data?.technicalLeaders?.length > 0 ? (
                      <>
                        <div className="mb-6">
                          {service_area_detail?.data?.includedAid && service_area_detail.data.includedAid.split(",").length > 0 && (
                            <>
                              <p className="mt-3 mb-1 ml-1 font-tahoma-bold">Enthaltene Hilfsmittel</p>
                              <div className="ml-4">
                                {service_area_detail?.data?.includedAid?.split(",")?.map((ia) => {
                                  return <li key={ia}>{ia}</li>;
                                })}
                              </div>
                            </>
                          )}
                          {service_area_detail?.data?.specialCriteria && service_area_detail.data.specialCriteria.length > 0 && (
                            <>
                              <p className="mt-3 mb-1 ml-1 font-tahoma-bold">Besondere Kriterien</p>
                              <div className="ml-4">
                                {service_area_detail?.data?.specialCriteria?.map((sc) => {
                                  return <li key={sc}>{sc}</li>;
                                })}
                              </div>
                            </>
                          )}
                          <p className="mt-3 mb-1 ml-1 font-tahoma-bold">Fachliche Leiter</p>
                          <div className="rounded-[12px] border border-AFPLightGray flex justify-between px-0 pr-2 flex-1">
                            {technicalLeader?.length > 0 && <AutoCompleteLabel selectedPersonOption={selectedAids} LegalPersonList={technicalLeader} handleChange={handleChange} />}
                          </div>
                          <p className="pl-2 text-sm text-red-400 mt-2">{fieldErrors?.leader?.status === true && fieldErrors?.leader?.error}</p>
                        </div>
                        <div className="flex justify-center items-center">
                          <PrimaryButton
                            title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.save}
                            btnStyle="max-w-[150px]"
                            type="submit"
                            loading={nowSubmitted.isLoading}
                            handler={submitHandle}
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-red-400 text-center mt-8 font-tahoma-bold">Keine Daten gefunden</p>
                    )}
                  </>
                )
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddAidsModal;
