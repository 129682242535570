const StateSelection = ({ handleSelect, data, placeholder, defaultValue = "" }) => {
  return (
    <div className="relative w-full">
      <select
        onChange={handleSelect}
        defaultValue={defaultValue.toString()}
        className={"flex h-10 w-full items-center px-3 justify-center border-gray-400 rounded-xl border text-sm outline-none"}
      >
        {placeholder && <option className="!text-black text-lg" value={"unknown"}>
          {placeholder}
        </option>}
        {data?.map((item, index) => {
          return (
            <option className="!text-black text-lg" key={index} value={item.value} selected={data.find((d) => d.id == defaultValue)}>
              {item.title}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default StateSelection;
