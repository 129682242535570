import { SET_PROFILE, FETCH_PROFILE_FAILURE, FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS } from "../actionTypes";

export const fetchProfileRequest = () => ({
  type: FETCH_PROFILE_REQUEST
});

export const setProfile = (payload) => ({
  type: SET_PROFILE,
  payload,
});

export const fetchProfileSuccess = (payload) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload,
});

export const fetchProfileFailure = (payload) => ({
  type: FETCH_PROFILE_FAILURE,
  payload,
});
