import Cookies from "js-cookie";

class CookieStorage {
  setLogin = (token, userId, remember=false) => {
    let thirtyDays;
    if(remember){
      thirtyDays = 30 * 24 * 60 * 60;
    } else {
      thirtyDays = 7 * 24 * 60 * 60;
    }
    const expirationDate = new Date(Date.now() + thirtyDays * 1000);
    Cookies.set("token", token, { expires: expirationDate } );
    Cookies.set("userId", userId, { expires: expirationDate } );
  };
  getLogin = () => {
    if (Cookies.get("token") && Cookies.get("userId")) {
      return true;
    } else {
      return false;
    }
  };
  logout = () => {
    Cookies.remove("token");
    Cookies.remove("userId");
  };
}

export default CookieStorage;
