import * as yup from "yup";

export const INITIALVALUESLOGIN = {
  email: "",
  password: "",
  remember: false,
};
export const INITIALVALUESFORGOT = {
  email: "",
};
export const INITIALVALUESRESET = {
  password: "",
  confirmpassword: "",
};

export const INITIALVALUESCHANGEPASSWORD = {
  oldPassword: "",
  password: "",
  repeat: "",
};

export const VALIDATIONSCHEMALOGIN = yup.object().shape({
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
  password: yup.string().required("Password muss ausgefüllt werden").min(6, "Password must be at least 6 characters").max(30, "Password must not exceed 40 characters"),
});
export const VALIDATIONSCHEMAFORGOT = yup.object().shape({
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
});
export const VALIDATIONSCHEMARESET = yup.object().shape({
  password: yup.string().required("Password muss ausgefüllt werden").min(6, "Password must be at least 6 characters").max(30, "Password must not exceed 40 characters"),
  confirmpassword: yup.string().required("Password muss ausgefüllt werden").min(6, "Password must be at least 6 characters").max(30, "Password must not exceed 40 characters"),
});

export const VALIDATIONSCHEMACHANGEPASSWORD = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Old Password muss ausgefüllt werden")
    .test(
      "Old password",
      "Old Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long. No spaces allowed.",
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/\\~-])(?=\S+$).{8,}$/.test(value) && !/\s/.test(value);
      }
    ),
  password: yup
    .string()
    .required("Password muss ausgefüllt werden")
    .test(
      "password",
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long. No spaces allowed.",
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/\\~-])(?=\S+$).{8,}$/.test(value) && !/\s/.test(value);
      }
    ),
  repeat: yup
    .string()
    .required("Passwort wiederholen muss ausgefüllt werden")
    .oneOf([yup.ref("password"), null], "Passwort must match"),
});

export const VALIDATIONSCHEMAINVITEDUSER = yup.object().shape({
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
  first_name: yup.string().required("Vorname muss ausgefüllt werden"),
  last_name: yup.string().required("Nachname muss ausgefüllt werden"),
});