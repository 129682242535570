/* eslint-disable */

import { FormControl, MenuItem, Select } from "@mui/material";

const CommonSelect = ({ handle, selectedOption, dataList }) => {
  const selectedIds = selectedOption ? selectedOption.id : dataList.length > 0 ? dataList[0].id : null;

  return (
    <>
      <FormControl sx={{ width: "100%", borderRadius: "12px" }}>
        <Select
          defaultValue={dataList[0].id}
          value={selectedIds}
          onChange={handle}
          labelId="demo-simple-select-autowidth-label"
          sx={{
            borderRadius: "12px",

            "&.MuiOutlinedInput-root .MuiSelect-select": {
              padding: "10px",
            },
          }}
        >
          {dataList.map((data) => (
            <MenuItem key={data.id} value={data.id} className="!text-lg">
              {data.account_holder}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CommonSelect;
