import {
  GET_APPLICATION_FAILURE,
  GET_APPLICATION_STARTED_ByID,
  GET_APPLICATION_SUCCESS,
  RESET_APPLICATION,
  RESET_APPLICATION_SIDEBAR,
  RESET_SET_APPLICATION,
  SET_APPLICATION_SIDEBAR,
  ADD_APPLICATION_STEP_STARTED,
  ADD_APPLICATION_STEP_SUCCESS,
  ADD_APPLICATION_STEP_FAILURE,
  CREATE_COMPLETE_APPLICATION_STARTED,
  CREATE_COMPLETE_APPLICATION_SUCCESS,
  CREATE_COMPLETE_APPLICATION_FAILURE,
  NEW_SERVICE_ORDER_COSTS_STARTED,
  NEW_SERVICE_ORDER_COSTS_SUCCESS,
  NEW_SERVICE_ORDER_COSTS_FAILURE,
  SEND_SIGNATURE_REQUEST_EMAIL_STARTED,
  SEND_SIGNATURE_REQUEST_EMAIL_SUCCESS,
  SEND_SIGNATURE_REQUEST_EMAIL_FAILURE,
  APPLICATION_PROCESS_STATUS_FAILURE,
  APPLICATION_PROCESS_STATUS_SUCCESS,
  APPLICATION_PROCESS_STATUS_STARTED,
  APPLICATION_LOCATION_VALIDATION_STARTED,
  APPLICATION_LOCATION_VALIDATION_SUCCESS,
  APPLICATION_LOCATION_VALIDATION_FAILURE,
  APPLICATION_LOCATION_VALIDATION_RESET,
  APPLICATION_SIGNATURE_BY_ID,
  APPLICATION_SIGNATURE_RESET,
  PARTICULAR_APPLICATION_SIGNATURE_RESET,
  ADD_SERVICE_APPLICATION_STEP_STARTED,
  ADD_SERVICE_APPLICATION_STEP_SUCCESS,
  ADD_SERVICE_APPLICATION_STEP_FAILURE,
} from "../actionTypes";

export const getApplicationStartedByID = (payload) => {
  return {
    type: GET_APPLICATION_STARTED_ByID,
    payload,
  };
};

export const resetApplication = (payload) => {
  return {
    type: RESET_APPLICATION,
    payload,
  };
};

export const getApplicationSuccess = (payload) => {
  return {
    type: GET_APPLICATION_SUCCESS,
    payload,
  };
};
export const getApplicationFailure = (payload) => {
  return {
    type: GET_APPLICATION_FAILURE,
    payload,
  };
};
export const setApplicationSidebar = () => {
  return {
    type: SET_APPLICATION_SIDEBAR,
  };
};
export const resetApplicationSidebar = () => {
  return {
    type: RESET_APPLICATION_SIDEBAR,
  };
};

export const addApplicationStepStarted = (payload) => {
  return {
    type: ADD_APPLICATION_STEP_STARTED,
    payload,
  };
};

export const addApplicationStepSuccess = (payload) => {
  return {
    type: ADD_APPLICATION_STEP_SUCCESS,
    payload,
  };
};
export const addApplicationStepFailure = (payload) => {
  return {
    type: ADD_APPLICATION_STEP_FAILURE,
    payload,
  };
};

export const addServiceApplicationStepStarted = (payload) => {
  return {
    type: ADD_SERVICE_APPLICATION_STEP_STARTED,
    payload,
  };
};

export const addServiceApplicationStepSuccess = (payload) => {
  return {
    type: ADD_SERVICE_APPLICATION_STEP_SUCCESS,
    payload,
  };
};

export const addServiceApplicationStepFailure = (payload) => {
  return {
    type: ADD_SERVICE_APPLICATION_STEP_FAILURE,
    payload,
  };
};

export const resetSetApplication = (payload) => {
  return {
    type: RESET_SET_APPLICATION,
    payload,
  };
};

export const createCompleteApplicationStarted = (payload) => {
  return {
    type: CREATE_COMPLETE_APPLICATION_STARTED,
    payload,
  };
};

export const createCompleteApplicationSuccess = (payload) => {
  return {
    type: CREATE_COMPLETE_APPLICATION_SUCCESS,
    payload,
  };
};
export const createCompleteApplicationFailure = (payload) => {
  return {
    type: CREATE_COMPLETE_APPLICATION_FAILURE,
    payload,
  };
};
export const newServiceOrderCostStarted = (payload) => {
  return {
    type: NEW_SERVICE_ORDER_COSTS_STARTED,
    payload,
  };
};

export const newServiceOrderCostSuccess = (payload) => {
  return {
    type: NEW_SERVICE_ORDER_COSTS_SUCCESS,
    payload,
  };
};
export const newServiceOrderCostFailure = (payload) => {
  return {
    type: NEW_SERVICE_ORDER_COSTS_FAILURE,
    payload,
  };
};

export const sendSignatureRequestEmailStarted = (payload) => {
  return {
    type: SEND_SIGNATURE_REQUEST_EMAIL_STARTED,
    payload,
  };
};

export const sendSignatureRequestEmailSuccess = (payload) => {
  return {
    type: SEND_SIGNATURE_REQUEST_EMAIL_SUCCESS,
    payload,
  };
};
export const sendSignatureRequestEmailFailure = (payload) => {
  return {
    type: SEND_SIGNATURE_REQUEST_EMAIL_FAILURE,
    payload,
  };
};

export const applicationProcessStatusStarted = (payload) => {
  return {
    type: APPLICATION_PROCESS_STATUS_STARTED,
    payload,
  };
};

export const applicationProcessStatusSuccess = (payload) => {
  return {
    type: APPLICATION_PROCESS_STATUS_SUCCESS,
    payload,
  };
};
export const applicationProcessStatusFailure = (payload) => {
  return {
    type: APPLICATION_PROCESS_STATUS_FAILURE,
    payload,
  };
};
/** business location validation required functionality */
export const applicationLocationValidationStarted = (payload) => {
  return {
    type: APPLICATION_LOCATION_VALIDATION_STARTED,
    payload,
  };
};

export const applicationLocationValidationSuccess = (payload) => {
  return {
    type: APPLICATION_LOCATION_VALIDATION_SUCCESS,
    payload,
  };
};
export const applicationLocationValidationFailure = (payload) => {
  return {
    type: APPLICATION_LOCATION_VALIDATION_FAILURE,
    payload,
  };
};
export const applicationLocationValidationReset = (payload) => {
  return {
    type: APPLICATION_LOCATION_VALIDATION_RESET,
    payload,
  };
};
export const applicationSignatureById = (payload) => {
  return {
    type: APPLICATION_SIGNATURE_BY_ID,
    payload,
  };
};
export const applicationSignatureReset = (payload) => {
  return {
    type: APPLICATION_SIGNATURE_RESET,
    payload,
  };
};
export const particularApplicationSignatureReset = (payload) => {
  return {
    type: PARTICULAR_APPLICATION_SIGNATURE_RESET,
    payload,
  };
};
