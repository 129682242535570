import { getApiCall, postApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const UPDATE_CMS_CONTENT = async (payload) => {
  const url = `${BASE_URL}${API.UPDATE_CMS_CONTENT}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCMS = async (paramsData) => {
  const url = `${BASE_URL}${API.ALL_CMS_CONTENT}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message || "Failed to fetch profile");
  }
};

export const getCMS_DForm = async (paramsData) => {
  const url = `${BASE_URL}${API.GET_CMS_DForm_CONTENT}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message || "Failed to fetch profile");
  }
};
export const getCMS_DForm_By_Id = async (paramsData) => {
  const url = `${BASE_URL}${API.GET_CMS_DForm_BY_ID}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message || "Failed to fetch profile");
  }
};

export const UPDATE_DFORM_CMS_BYID = async (payload) => {
  const url = `${BASE_URL}${API.UPDATE_DFORM_CMS_BY_ID}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};
