/* eslint-disable */

import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomCheckbox from "src/components/atoms/input/CustomCheckbox";
import { evidenceCheckDetail } from "src/helpers/dform/validateEvidence";
import * as Actions from "src/store/actions/evidence";
import { setEvidenceById } from "src/store/actions/evidence";

const EvidenceCheckbox = ({ evedenceData, evidence, handleCheckChangeDetailFill, entriesKey }) => {
  const checked =
    evidence?.evidenceDetail?.data && evedenceData?.inventoryID?.value && evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value]
      ? evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value]
      : false;
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(checked);
  const [initialCheck, setInitialCheck] = useState("");

  const handleCheckbox = async (event) => {
    let checkedValue = event.target.checked;
    setIsChecked(checkedValue);

    dispatch(
      setEvidenceById({
        key: evedenceData?.inventoryID?.value,
        value: checkedValue,
        type: 'checkbox'
      })
    );

    if (checkedValue) {
      if (!initialCheck) {
        setInitialCheck("check");
      }
    } else {
      setInitialCheck("");
      dispatch(Actions.removeEvidence(entriesKey, evedenceData?.inventoryID?.value));
    }
  };

  useEffect(() => {
    let newObj = {};
    let markObj = {};
    evidence?.data?.forEach((ab) => {
      ab?.prerequisiteGroup?.value;
      if (newObj[ab?.prerequisiteGroup?.value]) {
        newObj[ab?.prerequisiteGroup?.value]?.push(ab);
      } else {
        newObj[ab?.prerequisiteGroup?.value] = [ab];
        markObj[ab?.prerequisiteGroup?.value] = false;
      }
    });
    const isCheckUpdated = evidenceCheckDetail(newObj[entriesKey], evidence);
    if (isCheckUpdated) {
      handleCheckChangeDetailFill(entriesKey, true);
    } else {
      handleCheckChangeDetailFill(entriesKey, false);
    }
  }, [initialCheck]);

  return (
    <div className="w-full mb-5 flex justify-between flex-wrap" id="pdf-content">
      <div className="flex gap-x-2 items-start justify-start px-3" id={evedenceData?.inventoryID?.value}>
        <FormControlLabel
          sx={{
            display: "flex",
            alignItems: "start",
          }}
          control={<CustomCheckbox isChecked={isChecked} />}
          label={
            <div className="ml-2 p-0">
              <div
                className="p-0 m-0 font-bold"
                dangerouslySetInnerHTML={{
                  __html: evedenceData?.prerequisite?.ValueDescription,
                }}
              ></div>
              <div className="flex justify-start items-start mt-4">
                {evedenceData?.evidenceType?.value === "Erklärung" && (
                  <Checkbox
                    sx={{
                      marginLeft: "-10px",
                      marginTop: "-10px",
                      color: "#484848",
                      "&.Mui-checked": {
                        color: "#A31D1A",
                      },
                    }}
                    checked={isChecked}
                    onChange={handleCheckbox}
                  />
                )}
                <div
                  className="text-DarkCharcoal text-[14px]"
                  dangerouslySetInnerHTML={{
                    __html: evedenceData?.longDescription?.value,
                  }}
                ></div>
              </div>
            </div>
          }
        />
      </div>
      {!isChecked ? <span className="text-red-400 text-sm">* Pflichtfeld</span> : null}
    </div>
  );
};

export default EvidenceCheckbox;
