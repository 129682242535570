/*eslint-disable*/
import React from "react";
import style from "./documentFilter.module.css";

const DocumentFilterSelect = (props) => {
  const { label, id, extra, extraLabel, extraInput, options, formik, choose, setSelectDocument = () => {} } = props;

  const { getFieldProps, touched, errors } = formik;
  return (
    <div className={`${style.wrapper} ${extra}`}>
      <label htmlFor={id} className={`${style.label} ${extraLabel}`}>
        {label}
      </label>
      <div className="w-full">
        <div className="relative w-full">
          <select
            id={id}
            {...getFieldProps(id)}
            onChange={(e) => setSelectDocument(e.target.value)}
            className={`${style.select} ${extraInput} ${touched[id] && errors[id] ? "border-red-500 text-red-500 placeholder:text-red-500" : "border-gray-200"}`}
          >
            {choose && (
              <option className="!text-black text-lg" value="">
                {label}
              </option>
            )}
            {options.map((item, index) => {
              return (
                <option className="!text-black text-lg" key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M9.293 13.707a1 1 0 001.414 0l5-5a1 1 0 00-1.414-1.414L10 11.586l-4.293-4.293a1 1 0 00-1.414 1.414l5 5z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

        {touched[id] && errors[id] && <div className="warning">{errors[id]}</div>}
      </div>

      <style>
        {`
          /* Hide default dropdown arrow */
          select::-ms-expand {
            display: none;
          }
          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        `}
      </style>
    </div>
  );
};

DocumentFilterSelect.defaultProps = {
  label: "Select",
  id: "standardSelect",
  extra: "",
  variant: "",
  extraLabel: "",
  extraInput: "",
  options: [],
  choose: true,
  formik: {
    getFieldProps: () => {},
    touched: {},
    errors: {},
    handleChange: () => {},
    handleBlur: () => {},
    values: { email: "" },
  },
};

export default DocumentFilterSelect;
