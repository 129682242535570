import { getApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const getProcedureTemplate = async (paramsData) => {
  const url = `${BASE_URL}${API.PROCEDURE_TEMPLATE}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
