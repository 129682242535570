import Login from "src/components/template/Login";
import LoginForm from "src/components/organisms/login/LoginForm";

const { heading, subHeading, loginPolicy } = {
  heading: "Kundenportal",
  subHeading: "Bitte loggen Sie sich ein",
  loginPolicy:
    "Mit Ihrer Anmeldung erklären Sie sich mit unseren Nutzungsbedingungen und unserer Datenschutzrichtlinie einverstanden. Sie erklären sich außerdem damit einverstanden, kontobezogene E-Mails von der Agentur für Präqualifizierung GmbH zu erhalten, einschließlich wichtige Hinweise vom GKV-SV sowie zu Produktaktualisierungen.",
};

const loginPreField = {
  title: heading,
  subTitle: subHeading,
  LoginPolicy: loginPolicy,
};
const SignIn = () => {
  return (
    <>
      <div className="loginwrap">
        <div className="loginwcontainer">
          <Login data={loginPreField}>
            <LoginForm />
          </Login>
        </div>
      </div>
    </>
  );
};

export default SignIn;
