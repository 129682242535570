/* eslint-disable */
import { Box, Modal } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import NewUserForm from "src/components/atoms/modal/AddBusinessModal/user/NewUserForm";
import { VALIDATIONSCHEMALEADERS } from "src/services/validation/buinessPremises";
import { addLeaderByLocationStarted, setCheckAddLeader } from "src/store/actions/business";
const style = {
  p: 2,
  top: "50%",
  left: "50%",
  width: "90%",
  boxShadow: 24,
  maxWidth: "600px",
  bgcolor: "#FBFBFB",
  borderRadius: "12px",
  position: "absolute",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};
const FachleiterModal = ({ handleClose, handleOpen }) => {
  const business = useSelector((state) => state.management);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      title: "",
      salutation: "",
      location_id: "",
      selectedPersonOption: [],
    },
    validationSchema: VALIDATIONSCHEMALEADERS,
    onSubmit: (values) => {
      let { selectedPersonOption, ...rest } = values;
      let payload = {
        ...rest,
        professional_groups: values?.selectedPersonOption?.length > 0 ? values?.selectedPersonOption.map((value) => value.id) : [],
      };
      dispatch(addLeaderByLocationStarted({ ...payload, location_id: business?.leaderList?.locationId }));

      dispatch(setCheckAddLeader(true));

      setTimeout(() => {
        handleClose();
      }, 2000);
    },
  });

  return (
    <>
      {handleOpen && (
        <Modal open={handleOpen}>
          <Box sx={style} tabIndex={"undefined"}>
            <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl md:text-2xl font-tahoma-bold">neu Fachlichen Leiter</h2>
                <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2" onClick={handleClose} />
              </div>
              {business?.professionalGroup?.data && <NewUserForm formik={formik} handleClose={handleClose} />}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FachleiterModal;
