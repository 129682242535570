import {
  ADD_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT,
  ACCOUNTS_LIST,
  FETCH_ACCOUNTLIST_REQUEST,
  FETCH_ACCOUNTLIST_FAILURE,
  FETCH_ACCOUNTLIST_SUCCESS,
  SET_ACCOUNT_LIST,
  DELETE_ACCOUNT_LIST,
  START_USER_MANAGEMENT_UPDATE,
  RESET_USER_MANAGEMENT_UPDATE,
  REMOVE_USER_MANAGEMENT_BY_ID,
  Add_USER_MANAGEMENT_LIST,
  CREATED_USER_ACCOUNT,
  CREATED_RESET_USER_ACCOUNT,
  ADD_USER_MANAGEMENT,
  BUSINESSLIST_INVITE_USER_STARTED,
  BUSINESSLIST_INVITE_USER,
  FETCH_MANAGEMENT_FAILURE,
  USER_MANAGEMENT_LIST_STARTED,
  USER_MANAGEMENT_LIST,
  UPDATE_USER_MANAGEMENT_BY_ID_STARTED,
  UPDATE_USER_MANAGEMENT_BY_ID,
  REMOVE_USER_MANAGEMENT_BY_ID_SUCCESS,
  ADD_USER_MANAGEMENT_SUCCESS,
  ADD_USER_MANAGEMENT_FAILURE,
} from "../actionTypes";

export const addUserAccount = (payload) => {
  return {
    type: ADD_USER_ACCOUNT,
    payload,
  };
};

export const createdUserAccount = () => {
  return {
    type: CREATED_USER_ACCOUNT,
  };
};

export const createdResetUserAccount = (payload) => {
  return {
    type: CREATED_RESET_USER_ACCOUNT,
    payload,
  };
};


export const updateUserAccount = (payload) => {
  return {
    type: UPDATE_USER_ACCOUNT,
    payload,
  };
};

export const updateUserManagement = (payload) => {
  return {
    type: START_USER_MANAGEMENT_UPDATE,
    payload,
  };
};

export const resetUserManagement = () => {
  return {
    type: RESET_USER_MANAGEMENT_UPDATE,
  };
};

export const accountList = (params) => {
  return {
    type: ACCOUNTS_LIST,
    payload: params,
  };
};

export const fetchAccountListRequest = () => {
  return {
    type: FETCH_ACCOUNTLIST_REQUEST,
  };
};

export const setAccountList = (payload) => {
  return {
    type: SET_ACCOUNT_LIST,
    payload,
  };
};

export const fetchAccountListSuccess = (payload) => {
  return {
    type: FETCH_ACCOUNTLIST_SUCCESS,
    payload,
  };
};

export const fetchAccountListFailure = (payload) => {
  return {
    type: FETCH_ACCOUNTLIST_FAILURE,
    payload,
  };
};

export const deleteAccountList = (payload) => {
  return {
    type: DELETE_ACCOUNT_LIST,
    payload,
  };
};


/* user management */

export const removeUserManagementByID = (payload) => {
  return {
    type: REMOVE_USER_MANAGEMENT_BY_ID,
    payload,
  }
}

export const removeUserManagementByIDSuccess = (payload) => {
  return {
    type: REMOVE_USER_MANAGEMENT_BY_ID_SUCCESS,
    payload,
  }
}

export const addUserManagementOnList = (payload) => {
  return {
    type: Add_USER_MANAGEMENT_LIST,
    payload,
  }
}

export const updateUserManagementByIDStarted = (payload) => {
  return {
    type: UPDATE_USER_MANAGEMENT_BY_ID_STARTED,
    payload,
  }
}

export const updateUserManagementByID = (payload) => {
  return {
    type: UPDATE_USER_MANAGEMENT_BY_ID,
    payload,
  }
}
  
  export const addUserManagement = (payload) => {
    return {
      type: ADD_USER_MANAGEMENT,
      payload,
    };
  };
  
  export const addUserManagementSuccess = (payload) => {
    return {
      type: ADD_USER_MANAGEMENT_SUCCESS,
      payload,
    };
  };
  export const addUserManagementFailure = (payload) => {
    return {
      type: ADD_USER_MANAGEMENT_FAILURE,
      payload,
    };
  };
  export const fetchManagementFailure = (payload) => {
    return {
      type: FETCH_MANAGEMENT_FAILURE,
      payload,
    };
  };
  
  export const businessListInviteStarted = (payload) => {
    return {
      type: BUSINESSLIST_INVITE_USER_STARTED,
      payload,
    };
  };

  export const businessListInvite = (payload) => {
    return {
      type: BUSINESSLIST_INVITE_USER,
      payload,
    };
  };

  export const userManagementStarted = (payload) => {
    return {
      type: USER_MANAGEMENT_LIST_STARTED,
      payload,
    };
  };

  export const userManagementList = (payload) => {
    return {
      type: USER_MANAGEMENT_LIST,
      payload,
    };
  };
  