import React from "react";

const CardDoc = ({ children, css }) => {
  return <div className={`flex flex-col  w-48 h-56  rounded-xl border border-[#CBCBCB] shadow-md !bg-[#fff] hover:shadow-xl duration-300 cursor-pointer ${css}`}>{children}</div>;
};

CardDoc.defaultProps = {
  css: "",
  children: null,
};

export default CardDoc;
