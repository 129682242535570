import {
  DOWNLOAD_XML_STARTED,
  DOWNLOAD_XML_SUCCESS,
  DOWNLOAD_ORDER_STARTED,
  DOWNLOAD_ORDER_SUCCESS,
  DOWNLOAD_INVOICE_STARTED,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE,
  DOWNLOAD_CERTIFICATE_STARTED,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  GET_EVIDENCE_FILE_DOWNLOAD_STARTED,
  GET_EVIDENCE_FILE_DOWNLOAD_FAILURE,
  GET_EVIDENCE_FILE_DOWNLOAD_SUCCESS,
  GET_EVIDENCE_FILE_DOWNLOAD_RESET,
} from "../actionTypes";

export const downloadOrderStarted = () => {
  return {
    type: DOWNLOAD_ORDER_STARTED,
  };
};

export const downloadOrderSuccess = (payload) => {
  return {
    type: DOWNLOAD_ORDER_SUCCESS,
    payload,
  };
};
export const downloadInvoiceStarted = () => {
  return {
    type: DOWNLOAD_INVOICE_STARTED,
  };
};

export const downloadInvoiceSuccess = (payload) => {
  return {
    type: DOWNLOAD_INVOICE_SUCCESS,
    payload,
  };
};
export const downloadCertificateStarted = () => {
  return {
    type: DOWNLOAD_CERTIFICATE_STARTED,
  };
};

export const downloadCertificateSuccess = (payload) => {
  return {
    type: DOWNLOAD_CERTIFICATE_SUCCESS,
    payload,
  };
};
export const downloadXmlStarted = () => {
  return {
    type: DOWNLOAD_XML_STARTED,
  };
};

export const downloadXmlSuccess = (payload) => {
  return {
    type: DOWNLOAD_XML_SUCCESS,
    payload,
  };
};

export const downloadDocumentFailure = (payload) => {
  return {
    type: DOWNLOAD_DOCUMENT_FAILURE,
    payload,
  };
};

export const downloadEvidenceFileStarted = (payload) => {
  return {
    type: GET_EVIDENCE_FILE_DOWNLOAD_STARTED,
    payload
  };
};

export const downloadEvidenceFileSuccess = (payload) => {
  return {
    type: GET_EVIDENCE_FILE_DOWNLOAD_SUCCESS,
    payload,
  };
};

export const downloadEvidenceFileFailure = (payload) => {
  return {
    type: GET_EVIDENCE_FILE_DOWNLOAD_FAILURE,
    payload,
  };
};

export const downloadEvidenceFileReset = (payload) => {
  return {
    type: GET_EVIDENCE_FILE_DOWNLOAD_RESET,
    payload,
  };
};
