import React, { useEffect, useState } from "react";
import vectoricon from "src/assets/icons/vectoricon.png";
import { FaAngleLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setPageAidsById } from "src/store/actions/dform";
import { gethelpDeskContentStarted } from "src/store/actions/helpdesk";
import "./expandablecard.css";

let crispTest = "https://afpq.crisp.help/de/article/test-crisp-integration-with-afpq-portal-bz0ysb/";

const ExpandableCard = ({ children, item, page, aid, miniAids, helperText }) => {
  const [height, setHeight] = useState(240);
  const [expanded, setExpanded] = useState(false);
  const [article, setArticle] = useState(null);
  const helpdesk = useSelector((state) => state.helpdesk);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!helpdesk?.articleContent?.data[helperText?.url]) {
      if (helperText?.url) {
        dispatch(gethelpDeskContentStarted({ crisp_id: helperText?.url }));
      } else {
        dispatch(gethelpDeskContentStarted({ crisp_id: crispTest }));
      }
    }
  }, []);

  useEffect(() => {
    if (helpdesk?.articleContent?.data[helperText?.url]) {
      setArticle(helpdesk?.articleContent?.data[helperText?.url]);
    } else {
      setArticle(helpdesk?.articleContent?.data[crispTest]);
    }
  }, [helperText?.url, helpdesk?.articleContent]);

  const handleExpansion = () => {
    if (height === 240) {
      setHeight(400);
    } else {
      setHeight(240);
    }
    setExpanded((prevExpanded) => !prevExpanded);
  };
  const expandHandle = () => {
    dispatch(setPageAidsById({ page: page, aidId: aid }));
  };

  // Check if article.content exists and is a string before parsing
  const articleContent = article && typeof article === "string" ? article : '<CircularLoading css="min-h-[300px]" />';

  return (
    <>
      {miniAids ? (
        <div className="border border-AFPDisabled shadow-md hover:shadow-xl w-9 h-9 flex justify-center items-center rounded-full">
          <img src={vectoricon} alt="Icon" className="h-4 w-4 sm:h-5 sm:w-5 cursor-pointer" onClick={expandHandle} />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center gap-5 w-full">
            <div className="flex justify-start gap-5 items-center">
              <img src={vectoricon} alt="Icon" className="h-4 w-4 sm:h-5 sm:w-5" />
              <h2 className="text-black text-md lg:text-lg font-semibold">{item?.inputHint}</h2>
            </div>
            <span onClick={expandHandle} className="cursor-pointer">
              <FaAngleLeft />
            </span>
          </div>
          <div className="mt-4"></div>
          {helperText?.url && articleContent ? (
            <iframe srcDoc={articleContent} title="Embedded Content" height={height} style={{ border: "none" }}></iframe>
          ) : helperText?.value ? (
            <RenderContent helperText={helperText} height={height} />
          ) : (
            articleContent && <iframe srcDoc={articleContent} title="Embedded Content" height={height} style={{ border: "none" }}></iframe>
          )}
          {children}
          <button onClick={handleExpansion} className="mt-5 px-1 outline-none py-1 border w-[80px] self-center rounded-full border-AFPPrimary text-AFPPrimary border-solid">
            {!expanded ? "mehr" : "weniger"}
          </button>
        </>
      )}
    </>
  );
};
ExpandableCard.defaultProps = {
  item: {
    inputHint: "Ausfüllhilfe",
    helpText: "Auf jeder Seite finden Sie eine Ausfüllhilfe zum jeweiligen Schritt! Sie finden zusätzlich auch zu jedem einzelnen Kästchen eine Erklärung.",
  },
  children: null,
};
export default ExpandableCard;

export const RenderContent = ({ helperText, height }) => {
  if (helperText?.value) {
    if (isURL(helperText?.value)) {
      return <RenderEvidenceIframe url={helperText?.value} height={height} />;
    } else if (isHTML(helperText?.value)) {
      return <div dangerouslySetInnerHTML={{ __html: helperText?.value }}></div>;
    } else {
      return (
        <div>
          <div className="mt-4 ml-2 overflow-auto" style={{ height: height }}>
            <h2 dangerouslySetInnerHTML={{ __html: helperText?.value }} className="text-xl font-tahoma-bold" />
            <hr className="my-6" />
            <p dangerouslySetInnerHTML={{ __html: helperText?.value }} className="mt-4 text-sm leading-6" />
          </div>
        </div>
      );
    }
  }
};

export const RenderEvidenceIframe = ({ url, height }) => {
  const [article, setArticle] = useState(null);
  const helpdesk = useSelector((state) => state.helpdesk);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(gethelpDeskContentStarted({ crisp_id: url }));
  }, []);

  useEffect(() => {
    if (helpdesk?.articleContent?.data[url]) {
      setArticle(helpdesk?.articleContent?.data[url]);
    } else {
      setArticle(helpdesk?.articleContent?.data[crispTest]);
    }
  }, [url, helpdesk?.articleContent]);
  const articleContent = article && typeof article === "string" ? article : '<CircularLoading css="min-h-[300px]" />';
  return url && articleContent && <iframe srcDoc={articleContent} title="Embedded Content" height={height} style={{ border: "none" }}></iframe>;
};

export const isURL = (str) => {
  const pattern = /^(http:\/\/|https:\/\/)/;
  return pattern.test(str);
};

export const isHTML = (str) => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};
