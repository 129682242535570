import AccountHolderVerify from "../AccountHolderVerify";
import { useSelector } from "react-redux";
import CircularLoading from "src/components/atoms/loader/CircularLoading";

const VerifyCheckBoxWithAccount = ({ data, stepsCMS }) => {
  const accountList = useSelector((state) => state.settings.data);
  return (
    <div>
      {accountList ? (
        <AccountHolderVerify data={data} stepsCMS={stepsCMS} />
      ) : (
        <CircularLoading css="py-6" />
      )}
    </div>
  );
};

export default VerifyCheckBoxWithAccount;
