import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  FORGOT_USER_START,
  FORGOT_USER_SUCCESS,
  AUTH_USER_FAILURE,
  AUTH_USER_RESET,
  RESET_USER_START,
  RESET_USER_SUCCESS,
  CHANGE_PASSWORD_USER_START,
  CHANGE_PASSWORD_USER_SUCCESS,
  REGISTER_USER_START_MODIFY,
} from "../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  userLogin: false,
  userPassword: '',
  userRegistered: false,
  userForgot: false,
  userReset: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_START: {
      return { ...state, isLoading: true };
    }
    case LOGIN_USER_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload, userLogin:true };
    }

    case REGISTER_USER_START: {
      return { ...state, isLoading: true };
    }
    case REGISTER_USER_START_MODIFY: {
      return { ...state, isLoading: true };
    }
    case REGISTER_USER_SUCCESS: {
      return { ...state, isLoading: false, userRegistered:true, usercreated: {password: action.payload.password, email: action.payload.email} };
    }

    case FORGOT_USER_START: {
      return { ...state, isLoading: true };
    }
    case FORGOT_USER_SUCCESS: {
      return { ...state, isLoading: false, userForgot: true };
    }

    case RESET_USER_START: {
      return { ...state, isLoading: true };
    }
    case RESET_USER_SUCCESS: {
      return { ...state, isLoading: false, userReset: true };
    }

    case CHANGE_PASSWORD_USER_START: {
      return { ...state, isLoading: true };
    }
    case CHANGE_PASSWORD_USER_SUCCESS: {
      return { ...state, isLoading: false };
    }

    case AUTH_USER_RESET: {
      return { ...state, isLoading: false, userForgot: false, userRegistered: false, userReset: false };
    }
    case AUTH_USER_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
