import { getApiCall, postApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const fetchMessageList = async (paramsData) => {
  const url = `${BASE_URL}${API.MESSAGE_LIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const fetchMessageRead = async (payload) => {
  const url = `${BASE_URL}${API.READ_MESSAGE}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
