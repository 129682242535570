/* eslint-disable*/
import { useDispatch, useSelector } from "react-redux";
import NewCard from "./NewCard";
import vector6 from "src/assets/img/vector6.png";
import imagepreview from "src/assets/img/imagepreview.png";
import doc from "src/assets/img/doc.png";
import { formatDate } from "src/helpers/document";
import { removeUserFilesDocumentById, uploadDocumentStarted } from "src/store/actions/document";
import Cookies from "js-cookie";
import { BsEye } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import { API, BASE_URL } from "src/services/utils/defines";

function MainDocument({ document, id, location, isSearchEnable }) {
  const documents = useSelector((state) => state?.document);
  const documentCategory = documents?.documentCategory?.data
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const addDocument = (doc) => {
    const payload = {
      ...doc,
      contact_id: profile?.data?.owner_contact_id,
      creator_user: profile?.data?.first_name + " " + profile?.data?.last_name,
      location_id: id,
      date: new Date()
    }
    const formData = new FormData();
    formData.append("user_id", Cookies.get("userId"));
    formData.append("document", doc?.doc_url);
    dispatch(uploadDocumentStarted({...payload, file: formData,}));
  };

  const renderImage = (type) => {
    if (type == "PDF" || type == "pdf") {
      return vector6;
    } else if (type == "JPG" || type == "jpg" || type == "JPEG" || type == "jpeg" || type == "PNG" || type == "png") {
      return imagepreview;
    } else {
      return doc;
    }
  };

  const downloadNewHandler = (url) => {
    const link = window.document.createElement("a");
    link.href = `${BASE_URL}${API?.DOWNLOAD_DMS_DOCUMENT}?user_id=${Cookies.get("userId")}&document_id=${url}`;
    link.download = "document";
    link.style.display = "none";
    window.document.body.appendChild(link);
    window.open(link.href, "_blank");
    setTimeout(() => {
      window.document.body.removeChild(link);
    }, 100);
  };

  const removeDocumentById = (location_id, document_id) => {
    dispatch(removeUserFilesDocumentById({
      location_id:location_id,
      document_id:document_id
    }))
  }

  return (
    <div className="flex justify-center sm:justify-start">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-10 w-full">
        {documentCategory.map((category, index) => {
          const documentOne = document.find((doc) => doc?.category === category?.name);
          return documentOne ? (
            <div key={index} className="flex flex-col items-center sm:items-start pt-10 w-full">
              <div
                className={`flex flex-col w-48 h-56 rounded-xl border border-[#CBCBCB] shadow-md !bg-[#fff] duration-300`}
              >
                <img src={renderImage(documentOne.property_filetype)} className="mx-auto my-auto" />
                <div className="flex justify-end gap-3 pb-2 pr-1">
                  <BsEye className="flex-2 text-2xl hover:text-AFPPrimary cursor-pointer" onClick={(e) => {
                    e.preventDefault();
                    downloadNewHandler(documentOne?.id);
                    }} 
                  />
                  <ImBin className="flex-2 text-2xl hover:text-AFPPrimary cursor-pointer" onClick={()=>{removeDocumentById(id, documentOne?.id)}} />
                </div>  
              </div>
              <div className="pt-3 text-start">
                <p className="font-tahoma-bold">{documentOne?.category}</p>
                <p className="text-SmokeyGray">{formatDate(documentOne?.date)}</p>
                <p className="text-SmokeyGray">{documentOne?.docs}</p>
              </div>
            </div>
          ) : (
            !isSearchEnable && <NewCard key={index} category={category} handleOpen={addDocument} location={location} />
          );
        })}
      </div>
    </div>
  );
}

export default MainDocument;
