import { useRef } from "react";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa";
import { MdFlipCameraIos } from "react-icons/md";
import { HiCamera } from "react-icons/hi2";
import { RiCameraOffFill } from "react-icons/ri";

const CameraEvidenceUpload = ({ uploadedImage, setUploadedImage, showCamera, setShowCamera }) => {
  const webcamRef = useRef(null);

  const handleCapture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const binaryImage = await fetch(imageSrc)
        .then(res => res.blob())
      setUploadedImage(binaryImage);
      setShowCamera(false);
    }
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-full md:w-[90%]">
        {showCamera && (
          <div className="relative w-full aspect-w-16 aspect-h-9">
            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" style={{ display: uploadedImage ? "none" : "block", width: "100%" }} />
            <div className="flex justify-center items-center">
              <div className="mt-2 border border-AFPPrimary flex items-center justify-center px-3 py-1 rounded-md cursor-pointer">
                <HiCamera className="text-AFPPrimary text-xl mr-2" />
                <button className="text-AFPPrimary" onClick={handleCapture}>
                  Capture
                </button>
              </div>
            </div>
          </div>
        )}
        {!showCamera && !uploadedImage && (
          <div className="flex justify-center items-center ">
            <div className="border text-AFPPrimary border-AFPPrimary flex items-center justify-center px-3 py-1 rounded-md cursor-pointer">
              <FaCamera className="mr-2 text-xl" />
              <button className="text-AFPPrimary" onClick={() => setShowCamera(true)}>
                Kamera
              </button>
            </div>
          </div>
        )}
        {uploadedImage && (
          <div>
            <img src={URL.createObjectURL(new Blob([uploadedImage]))} alt="Uploaded" />
            <div className="flex justify-center gap-4 mt-2">
              <div className="border border-AFPPrimary flex items-center justify-center px-3 py-1 rounded-md cursor-pointer">
                <MdFlipCameraIos className="text-AFPPrimary mr-2" />
                <button
                  className="text-AFPPrimary"
                  onClick={() => {
                    setUploadedImage(null);
                    setShowCamera(true);
                  }}
                >
                  Re-Take
                </button>
              </div>
              <div className="border border-AFPPrimary flex items-center justify-center px-3 py-1 rounded-md cursor-pointer">
                <RiCameraOffFill className="text-AFPPrimary mr-2" />
                <button
                  className="text-AFPPrimary"
                  onClick={() => {
                    setUploadedImage(null);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CameraEvidenceUpload;