import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const CircularLoading = ({ css }) => {
  return (
    <div className={`flex justify-center items-center ${css}`}>
      <CustomCircularLoader />
    </div>
  );
};

export default CircularLoading;
