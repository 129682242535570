/* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { addApplicationStepStarted, createCompleteApplicationStarted } from "src/store/actions/application";
import { useEffect, useState } from "react";
import StepFooterButtons from "src/components/molecules/dform/StepFooterButtons";
import StepsForm from "src/components/molecules/dform/StepsForm";
import { ServiceOrderPayloadModify } from "src/helpers/dform";
import { FormStepValidation, signatureValid, umzugAddressValid } from "src/helpers/dform/validateForm";
import Cookies from "js-cookie";

const DynamicFormSteps = ({ activeStep, stepChangeHandle, id, step_id }) => {
  const application = useSelector((state) => state.application);
  const currentStep = useSelector((state) => state.currentStep);
  const { data, addingStep } = application;
  const [closeForm, setCloseForm] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));

  /** Speichern & Beenden button handling */
  const saveHandle = () => {
    setCloseForm(true);
    saveFormData(false);
  };

  /** saving form data in DB */
  const saveFormData = (addNewService) => {
    dispatch(
      addApplicationStepStarted({
        procedure_data: [{ ...data, [currentStep.stepID]: currentStep.data }],
        step_id: step_id,
        service_order_types_id: id,
        procedure_form_id: Cookies.get("submitted_id"),
        addNewService,
      })
    );
  };

  const prevStepHandle = () => {
    if (!addingStep.isLoading) {
      activeStep !== 0 ? stepChangeHandle(activeStep - 1) : navigate("/verfahren/uebersicht");
    }
  };

  useEffect(() => {
    let findCheck = FormStepValidation(currentStep?.data);

    if (findCheck?.length > 0) {
      setIsDisable(true);
    } else {
      const isFirmaStep = currentStep?.stepID === "02_FIRMA";
      if (isFirmaStep) {
        if (application?.locationValid?.data?.is_valid) {
          setIsDisable(false);
        } else {
          setIsDisable(true);
        }
      } else {
        setIsDisable(false);
      }
    }
  }, [currentStep?.data, application?.locationValid?.data?.is_valid]);

  const nextStepHandle = () => {
    /** check only umzug new address validation */
    if (id == "UA" && activeStep == 2) {
      let locationfindCheck = umzugAddressValid(currentStep);
      if (locationfindCheck?.length > 0) {
        toast.error("Bitte geben Sie die neue Adresse ein");
        return;
      }
    }
    /** if this aa, iwa, ka, ua form in 2 step and not valid location move formward prevent */
    if (id == "AA" || id == "IWA" || id == "KA" || id == "UA" || id == "SA" || id == "EPQ" || id == "REPQ") {
      const serviceAreaDetail = JSON.parse(localStorage.getItem("locationValidation"));
      if (activeStep == 1) {
        if (!serviceAreaDetail?.is_valid) {
          toast.error("gemäß unserer AGBs ist aktuell keine Kündigung möglich. Dies ist nur innerhalb von 6 Wochen nach einem Verfahren möglich");
          return;
        }
      }
    }
    /** Form Step Validation */
    let findCheck = FormStepValidation(currentStep?.data);
    if (findCheck?.length > 0) {
      toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
    } else {
      if (!addingStep.isLoading) {
        if (activeStep === 0) {
          if (currentStep?.data[0]?.inputTypeValue === true) {
            saveFormData(false);
          } else {
            toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
          }
        } else {
          saveFormData(false);
        }
      }
    }
  };

  useEffect(() => {
    if (closeForm) {
      if (addingStep.isLoading === false && addingStep.status === true) {
        setTimeout(() => {
          navigate("/verfahren/uebersicht");
        }, 400);
      }
    }
    if (addingStep.isLoading === false && addingStep.status === true) {
      if (!closeForm) {
        stepChangeHandle(activeStep + 1);
      }
    }
  }, [addingStep]);

  /** saving dynamic form details in erp */
  const createServiceOrderSubmit = () => {
    let findCheck = signatureValid(currentStep?.data);
    if (findCheck?.length > 0) {
      toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
    } else {
      let ownerFind = currentStep?.data.find((stepvalue) => stepvalue.id === "05_SIGNATURE_OWNER");
      let owner_id = id == "EPQ" ? (ownerFind ? ownerFind.inputTypeValue : "") : locationValidation?.existing_owner_id;
      if (id == "IWA") {
        dispatch(
          createCompleteApplicationStarted({
            ...ServiceOrderPayloadModify({
              procedure_data: [{ ...application.data, [currentStep.stepID]: currentStep.data }],
              step_id: step_id,
              service_order_types_id: id,
              procedure_form_id: Cookies.get("submitted_id"),
              owner_id,
            }),
            existing_owner_id: locationValidation?.existing_owner_id,
          })
        );
      } else {
        dispatch(
          createCompleteApplicationStarted(
            ServiceOrderPayloadModify({
              procedure_data: [{ ...application.data, [currentStep.stepID]: currentStep.data }],
              step_id: step_id,
              service_order_types_id: id,
              procedure_form_id: Cookies.get("submitted_id"),
              owner_id,
            })
          )
        );
      }
    }
  };

  return (
    <>
      <div className="pl-4 md:pl-8 lg:pl-16 pr-2 md:pr-3 lg:pr-5">
        <div className="relative md:pt-[4.5rem] py-4 mx-3 sm:mx-4 md:mx-8">
          <div className="text-black w-full">
            <StepsForm
              activeStep={activeStep}
              data={data}
              step_id={step_id}
              stepID={currentStep.stepID}
              createServiceOrderSubmit={createServiceOrderSubmit}
              saveFormData={saveFormData}
            />
            <StepFooterButtons activeStep={activeStep} saveHandle={saveHandle} prevStepHandle={prevStepHandle} nextStepHandle={nextStepHandle} disable={isDisable} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicFormSteps;
