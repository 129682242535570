/*eslint-disable */
import UserTableBody from "src/components/pages/settings/Management/UserTableBody";
import { useSelector } from "react-redux";
import { TableContainer, Table, Paper } from "@mui/material";
import TableHeader from "src/components/atoms/common/TableHeader";
import useSortableData from "src/hooks/useSortableData";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
const standardTableCss = {
  tableContainer: {},
  table: {},
  Head: {
    TableHead: {},
    HeadTableRow: {
      th: {},
    },
  },
  Body: {
    TableBody: {},
    TableBodyRow: {},
  },
};
const BusinessList = ({ headList, bodyList, userRemove }) => {
  
  const getAllLocation = JSON.parse(localStorage.getItem("getAllLocation"));
  
  const locationMap = getAllLocation?.reduce((map, location) => {
    map[location?.location_id] = location?.name;
    return map;
  }, {});
  
  const LocationBodyList = bodyList.map((user) => {
    let locationNames='Unbekannt';
    if (Array?.isArray(user?.location_id)) {
      locationNames = locationMap[user?.location_id[0]] || locationNames;
    }
    return { ...user, b_location: locationNames };
  });

  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useSortableData(LocationBodyList);
  const settings = useSelector((state) => state?.settings);
  return (
    <>
      <div className="mb-7 w-full">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHeader tableData={headList} increaseSort={requestSortIncrease} decreaseSort={requestSortDecrease} />
            {sortedData?.length > 0 && <UserTableBody bodyList={sortedData} standardBody={standardTableCss?.Body} userRemove={userRemove} />}
          </Table>
          {settings?.user?.isLoading && (
            <div className="w-full flex justify-center items-center my-6">
              <CustomCircularLoader />
            </div>
          )}
          {sortedData?.length <= 0 && !settings?.user?.isLoading && <h4 className="text-red-400 text-center my-6 flex justify-center items-center gap-2">Keine Daten gefunden</h4>}
        </TableContainer>
      </div>
    </>
  );
};
export default BusinessList;
