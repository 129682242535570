export const userDocumentTableHeaderCMS = (name, category, date) => {
  return [
    {
      id: 1,
      title: name,
      icons: true,
      sortKey: "name",
    },
    {
      id: 2,
      title: category,
      icons: true,
      sortKey: "category",
    },
    {
      id: 3,
      title: date,
      icons: true,
      sortKey: "date",
    },

    {
      id: 4,
      title: "",
      icons: false,
    },
  ];
};
