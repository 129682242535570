import { Navigate } from "react-router-dom";
import CookieStorage from "../storage/cookiesStorage";

export const routeController = (component, protectedRoute = false) => {
  const cookie = new CookieStorage();
  if (protectedRoute) {
    if (cookie.getLogin()) {
      return component;
    } else {
      return <Navigate to="/login" replace />;
    }
  } else {
    if (cookie.getLogin()) {
      return <Navigate to="/" replace />;
    } else {
      return component;
    }
  }
};
