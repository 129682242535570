/* eslint-disable*/
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NewFormTemplate from "src/components/molecules/dform/NewFormTemplate";
import { currentDatePreview } from "src/helpers/document";
import { artDes } from "src/mockData/ImageHtml";

const ImageHtmlFinished = ({ data }) => {
  const profile = useSelector((state) => state.profile);
  const { id } = useParams();
  // const today = new Date();
  // const day = today.getDate();
  // const month = today.getMonth() + 1; // Adding 1 because months are zero-indexed
  // const year = today.getFullYear();
  const form_id_name = localStorage?.getItem("form_id_name") && JSON?.parse(localStorage?.getItem("form_id_name"));
  const dform_name = form_id_name && form_id_name.find((name) => id == name.id);
  return (
    <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen">
      <div className="">
        <div className="py-4 px-8 border border-gray-800">
          <div className="flex md:flex-row flex-col justify-between">
            <div>
              <div className="md:m-16">
                <p>Afp Agentur fur Praqualifizierung</p>
                <p>Carl-Mannich-StaBe 26</p>
                <p>65760 Eschborn</p>
              </div>
            </div>
            <div className="md:mt-10 mt-5 w-44">
              {/* <h2 className="font-bold mb-3">{profile?.data?.company_name}</h2> */}
              <h4 className="mt-3">{currentDatePreview()}</h4>
            </div>
          </div>
          <div className="font-tahoma-bold border bg-gray-100 border-gray-200 p-4 mt-8">
            <h4>1. Antrag auf {dform_name?.description}</h4>
          </div>
          <div class="grid md:grid-cols-[20%,80%] grid-cols-[100%] mt-8">
            {artDes(".").map((item, index) => {
              return (
                <Fragment key={index}>
                  <p class="bg-gray-100 py-1 px-4 border border-gray-200 md:text-base text-xs w-full">{item.key}</p>
                  <p class="py-1 px-4 border border-gray-200 md:text-base text-xs">{item.value}</p>
                </Fragment>
              );
            })}
          </div>
          <div>
            {data &&
              data.map(
                (item, index) =>
                  index < 4 && (
                    <Fragment key={index} className="w-full">
                      {item && index === 0 ? (
                        <table className="w-full border-black">
                          {item.map((detail, i) => (
                            <NewFormTemplate data={detail} key={i} />
                          ))}
                        </table>
                      ) : index === 3 ? (
                        <table key={index} className="w-full border border-black mt-5">
                          <div className="border-b py-2 pl-2 border-black">
                            <h2 className="font-tahoma-bold">Richtigkeit Ihrer Angaben</h2>
                          </div>
                          {item.map((detail, i) => (
                            <NewFormTemplate data={detail} key={i} />
                          ))}
                        </table>
                      ) : index === 1 ? (
                        item.map((detail, i) => (
                          <table key={index} className="w-full border-black">
                            <NewFormTemplate data={detail} key={i} />
                          </table>
                        ))
                      ) : (
                        item.map((detail, i) => (
                          <table key={index} className="w-full border-black">
                            <NewFormTemplate data={detail} key={i} />
                          </table>
                        ))
                      )}
                    </Fragment>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageHtmlFinished;
