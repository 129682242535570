import { GET_RECRUIT_CLIENT_FAILURE, GET_RECRUIT_CLIENT_STARTED, GET_RECRUIT_CLIENT_SUCCESS } from "../actionTypes";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECRUIT_CLIENT_STARTED: {
      return { ...state, isLoading: true, error: null };
    }
    case GET_RECRUIT_CLIENT_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload, error: null };
    }
    case GET_RECRUIT_CLIENT_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
