import { BsFillQuestionCircleFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import SchoolIcon from "@mui/icons-material/School";

export const HELPSERVICESLIST = [
  {
    id: 1,
    cmsId: "Knowledge",
    icon: <SchoolIcon className="text-AFPPrimary !text-5xl" />,
  },
  {
    id: 2,
    cmsId: "faq",
    icon: <BsFillQuestionCircleFill className="text-AFPPrimary !text-5xl" />,
  },
  {
    id: 3,
    cmsId: "contact",
    icon: <MdEmail className="text-AFPPrimary !text-5xl" />,
  },
];

export const HELPTABLEHEAD = (created, news, status, date) => {
  return [
    {
      id: 1,
      title: created,
      key: ["title", "subtitle"],
      align: "left",
    },
    {
      id: 2,
      title: news,
      key: ["news"],
      align: "center",
    },
    {
      id: 3,
      title: status,
      key: ["status"],
      align: "center",
    },
    {
      id: 4,
      title: date,
      key: ["date"],
      align: "center",
    },
  ];
};

export const helpGroupButton = [
  {
    id: 1,
    cmsId: "all",
    title: "Alle",
  },
  {
    id: 2,
    cmsId: "open",
    title: "Offen",
  },
  {
    id: 3,
    cmsId: "completed",
    title: "Abgeschlossen",
  },
];
