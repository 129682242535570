/* eslint-disable */
import { put, call } from "redux-saga/effects";
import { fetchProfileFailure, fetchProfileSuccess } from "src/store/actions/settings";
import { getProfile, putProfile } from "../requests/settings";
import toast from "react-hot-toast";
import CookieStorage from "src/services/storage/cookiesStorage";
import Cookies from "js-cookie";

export function* fetchProfile() {
  const cookie = new CookieStorage();
  try {
    const paramsData = {
      queryParams: {
        id: Cookies.get("userId"),
      },
      isAuthorized: true,
    }
    const data = yield call(() => {return getProfile(paramsData)});
    yield put(fetchProfileSuccess(data));
  } catch (error) {
    yield put(fetchProfileFailure(error));
  }
}

export function* updateProfile(action) {
  try {
    const data = yield call(() => {
      return putProfile({ ...action.payload });
    });
    toast.success(data?.message);
    yield put(fetchProfileSuccess(data));
  } catch (error) {
    yield put(fetchProfileFailure(error));
  }
}
