import { MESSAGE_LIST, SET_MESSAGE_LIST, MESSAGE_LIST_FAILURE, MESSAGE_READ, MESSAGE_READ_FAILURE, MESSAGE_READ_SUCCESS } from "../actionTypes";

export const messageList = () => {
  return {
    type: MESSAGE_LIST,
  };
};

export const setMessageList = (payload) => {
  return {
    type: SET_MESSAGE_LIST,
    payload,
  };
};
export const messageListFailure = (payload) => {
  return {
    type: MESSAGE_LIST_FAILURE,
    payload,
  };
};

export const messageRead = (payload) => {
  return {
    type: MESSAGE_READ,
    payload
  };
};

export const MessageReadSuccss = (payload) => {
  return {
    type: MESSAGE_READ_SUCCESS,
    payload,
  };
};
export const messageReadFailure = (payload) => {
  return {
    type: MESSAGE_READ_FAILURE,
    payload,
  };
};
