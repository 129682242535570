import { AiFillEdit } from "react-icons/ai";
import { useSelector } from "react-redux";

const EditIconCMS = ({ handleOpen, css }) => {
  const profile = useSelector((state) => state.profile);
  return (
    profile?.data?.role === "admin" && (
      <span className="w-[20px] h-auto">
        <AiFillEdit onClick={handleOpen} size={16} className={`text-SmokeyGray cursor-pointer hover:text-SeaGreen ${css}`} />
      </span>
    )
  );
};

EditIconCMS.defaultProps = {
  handleOpen: () => {},
  css: "",
};

export default EditIconCMS;
