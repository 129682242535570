import {
  ADD_EVIDENCE,
  ASIGNATURE_UPLOAD_FAILURE,
  ASIGNATURE_UPLOAD_SUCCESS,
  CLEAR_EVIDENCES,
  DELETE_SINGLE_EVIDENCE_FAILURE,
  DELETE_SINGLE_EVIDENCE_STARTED,
  DELETE_SINGLE_EVIDENCE_SUCCESS,
  GET_EVIDENCE_DB_FAILURE,
  GET_EVIDENCE_DB_SO_ID_STARTED,
  GET_EVIDENCE_DB_STARTED,
  GET_EVIDENCE_DB_SUCCESS,
  NEW_SERVICE_ORDER_EVIDENCE_FAILURE,
  NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED,
  NEW_SERVICE_ORDER_EVIDENCE_STARTED,
  NEW_SERVICE_ORDER_EVIDENCE_SUCCESS,
  REMOVE_EVIDENCE,
  SAVE_EVIDENCE_DB_FAILURE,
  SAVE_EVIDENCE_DB_STARTED,
  SAVE_EVIDENCE_DB_SUCCESS,
  SAVE_EVIDENCE_ERP_FAILURE,
  SAVE_EVIDENCE_ERP_STARTED,
  SAVE_EVIDENCE_ERP_SUCCESS,
  SET_EVIDENCE_BY_ID,
  SIGNATURE_UPLOAD_STARTED,
  UPLOAD_EVIDENCE_FAILURE,
  UPLOAD_EVIDENCE_STARTED,
  UPLOAD_EVIDENCE_SUCCESS,
  UPLOAD_SERVER_ORDER_EVIDENCE_FAILURE,
  UPLOAD_SERVER_ORDER_EVIDENCE_STARTED,
  UPLOAD_SERVER_ORDER_EVIDENCE_SUCCESS,
  UPLOAD_SERVICE_ORDER_EVIDENCE_FAILURE,
  UPLOAD_SERVICE_ORDER_EVIDENCE_RESET,
  UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED,
  UPLOAD_SERVICE_ORDER_EVIDENCE_SUCCESS,
} from "../actionTypes";

export const newServiceOrderEvidenceStarted = (payload) => {
  return {
    type: NEW_SERVICE_ORDER_EVIDENCE_STARTED,
    payload,
  };
};
export const newServiceOrderEvidencePendingStarted = (payload) => {
  return {
    type: NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED,
    payload,
  };
};

export const newServiceOrderEvidenceSuccess = (payload) => {
  return {
    type: NEW_SERVICE_ORDER_EVIDENCE_SUCCESS,
    payload,
  };
};
export const newServiceOrderEvidenceFailure = (payload) => {
  return {
    type: NEW_SERVICE_ORDER_EVIDENCE_FAILURE,
    payload,
  };
};

/**  user who got the email for signature  */
export const signatureUploadStarted = (payload) => {
  return {
    type: SIGNATURE_UPLOAD_STARTED,
    payload,
  };
};

export const signatureUploadSuccess = (payload) => {
  return {
    type: ASIGNATURE_UPLOAD_SUCCESS,
    payload,
  };
};
export const signatureUploadFailure = (payload) => {
  return {
    type: ASIGNATURE_UPLOAD_FAILURE,
    payload,
  };
};
/**  upload files(image, pdf, doc)  */
export const uploadEvidenceStarted = (payload) => {
  return {
    type: UPLOAD_EVIDENCE_STARTED,
    payload,
  };
};

export const uploadEvidenceSuccess = (payload) => {
  return {
    type: UPLOAD_EVIDENCE_SUCCESS,
    payload,
  };
};
export const uploadEvidenceFailure = (payload) => {
  return {
    type: UPLOAD_EVIDENCE_FAILURE,
    payload,
  };
};
/**  upload files(image, pdf, doc) to erp */
export const uploadServiceOrderEvidenceStarted = (payload) => {
  return {
    type: UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED,
    payload,
  };
};

export const uploadServiceOrderEvidenceSuccess = (payload) => {
  return {
    type: UPLOAD_SERVICE_ORDER_EVIDENCE_SUCCESS,
    payload,
  };
};
export const uploadServiceOrderEvidenceFailure = (payload) => {
  return {
    type: UPLOAD_SERVICE_ORDER_EVIDENCE_FAILURE,
    payload,
  };
};
export const uploadServiceOrderEvidenceReset = (payload) => {
  return {
    type: UPLOAD_SERVICE_ORDER_EVIDENCE_RESET,
    payload,
  };
};
export const uploadServerOrderEvidenceStarted = (payload) => {
  return {
    type: UPLOAD_SERVER_ORDER_EVIDENCE_STARTED,
    payload,
  };
};

export const uploadServerOrderEvidenceSuccess = (payload) => {
  return {
    type: UPLOAD_SERVER_ORDER_EVIDENCE_SUCCESS,
    payload,
  };
};
export const uploadServerOrderEvidenceFailure = (payload) => {
  return {
    type: UPLOAD_SERVER_ORDER_EVIDENCE_FAILURE,
    payload,
  };
};
/**  save evidence to db  */
export const saveEvidenceDBStarted = (payload) => {
  return {
    type: SAVE_EVIDENCE_DB_STARTED,
    payload,
  };
};

export const saveEvidenceDBSuccess = (payload) => {
  return {
    type: SAVE_EVIDENCE_DB_SUCCESS,
    payload,
  };
};
export const saveEvidenceDBFailure = (payload) => {
  return {
    type: SAVE_EVIDENCE_DB_FAILURE,
    payload,
  };
};
/**  save evidence to erp  */
export const saveEvidenceERPStarted = (payload) => {
  return {
    type: SAVE_EVIDENCE_ERP_STARTED,
    payload,
  };
};

export const saveEvidenceERPSuccess = (payload) => {
  return {
    type: SAVE_EVIDENCE_ERP_SUCCESS,
    payload,
  };
};
export const saveEvidenceERPFailure = (payload) => {
  return {
    type: SAVE_EVIDENCE_ERP_FAILURE,
    payload,
  };
};
/**  get evidence to db  */
export const getEvidenceDBStarted = (payload) => {
  return {
    type: GET_EVIDENCE_DB_STARTED,
    payload,
  };
};
export const getEvidenceDBBySOidStarted = (payload) => {
  return {
    type: GET_EVIDENCE_DB_SO_ID_STARTED,
    payload,
  };
};

export const getEvidenceDBSuccess = (payload) => {
  return {
    type: GET_EVIDENCE_DB_SUCCESS,
    payload,
  };
};
export const getEvidenceDBFailure = (payload) => {
  return {
    type: GET_EVIDENCE_DB_FAILURE,
    payload,
  };
};
export const setEvidenceById = (payload) => {
  return {
    type: SET_EVIDENCE_BY_ID,
    payload,
  };
};

export const addEvidence = (key, evidence) => ({ type: ADD_EVIDENCE, payload: { key, evidence } });
export const removeEvidence = (key, evidenceId) => ({ type: REMOVE_EVIDENCE, payload: { key, evidenceId } });
export const clearEvidence = (key) => ({ type: CLEAR_EVIDENCES, payload: key });

export const deleteUploadedEvidenceStarted = (payload) => {
  return {
    type: DELETE_SINGLE_EVIDENCE_STARTED,
    payload,
  };
};
export const deleteUploadedEvidenceSuccess = (payload) => {
  return {
    type: DELETE_SINGLE_EVIDENCE_SUCCESS,
    payload,
  };
};
export const deleteUploadedEvidenceFailure = (payload) => {
  return {
    type: DELETE_SINGLE_EVIDENCE_FAILURE,
    payload,
  };
};
