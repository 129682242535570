// import { DOCUMENTLISTS } from "src/mockData/document";
/* eslint-disable */
import {
  LIST_FROM_TO_YOU_STARTED,
  LIST_FROM_TO_YOU_SUCCESS,
  DOCUMENT_LIST_FAILURE,
  LIST_USER_FILES_FAILURE,
  LIST_USER_FILES_SUCCESS,
  LIST_USER_FILES_STARTED,
  LIST_DOCUMENT_CATEGORY_STARTED,
  LIST_DOCUMENT_CATEGORY_SUCCESS,
  LIST_DOCUMENT_CATEGORY_FAILURE,
  UPLOAD_DOCUMENT_STARTED,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  UPLOAD_DMS_DOCUMENT_STARTED,
  UPLOAD_DMS_DOCUMENT_SUCCESS,
  UPLOAD_DMS_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_BASE64_STARTED,
  UPLOAD_DOCUMENT_BASE64_SUCCESS,
  UPLOAD_DOCUMENT_BASE64_FAILURE,
  REMOVE_USER_FILES_DOCUMENT_BY_ID,
} from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  newUser: null,
  detail: null,
  fromToYouList: {data: null, isLoading: false, error: null, loadmoreButton: true},
  document: {
    // updateBusiness: null,
    // documentList: DOCUMENTLISTS,
  },
  selfDocuments: {
    data: null,
    isLoading: false,
    error: null,
  },
  documentCategory: {
    data: null,
    isLoading: false,
    error: null,
  },
  documentUpload: {
    data: null,
    isLoading: false,
    error: null,
  },
  dmsDocumentUpload: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    // case ADD_DOCUMENT_BY_ID: {
    //   let documents = state.document.documentList.map((doc) => {
    //     if (action.payload.doc_id == doc.id) {
    //       let docs = doc.documents.find((doc) => doc.category === action.payload.category);
    //       if (docs) {
    //         let docfilter = doc.documents.filter((doc) => doc.category !== docs.category);
    //         let { doc_id, ...rest } = action.payload; // eslint-disable-line no-unused-vars
    //         return { ...doc, documents: [...docfilter, rest] };
    //       } else {
    //         let { doc_id, ...rest } = action.payload; // eslint-disable-line no-unused-vars
    //         return { ...doc, documents: [...doc.documents, rest] };
    //       }
    //     } else {
    //       return doc;
    //     }
    //   });
    //   return { ...state, document: { documentList: documents }, isLoading: true };
    // }
    case LIST_FROM_TO_YOU_STARTED: {
      return { ...state, fromToYouList: {...state?.fromToYouList, isLoading: true, error: null, loadmoreButton: false} };
    }
    case LIST_FROM_TO_YOU_SUCCESS: {
      if(action.payload.page > 1){
        if(action.payload.data?.length>0){
          let newData =  [...state?.fromToYouList?.data, ...action?.payload?.data]
          return { ...state, fromToYouList: {...state?.fromToYouList, data: newData, isLoading: false, error: null, loadmoreButton: true} };
        } else {
          return { ...state, fromToYouList: {...state?.fromToYouList, data: state?.fromToYouList?.data, isLoading: false, error: null, loadmoreButton: false} };
        }
      } else {
        return { ...state, fromToYouList: {...state?.fromToYouList, data: action.payload.data, isLoading: false, error: null, loadmoreButton: true} };
      }
    }
    case DOCUMENT_LIST_FAILURE: {
      return { ...state, fromToYouList: {...state?.fromToYouList, data: null, isLoading: false, error: null, loadmoreButton: false} };
    }
    case LIST_USER_FILES_STARTED: {
      return {
        ...state,
        selfDocuments: {
          ...state.selfDocuments,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case LIST_USER_FILES_SUCCESS: {
      return {
        ...state,
        selfDocuments: {
          ...state.selfDocuments,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case LIST_USER_FILES_FAILURE: {
      return {
        ...state,
        selfDocuments: {
          ...state.selfDocuments,
          data: null,
          isLoading: false,
          error: true,
        },
      };
    }
    case REMOVE_USER_FILES_DOCUMENT_BY_ID: {
      const location_id = action.payload.location_id;
      const document_id = action.payload.document_id;
      const selfDocuments = state?.selfDocuments?.data;

      const filteredDocuments = selfDocuments.map((item) => {
        let filterItem = item;
        if (filterItem?.location_id === location_id) {
          const filterDocumentList = filterItem.documents;
          const matchDocumentId = filterDocumentList.some((doc) => doc.id === document_id);
          if (matchDocumentId) {
            const filterDocuments = filterDocumentList.filter((document) => document?.id !== document_id);
            return { ...filterItem, documents: filterDocuments };
          } else {
            return filterItem;
          }
        } else {
          return filterItem;
        }
      });

      return {
        ...state,
        selfDocuments: {
          ...state.selfDocuments,
          data: filteredDocuments,
        },
      };
    }
    case LIST_DOCUMENT_CATEGORY_STARTED: {
      return {
        ...state,
        documentCategory: {
          ...state.documentCategory,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case LIST_DOCUMENT_CATEGORY_SUCCESS: {
      return {
        ...state,
        documentCategory: {
          ...state.documentCategory,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case LIST_DOCUMENT_CATEGORY_FAILURE: {
      return {
        ...state,
        documentCategory: {
          ...state.documentCategory,
          data: null,
          isLoading: false,
          error: true,
        },
      };
    }
    case UPLOAD_DOCUMENT_STARTED: {
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case UPLOAD_DOCUMENT_FAILURE: {
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          data: null,
          isLoading: false,
          error: true,
        },
      };
    }
    case UPLOAD_DOCUMENT_BASE64_STARTED: {
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case UPLOAD_DOCUMENT_BASE64_SUCCESS: {
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case UPLOAD_DOCUMENT_BASE64_FAILURE: {
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          data: null,
          isLoading: false,
          error: true,
        },
      };
    }
    case UPLOAD_DMS_DOCUMENT_STARTED: {
      return {
        ...state,
        dmsDocumentUpload: {
          ...state.dmsDocumentUpload,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case UPLOAD_DMS_DOCUMENT_SUCCESS: {
      const location_id = action.payload.location_id;
      const document_id = action.payload.data;
      const selfDocuments = state?.selfDocuments?.data;
      const { category, file_name } = action.payload;

      const filteredDocuments = selfDocuments.map((item) => {
        let filterItem = item;
        if (filterItem?.location_id === location_id) {
          const filterDocumentList = filterItem.documents;
          const documentDetail = {
            id: document_id,
            icon: "vector6",
            category,
            date: action.payload.date,
            property_category: category,
            property_filename: file_name,
            property_filetype: file_name?.split(".")[file_name?.split(".")?.length - 1],
            doc_url: "/dms/r/2110e383-dce0-440c-b1e5-2db3f79ce5be/o2/" + document_id
          };
          return { ...filterItem, documents: [...filterDocumentList, documentDetail] };
        } else {
          return filterItem;
        }
      });

      return {
        ...state,
        dmsDocumentUpload: {
          ...state.dmsDocumentUpload,
          data: action?.payload?.data,
          isLoading: false,
          error: null,
        },
        selfDocuments: {
          ...state.selfDocuments,
          data: filteredDocuments,
        },
      };
    }
    case UPLOAD_DMS_DOCUMENT_FAILURE: {
      return {
        ...state,
        dmsDocumentUpload: {
          ...state.dmsDocumentUpload,
          data: null,
          isLoading: false,
          error: true,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
