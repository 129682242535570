/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { procedureBreadcrumbs } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import ProcedureTab from "src/components/molecules/tabs/ProcedureTab";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { applicationTabs } from "src/mockData/settingMock";

const ApplicationNavigator = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;
  const { id } = useParams()
  const title = applicationTabs().tabs.find((tab) => tab.uuid == id);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-10 py-8 min-h-screen bg-AFPBGPrimary">
        {sidebar_menu?.sidebar_list?.applications && <Breadcrumbs data={procedureBreadcrumbs(sidebar_menu?.sidebar_list?.applications, title?.label)} />}
        {cmsDashboarddata.listLoaded && (
          <>
            <ProcedureTab tabList={applicationTabs(cmsDashboarddata.document.tab)} />
          </>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export default ApplicationNavigator;
