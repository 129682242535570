import ContentLayout from "src/components/atoms/common/ContentLayout";
import Dashboard from "src/components/organisms/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { useEffect } from "react";
import { getDashboardDataStarted, getDashboardMessageStarted } from "src/store/actions/dashboard";
import CircularLoader from "src/components/atoms/loader/CircularLoader";

const Home = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dashboard = useSelector((state) => state.dashboard);
  const { isModalOpen, modalData } = cmsDashboarddata;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardMessageStarted());
    dispatch(getDashboardDataStarted());
  }, []);

  return (
    <>
      <ContentLayout>
        <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen-minus-3 bg-AFPBGPrimary">
          {cmsDashboarddata.listLoaded && !dashboard.isLoading ? <Dashboard /> : <CircularLoader css="h-[90vh]" />}
        </div>
        {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
      </ContentLayout>
    </>
  );
};

export default Home;
