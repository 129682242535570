const StandardSearch = ({ value, handleSearch, placeholder, css = ".7rem" }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`absolute top-0 bottom-0 mt-[${css}] w-[1.4rem] my-auto  text-SmokeyGray left-3`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
      </svg>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleSearch}
        className="w-full py-2 pl-10 pr-4 text-SmokeyGray border  rounded-full outline-none bg-gray-50 focus:bg-white border-gray-400"
      />
    </>
  );
};

StandardSearch.defaultProps = {
  value: "",
  placeholder: "Suchen",
  handleSearch: () => {},
};

export default StandardSearch;
