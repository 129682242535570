import {
  ADD_BUSINESS_PREMISES,
  ADD_LEADER_BY_LOCATION_FAILURE,
  ADD_LEADER_BY_LOCATION_STARTED,
  ADD_LEADER_BY_LOCATION_SUCCESS,
  ADD_OWNER_BY_LOCATION_FAILURE,
  ADD_OWNER_BY_LOCATION_STARTED,
  ADD_OWNER_BY_LOCATION_SUCCESS,
  BUSINESS_PREMISES_FAILURE,
  CLEAR_CONTACT_LIST,
  CREATED_BUSINESS_PREMISES,
  CREATED_RESET_BUSINESS_PREMISES,
  DELETE_BUSINESS_PREMISES,
  DETAIL_BUSINESS_PREMISES,
  DETAIL_SET_BUSINESS_PREMISES,
  FAILURE_PROFESSIONAL_GROUP,
  GET_LEADER_BY_LOCATION_FAILURE,
  GET_LEADER_BY_LOCATION_STARTED,
  GET_LEADER_BY_LOCATION_SUCCESS,
  GET_PROFESSIONAL_GROUP,
  LIST_BUSINESS_PREMISES,
  LIST_CONTACT,
  LIST_UPDATE_BUSINESS_PREMISES,
  OWNER_BY_LOCATION_FAILURE,
  OWNER_BY_LOCATION_STARTED,
  OWNER_BY_LOCATION_SUCCESS,
  SET_CONTACT_LIST,
  SET_PROFESSIONAL_GROUP,
  UPDATE_BUSINESS_PREMISES,
  UPDATE_BUSINESS_PREMISES_SUCCESS,
  SET_CHECK_NEW_LEADER,
  SET_CHECK_NEW_LOCATION,
  GET_ALL_BUSINESS_STARTED,
  GET_ALL_BUSINESS_SUCCESS,
  GET_ALL_BUSINESS_FAILURE,
} from "../actionTypes";

export const createdBusinessPremises = () => {
  return {
    type: CREATED_BUSINESS_PREMISES,
  };
};

export const createdResetBusinessPremises = (payload) => {
  return {
    type: CREATED_RESET_BUSINESS_PREMISES,
    payload,
  };
};

export const addBusinessPremises = (payload) => {
  return {
    type: ADD_BUSINESS_PREMISES,
    payload,
  };
};

export const listBusinessPremises = (payload) => {
  return {
    type: LIST_BUSINESS_PREMISES,
    payload,
  };
};

export const listInitialBusinessPremises = (payload) => {
  return {
    type: LIST_UPDATE_BUSINESS_PREMISES,
    payload,
  };
};
export const detailBusinessPremises = (payload) => {
  return {
    type: DETAIL_BUSINESS_PREMISES,
    payload,
  };
};

export const detailSetBusinessPremises = (payload) => {
  return {
    type: DETAIL_SET_BUSINESS_PREMISES,
    payload,
  };
};

export const updateBusinessPremises = (payload) => {
  return {
    type: UPDATE_BUSINESS_PREMISES,
    payload,
  };
};
export const updateBusinessPremisesSuccess = (payload) => {
  return {
    type: UPDATE_BUSINESS_PREMISES_SUCCESS,
    payload,
  };
};

export const deleteBusinessPremises = (payload) => {
  return {
    type: DELETE_BUSINESS_PREMISES,
    payload,
  };
};

export const businessPremisesFailure = (payload) => {
  return {
    type: BUSINESS_PREMISES_FAILURE,
    payload,
  };
};

export const businessPremisesContacts = () => {
  return {
    type: LIST_CONTACT,
  };
};

export const contactSetBusinessPremises = (payload) => {
  return {
    type: SET_CONTACT_LIST,
    payload,
  };
};
export const contactClearBusinessPremises = (payload) => {
  return {
    type: CLEAR_CONTACT_LIST,
    payload,
  };
};

export const getProfessionalGroups = () => {
  return {
    type: GET_PROFESSIONAL_GROUP,
  };
};

export const setProfessionalGroups = (payload) => {
  return {
    type: SET_PROFESSIONAL_GROUP,
    payload,
  };
};

export const failureProfessionalGroups = (payload) => {
  return {
    type: FAILURE_PROFESSIONAL_GROUP,
    payload,
  };
};

export const getLeaderByLocationStarted = (payload) => {
  return {
    type: GET_LEADER_BY_LOCATION_STARTED,
    payload,
  };
};
export const getLeaderByLocationSuccess = (payload) => {
  return {
    type: GET_LEADER_BY_LOCATION_SUCCESS,
    payload,
  };
};
export const getLeaderByLocationFailure = (payload) => {
  return {
    type: GET_LEADER_BY_LOCATION_FAILURE,
    payload,
  };
};

export const addLeaderByLocationStarted = (payload) => {
  return {
    type: ADD_LEADER_BY_LOCATION_STARTED,
    payload,
  };
};
export const addLeaderByLocationSuccess = (payload) => {
  return {
    type: ADD_LEADER_BY_LOCATION_SUCCESS,
    payload,
  };
};
export const addLeaderByLocationFailure = (payload) => {
  return {
    type: ADD_LEADER_BY_LOCATION_FAILURE,
    payload,
  };
};

export const ownerByLocationStarted = (payload) => {
  return {
    type: OWNER_BY_LOCATION_STARTED,
    payload,
  };
};
export const ownerByLocationSuccess = (payload) => {
  return {
    type: OWNER_BY_LOCATION_SUCCESS,
    payload,
  };
};
export const ownerByLocationFailure = (payload) => {
  return {
    type: OWNER_BY_LOCATION_FAILURE,
    payload,
  };
};

export const addOwnerByLocationStarted = (payload) => {
  return {
    type: ADD_OWNER_BY_LOCATION_STARTED,
    payload,
  };
};
export const addOwnerByLocationSuccess = (payload) => {
  return {
    type: ADD_OWNER_BY_LOCATION_SUCCESS,
    payload,
  };
};
export const addOwnerByLocationFailure = (payload) => {
  return {
    type: ADD_OWNER_BY_LOCATION_FAILURE,
    payload,
  };
};
export const setCheckAddLeader = (payload) => ({
  type: SET_CHECK_NEW_LEADER,
  payload,
});
export const setCheckAddLocation = (payload) => ({
  type: SET_CHECK_NEW_LOCATION,
  payload,
});

export const getAllBusinessStarted = (payload) => {
  return {
    type: GET_ALL_BUSINESS_STARTED,
    payload,
  };
};

export const getAllBusinessSuccess = (payload) => ({
  type: GET_ALL_BUSINESS_SUCCESS,
  payload,
});

export const getAllBusinessFailure = (payload) => ({
  type: GET_ALL_BUSINESS_FAILURE,
  payload,
});
