import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

const EvidenceCMSButtonCard = ({ value }) => {
  const dispatch = useDispatch();
  const DisplayName = value ? value.replaceAll("_", " ") : "Name";
  return (
    <TableRow sx={{ background: "#f7f7f7" }}>
      <TableCell>
        <p>{DisplayName}</p>
      </TableCell>
      <TableCell className="!pl-3">
        <div className="flex justify-center items-center">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "evidence_cms",
                  unique: value,
                  heading: DisplayName,
                })
              );
            }}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EvidenceCMSButtonCard;
