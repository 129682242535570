import { useState } from "react";
import styles from "./uploadInput.module.css";
import { BiCloudUpload } from "react-icons/bi";
import toast from "react-hot-toast";
import vector6 from "src/assets/img/vector6.png";
import doc from "src/assets/img/doc.png";
import imagePre from "src/assets/img/imagepreview.png";

const UploadInputField = ({ formik, resetDocStatus }) => {
  const [selectedName, setSelectedName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedName(file.name);
    const fileName = e.target.value;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    const fileNameWithExtension = fileName.split("\\").pop().split("/").pop();
    if (fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "docx" || fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
      formik.setFieldValue("icon", fileExtension === "pdf" ? vector6 : fileExtension === "doc" || fileExtension === "docx" ? doc : imagePre);
      formik.setFieldValue("docs", fileNameWithExtension);
      formik.setFieldValue("doc_type", fileExtension);
      formik.setFieldValue("doc_url", e.target.files[0]);
      resetDocStatus(true)
    } else {
      toast.error("Ungültiger Dateityp. Es werden nur Bilder, PDF- und Dokumentdateien akzeptiert.");
    }
  };
  return (
    <div className={`${styles.uploadInputContainer} parent `}>
      <div className="file-upload p-[1.25rem] flex justify-center relative cursor-pointer text-gray-400">
        <h3 className="text-center"> {selectedName || <BiCloudUpload size={32} />}</h3>
        <input type="file" className={styles.uploadInput} onChange={handleFileChange} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
      </div>
    </div>
  );
};

export default UploadInputField;
