/* eslint-disable */
import React, { useEffect, useState } from "react";
import MTable from "src/components/pages/settings/Management/MTable";
import { CircularProgress, TableBody, TableCell, TableRow } from "@mui/material";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import MessageModalDForm from "../cms/MessageModal/MessageModalDForm";
import { cmsModalOpenForm, getDFormCMSbyId } from "src/store/actions/cmsDashboard";
import { procedureTemplates } from "src/store/actions/procedure";
import StateSelection from "src/components/atoms/select/StateSelection";
import { gethelpDeskListStarted } from "src/store/actions/helpdesk";

const ProcudureCMSTable = () => {
  const dispatch = useDispatch();
  /** droplist */
  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceSelected, setServiceSelected] = useState("");
  const [isReload, setIsReload] = useState(false);
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const procedure = useSelector((state) => state.procedure);
  const admincmsisLoading = cmsDocument?.adminDFormCMS?.data?.isLoading;
  const admincmscontent = cmsDocument?.adminDFormCMS?.data?.content;
  const admincmshead = cmsDocument?.adminDFormCMS?.data?.steps;
  const isModalOpen = cmsDocument?.adminDFormCMS?.isModalOpen;
  const stephead = admincmshead.find((head) => head.id == serviceSelected);

  /** dropdown selection api */
  useEffect(() => {
    dispatch(procedureTemplates());
  }, []);

  /** dropdown selection */
  useEffect(() => {
    if (procedure.data) {
      let newOptions = procedure.data
        .map((service) => {
          if (service.id == "EPQ" || service.id == "REPQ" || service.id == "IWA" || service.id == "KA" || service.id == "SA" || service.id == "UA" || service.id == "AA") {
            return { title: service.description, value: service.id };
          }
          return undefined;
        })
        .filter((service) => service !== undefined);
      if (newOptions) {
        setServiceOptions(newOptions);
        setServiceSelected(newOptions[0].value);
        dispatch(getDFormCMSbyId({ service_order_type: newOptions[0].value }));
      }
    }
  }, [procedure]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setServiceSelected(value);
    dispatch(getDFormCMSbyId({ service_order_type: value }));
  };

  useEffect(() => {
    if (isReload) {
      dispatch(getDFormCMSbyId({ service_order_type: serviceSelected }));
      setIsReload(false);
    }
  }, [isReload]);

  useEffect(() => {
    dispatch(gethelpDeskListStarted());
  }, []);

  return (
    <div>
      {serviceSelected && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-tahoma-bold text-xl my-4">
              {serviceSelected &&
                serviceOptions.find((head) => head.value == serviceSelected) &&
                serviceOptions.find((head) => head.value == serviceSelected).title + "-" + serviceOptions.find((head) => head.value == serviceSelected)?.value}
            </h3>
            {!procedure.isLoading && (
              <div className="!w-full md:!w-[200px] lg:!w-[260px] xl:!w-[300px] 2xl:!w-[340px]">
                <StateSelection handleSelect={handleSelectChange} data={serviceOptions} />
              </div>
            )}
          </div>
          {admincmsisLoading ? (
            <div className="flex justify-center items-center min-h-[40vh]">
              <CircularProgress sx={{ color: "#A31D1A" }} />
            </div>
          ) : (
            <MTable headList={headList} tableCss={"w-[650px]"}>
              <TableBody>
                {Object.entries(admincmscontent) && Object.entries(admincmscontent)?.length > 0 ? (
                  Object.entries(admincmscontent).map(([key, value], index) => {
                    let findOne = admincmshead.find((head) => head.id == key);
                    return (
                      <TableRow sx={{ background: "#f7f7f7" }}>
                        <TableCell>
                          <p>{findOne && findOne?.title}</p>
                        </TableCell>
                        <TableCell>
                          <p
                            className="text-center flex justify-center cursor-pointer"
                            onClick={() => {
                              dispatch(cmsModalOpenForm({ step_id: key, modalOpenType: "content" }));
                            }}
                          >
                            <AiFillEdit />
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <p className="text-red-400 text-center mt-2 font-tahoma-bold">Keine Daten gefunden</p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </MTable>
          )}
        </>
      )}
      {isModalOpen && <MessageModalDForm open={isModalOpen} serviceSelected={serviceSelected} setIsReload={setIsReload} />}
    </div>
  );
};

const headList = [
  {
    id: 1,
    title: "Title",
    key: ["title", "subtitle"],
    align: "left",
  },
  {
    id: 4,
    title: "Actions ",
    key: ["date"],
    align: "center",
  },
];

export default ProcudureCMSTable;
