import { GET_DASHBOARD_DATA_FAILURE, GET_DASHBOARD_DATA_STARTED, GET_DASHBOARD_DATA_SUCCESS, GET_DASHBOARD_MESSAGE_FAILURE, GET_DASHBOARD_MESSAGE_STARTED, GET_DASHBOARD_MESSAGE_SUCCESS } from "../actionTypes";

export const getDashboardDataStarted = (payload) => {
  return {
    type: GET_DASHBOARD_DATA_STARTED,
    payload,
  };
};

export const getDashboardDataSuccess = (payload) => {
  return {
    type: GET_DASHBOARD_DATA_SUCCESS,
    payload,
  };
};
export const getDashboardDataFailure = (payload) => {
  return {
    type: GET_DASHBOARD_DATA_FAILURE,
    payload,
  };
};
export const getDashboardMessageStarted = (payload) => {
  return {
    type: GET_DASHBOARD_MESSAGE_STARTED,
    payload,
  };
};

export const getDashboardMessageSuccess = (payload) => {
  return {
    type: GET_DASHBOARD_MESSAGE_SUCCESS,
    payload,
  };
};
export const getDashboardMessageFailure = (payload) => {
  return {
    type: GET_DASHBOARD_MESSAGE_FAILURE,
    payload,
  };
};
