import * as yup from "yup";

export const initialValuesRegister = {
  salutation: "",
  title: "",
  first_name: "",
  last_name: "",
  company_name: "",
  email: "",
  password: "",
  repeat: "",
};

export const validationSchemaRegister = yup.object().shape({
  salutation: yup.string().required("Anrede muss ausgefüllt werden"),
  // title: yup.string().required("Titel muss ausgefüllt werden"),
  first_name: yup.string().required("Vorname muss ausgefüllt werden"),
  last_name: yup.string().required("Nachname muss ausgefüllt werden"),
  company_name: yup.string().required("Firmenname muss ausgefüllt werden"),
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
  password: yup
    .string()
    .required("Passwort muss ausgefüllt werden")
    .test(
      "password",
      "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten und mindestens 8 Zeichen lang sein. Leerzeichen sind nicht zulässig.",
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/\\~-])(?=\S+$).{8,}$/.test(value) && !/\s/.test(value);
      }
    ),
  repeat: yup
    .string()
    .required("Passwort wiederholen muss ausgefüllt werden")
    .oneOf([yup.ref("password"), null], "Das Passwort muss übereinstimmen"),
});
