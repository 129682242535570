import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import AddressRelocation from '../PreviewSummary/AddressRelocation';
import DropdownTag from '../PreviewSummary/DropdownTag';
import GroupButton from '../PreviewSummary/GroupButton';

const NewFormTemplate = ({ data }) => {
  const cmsDocument = useSelector((state) => state?.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;

  if (data.inputType === 'checkBox') {
    return (
      <div className={data.id.includes('01_START') ? 'my-4 border border-black' : ''}>
        {data.id.includes('01_START') ? (
          <div className="w-full flex flex-col my-4">
            <table>
              <thead>
              <tr className="flex border-b border-black">
                <th className="p-4 font-extrabold text-normal font-tahoma-bold text-black">{stepsCMS[data?.id]?.title}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
              <FormControlLabel
                className='!ml-4'
                control={
                  <Checkbox
                    className="p-1 m-1"
                    disabled
                    sx={{
                      color: '#484848',
                      '&.Mui-checked': {
                        color: '#A31D1A',
                      },
                    }}
                    checked={data?.inputTypeValue ? data?.inputTypeValue : false}
                  />
                }
                label={<span className='text-black'>{stepsCMS[data?.id].inputLabel}</span>}/>
              </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full flex flex-col pl-2">
            <h1 className=" text-normal font-tahoma-bold text-black">{stepsCMS[data?.id]?.title}</h1>

            <div className="text-black font-tahoma-bold pl-4">
              <FormControlLabel
                control={
                  <Checkbox
                    className="p-1 m-1"
                    disabled
                    sx={{
                      color: '#484848',
                      '&.Mui-checked': {
                        color: '#A31D1A',
                      },
                    }}
                    checked={data?.inputTypeValue ? data?.inputTypeValue : false}
                  />
                }
                label={<span className='text-black'>{stepsCMS[data?.id].inputLabel}</span>}
              />
            </div>
          </div>
        )}
      </div>
    );
  } else if (data?.inputType === 'groupButton') {
    return <GroupButton stepsCMS={stepsCMS} data={data} />;
  } else if (data?.inputType === 'dropdownTag' && data?.inputTypeId === 'professionalGroup') {
    return <DropdownTag stepsCMS={stepsCMS} data={data} />;
  } else if (data.inputType === 'service-areas-modal') {
    return (
      <>
        <div className="border border-black bg-gray-100 py-2 pl-2 mt-4">
          <h2>Versorgungsbereich</h2>
        </div>
        <div className="my-3">
          <ul className="flex flex-col ">
            {data?.inputTypeValue?.length > 0 &&
              data?.inputTypeValue?.map((area) => {
                return (
                  <div key={area.selectedArea}>
                    <h1 className="text-normal font-tahoma-bold text-black border-r border-l border-t border-black pl-2 py-1">
                      {stepsCMS[data?.id]?.pdfTitle} - {data?.inputTypeValue[0].description}
                    </h1>
                    <li className=" border border-black flex-col mb-5">
                      <div className="grid grid-cols-3 border-b border-black min-h-8">
                        <h3 className="col-span-1 border-r border-black pl-2">Enthaltene Hilfsmittel</h3>
                        <p className="pl-2 col-span-2">{area?.description}</p>
                      </div>
                      <div className="grid grid-cols-3 border-b border-black min-h-8">
                        <h3 className="col-span-1 border-r border-black pl-2">Besondere Kriterien</h3>
                        <ul className="pl-4 list-none col-span-2">
                          {area?.specialCriteria?.map((tool, toolIndex) => (
                            <li className="" key={toolIndex}>
                              {tool}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="grid grid-cols-3 min-h-8">
                        <h3 className="col-span-1 border-r border-black pl-2">Fachliche Leiter</h3>
                        <p className="pl-2 col-span-2">
                          {' '}
                          {area?.selectedTechnicalLeaders?.map((leader, i) => {
                            return i === 0 ? leader?.name : ', ' + leader?.name;
                          })}
                        </p>
                      </div>
                    </li>
                  </div>
                );
              })}
          </ul>
        </div>
      </>
    );
  } else if (data.inputType === 'address-relocation') {
    return data && <AddressRelocation data={data} />;
  } else if (data.inputType === 'verifyCheckBoxWithAccount') {
    return (
      <div className="border m-2 border-black">
        <h1 className="text-normal font-tahoma-bold text-black pl-2 ">{stepsCMS[data?.id]?.title}</h1>
        <p className="">{stepsCMS[stepsCMS[data?.id]?.id]?.description}</p>
        <p className="my-2 border flex">
          <span className=" w-1/2 border-r pl-2">Kontoinhaber/in: </span>
          <span className="pl-2">{data?.inputPaymentValue?.account_holder}</span>
        </p>
        <p className="my-2 flex border-b">
          <span className=" w-1/2 border-r pl-2">IBAN: </span>
          <span className="pl-2">{data?.inputPaymentValue?.iban}</span>
        </p>
        <p className="my-2 flex border-b">
          <span className=" w-1/2 border-r pl-2">BIC: </span>
          <span className="pl-2">{data?.inputPaymentValue?.bic}</span>
        </p>
        <p className="my-2 flex border-b">
          <span className=" w-1/2 border-r pl-2">Kreditinstitut: </span>
          <span className="pl-2">{data?.inputPaymentValue?.credit_institute}</span>
        </p>
        <div className="text-black font-tahoma-bold flex">
          <FormControlLabel
            control={
              <Checkbox
                className="flex-start"
                disabled
                sx={{
                  color: '#484848',
                  '&.Mui-checked': {
                    color: '#A31D1A',
                  },
                }}
                checked={data?.inputTypeValue ? data?.inputTypeValue : false}
              />
            }
            label={<span style={{ alignItems: 'flex-start', color: 'black' }}>{stepsCMS[data?.id].inputLabel}</span>}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default NewFormTemplate;
