import { postApiCall, getApiCall, putApiCall, deleteApiCall } from "src/services/utils/axios";
import { BASE_URL, API } from "src/services/utils/defines";

export const ADDUSERSACCOUNTSAPI = async (payload) => {
  const url = `${BASE_URL}${API.ADDUSERSACCOUNTS}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ACCOUNTLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.ACCOUNTSLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const UPDATEACCOUNTAPI = async (payload) => {
  const url = `${BASE_URL}${API.UPDATEUSERSACCOUNTS}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await putApiCall(url, payload, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GET_ACCOUNT_LIST = async (paramsData) => {
  const url = `${BASE_URL}${API.ACCOUNTSLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message || "Failed to fetch Account List");
  }
};

export const PUT_USER_ADDRESS = async (payload) => {
  const url = `${BASE_URL}${API.UPDATEUSERDETAIL}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await putApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message || "Failed to fetch profile");
  }
};

export const DEL_USER_ADDRESS = async (payload) => {
  const url = `${BASE_URL}${API.DELETEACCOUNT}?id=${payload}`;

  try {
    const paramsData = { isAuthorized: true };
    const response = await deleteApiCall(url, {}, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message || "Failed to fetch profile");
  }
};

export const ADD_USER_MANAGEMENT = async (payload) => {
  const url = `${BASE_URL}${API.ADDUSERMANAGEMENT}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

/* user management */

export const USER_MANAGEMENT_LIST = async (paramsData) => {
  const url = `${BASE_URL}${API.USERMANAGEMENTLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const UPDATE_USER_MANAGEMENT = async (payload) => {
  const url = `${BASE_URL}${API.UPDATEUSERMANAGEMENT}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await putApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const DELETE_USER_MANAGEMENT = async (payload) => {
  const url = `${BASE_URL}${API.DELETEUSERMANAGEMENT}?id=${payload?.id}&user_id=${payload?.user_id}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await deleteApiCall(url, {}, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};
