import { useSelector } from "react-redux";

import CMSButtonCard from "./CMSButtonCard";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";

const CMSButtonList = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  return (
    <div className="my-4">
      <hr className="my-6" />
      <div>
        {cmsDashboarddata?.dynamic_form_buttons && (
          <div>
            <TableContainer component={Paper} className={"w-[650px]"}>
              <Table aria-label="simple table">
                <TableBody>
                  {Object.keys(cmsDashboarddata?.dynamic_form_buttons).map(
                    (value) => {
                      return (
                        value !== "cms_key" && (
                          <CMSButtonCard value={value}></CMSButtonCard>
                        )
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default CMSButtonList;
