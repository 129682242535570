import { GET_RECRUIT_CLIENT_FAILURE, GET_RECRUIT_CLIENT_STARTED, GET_RECRUIT_CLIENT_SUCCESS } from "../actionTypes";

export const getRecruitClientStarted = (payload) => {
  return {
    type: GET_RECRUIT_CLIENT_STARTED,
    payload,
  };
};

export const getRecruitClientSuccess = (payload) => {
  return {
    type: GET_RECRUIT_CLIENT_SUCCESS,
    payload,
  };
};

export const getRecruitClientFailure = (payload) => {
  return {
    type: GET_RECRUIT_CLIENT_FAILURE,
    payload,
  };
};
