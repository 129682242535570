import {
  CMS_DFORM_CONTENT,
  CMS_DFORM_CONTENT_FAILURE,
  CMS_DFORM_CONTENT_SUCCESS,
  GET_DFORM_CMS_BY_ID,
  GET_DFORM_CMS_BY_ID_FAILURE,
  GET_DFORM_CMS_BY_ID_SUCCESS,
  LIST_CMS_CONTENT,
  LIST_CMS_CONTENT_FAILURE,
  LIST_CMS_CONTENT_SUCCESS,
  MODAL_CLOSE_CMS,
  MODAL_CLOSE_CMS_DFORM,
  MODAL_OPEN_CMS,
  MODAL_OPEN_CMS_DFORM,
  UPDATE_CMS_CONTENT,
  UPDATE_CMS_CONTENT_FAILURE,
  UPDATE_CMS_CONTENT_SUCCESS,
  UPDATE_DFORM_CMS_BY_ID,
} from "../actionTypes";

const initialState = {
  isModalOpen: false,
  isLoading: false,
  listLoaded: false,
  modalData: {
    cms_key: "dashboard",
    unique: "next",
    heading: "Startseite",
    data: null,
  },
  dFormCMS: {
    data: null,
    isLoading: false,
    error: null,
  },
  adminDFormCMS: {
    data: {
      content: {},
      steps: [],
      modalData: null,
      currentStep: '',
      isLoading: false,
    },
    isModalOpen: false,
    // 'content || steps'
    modalOpenType: '',
    isLoading: false,
    error: null,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPEN_CMS: {
      return {
        ...state,
        modalData: {
          ...action.payload,
          data: state[action.payload.cms_key][action.payload.unique],
        },
        isModalOpen: true,
      };
    }
    case MODAL_CLOSE_CMS: {
      return { ...state, modalData: null, isModalOpen: false };
    }
    case LIST_CMS_CONTENT: {
      return { ...state, isLoading: true };
    }
    case LIST_CMS_CONTENT_SUCCESS: {
      let cmsObj = {};
      action.payload.data.forEach((data) => {
        cmsObj[data.cms_key] = { ...data.cms_data, cms_key: data.cms_key };
      });
      return { ...state, isLoading: false, listLoaded: true, ...cmsObj };
    }
    case LIST_CMS_CONTENT_FAILURE: {
      return { ...state, isLoading: false };
    }
    case UPDATE_CMS_CONTENT: {
      return { ...state, isLoading: true };
    }
    case UPDATE_CMS_CONTENT_SUCCESS: {
      return { ...state, isLoading: false, adminDFormCMS: {...state.adminDFormCMS, isLoading: false, isModalOpen: false,} };
    }
    case UPDATE_CMS_CONTENT_FAILURE: {
      return { ...state, isLoading: false, adminDFormCMS: {...state.adminDFormCMS, isLoading: false, isModalOpen: false,} };
    }
    case CMS_DFORM_CONTENT: {
      return {
        ...state,
        dFormCMS: {
          ...state.dFormCMS,
          isLoading: true,
        },
      };
    }
    case CMS_DFORM_CONTENT_SUCCESS: {
      return {
        ...state,
        dFormCMS: {
          ...state.dFormCMS,
          isLoading: false,
          data: action.payload
        },
      };
    }
    case CMS_DFORM_CONTENT_FAILURE: {
      return {
        ...state,
        dFormCMS: {
          ...state.dFormCMS,
          isLoading: false,
        },
      };
    }
    /** dform cms */
    case MODAL_OPEN_CMS_DFORM: {
      if(action.payload.modalOpenType == "content"){
        return {
          ...state,
          adminDFormCMS: {
            ...state.adminDFormCMS,
            data: {
              ...state.adminDFormCMS.data,
              modalData: state.adminDFormCMS.data.content[action.payload.step_id],
              currentStep: action.payload.step_id,
            },
            isModalOpen: true,
          },
        };
      } else if(action.payload.modalOpenType == "steps"){
        return state
      } else {
        return state
      }
    }
    case MODAL_CLOSE_CMS_DFORM: {
      return { 
        ...state, 
        adminDFormCMS: {
          ...state.adminDFormCMS,
          data: {
            ...state.adminDFormCMS.data,
            modalData: null
          },
        isModalOpen: false,
        },
      };
    }
    case GET_DFORM_CMS_BY_ID: {
      return { 
        ...state, 
        adminDFormCMS: {
          ...state.adminDFormCMS,
          data: {
            ...state.adminDFormCMS.data,
            content: {},
            steps: [],
            modalData: null,
            currentStep: '',
            isLoading: true,
          },
        isModalOpen: false,
        },
      };
    }
    case GET_DFORM_CMS_BY_ID_SUCCESS: {
      return { 
        ...state, 
        adminDFormCMS: {
          ...state.adminDFormCMS,
          data: {
            ...state.adminDFormCMS.data,
            content: action.payload.content,
            steps: action.payload.step,
            modalData: null,
            currentStep: '',
            isLoading: false,
          },
        isModalOpen: false,
        },
      };
    }
    case GET_DFORM_CMS_BY_ID_FAILURE: {
      return { 
        ...state, 
        adminDFormCMS: {
          ...state.adminDFormCMS,
          data: {
            ...state.adminDFormCMS.data,
            content: {},
            steps: [],
            modalData: null,
            currentStep: '',
            isLoading: false,
          },
        isModalOpen: false,
        },
      };
    }
    case UPDATE_DFORM_CMS_BY_ID: {
      return { ...state, adminDFormCMS: {...state.adminDFormCMS, isLoading: true} };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
