/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import ImageHtml from "src/components/pages/ImageHtml";
import { SecondaryButton } from "src/components/atoms/button";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { applicationProcessStatusStarted, applicationSignatureReset, sendSignatureRequestEmailStarted } from "src/store/actions/application";
import FilterField from "src/components/molecules/dform/FilterField";
import Cookies from "js-cookie";
import { sendSignaturePayloadModify, setShowDifferntNameModify } from "src/helpers/dform";
import toast from "react-hot-toast";
import SignatureCard from "./SignatureCard";
import { contactListShow } from "src/helpers/dform/validateForm";
import BackdropButton from "src/components/atoms/button/BackdropButton";

function ApplicationVerify({ data, createServiceOrderSubmit, saveFormData }) {
  const [showName, setShowName] = useState([]);
  const [showDifferntName, setShowDifferntName] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentStep = useSelector((state) => state.currentStep);
  const application = useSelector((state) => state.application);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDashboarddata?.dFormCMS?.data;
  const allContact = JSON.parse(localStorage.getItem("businessPremisesContactHandler"));
  const serviceAreaDetail = JSON.parse(localStorage.getItem("locationValidation"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const savedData = Object?.values(application?.data)?.filter((item, index) => {
      return index === 1;
    });
    const result = savedData[0]?.filter((data) => {
      return data.inputType === "dropdownTag";
    });
    if (allContact != null) {
      const contactList = contactListShow(allContact, result, id, serviceAreaDetail);
      contactList && setShowName([...contactList]);
    }
  }, []);

  /** get the current dynamic application status - get-procedure-details */

  useEffect(() => {
    dispatch(applicationProcessStatusStarted({ procedure_id: Cookies.get("submitted_id") }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(applicationSignatureReset());
    };
  }, []);

  useEffect(() => {
    const inhaber = currentStep?.data.find((step) => step?.inputType === "signaturedropdown");
    setShowDifferntName(
      setShowDifferntNameModify({
        procedure_data: [{ ...application.data, [currentStep?.stepID]: currentStep?.data }],
        service_order_types_id: id,
        inhaber_id: inhaber?.inputTypeValue?.toString(),
        contactSignature: application?.contactSignature?.data,
      })
    );
  }, [application?.contactSignature]);

  const sendSignatureRequestEmailHandle = () => {
    const inhaber = currentStep?.data?.find((step) => step?.inputType === "signaturedropdown");
    if (inhaber?.inputTypeValue) {
      saveFormData(true);
      dispatch(
        sendSignatureRequestEmailStarted(
          sendSignaturePayloadModify({
            procedure_data: [{ ...application?.data, [currentStep?.stepID]: currentStep?.data }],
            service_order_types_id: id,
            procedure_form_id: Cookies.get("submitted_id"),
            user_id: Cookies.get("userId"),
            inhaber_id: inhaber?.inputTypeValue?.toString(),
            signatures: application?.contactSignature?.data,
          })
        )
      );
    } else {
      toast.error("Bitte wählen Sie den Inhaber aus");
    }
  };

  return (
    <div className="">
      <h1 className="!mb-5 text-xl font-tahoma-bold m-3 sm:m-4 md:m-6 text-black">{stepsCMS?.['05_ALMOST_THERE']?.title}</h1>
      <div className="mx-3 sm:mx-4 md:mx-6 mt-0 p-0 ">
        <div className="justify-start gap-12 flex flex-col md:flex-row">
          <p className="textStyle max-w-[540px] mb-5 pt-5">{stepsCMS?.['05_ALMOST_THERE']?.description}</p>
        </div>
        {data?.map((fdata, i) => {
          if (fdata?.inputType === "shorten-final") {
            return (
              <Fragment key={i}>
                <FilterField data={fdata} />
              </Fragment>
            );
          }
        })}
        <div>
          <div className="w-full md:w-[841px]">{Object?.values(application?.data) && <ImageHtml data={Object?.values(application?.data)} />}</div>
          <div className="pt-10">
            {application?.dformStatus?.data?.data?.status !== "signatures-completed" && application?.dformStatus?.data?.data?.status !== "completed" && (
              <h1 className="text-black  text-lg font-tahoma-bold">Unterschrift Inhaber</h1>
            )}

            <div className="pt-5 md:pt-0 flex flex-col md:flex-row gap-8">
              <div className="flex flex-col gap-8 w-full md:w-[841px]">
                {data?.map((fdata, i) => {
                  if (fdata?.inputType === "service-order-cost") {
                    return null;
                  } else {
                    return (
                      <Fragment key={i}>
                        <FilterField data={fdata} />
                      </Fragment>
                    );
                  }
                })}

                <div className="pt-10">
                  {showName.length > 0 &&
                    showName.map((item) => {
                      let signature =
                        application?.dformStatus?.data?.data?.SignatureList?.length > 0 &&
                        application?.dformStatus?.data?.data?.SignatureList?.find((signature) => signature?.contact_id == item.contact_id);
                      let usersignature = signature ? false : application?.contactSignature?.data[item?.contact_id] ? application?.contactSignature?.data[item?.contact_id] : false;
                      return <SignatureCard key={item.contact_id} signature={signature} item={item} usersignature={usersignature} />;
                    })}

                  <div className="justify-center flex flex-col items-center gap-8 pt-12">
                    {application?.dformStatus?.data?.data?.status === "ongoing" || application?.dformStatus?.data?.data?.status === "signatures-requested" ? (
                      application?.dformStatus?.data?.data?.status !== "signatures-requested" &&
                      application?.dformStatus?.data?.data?.status !== "signatures-completed" &&
                      application?.dformStatus?.data?.data?.status !== "completed" &&
                      (!currentStep?.data[0].inputTypeValue ? (
                        <SecondaryButton
                          variant="disabled"
                          title={
                            showDifferntName
                              ? cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.check_signatures
                              : cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.request_signature
                          }
                          css="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                        />
                      ) : (
                        <BackdropButton
                          title={
                            showDifferntName
                              ? cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.check_signatures
                              : cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.request_signature
                          }
                          btnStyle="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                          icon={<FaCheck />}
                          loading={application?.sendEmail?.isLoading}
                          position="after"
                          handler={sendSignatureRequestEmailHandle}
                        />
                      ))
                    ) : application?.dformStatus?.data?.data?.status === "signatures-completed" ? (
                      <BackdropButton
                        title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.submit_for_a_fee}
                        btnStyle="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                        icon={<FaCheck />}
                        loading={application?.createdApplication?.isLoading}
                        position="after"
                        handler={createServiceOrderSubmit}
                      />
                    ) : null}
                  </div>

                  {data?.map((fdata, i) => {
                    if (fdata?.inputType === "service-order-cost") {
                      return (
                        <Fragment key={i}>
                          <FilterField data={fdata} />
                        </Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}

                  {application?.dformStatus?.data?.data?.status !== "signatures-completed" && application?.dformStatus?.data?.data?.status !== "completed" && !showDifferntName && (
                    <h4 className="text-red-400 text-center mt-8 text-md">Unterschriften fehlen</h4>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationVerify;
