/*eslint-disable */
import React, { useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteBusinessPremises } from "src/store/actions/business";
import editIcon from "src/assets/icons/edit.png";
import editDisabledIcon from "src/assets/icons/edit-disabled.png";
import deleteIcon from "src/assets/icons/delete.png";
import deleteDisabledIcon from "src/assets/icons/delete-desabled.png";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";
import DeleteModal from "src/components/atoms/modal/DeleteModal";
import { isMoreThan4WeeksPast } from "src/helpers/document";

const BusinessTableBody = ({ bodyContent }) => {
  const [showInhaber, setShowInhaber] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (id) => {
    if (profile?.data?.role === "admin") {
      navigate("/admin/betriebsstaetten/" + id);
    } else {
      navigate("/betriebsstaetten/" + id);
    }
  };
  const showAllInhaber = (id) => {
    if (id == showInhaber) {
      setShowInhaber("");
    } else setShowInhaber(id);
  };
  const businessDeleteHandle = (id) => {
    dispatch(deleteBusinessPremises(id));
  };

  const handleSelectedId = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    businessDeleteHandle(selectedId);
  };

  const deleteModalClose = () => {
    setSelectedId("");
    setShowDeleteModal(false);
  };

  return (
    <>
      <DeleteModal handleOpen={deleteModalClose} showModal={showDeleteModal} confirmHandle={handleDelete} />
      <TableBody>
        {Array.isArray(bodyContent) &&
          bodyContent.map((item, index) => {
            const bgLocationAccess = isMoreThan4WeeksPast(item?.date)
            const editAccess = (item?.date?.length>0 || item?.service_order_nbr?.length>0) && !item.editable ? true : false;

            console.log("editAccess==> ", editAccess);
            return (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  td: {
                    color: bgLocationAccess ? "#A31D1A" : "inherit",
                    borderBottom: "none",
                    "&:first-of-type": {
                      paddingLeft: "40px",
                    },
                  },
                  th: {
                    borderBottom: "none",
                  },
                  background: bgLocationAccess ? "#fff1f1" : index % 2 === 0 ? "#FBFBFB" : "inherit",
                }}
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {item.street}, {item.house_number}
                </TableCell>
                <TableCell>{item?.ik_number.includes("[") ? JSON.parse(item?.ik_number)[0] : item?.ik_number.join(",")}</TableCell>
                <TableCell>
                  <span
                    className={`${
                      editAccess
                        ? "#A31D1A"
                        : item.varified === "success"
                        ? "text-SeaGreen"
                        : item.varified === "pending"
                        ? "text-BrightYellow"
                        : item.varified === "failed"
                        ? "text-DeepCarminePink"
                        : "text-AFPHeading"
                    }`}
                  >
                    {item?.date}
                  </span>
                </TableCell>
                <TableCell className="contentHover" style={{ minWidth: "150px" }}>
                  {item.business_owners?.length > 0 && (
                    <div className="flex justify-between">
                      <span>
                        {showInhaber != item.id ? (
                          `${item.business_owners[0]?.title} ${item.business_owners[0]?.first_name} ${item.business_owners[0]?.last_name}`
                        ) : (
                          <div className="flex flex-col">
                            {item.business_owners.map((owner, index) => (
                              <span key={index} className="flex flex-col">
                                {`${owner?.title} ${owner?.first_name} ${owner?.last_name}`}
                              </span>
                            ))}
                          </div>
                        )}
                      </span>
                      {showInhaber != item.id ? (
                        <div className="h-auto !w-[30px] flex justify-end">
                          <IoIosArrowDropdownCircle
                            onClick={() => {
                              showAllInhaber(item.id);
                            }}
                            fontSize={16}
                            className="cursor-pointer"
                          />
                        </div>
                      ) : (
                        <div className="h-auto !w-[30px] flex justify-end">
                          <IoIosArrowDropupCircle
                            onClick={() => {
                              showAllInhaber(item.id);
                            }}
                            fontSize={16}
                            className="cursor-pointer"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </TableCell>

                <TableCell className="contentHover" style={{ minWidth: "150px" }}>
                  {item.technical_leaders.length > 0 && (
                    <div className="flex justify-between w-full">
                      <span>
                        {showInhaber != item.id ? (
                          `${item.technical_leaders[0]?.title} ${item.technical_leaders[0]?.first_name} ${item.technical_leaders[0]?.last_name}`
                        ) : (
                          <div className="flex flex-col">
                            {item.technical_leaders.map((owner, index) => (
                              <span key={index} className="flex flex-col">
                                {`${owner?.title} ${owner?.first_name} ${owner?.last_name}`}
                              </span>
                            ))}
                          </div>
                        )}
                      </span>
                      {showInhaber != item.id ? (
                        <div className="h-auto !w-[30px] flex justify-end">
                          <IoIosArrowDropdownCircle
                            onClick={() => {
                              showAllInhaber(item.id);
                            }}
                            fontSize={16}
                            className="cursor-pointer"
                          />
                        </div>
                      ) : (
                        <div className="h-auto !w-[30px] flex justify-end">
                          <IoIosArrowDropupCircle
                            onClick={() => {
                              showAllInhaber(item.id);
                            }}
                            fontSize={16}
                            className="cursor-pointer"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: 50 }}>
                  <div className="flex text-OxfordBlue justify-center gap-2">
                    {/* {!item.editMode ? (
                      <img
                        src={editIcon}
                        className={`w-auto h-auto ${editAccess ? "opacity-30 cursor-not-allowed" : "cursor-pointer"}`}
                        onClick={() => {
                          handleClick(item.id);
                        }}
                      />
                    ) : (
                      <img
                        src={editDisabledIcon}
                        className={`w-auto h-auto ${editAccess ? "opacity-30 cursor-not-allowed" : "cursor-pointer"}`}
                        onClick={() => {
                          handleClick(item.id);
                        }}
                      />
                    )} */}
                    <img
                      src={editIcon}
                      className={`w-auto h-auto cursor-pointer`}
                      onClick={() => {
                        handleClick(item.id);
                      }}
                    />
                    <img
                      src={editAccess ? deleteDisabledIcon : deleteIcon}
                      className={`w-auto h-auto ${editAccess ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      onClick={() => {
                        !editAccess && handleSelectedId(item.id);
                      }}
                    />
                    
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};

BusinessTableBody.defaultProps = {
  bodyContent: [],
};
export default BusinessTableBody;
