import styles from "./style.module.css";
import { Avatar, Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import { parseDateFormat } from "src/helpers/document";

const style = {
  top: "50%",
  left: "50%",
  width: "90%",
  maxWidth: "840px",
  outline: " none",
  bgcolor: "#FBFBFB",
  marginBottom: "50px",
  borderRadius: "12px",
  position: "relative",
  paddingBottom: "20px",
  transform: "translate(-50%, -50%)",
};

const MessageModal = ({ open, handleClose, message }) => {
  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={styles.modalContainer}>
      <Box sx={style} className={styles.innerContainer}>
        <div className="h-auto max-h-[70vh] overflow-y-auto customScrollBar">
          <div className="flex justify-between items-center bg-gray-300 rounded-t-xl py-2 px-6">
            <h2 className="text-xl md:text-2xl font-semibold">{message?.regarding}</h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer" onClick={handleClose} />
          </div>
          <div className="px-6 flex justify-start items-center gap-1 pt-2">
            <Avatar>
              <PersonIcon />
            </Avatar>
            <div className="flex flex-col justify-start text-sm">
              <p className="">
              Zu <span className="font-bold text-AFPPrimary">{message?.from}</span> An {parseDateFormat(message?.date)}
              </p>
              <div className="flex items-center text-AFPPrimary gap-x-2">
                <button>
                  <EmailIcon fontSize="10" /> Einzelheiten
                </button>
                <button>
                  <InfoIcon fontSize="12" /> Überschriften
                </button>
              </div>
            </div>
          </div>
          <div className="px-6 flex flex-col gap-2">
            <hr className="my-4" />
            <div dangerouslySetInnerHTML={{ __html: message?.originalMessage }} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default MessageModal;
