/* eslint-disable */

import React, { useState, useRef } from "react";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const SignatureCanvas = ({ signatureUploadHandle }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const evidence = useSelector((state) => state.evidence);

  const { signatureUpload } = evidence;

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    const canvas = canvasRef.current;

    canvas.getContext("2d").beginPath();
    canvas.getContext("2d").moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;

    const { offsetX, offsetY } = nativeEvent;
    const canvas = canvasRef.current;

    canvas.getContext("2d").lineTo(offsetX, offsetY);
    canvas.getContext("2d").stroke();
  };

  const finishDrawing = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    setSignatureDataURL(dataURL);
    setIsDrawing(false);
  };

  const clearSignature = () => {
    if (signatureUpload.isLoading) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    setSignatureDataURL(null);
  };

  const handleSaveSignature = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const isEmptyCanvas = context.getImageData(0, 0, canvas.width, canvas.height).data.every((pixel) => pixel === 0);

    if (!signatureDataURL || isEmptyCanvas) {
      toast.error("Bitte unterschreiben Sie vor dem Speichern.");
      return;
    }

    signatureUploadHandle(signatureDataURL);

  };

  return (
    <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-AFPBGPrimary">
      <div className="flex justify-center">
        <canvas
          ref={canvasRef}
          width={800}
          height={300}
          style={{ border: "1px solid black" }}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={finishDrawing}
          onMouseOut={finishDrawing}
        />
      </div>
      <div className="md:flex-row flex-col flex justify-center items-center gap-6 mt-24">
        <SecondaryButton variant={signatureUpload.isLoading ? "disabled" : "secondary"} title={"Zurücksetzen"} css={"w-full max-w-[200px]"} handler={clearSignature} />
        <PrimaryButton title="Einreichen" btnStyle="w-full max-w-[200px] " handler={handleSaveSignature} loading={signatureUpload.isLoading} />
      </div>
    </div>
  );
};

export default SignatureCanvas;
