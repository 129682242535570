import React from "react";
import { InputStandardField } from "../../input";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import { salutationOptions } from "src/mockData/register";
import StandardSelect from "../../select/StandardSelect";

const NewFormInhaber = ({ formik, handleClose }) => {
  const isSalutationSelected = formik.values.salutation && formik.values.salutation !== "";
  return (
    <>
      <form className="flex flex-col gap-4 mb-2" onSubmit={formik.handleSubmit}>
        <StandardSelect
          extra="!flex-col !items-start"
          extraLabel="w-full md:w-[200px] lg:w-[260px] xl:w-[300px] 2xl:w-[340px]"
          label={"Anrede"}
          choose={!isSalutationSelected}
          placeholder="Herr/Frau"
          id="salutation"
          options={salutationOptions}
          formik={formik}
        />
        <InputStandardField extra="!items-start" label={"Title"} placeholder="Title" id="title" formik={formik} />
        <InputStandardField id="email" formik={formik} extra="!items-start" placeholder="Email" label="Email" />
        <InputStandardField id="first_name" formik={formik} extra="!items-start" placeholder="John" label="Vorname" />
        <InputStandardField id="last_name" formik={formik} extra="!items-start" placeholder="Doe" label="Nachname" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
          <PrimaryButton title="Speichern" type="submit" />
          <SecondaryButton variant="secondary" title="Abbrechen" handler={handleClose} />
        </div>
      </form>
    </>
  );
};

export default NewFormInhaber;
