import { INITIALVALUESFORGOT, VALIDATIONSCHEMAFORGOT } from "src/services/validation/users";
import { useFormik } from "formik";
import { SubmitButton } from "src/components/atoms/button";
import { InputField } from "src/components/atoms/input";
import { useDispatch, useSelector } from "react-redux";
import { authReset, forgotUser } from "src/store/actions/users";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const { email, btnHeading } = {
  email: "E-Mail",
  btnHeading: "Anfordern",
};

const ForgotForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.users);
  const formik = useFormik({
    initialValues: INITIALVALUESFORGOT,
    validationSchema: VALIDATIONSCHEMAFORGOT,
    onSubmit: (values) => {
      dispatch(forgotUser({ baseurl: window.location.origin, email: values.email }));
    },
  });
  useEffect(() => {
    if (users.userForgot) {
      navigate("/login");
      dispatch(authReset({}));
    }
  }, [users]);
  return (
    <form className="p-4 2xl:pt-20" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-8">
        <InputField name="email" css="!font-tahoma-bold" label={email} type="email" variant="standard" formik={formik} />
      </div>

      <SubmitButton css="flexCenter mt-4 2xl:mt-16" type="submit" btnTitle={btnHeading} loading={users?.isLoading} />
    </form>
  );
};

export default ForgotForm;
