/* eslint-disable */
import { Box, Modal } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import PdfPreview from "./PdfPreview";
import ImagePreview from "./ImagePreview";

const LightBoxGallery = ({ isOpen, onClose, selectedFile }) => {
  console.log("currentIndexcurrentIndexcurrentIndex", selectedFile);

  if (!isOpen) return null;

  const style = {
    p: 2,
    top: "50%",
    left: "50%",
    width: " 94%",
    maxWidth: 1080,
    padding: "20px",
    border: "none",
    outline: "none",
    position: "absolute",
    borderRadius: "12px",
    bgcolor: "transparent",
    transform: "translate(-50%, -50%)",
    WebkitBackdropFilter: "blur(40px)",
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="bg-SilverSandLight">
        <Box sx={style}>
          <div className="relative w-full h-full flex justify-center items-center px-6">
            <button onClick={onClose} className="absolute top-5 -right-8 p-1 text-black bg-transparent rounded-full z-50">
              <MdOutlineClose fontSize={30} />
            </button>
            {selectedFile.type === "fileupload" ? (
              <PdfPreview selectedFile={selectedFile} />
            ) : (
              <div className="bg-transparent h-[90vh] max-w-[1400px] flex-1 flex justify-center items-center p-4 rounded-lg">
               <ImagePreview selectedFile={selectedFile?.url} />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

LightBoxGallery.defaultProps = {
  isOpen: false,
  images: [],
  onClose: () => {},
  currentIndex: 0,
};

export default LightBoxGallery;
