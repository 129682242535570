import { useEffect } from "react";

const ChatBoat = () => {
  const CRISP_WEBSITE_ID = "95b924a2-c831-4b03-a266-7396f51b95dd";

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;

    const crispScript = document.createElement("script");
    crispScript.src = "https://client.crisp.chat/l.js";
    crispScript.async = 1;
    document.getElementsByTagName("head")[0].appendChild(crispScript);
  }, []);

  return <div id="crisp-chatbox"></div>;
};

export default ChatBoat;
