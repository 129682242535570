import { CircularProgress } from "@mui/material";

const SubmitButton = ({ btnTitle, endIcon, handler, type, loading, css, btnCss }) => {
  return (
    <div className={css}>
      <button disabled={loading} onClick={handler} type={type ? type : "button"} className={`defaultBtn ${btnCss}`}>
        {loading ? (
          <CircularProgress size={18} sx={{ color: "#ee9593" }} />
        ) : (
          <span>
            {btnTitle}
            {endIcon}
          </span>
        )}
      </button>
    </div>
  );
};

SubmitButton.defaultProps = {
  btnTitle: "Submit",
  type: "button",
  loading: false,
  css: "",
  btnCss: "",
  handler: () => {},
};

export default SubmitButton;
