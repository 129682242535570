import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import { validateTabs } from "src/helpers/setting";
import { PrimaryBodyTab, PrimaryHeadTab } from "src/components/atoms/tabs";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { useDispatch, useSelector } from "react-redux";

export default function PrimaryTab({ tabList }) {
  const profile = useSelector((state) => state.profile);
  const settings = useSelector((state) => state.settings);
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  const handleChange = (event, newValue) => {
    console.log("event: " + event);
    if(settings?.user?.updateUser?.id){
      console.log("event: ", event);
    } else {
      validateTabs(
        tabList,
        id,
        setValue,
        navigate,
        profile?.data?.role,
        newValue
      );
    }
  };

  useEffect(() => {
    validateTabs(tabList, id, setValue, navigate, profile?.data?.role);
  }, [id]);

  const dispatch = useDispatch();

  return (
    <>
      <h3 className="font-tahoma-bold text-2xl ml-5 mb-3 flex items-center gap-3">
        {cmsDashboarddata?.settings?.heading?.setting}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "heading",
                heading: "Einstellungen",
              })
            );
          }}
        />
      </h3>
      <Box sx={{ width: "100%", padding: 2 }}>
        <EditIconCMS
          css="float-right"
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "tabs",
                heading: "Settings",
              })
            );
          }}
        />
        <PrimaryHeadTab
          handleChange={handleChange}
          value={value}
          tabList={tabList.tabs}
          checked={!!settings?.user?.updateUser?.id}
        />
        <PrimaryBodyTab value={value} tabList={tabList.tabs} />
      </Box>
    </>
  );
}
