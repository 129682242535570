/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import DecreasefillCard from "../DecreasefillCard";
import ExpandableCard from "../ExpandableCard";
import { useSelector } from "react-redux";

const ShowExpanded = ({ showCard, page, aid, css = "", helperText, change = false }) => {
  const [miniAids, setMiniAids] = useState(false);
  const [initialTop, setInitialTop] = useState(1);
  const [initialComponentPosition, setInitialComponentPosition] = useState(null);
  const [initialDocumentPosition, setInitialDocumentPosition] = useState(null);
  const componentRef = useRef(null);

  const dform = useSelector((state) => state.dform);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window?.scrollY || window?.scrollTop || document?.documentElement?.scrollTop;
      if (change) {
        initialDocumentPosition - (initialComponentPosition - 250) && setInitialTop(initialDocumentPosition - (initialComponentPosition - 340));
      }
      setInitialDocumentPosition(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [initialTop]);

  useEffect(() => {
    const componentPosition = componentRef?.current?.getBoundingClientRect() || 1;
    setInitialComponentPosition(componentPosition?.top);
    setInitialDocumentPosition(window.scrollY || window.scrollTop || document.documentElement.scrollTop);
  }, []);

  useEffect(() => {
    if (dform?.page_aids && dform?.page_aids[page] && dform?.page_aids[page].includes(aid)) {
      if (!miniAids) {
        setMiniAids(true);
      }
    } else {
      if (miniAids) {
        setMiniAids(false);
      }
    }
  }, [dform?.page_aids]);

  return (
    <>
      <div ref={componentRef} className={`hidden md:block duration-100 transition-all ${miniAids ? "md:min-w-[90px] lg:min-w-[90px] xl:min-w-[90px]" : "md:w-[25%]"} ${css}`}>
        <div className="w-full"></div>
      </div>
      <div className={`absolute top-[${initialTop}px] right-1 ${!miniAids ? "md:w-[25%]" : "md:w-[5%]"} ${css}`} style={{ top: initialTop + "px" }}>
        {showCard && (
          <>
            <DecreasefillCard className="relative w-[100%]" miniAids={miniAids}>
              <ExpandableCard page={page} aid={aid} miniAids={miniAids} helperText={helperText} />
            </DecreasefillCard>
          </>
        )}
      </div>
    </>
  );
};

export default ShowExpanded;
