import { Backdrop } from "@mui/material";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const BackdropLoader = ({ open, borderRadius }) => {
  return (
    <Backdrop sx={{ color: "fff", zIndex: 1500, backdropFilter: "blur(2x)", backgroundColor: "rgba(0, 0, 0, 0.25)", borderRadius: borderRadius }} open={open}>
      <CustomCircularLoader />
    </Backdrop>
  );
};
BackdropLoader.defaultProps = {
  open: false,
  borderRadius: 0,
};

export default BackdropLoader;
