import { GET_MARKETING_FAILURE, GET_MARKETING_STARTED, GET_MARKETING_SUCCESS } from "../actionTypes";

export const getMarketingStarted = (payload) => {
  return {
    type: GET_MARKETING_STARTED,
    payload,
  };
};

export const getMarketingSuccess = (payload) => {
  return {
    type: GET_MARKETING_SUCCESS,
    payload,
  };
};

export const getMarketingFailure = (payload) => {
  return {
    type: GET_MARKETING_FAILURE,
    payload,
  };
};
