/* eslint-disable */
export const evidenceCheckDetail = (payload, evidenceDetail) => {
  let checkEvidenceSubmitable = true;
  payload.forEach((evidence) => {
    if (checkEvidenceSubmitable) {
      if (evidence?.evidenceType?.fieldTpe === "checkbox") {
      } else if (evidence?.evidenceType?.fieldTpe === "signature") {
        let checkValue = evidenceDetail?.evidenceDetail?.data[
          evidence.inventoryID.value
        ]
          ? evidenceDetail?.evidenceDetail?.data[evidence.inventoryID.value]
          : "";
        if (checkValue?.length > 0) {
          checkEvidenceSubmitable = checkEvidenceSubmitable;
        } else {
          checkEvidenceSubmitable = false;
        }
      } else if (evidence?.evidenceType?.fieldTpe === "imageupload") {
        let checkValue = evidenceDetail?.evidenceDetail?.data[
          evidence.inventoryID.value
        ]
          ? evidenceDetail?.evidenceDetail?.data[evidence.inventoryID.value]
          : "";
        if (checkValue?.length > 0) {
          checkEvidenceSubmitable = checkEvidenceSubmitable;
        } else {
          checkEvidenceSubmitable = false;
        }
      } else if (evidence?.evidenceType?.fieldTpe === "fileupload") {
        let checkValue = evidenceDetail?.evidenceDetail?.data[
          evidence.inventoryID.value
        ]
          ? evidenceDetail?.evidenceDetail?.data[evidence.inventoryID.value]
          : "";
        if (checkValue?.length > 0) {
          checkEvidenceSubmitable = checkEvidenceSubmitable;
        } else {
          checkEvidenceSubmitable = false;
        }
      } else if (evidence?.evidenceType?.fieldTpe === "input") {
        let checkValue = evidenceDetail?.evidenceDetail?.data[
          evidence.inventoryID.value
        ]
          ? evidenceDetail?.evidenceDetail?.data[evidence.inventoryID.value]
          : "";
        if (checkValue?.length > 0) {
          checkEvidenceSubmitable = checkEvidenceSubmitable;
        } else {
          checkEvidenceSubmitable = false;
        }
      } else if (evidence?.evidenceType?.fieldTpe === "textarea") {
        let checkValue = evidenceDetail?.evidenceDetail?.data[
          evidence.inventoryID.value
        ]
          ? evidenceDetail?.evidenceDetail?.data[evidence.inventoryID.value]
          : "";
        if (checkValue?.length > 0) {
          checkEvidenceSubmitable = checkEvidenceSubmitable;
        } else {
          checkEvidenceSubmitable = false;
        }
      } else {
      }
    }
  });
  return checkEvidenceSubmitable;
};
