/*eslint/disable*/
import React from "react";

const DropdownTag = ({ stepsCMS, data }) => {
  let group = JSON.parse(
    localStorage.getItem("businessPremisesContactHandler")
  ).filter((contact) => data?.inputTypeValue?.includes(contact?.contact_id));
  return (
    <>
      <div className="flex border py-1 border-black">
        <h1 className="text-normal font-bold text-black w-1/2 border-r pl-1 border-black">
          {stepsCMS[data?.id]?.subTitle}
        </h1>
        <p className="pl-2">
          {group?.length > 0 &&
            group?.map((contact, i) => {
              return i == 0
                ? contact?.title + " " + contact?.first_name + " " + contact?.last_name
                : ", " +  contact?.title + " " + contact?.first_name + " " + contact?.last_name;
            })}
        </p>
      </div>
    </>
  );
};

export default DropdownTag;
