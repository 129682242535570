import { getApiCall } from "src/services/utils/axios";
import { API, BASE_URL } from "src/services/utils/defines";

export const GET_HELPDESK_LIST = async (paramsData) => {
    const url = `${BASE_URL}${API.GET_HELPDESK_LIST}`;
    try {
      const response = await getApiCall(url, paramsData);
      return response;
    } catch ({ response }) {
      throw new Error(response?.data?.error?.message);
    }
  };

export const GET_HELPDESK_DETAIL = async (paramsData) => {
    const url = `${BASE_URL}${API.GET_HELPDESK_DETAIL}`;
    try {
      const response = await getApiCall(url, paramsData);
      return response;
    } catch ({ response }) {
      throw new Error(response?.data?.error?.message);
    }
  };

export const GET_HELPDESK_CONTENT = async (paramsData) => {
    const url = `${BASE_URL}${API.GET_HELPDESK_CONTENT}`;
    try {
      const response = await getApiCall(url, paramsData);
      return response;
    } catch ({ response }) {
      throw new Error(response?.data?.error?.message);
    }
  };