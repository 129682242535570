import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const NavSubLink = ({ link, handleMenuClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const checkHyperLink = (pathname) => {
    if (typeof pathname == "string") {
      return location.pathname === pathname ? "text-black hover:text-black bg-BrightGray" : "text-gray-500 hover:text-gray-800 hover:bg-BrightGray";
    } else {
      if ((pathname[1].split("/")[1] === location.pathname.split("/")[1]) || (pathname[1].split("/")[1] === location.pathname.split("/")[2]) || (pathname[1].split("/")[1] === location.pathname.split("/")[3])) {
        return "text-black hover:text-black";
      }
      for (let i = 0; i < pathname.length; i++) {
        if (location.pathname === pathname[i]) {
          return "text-black hover:text-black";
        }
      }
      return "text-gray-500 hover:text-gray-800 hover:bg-BrightGray";
    }
  };
  const clickLink = (pathname) => {
    navigate(pathname);
  };
  return (
    <li className="items-center">
      <div
        className={`sideNavcontainerChild ${checkHyperLink(link?.link)}`}
        onClick={() => {
          clickLink(link?.click);
          handleMenuClose()
        }}
      >
        {link?.link.includes(location.pathname.replace('/admin','')) ? (
          <>
            {link.icon[0]} {link.title}
          </>
        ) : (
          <>
            {link.icon[1]} {link.title}
          </>
        )}
      </div>
    </li>
  );
};

NavSubLink.defaultProps = {
  link: {
    link: "",
    click: "",
    icon: ["", ""],
    title: ""
  },
  handleMenuClose: () => {}
};

export default NavSubLink;
