/* eslint-disable */
import "src/components/pages/dform/DynamicFormSteps/stepper.css";
import React, { useLayoutEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Stepper, StepLabel, Step } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { useSelector } from "react-redux";

const DynamicStepper = ({ steps, activeStep, stepChangeHandle }) => {
  let percentage = JSON.parse(localStorage.getItem("percentageStep"));
  const scrollRef = useRef();

  const connectorRef = useRef(null);
  const [lineWidth, setLineWidth] = useState(0);

  useLayoutEffect(() => {
    const updateLineWidth = () => {
      if (connectorRef.current) {
        const lineElement = connectorRef.current.querySelector(`.${stepConnectorClasses.line}`);
        if (lineElement) {
          setLineWidth(lineElement.offsetWidth);
        }
      }
    };

    updateLineWidth();
    window.addEventListener("resize", updateLineWidth);

    return () => window.removeEventListener("resize", updateLineWidth);
  }, []);

  useLayoutEffect(() => {
    const scrollRefAnimate = scrollRef.current.getBoundingClientRect().top;
    const onScroll = () => {
      if (window.scrollY > 0) {
        scrollRef.current.style.position = "fixed";
        scrollRef.current.style.top = "5rem";
        scrollRef.current.style.left = 0;
        scrollRef.current.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
      } else {
        scrollRef.current.style.top = 0;
        scrollRef.current.style.position = "relative";
        scrollRef.current.style.boxShadow = "none";
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#1C9D5B",
        ...(steps.length - 1 === 5 &&
          percentage === 5 && {
            borderColor: "red",
          }),
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#1C9D5B",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 2,
      borderRadius: 1,
      marginTop: "10px",
      marginLeft: "-10px",
      position: "relative",
    },
  }));

  const CompletionPercentage = styled("span")({
    position: "absolute",
    transform: "translate(0, 0)",
    fontWeight: "600 !important",
  });

  const completionPercentage = Math.round((percentage / (steps.length - 1)) * 100);

  return (
    <div className={`pl-4 md:pl-8 lg:pl-16 pr-2 md:pr-3 lg:pr-5 w-full inside bg-white pb-10 pt-6`} ref={scrollRef}>
      <div className="mx-3 sm:mx-4 md:mx-8">
        <Box className="w-full  pr-[150px] pt-[1.8rem]">
          <Stepper activeStep={activeStep} connector={<QontoConnector ref={connectorRef} />}>
            {steps.map((label, index) => (
              <Step key={label} onClick={() => stepChangeHandle(index)}>
                <StepLabel
                  StepIconComponent={(props) => <StepIcon {...props} index={index} percentage={percentage} />}
                  classes={{
                    label: `step-label ${index <= percentage ? "isActive" : ""} ${index === 5 && percentage === 5 ? "lastIndex" : ""}`,
                  }}
                  className="stepperIcons"
                >
                  {label}
                  <div className="percentageWrapper">
                    <div
                      className="text-sm lg:text-[16px] absolute font-bold w-full flex justify-center "
                      style={{
                        bottom: "-50px",
                        left: `-${lineWidth / 2 + 12}px`,
                        transform: "translate(-50%, 0)",
                      }}
                    >
                      {index !== 0 && index === percentage && index !== 5 && (
                        <>
                          {completionPercentage}% {completionPercentage >= 60 && completionPercentage <= 80 && " Fast Geschafft!"}
                        </>
                      )}
                    </div>
                  </div>
                </StepLabel>
                {/* {index !== 0 && index === percentage && index !== 5 && (
                  <CompletionPercentage className={`percetage ${index === percentage && index !== 0 ? "active-color" : ""}`}>{completionPercentage}%</CompletionPercentage>
                )} */}
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
    </div>
  );
};

const StepIcon = (props) => {
  const { active, completed, index, percentage } = props;
  const application = useSelector((state) => state.application);
  const backgroundColor = index === 5 && percentage === 5 ? "red" : active ? "#1C9D5B" : completed ? "#1C9D5B" : "#D9D9D9";
  return (
    <div
      style={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        cursor:
          application?.sendEmail?.isLoading || application?.createdApplication?.isLoading || application?.addingStep?.isLoading
            ? ""
            : index <= percentage &&
              application?.dformStatus?.data?.data?.status !== "completed" &&
              application?.dformStatus?.data?.data?.status !== "signatures-completed" &&
              application?.createdApplication?.isLoading !== true
            ? "pointer"
            : "",
        // backgroundColor: active ? "#1C9D5B" : completed ? "#1C9D5B" : "#D9D9D9",
        backgroundColor: backgroundColor,

        border: completed && "2px solid #1C9D5B",
      }}
    />
  );
};

export default DynamicStepper;
