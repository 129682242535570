import { useEffect, useState } from "react";
import BackdropLoader from "../../loader/BackdropLoader";

const BackdropSubmit = ({ btnTitle, endIcon, handler, type, loading, css, btnCss, borderRadius, disabled=false }) => {
  const [backdropOpen, setBackdropOpen] = useState(false);

  const backdropOpenHandle = () => {
    setBackdropOpen(true);
  };
  const backdropCloseHandle = () => {
    setBackdropOpen(false);
  };

  useEffect(() => {
    if (loading) {
      backdropOpenHandle();
    } else {
      backdropCloseHandle();
    }
  }, [loading]);

  return (
    <>
      <div className={css}>
        <button disabled={loading||disabled} onClick={handler} type={type ? type : "button"} className={`defaultBtn ${btnCss} ${disabled &&"text-gray-500 bg-white border-SmokeyGray border hover:bg-white cursor-not-allowed hover:!font-tahoma"}`}>
          <span>
            {btnTitle}
            {endIcon}
          </span>
        </button>
      </div>
      <BackdropLoader open={backdropOpen} borderRadius={borderRadius} />
    </>
  );
};

BackdropSubmit.defaultProps = {
  btnTitle: "Submit",
  borderRadius: 0,
  type: "button",
  loading: false,
  css: "",
  btnCss: "",
  handler: () => {},
};

export default BackdropSubmit;
