import { Paper, Table, TableContainer } from "@mui/material";
import MHead from "./MHead";


const MTable = ({ headList, tableCss, children }) => {
  return (
    <TableContainer component={Paper} className={tableCss}>
      <Table aria-label="simple table">
        <MHead headList={headList} />
        {children}
      </Table>
    </TableContainer>
  );
};

export default MTable;
