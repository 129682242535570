import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Admin = ({ children }) => {
  const profile = useSelector((state) => state.profile);
  if (profile?.data?.role) {
    if (profile?.data?.role !== "admin") {
      if (window.location.pathname.includes("admin")) {
        return <Navigate to="/" replace />;
      }
    }
  }
  return children;
};

export default Admin;
