/* eslint-disable */
import React, { useEffect, useRef } from "react";
import CameraEvidenceUpload from "./CameraEvidenceUpload";
import toast from "react-hot-toast";
const CameraEvidence = ({ uploadedImage, setUploadedImage, showCamera, setShowCamera, cameraAccess, setCameraAccess }) => {
  const mediaStreamRef = useRef(null);
  const handleRequestCameraAccess = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        mediaStreamRef.current = stream;
        setCameraAccess(true);
      })
      .catch((error) => {
        setCameraAccess(false);
        if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
          toast.error("Keine Webcam gefunden. Bitte schließen Sie eine Webcam an.");
        } else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
          toast.error("Die Berechtigung zum Zugriff auf die Kamera wurde verweigert. Bitte erlauben Sie den Zugriff auf die Kamera.");
        } else {
          toast.error("Fehler Kamerazugriff: " + error.message);
        }
      });
  };

  useEffect(() => {
    if (cameraAccess === false) {
      const retryInterval = setInterval(() => {
        navigator.permissions.query({ name: "camera" }).then((permissionStatus) => {
          if (permissionStatus.state === "granted") {
            handleRequestCameraAccess();
            clearInterval(retryInterval);
          }
        });
      }, 3000);

      return () => clearInterval(retryInterval);
    }
  }, [cameraAccess]);

  useEffect(() => {
    handleRequestCameraAccess();
    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return <>{cameraAccess && <CameraEvidenceUpload uploadedImage={uploadedImage} setUploadedImage={setUploadedImage} showCamera={showCamera} setShowCamera={setShowCamera} />}</>;
};
export default CameraEvidence;
