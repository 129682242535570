import { MESSAGE_LIST, SET_MESSAGE_LIST, MESSAGE_LIST_FAILURE, MESSAGE_READ, MESSAGE_READ_SUCCESS, MESSAGE_READ_FAILURE } from "../actionTypes";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  readMessage: {
    data: null,
    error: null,
    isLoading: false,
  }
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_LIST: {
      return { ...state, isLoading: true, error: null };
    }
    case SET_MESSAGE_LIST: {
      return { ...state, isLoading: false, data: action.payload, error: null };
    }
    case MESSAGE_LIST_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case MESSAGE_READ: {
      return { ...state, readMessage:{...state.readMessage, isLoading: true, error: null } };
    }
    case MESSAGE_READ_SUCCESS: {
      return { ...state, readMessage:{...state.readMessage, isLoading: false, data: action.payload, error: null } };
    }
    case MESSAGE_READ_FAILURE: {
      return { ...state, readMessage:{...state.readMessage, loading: false, error: action.payload } };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
